import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";

import { MaterialListResponse } from "Api/Responses/MaterialResponse";
import { Button, Checkbox, Icon, Icons, PopoverList, SelectMultiValueRemove, SelectValueContainer } from "Uikit";
import { Table } from "Uikit/Table/Table";
import { MaterialsRowActionPopover } from "./MaterialsRowActionPopover";
import Api from "Api";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { MaterialType } from "Enums";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { useDialog } from "hooks/useDialog";
import { MaterialChangeStateRequest } from "Api/Requests/MaterialRequest";
import { EmptyMaterialList } from "./EmptyMaterialList";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { useInvalidate } from "hooks/useInvalidate";
import { AccountableUser, UserListResponse } from "Api/Responses/UserResponse";
import { TVoidFunction } from "types";
import { LogoSize } from "Api/Services/UploadApi";
import { useResponsibleList } from "Api/Hooks/useResponsibleList";

const materialTypes: { [key: string]: string } = {
    VIDEO: "Видео",
    DOCUMENT: "Документ",
    ARTICLE: "Статья",
    SCORM: "SCORM",
};

interface MaterialTableProps {
    categoryId?: string;
    materials: MaterialListResponse[];
    refetchMaterials: TVoidFunction;
    pageCount: number;
    pagination: PaginationState;
    sorting: SortingState;
    filters: { [id: string]: any };
    setPagination: (pagination: SetStateAction<PaginationState>) => void;
    setSorting: (sort: SetStateAction<SortingState>) => void;
    setFilters: (filter: SetStateAction<{ [id: string]: any }>) => void;
    isFetching: boolean;
    handleAddCategory: TVoidFunction;
}

export const MaterialsTable = ({
    categoryId,
    materials,
    refetchMaterials,
    pageCount,
    pagination,
    sorting,
    filters,
    setPagination,
    setSorting,
    setFilters,
    isFetching,
    handleAddCategory,
}: MaterialTableProps) => {
    const navigate = useNavigate();
    const { dialogState, openDialog, closeDialog } = useDialog();
    const invalidate = useInvalidate();

    const [isSelectedState, setIsSelectedState] = useState<boolean>(false);
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [rowSelection, setRowSelection] = useState<{ [key: number]: boolean }>({});
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [isFilterShow, setIsFilterShow] = useState(false);
    const typesOptions = useMemo(
        () => Object.keys(materialTypes).map((key) => ({ value: key, label: materialTypes[key] })),
        [],
    );

    const [ratingRange, setRatingRange] = useState({ minValue: 0, maxValue: 0 });

    const isFiltersEmpty = useMemo(() => {
        if (Object.keys(filters).indexOf("searchQuery.contains") !== -1) {
            return false;
        }

        return Object.keys(filters).every((f) => !filters[f]);
    }, [filters]);

    const fetchRatingPoints = async () => {
        try {
            const ratingPoints = await Api.Material.GetFilterRating();

            setRatingRange({ minValue: ratingPoints.minRatingPoints, maxValue: ratingPoints.maxRatingPoints });
        } catch (error) {
            console.log(error);
        }
    };

    const onSearch = (searchQuery: string) => {
        setFilters((prevState) => ({ ...prevState, "searchQuery.contains": searchQuery }));
    };

    const onCopy = useCallback(
        (material: MaterialListResponse) => {
            closeDialog();
            navigate(`/admin/material/copy/${material.type}/${material.id}?editMode=true`);
        },
        [closeDialog, navigate],
    );

    const onCopyHandler = useCallback(
        (material: MaterialListResponse) => {
            openDialog({
                title: "Создание копии материала",
                description: "«" + material.title + "»",
                content:
                    "Вы уверены, что хотите создать копию материала? Копируется весь материал со всеми настройками",
                closeBtnText: "Отмена",
                submitBtnText: "Создать копию",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => onCopy(material),
            });
        },
        [openDialog, closeDialog, onCopy],
    );

    const onArchive = useCallback(
        async (data: string[]) => {
            try {
                const materialArchiveRequest = new MaterialChangeStateRequest();
                materialArchiveRequest.resourceIds = data;
                materialArchiveRequest.state = "ARCHIVED";

                await Api.Material.changeState(materialArchiveRequest);
                setRowSelection({});
                refetchMaterials();
            } catch (error) {
                console.log(error);
            }
            closeDialog();
        },
        [closeDialog, refetchMaterials],
    );

    const onArchiveHandler = useCallback(
        (data: MaterialListResponse | string[]) => {
            const rows = Array.isArray(data) ? data : [data.id];
            let materialTitle = "";

            if (!Array.isArray(data)) {
                materialTitle = data.title;
            }

            if (Array.isArray(data) && data.length === 1) {
                materialTitle = materials.find((material) => material.id === rows[0])!.title;
            }

            openDialog({
                title: Array.isArray(data) && data.length > 1 ? "Архивирование материалов" : "Архивирование материала",
                description:
                    Array.isArray(data) && data.length > 1
                        ? `Выбрано материалов: ${data.length}`
                        : `«${materialTitle}»`,
                content:
                    Array.isArray(data) && data.length > 1
                        ? "Вы действительно хотите архивировать материалы? Материалы переместятся в Архив и исчезнут у пользователей"
                        : "Вы действительно хотите архивировать материал? Материал переместится в Архив и исчезнет у пользователей",
                closeBtnText: "Отмена",
                submitBtnText: "Архивировать",
                submitBtnColor: "danger",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => onArchive(rows).then(),
            });
        },
        [closeDialog, openDialog, onArchive, materials],
    );

    const onState = useCallback(
        async (data: string[], state: string) => {
            try {
                const materialStateRequest = new MaterialChangeStateRequest();
                materialStateRequest.resourceIds = data;
                materialStateRequest.state = state;

                await Api.Material.changeState(materialStateRequest);
                setRowSelection({});
                refetchMaterials();
            } catch (error) {
                console.log(error);
            }
            closeDialog();
        },
        [closeDialog, refetchMaterials],
    );

    const onStateHandler = useCallback(
        (state: string) => {
            setIsSelectedState(false);
            const ids = Object.keys(rowSelection);
            const isMultiple = ids.length > 1;
            const singleMaterialTitle = !isMultiple
                ? "«" + materials.find((i) => i.id === ids[0])!.title + "»"
                : undefined;

            openDialog({
                title: `Изменить статус на «${state === "ACTIVE" ? "Активен" : "Скрыт"}»`,
                description: isMultiple ? `Выбрано материалов: ${ids.length}` : singleMaterialTitle,
                content:
                    state === "ACTIVE"
                        ? `Все пользователи, у кого есть доступ, увидят ${
                              !isMultiple ? "данный материал" : "данные материалы"
                          }`
                        : `У всех пользователей ${
                              !isMultiple ? "пропадет данный материал" : "пропадут данные материалы"
                          }`,
                closeBtnText: "Отмена",
                submitBtnText: "Изменить",
                submitBtnColor: "primary",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onState(ids, state).then(() => {
                        invalidate("materials");
                        closeDialog();
                    });
                },
            });
        },
        [invalidate, closeDialog, openDialog, rowSelection, onState, materials],
    );

    const responsibleList = useResponsibleList();
    const loadResponsibleListOptions = useCallback(
        async (inputValue: string): Promise<any> => {
            if (inputValue === "") {
                return {
                    options: [],
                    hasMore: false,
                };
            }

            const selectOptions = responsibleList
                .filter((p) => p.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)
                .map((p) => ({ label: p.label, value: p.payload }));

            return {
                options: selectOptions,
                hasMore: false,
            };
        },
        [responsibleList],
    );

    const formatUserOptionsLabel = ({
        label,
        value,
    }: {
        label: string;
        value: AccountableUser | UserListResponse | undefined;
    }): React.ReactElement => {
        return (
            <div className="flex gap-2">
                {value?.avatarId ? (
                    <img
                        src={`/service/lms-upload/api/file/download/${value.avatarId}`}
                        className="w-5 h-5 rounded-full"
                    />
                ) : (
                    <div
                        className={`flex-center w-5 h-5 rounded-full`}
                        style={{ backgroundColor: value?.defaultAvatarColor }}
                    >
                        <span className="text-white text-xxs">
                            {value?.firstName[0]}
                            {value?.lastName[0]}
                        </span>
                    </div>
                )}
                <div>{label}</div>
            </div>
        );
    };

    const columns = React.useMemo<ColumnDef<MaterialListResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "Название",
                accessorKey: "title",
                className: "w-1/2",
                cell: (info) => {
                    return (
                        <div
                            onClick={() =>
                                navigate(`/admin/material/${info.row.original.type}/${info.row.original.id}`)
                            }
                            className="group flex items-center space-x-3 cursor-pointer"
                        >
                            <div>
                                <div
                                    className="bg-cover bg-center w-[54px] h-9 rounded-md"
                                    style={{
                                        backgroundImage: `url('${
                                            info.row.original.logoId
                                                ? Api.Upload.GetLogo(info.row.original.logoId, LogoSize.THUMB_MICRO)
                                                : "/img/article-card-cover.jpg"
                                        }')`,
                                    }}
                                ></div>
                            </div>
                            <MultiClumpTooltip
                                className="group-hover:text-blue"
                                clamp={1}
                                label={String(info.getValue())}
                            />
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Статус",
                accessorKey: "state",
                cell: (info) =>
                    info.getValue() === "ACTIVE" ? "Активен" : <span className="text-gray-text">Скрыт</span>,
                footer: (props) => props.column.id,
            },
            {
                header: "Тип",
                accessorKey: "type",
                cell: (info) => materialTypes[info.row.original.type],
                footer: (props) => props.column.id,
            },
            {
                header: "Баллы",
                accessorKey: "ratingPoints",
                footer: (props) => props.column.id,
                cell: (info) => {
                    return <div>{String(info.getValue())}</div>;
                },
            },
            {
                header: "Обновлен",
                accessorKey: "modifyTimestamp",
                enableResizing: true,
                size: 200,
                cell: (info) => {
                    return formatDate(new Date(Number(info.getValue()) * 1000), DateFormat.DATE_TIME_LONG);
                },
                footer: (props) => props.column.id,
            },
            {
                header: "",
                id: "buttons",
                accessor: "[row identifier to be passed to button]",
                enableResizing: true,
                size: 90,
                cell: ({ row }) => {
                    return (
                        <div className="flex" id={"adminMaterialsTableGroupButton" + row.id}>
                            <Button
                                shape={"round"}
                                color={"common"}
                                icon={
                                    <Icon
                                        icon={Icons.Pencil}
                                        width="18px"
                                        height="18px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement={"center"}
                                onClick={() =>
                                    navigate(`/admin/material/${row.original.type}/${row.original.id}?editMode=true`)
                                }
                            />

                            <MaterialsRowActionPopover
                                material={row.original}
                                onCopyHandler={onCopyHandler}
                                onArchiveHandler={onArchiveHandler}
                            />
                        </div>
                    );
                },
            },
        ],
        [navigate, onArchiveHandler, onCopyHandler],
    );

    const materialFilters = useMemo(
        () => [
            {
                label: "Дата обновления",
                fields: [
                    {
                        accessor: "modifyTimestamp",
                        type: "date-range",
                    },
                ],
            },
            {
                label: "Награда, баллы",
                fields: [
                    {
                        accessor: "ratingPoints",
                        type: "range",
                        min: ratingRange.minValue,
                        max: ratingRange.maxValue,
                    },
                ],
            },
            {
                label: "Тип материала",
                fields: [
                    {
                        accessor: "type.in",
                        type: "multi-select",
                        placeholder: "Выберите тип",
                        options: typesOptions,
                    },
                ],
            },
            {
                label: "Ответственный",
                fields: [
                    {
                        accessor: "managerUserId.in",
                        type: "async-multi-select",
                        placeholder: "Выберите ответственного",
                        loadOptions: loadResponsibleListOptions,
                        formatOptionLabel: formatUserOptionsLabel,
                        components: {
                            MultiValueRemove: SelectMultiValueRemove,
                            ValueContainer: SelectValueContainer({}),
                        },
                        lazy: true,
                    },
                ],
            },
            {
                label: "Дополнительно",
                fields: [
                    {
                        accessor: "courseId.isNull",
                        label: "Не задействованы в курсах",
                        type: "checkbox",
                        default: false,
                    },
                ],
            },
        ],
        [ratingRange, typesOptions, loadResponsibleListOptions],
    );

    const onCreateHandler = (e: MouseEvent, name: string) => {
        navigate(`/admin/material/add/${name}` + (categoryId ? `?categoryId=${categoryId}` : ""));
    };

    const controlButtons = (
        <div className="flex justify-end items-center space-x-4">
            {/* <Button variant="outline" color="common" size="medium" className="border-[#E6E9ED] rounded-lg font-medium">
                Импорт
            </Button> */}

            {/*<Button*/}
            {/*    variant="outline"*/}
            {/*    color="secondary"*/}
            {/*    size="medium"*/}
            {/*    className="border-[#E6E9ED] rounded-lg font-medium"*/}
            {/*    icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}*/}
            {/*    iconPlacement={"left"}*/}
            {/*    onClick={() => {*/}
            {/*        setIsFilterShow(true);*/}
            {/*    }}*/}
            {/*    id="adminMaterialsBtnFilter"*/}
            {/*>*/}
            {/*    Фильтры*/}
            {/*</Button>*/}

            <PopoverList
                offset={[0, 18]}
                visible={isCreateOpen}
                onClickOutside={() => setIsCreateOpen(false)}
                className=""
            >
                <Button
                    size={"medium"}
                    className="rounded-lg whitespace-nowrap font-medium"
                    icon={<Icon icon={Icons.PlusFilled} color="fill-white" width={20} height={20} />}
                    iconPlacement={"left"}
                    onClick={() => setIsCreateOpen(true)}
                    id="adminMaterialBtnCreateMaterial"
                >
                    Создать материал
                </Button>
                <PopoverList.Item name={String(MaterialType.Article)} onClickItem={onCreateHandler}>
                    Статья
                </PopoverList.Item>
                <PopoverList.Item name={String(MaterialType.Video)} onClickItem={onCreateHandler}>
                    Видео
                </PopoverList.Item>
                {/*<PopoverList.Item name={String(MaterialType.Document)} onClickItem={onCreateHandler}>*/}
                {/*    Документ*/}
                {/*</PopoverList.Item>*/}
                {/*<PopoverList.Item name={String(MaterialType.SCORM)} onClickItem={onCreateHandler}>*/}
                {/*    SCORM*/}
                {/*</PopoverList.Item>*/}
                {/* <PopoverList.Item name={String(MaterialType.HTML)} onClickItem={onCreateHandler}>
                    HTML
                </PopoverList.Item> */}
            </PopoverList>
        </div>
    );

    const selectedRowButtons = (
        <div className="flex items-center space-x-5">
            <Button
                onClick={() => onArchiveHandler(Object.keys(rowSelection))}
                color="danger"
                size="medium"
                id="adminMaterialsBtnArchive"
            >
                Архивировать
            </Button>
            <PopoverList offset={[0, 10]} visible={isSelectedState} onClickOutside={() => setIsSelectedState(false)}>
                <Button
                    variant="outline"
                    size="medium"
                    color="common"
                    className="border-gray-blue"
                    icon={<Icon className="" icon={Icons.ChevronDown} width={10} height={6} />}
                    iconPlacement="right"
                    onClick={() => setIsSelectedState(true)}
                    id="adminMaterialsBtnChangeStatus"
                >
                    Изменить статус
                </Button>
                <PopoverList.Item name="setStatusHide" onClickItem={() => onStateHandler("HIDDEN")}>
                    Скрыт
                </PopoverList.Item>
                <PopoverList.Item name="setStatusActive" onClickItem={() => onStateHandler("ACTIVE")}>
                    Активен
                </PopoverList.Item>
            </PopoverList>
        </div>
    );

    useEffect(() => {
        fetchRatingPoints().then();
    }, []);

    useEffect(() => {
        setRowSelection({});
    }, [categoryId]);

    return (
        <>
            <Confirmation {...dialogState} />

            {isFiltersEmpty && materials && materials.length === 0 && (
                <EmptyMaterialList
                    isCategories={!!categoryId}
                    onCreateMaterial={onCreateHandler}
                    onCreateCategory={handleAddCategory}
                />
            )}

            {!isFiltersEmpty || (materials && materials.length > 0) ? (
                <>
                    <Table
                        id="adminMaterials"
                        columns={columns}
                        searchTitle="Поиск по названию"
                        pageCount={pageCount}
                        data={materials}
                        pagination={pagination}
                        onPaginationChange={setPagination}
                        isFetching={isFetching}
                        rowSelection={rowSelection}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        columnFilters={columnFilters}
                        controlButtons={controlButtons}
                        selectButtons={selectedRowButtons}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        onSearch={onSearch}
                    />
                    <Filter
                        isActive={isFilterShow}
                        setIsActive={setIsFilterShow}
                        configuration={materialFilters as IFilterRow[]}
                        filters={filters}
                        onChange={setFilters}
                    />
                </>
            ) : null}
        </>
    );
};
