import React, { useState } from "react";
import clsx from "clsx";
import {
    TeamUser,
    TeamInUserSpaceResponse,
    TeamTreeCurrentUserResponseTeam,
    TeamTreeCurrentUserResponseTeamUser,
} from "Api/Responses/TeamResponse";
import { numWord } from "helpers/numWord";
import { Widget } from "Components/Widget";
import { Icons, Icon } from "Uikit";
import { TeamUsers } from "../Team/TeamUsers";
import { useNavigate } from "react-router-dom";

interface ITeamsListProps {
    title?: string;
    teams: TeamInUserSpaceResponse[] | TeamTreeCurrentUserResponseTeam[];
    onClick?: (id: string) => void;
    count?: number;
    countLabel?: string;
    limitDisplayed?: number;
    teamId?: string;
    scrollable?: boolean;
}

export const sortUsers = (list: TeamUser[] | TeamTreeCurrentUserResponseTeamUser[]) => {
    if (Array.from(new Set((list as TeamUser[]).map(({ rating }) => rating))).length > 1) {
        // Если рейтинг неодинаковый
        return (list as TeamUser[]).sort((a, b) => b.rating - a.rating);
    } else {
        // Иначе - сортировка по алфавиту
        return list.sort((a, b) => {
            if (a.firstName > b.firstName) {
                return 1;
            }
            if (b.firstName > a.firstName) {
                return -1;
            }
            return 0;
        });
    }
};

export const TeamsList = ({
    title,
    teams,
    onClick,
    count,
    countLabel,
    teamId,
    limitDisplayed,
    scrollable,
}: ITeamsListProps) => {
    const navigate = useNavigate();

    const [teamsDisplayedLength, setTeamsDisplayedLength] = useState(limitDisplayed ?? teams?.length);
    const isExpandable = limitDisplayed && limitDisplayed < teams?.length;

    return (
        <div>
            {title && (
                <div
                    className={`flex items-center justify-between gap-5 2xl:gap-6.25 mb-5 2xl:mb-6.25 w-full ${
                        isExpandable ? "cursor-pointer" : ""
                    }`}
                    onClick={
                        limitDisplayed
                            ? () => {
                                  setTeamsDisplayedLength(
                                      teamsDisplayedLength === limitDisplayed ? teams?.length : limitDisplayed,
                                  );
                              }
                            : undefined
                    }
                >
                    <h2 className="hidden sm:block 2xl:!text-3xl 2xl:!leading-[35px]">{title}</h2>
                    <h3 className="block sm:hidden">{title}</h3>
                    {countLabel && (
                        <p className="hidden sm:block text-sm text-gray-light leading-4 2xl:text-md">{countLabel}</p>
                    )}
                    {count && teamId && (
                        <p className="block sm:hidden text-blue p1" onClick={() => navigate(`/team/${teamId}/nested`)}>
                            Все {count}
                        </p>
                    )}
                    {isExpandable && (
                        <div
                            className={clsx(
                                "w-7.5 2xl:w-[38px] h-7.5 2xl:h-[38px] ml-auto rounded-full flex flex-col items-center justify-center bg-background-light",
                                teamsDisplayedLength === limitDisplayed && "rotate-180",
                            )}
                        >
                            <Icon
                                icon={Icons.ChevronUp}
                                width={15}
                                height={15}
                                color={"fill-blue-drk"}
                                className="2xl:!w-4.5 2xl:!h-4.5"
                            />
                        </div>
                    )}
                </div>
            )}
            <div
                className={clsx(
                    "flex sm:grid flex-col gap-2 sm:gap-x-6.5 2xl:gap-x-8 gap-y-6 2xl:gap-y-7.5 sm:grid-cols-4",
                    scrollable && "scrollbar-hidden !flex-row overflow-auto w-[calc(100%+12px)]",
                )}
            >
                {teams?.slice(0, teamsDisplayedLength).map((team) => {
                    const {
                        // mentors = [],
                        users = [],
                        name,
                    } = team as TeamTreeCurrentUserResponseTeam;
                    const { usersCount = users.length } = team as TeamInUserSpaceResponse;

                    return (
                        <div key={`${team.id}`} className={scrollable ? "min-w-68.5" : ""}>
                            <Widget
                                title={name}
                                onClick={() => {
                                    onClick?.(team.id);
                                }}
                            >
                                {/* <Widget.Chart
                              charts={[
                                  {
                                    state: 'passed',
                                    size: 0.7
                                  },
                                  {
                                    state: 'failed',
                                    size: 0.8
                                  },
                                  {
                                    state: 'processed',
                                    size: 0.9
                                  },
                              ]}
                              count={120}
                          /> */}
                                <div className="flex flex-col gap-2 2xl:gap-2.5">
                                    {/*{mentors.length > 0 && (*/}
                                    {/*    <TeamUsers*/}
                                    {/*        usersCount={team.mentorsCount}*/}
                                    {/*        users={sortUsers(mentors).slice(0, 4)}*/}
                                    {/*        label={numWord(mentors.length, ["наставник", "наставника", "наставников"])}*/}
                                    {/*        fullListLength={mentors.length}*/}
                                    {/*    />*/}
                                    {/*)}*/}
                                    <TeamUsers
                                        usersCount={team.usersCount}
                                        users={sortUsers(users).slice(0, 4)}
                                        label={numWord(usersCount, ["сотрудник", "сотрудника", "сотрудников"])}
                                        fullListLength={usersCount}
                                    />
                                </div>
                            </Widget>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
