import React, { useCallback, useEffect, useRef, useState } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import Api from "Api/index";
import { Loader } from "Uikit/Loader/Loader";
import { Search } from "Uikit/Search";
import { useDebounceValue } from "hooks/useDebounceValue";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "../../../slices/headerSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { LogoSize } from "Api/Services/UploadApi";

export const PersonalProgress = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [search, setSearch] = useState("");
    const [debounceSearch] = useDebounceValue(search, 500);

    const observerElem = useRef<HTMLDivElement>(null);

    const { data: user } = useQuery(["users", "personal", id], () =>
        id ? Api.User.Read(id) : Api.User.GetCurrentUser(),
    );
    const {
        data: progress,
        isLoading,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery(
        ["user-progress", "collection", debounceSearch, user?.id],
        ({ pageParam = 0 }) => {
            const filtersData: any = {};

            if (debounceSearch) {
                filtersData["searchQuery.contains"] = debounceSearch;
            }

            return Api.Statistic.GetUserProgress(
                user?.id ?? "",
                pageParam,
                15,
                [{ id: "id", desc: true }],
                filtersData,
            );
        },
        {
            getNextPageParam: (lastPage, allPages) => {
                const nextPage = allPages.length;
                return lastPage.Content.length !== 0 ? nextPage : undefined;
            },
        },
    );

    const handleObserver = useCallback(
        (entries: any) => {
            const [target] = entries;

            if (target.isIntersecting && hasNextPage) {
                fetchNextPage().then();
            }
        },
        [fetchNextPage, hasNextPage],
    );
    useEffect(() => {
        const element = observerElem.current;
        const option = { threshold: 0 };

        const observer = new IntersectionObserver(handleObserver, option);

        if (element) {
            observer.observe(element);
            return () => observer.unobserve(element);
        }
    }, [fetchNextPage, hasNextPage, handleObserver]);
    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Прогресс"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    if (!progress && !isLoading) {
        return null;
    }

    return (
        <div className="flex flex-col space-y-4 pt-3 px-3 w-full">
            <Search
                id="personalProgressSearchInput"
                placeholder="Поиск по названию"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
            />
            {progress?.pages.map((page, pageIndex) => {
                return (
                    <div key={pageIndex}>
                        {page.Content.map((item, index) => {
                            return (
                                <div key={index} className="flex mb-3 p-2 bg-background-light rounded-2xl">
                                    <img
                                        className="mr-2 w-20 h-14 rounded-lg object-cover"
                                        src={Api.Upload.GetLogo(item.logoId, LogoSize.THUMB_MICRO)}
                                        alt={item.title}
                                    />
                                    <div className="flex flex-col">
                                        <h5 className="mb-1 text-black">{item.title}</h5>
                                        <div className="flex">
                                            <span className="mr-3 p4 text-blue-drk">
                                                {/*{item.type === "PROGRAM" && "Программа"}*/}
                                                {item.type === "COURSE" && "Курс"}
                                                {/*{item.type === "EXERCISE" && "Задание"}*/}
                                                {/*{item.type === "QUIZ" && "Тест"}*/}
                                            </span>
                                            <span className="p4 text-blue-drk">
                                                {item.progressStatus === "PASSED" && "Пройдено"}
                                                {item.progressStatus === "ON_REVIEW" && "На проверке"}
                                                {item.progressStatus !== "PASSED" &&
                                                    item.progressStatus !== "ON_REVIEW" &&
                                                    "В процессе"}
                                                {/*{item.progressStatus !== "PASSED" */}
                                                {/*    && item.progressStatus !== "ON_REVIEW" */}
                                                {/*    && `В процессе (${item.progressPercentage}%)`}*/}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
            <div className="flex justify-center w-full" ref={observerElem}>
                {isFetchingNextPage && hasNextPage && (
                    <div className="py-4">
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
};
