import { flattenDeep } from "helpers/flattenDeep";

export class TeamModel {
    id: string;
    name: string;
    parentId: string;
    usersCount: number;
    // mentorsCount: number;
    accessible: boolean;
    subTeams: TeamModel[];
}
export const flattenTeams = (teams: TeamModel[]): TeamModel[] => flattenDeep<TeamModel>(teams, (team) => team.subTeams);
