import React, { ReactNode, ForwardedRef, forwardRef, useEffect } from "react";
import { Icon, Icons, flash } from "Uikit";
import { Tooltip } from "Uikit/Tooltip";
import { Accept, useDropzone } from "react-dropzone";
import { useCombinedRefs } from "hooks/useCombinedRef";
import { humanFileSize } from "helpers/humanFileSize";
import { FILES_TYPE_ICONS, FILES_TYPE_SHORT_NAME } from "constants/attachments";
import { ProgressBar } from "./ProgressBar";
import { TVoidFunction } from "types";
import { getFileSizeErrorMessage } from "helpers/file";
import { UIErrorMessages, WSEventVideoActionTypes } from "Enums";

export interface FileSizeByType {
    [key: string]: number;
}

interface FileUploadProps {
    className?: string;
    onChange?: (file: File) => void;
    onCancelUpload?: TVoidFunction;
    maxSize?: FileSizeByType | number;
    accept?: Accept;
    acceptDescription?: string;
    multiple?: boolean;
    attachment?: IFile;
    id?: string;
    videoProcessingStatus?: WSEventVideoActionTypes | null;
    showOptimizationModal?: TVoidFunction;
}

interface IFile extends File {
    extension?: string;
    uploaded?: number;
    uploadedPercent?: number;
    status?: number;
}

const TextWrapper = ({ children }: { children: ReactNode }) => {
    return <div className="text-gray-text">{children}</div>;
};

export const FileUpload = forwardRef(
    (
        {
            className,
            onChange,
            onCancelUpload,
            maxSize,
            accept = {},
            acceptDescription = "",
            multiple = false,
            attachment,
            id,
            videoProcessingStatus = null,
            showOptimizationModal,
        }: FileUploadProps,
        ref: ForwardedRef<HTMLInputElement>,
    ) => {
        const inputRef = useCombinedRefs(ref);
        const { getRootProps, getInputProps, fileRejections } = useDropzone({ onDrop, accept, onDropRejected });

        const [file, setFile] = React.useState<any>(attachment);

        const isMaxSizeValid = (file: File): boolean => {
            if (!maxSize) {
                return true;
            }

            if (typeof maxSize === "number") {
                return file.size < maxSize;
            }

            return Object.keys(maxSize).some((type) => {
                if (RegExp(type).exec(file.type)) {
                    return file.size < maxSize[type];
                }

                return false;
            });
        };

        function onDrop<T extends IFile>(files: T[]) {
            if (!files?.length) {
                return;
            }

            if (!Object.keys(accept).some((type) => RegExp(type).exec(files[0].type))) {
                flash.error("Для загрузки допустимы следующие форматы - MP4");

                return;
            }

            if (!isMaxSizeValid(files[0])) {
                flash.error(getFileSizeErrorMessage("2 ГБ"));

                return;
            }

            onChange?.(files[0]);
        }

        function onInputChange(e: any) {
            const {
                target: { files },
            } = e;

            if (!files?.length) {
                return;
            }

            if (!Object.keys(accept).some((type) => RegExp(type).exec(files[0].type))) {
                // flash.error("Для загрузки допустимы следующие форматы - MP4");
                flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);

                return;
            }

            if (!isMaxSizeValid(files[0])) {
                flash.error(getFileSizeErrorMessage("2 ГБ"));

                return;
            }

            onChange?.(files[0]);
        }

        function onDropRejected() {
            fileRejections.forEach((f) => {
                f.errors.forEach((e) => {
                    if (e.code === "file-invalid-type") {
                        flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);
                    }
                });
            });
        }

        useEffect(() => {
            setFile(attachment ?? undefined);
        }, [attachment]);

        const isVideo = (file?.file?.type || file?.contentType)?.includes("video") ?? false;

        return !file ? (
            <div
                {...getRootProps({
                    className:
                        "group flex flex-col items-center p-4.5 max-w-200 border border-dashed border-gray-light rounded-lg cursor-pointer hover:border-primary " +
                        className,
                })}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (videoProcessingStatus === WSEventVideoActionTypes.PROCESSING_IN_PROGRESS) {
                        showOptimizationModal?.();
                        return;
                    }

                    if (inputRef) {
                        inputRef.current.click();
                    }
                }}
                id={id + "Field"}
            >
                <input {...getInputProps({ multiple })} onChange={onInputChange} ref={inputRef} />
                <Icon
                    className="mb-2"
                    icon={Icons.Upload}
                    width="58px"
                    height="58px"
                    color="fill-gray-blue group-hover:fill-blue"
                />
                <div className="pb-1 text-gray-dark">
                    <span className="text-blue">Выберите файл</span> или перетащите мышью
                </div>
                <div className="text-xs text-gray-text">{acceptDescription}</div>
            </div>
        ) : (
            <div
                className="relative flex w-full items-center p-4 max-w-200 border border-gray-light rounded-lg"
                id={id + "Process"}
            >
                <div>
                    <Icon
                        className="mr-6"
                        icon={FILES_TYPE_ICONS[(file?.file || file)?.extension || ""] || Icons.FileOther}
                        width="32px"
                        height="32px"
                    />
                </div>
                <div className="w-full">
                    <div className="flex items-center w-full pb-0.5">
                        <div>{(file.file || file)?.name}</div>
                        {(String(videoProcessingStatus) !== WSEventVideoActionTypes.PROCESSING_COMPLETE ||
                            (file.status && file.status !== "done") ||
                            (!file.status && !file.disposableLink)) && (
                            <div
                                className="ml-auto cursor-pointer"
                                onClick={() => {
                                    onCancelUpload?.();
                                    setFile(null);
                                }}
                            >
                                <Icon icon={Icons.CloseBig} width={16} height={16} />
                            </div>
                        )}
                    </div>

                    {file.status === "done" && (
                        <TextWrapper>
                            {FILES_TYPE_SHORT_NAME[(file?.file || file)?.extension]} - {humanFileSize(file.size, true)}
                        </TextWrapper>
                    )}

                    {file.status === "loading" && (
                        <TextWrapper>
                            {`${humanFileSize(file.uploaded, true)} / ${humanFileSize(file.size, true)} – осталось ${
                                file.timeLeft
                            }`}
                        </TextWrapper>
                    )}

                    {isVideo && file.status === "done" && (
                        <Tooltip
                            className="flex"
                            content={
                                "Файл в очереди на конвертацию, это может занять много времени. Можно конвертировать файл самостоятельно и загрузить заново. Для этого надо отменить текущую загрузку."
                            }
                            maxWidth={465}
                        >
                            <TextWrapper>Видео обрабатывается...</TextWrapper>
                        </Tooltip>
                    )}

                    {/* <div className="text-gray-text">
                        {isVideo && !isVideoProcessed ? "Видео обрабатывается" : humanFileSize(file.size, true)}
                    </div> */}
                    {((file.status && file.status !== "done") || (!file.status && !file.disposableLink)) && (
                        <div className="mt-2">
                            <ProgressBar value={file.uploadedPercent || 0} />
                        </div>
                    )}
                </div>
            </div>
        );
    },
);
