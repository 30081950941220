import { FileUploadType, MaterialType } from "Enums";
import { humanFileSize } from "helpers/humanFileSize";
import React, { useEffect, useState } from "react";
import { FILES_TYPE_ICONS } from "constants/attachments";

import { FileUpload, flash, Icon, Icons, Tooltip } from "Uikit";
import { Section } from "Uikit/Forms/Section";
import { IMaterialForm } from "../../Material";
import { useUpload } from "hooks/useUpload";

interface MaterialAttachmentProps {
    attachment?: any;
    type: // MaterialType.SCORM |
    MaterialType.HTML;
    material?: IMaterialForm;
    onChange: (field: string, value: any) => void;
    isFormDirty?: boolean;
}

export const MaterialAttachment = ({ attachment, type, material, onChange, isFormDirty }: MaterialAttachmentProps) => {
    const [isUploading, setIsUploading] = useState(false);

    const hostname = window.location.hostname.includes("localhost")
        ? "http://localhost:8080"
        : window.location.protocol + "//" + window.location.hostname;

    const sectionTitle = {
        SCORM: "SCORM файл",
        HTML: "HTML5 файл",
    };

    const removeFile = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        setFile(null);
        onChange("fileId", null);

        onChange("file", null);
    };

    const openScormPlayerInNewWindow = () => {
        sessionStorage.setItem("currentMaterial", JSON.stringify(material));
        sessionStorage.setItem("scormViewMode", "admin");
        window.open(`/scorm/${attachment.id}`, "_blank");
    };

    const { upload } = useUpload();
    const [file, setFile] = useState<any>(attachment);

    function uploadFile(file: any) {
        if (!file) {
            return;
        }

        setIsUploading(true);
        onChange?.("fileId", null);
        onChange?.("file", null);

        const onUpdate = async (file: any) => setFile(file);
        const onFinished = async (file: any) => {
            onChange?.("fileId", file.serverData.id);
            onChange?.("file", file.serverData);

            setFile(file.serverData);
        };

        const extensionIdx = file.name.lastIndexOf(".");
        file["extension"] = file.name.slice(extensionIdx + 1);

        const u = upload({
            file: file,
            type: "scorm",
            onUpdate,
            onFinished,
            fileUploadType: FileUploadType.RESOURCE_ATTACHMENT,
        });

        u.start()
            .then(() => {
                setIsUploading(false);
                setFile(u);
            })
            .catch(() => {
                setFile(null);
                flash.error("Загруженный файл не является SCORM-курсом");
            });
    }

    function cancelUpload() {
        file?.cancel();
        setFile(attachment);
    }

    useEffect(() => {
        setFile(attachment);
    }, [attachment]);

    return (
        <Section label={sectionTitle[type]} isRequired={true}>
            {!file || isUploading ? (
                <FileUpload
                    attachment={file}
                    onChange={uploadFile}
                    onCancelUpload={cancelUpload}
                    accept={{
                        "application/zip": [],
                        "application/x-zip-compressed": [],
                    }}
                    maxSize={2147483648}
                    acceptDescription="ZIP до 2 ГБ"
                    id="adminNewMaterialScormFileUpload"
                />
            ) : (
                <div className="group relative w-full flex justify-center" id="adminNewMaterialScormFile">
                    <Tooltip
                        className="w-full max-w-[700px] flex rounded-md cursor-pointer"
                        content={<span>Файл будет доступен для просмотра после сохранения изменений</span>}
                        disabled={!isFormDirty || (!isFormDirty && !material?.id)}
                    >
                        <div
                            className={
                                "w-full max-w-[700px] flex p-4 border border-gray-blue rounded-md cursor-pointer"
                            }
                            onClick={() => {
                                if (!isFormDirty || (!isFormDirty && !material?.id)) {
                                    openScormPlayerInNewWindow();
                                }
                            }}
                        >
                            <div
                                className={
                                    "flex items-center mr-auto" +
                                    (!isFormDirty || (!isFormDirty && !material?.id) ? "" : " opacity-50")
                                }
                            >
                                <Icon
                                    className="mr-5"
                                    icon={FILES_TYPE_ICONS[attachment?.extension]}
                                    width="32px"
                                    height="32px"
                                />
                                <div>
                                    <div className="pb-2 max-w-[550px]">
                                        <Tooltip
                                            content={<span className="break-all">{attachment?.name}</span>}
                                            disabled={attachment?.name.length < 64}
                                        >
                                            <span className="block truncate w-full">{attachment?.name}</span>
                                        </Tooltip>
                                    </div>
                                    <div className="text-gray-text">
                                        {humanFileSize(attachment?.size, true)} – Успешно загружено
                                    </div>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="px-1">
                                    <a
                                        href={hostname + "/service/lms-upload/api/scorm/download/" + attachment?.id}
                                        download={name}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        className="flex-shrink"
                                    >
                                        <Icon icon={Icons.Download} width={20} height={20} color="fill-blue-drk" />
                                    </a>
                                </div>
                                {/*{type !== MaterialType.SCORM && <Icon icon={Icons.Upload} width="20px" height="20px" />}*/}
                                <div onClick={(e) => removeFile(e)} className=" px-1">
                                    <Icon
                                        icon={Icons.Delete}
                                        width={20}
                                        height={20}
                                        color="fill-blue-drk cursor-pointer"
                                    />
                                </div>
                            </div>
                        </div>
                    </Tooltip>
                </div>
            )}
        </Section>
    );
};
