import { Expose, Type } from "class-transformer";
import { BaseResponse } from "../BaseResponse";

export class RatingReadResponse extends BaseResponse {
    // @Expose()
    // @Type(() => String)
    // public materialType: MaterialType;

    // @Expose()
    // @Type(() => Number)
    // public min: number;

    // @Expose()
    // @Type(() => Number)
    // public mid: number;

    // @Expose()
    // @Type(() => Number)
    // public max: number;

    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => Number)
    public minArticle: number;

    @Expose()
    @Type(() => Number)
    public minVideo: number;

    // @Expose()
    // @Type(() => Number)
    // public minDocument: number;

    // @Expose()
    // @Type(() => Number)
    // public minScorm: number;

    @Expose()
    @Type(() => Number)
    public minHtml: number;

    @Expose()
    @Type(() => Number)
    public minTest: number;

    @Expose()
    @Type(() => Number)
    public minExercise: number;

    @Expose()
    @Type(() => Number)
    public midArticle: number;

    @Expose()
    @Type(() => Number)
    public midVideo: number;

    // @Expose()
    // @Type(() => Number)
    // public midDocument: number;

    // @Expose()
    // @Type(() => Number)
    // public midScorm: number;

    @Expose()
    @Type(() => Number)
    public midHtml: number;

    @Expose()
    @Type(() => Number)
    public midTest: number;

    @Expose()
    @Type(() => Number)
    public midExercise: number;

    @Expose()
    @Type(() => Number)
    public maxArticle: number;

    @Expose()
    @Type(() => Number)
    public maxVideo: number;

    // @Expose()
    // @Type(() => Number)
    // public maxDocument: number;

    // @Expose()
    // @Type(() => Number)
    // public maxScorm: number;

    @Expose()
    @Type(() => Number)
    public maxHtml: number;

    @Expose()
    @Type(() => Number)
    public maxTest: number;

    @Expose()
    @Type(() => Number)
    public maxExercise: number;
}

export class RatingCommandResponse {
    @Expose()
    @Type(() => Number)
    public Id: number;

    @Expose()
    @Type(() => String)
    public Image: string;

    @Expose()
    @Type(() => String)
    public FirstName: string;

    @Expose()
    @Type(() => String)
    public LastName: string;

    @Expose()
    @Type(() => String)
    public MiddleName: string;

    @Expose()
    @Type(() => String)
    public Login: string;

    @Expose()
    @Type(() => String)
    public Email: string;

    @Expose()
    @Type(() => Number)
    public Pass: number;

    @Expose()
    @Type(() => Number)
    public Point: number;
}

export class RatingReadByTypeResponse {
    @Expose({ name: "low" })
    @Type(() => Number)
    public EASY: number;

    @Expose({ name: "medium" })
    @Type(() => Number)
    public MEDIUM: number;

    @Expose({ name: "high" })
    @Type(() => Number)
    public HARD: number;
}

export class JobTitle {
    id: string;
    name: string;
    userCount: number;
}

export class Team {
    id: string;
    name: string;
}

export class UIRatingListResponse {
    @Expose()
    @Type(() => Number)
    achieveRating: number;

    @Expose()
    @Type(() => Number)
    courseRating: number;

    @Expose()
    @Type(() => Number)
    exerciseRating: number;

    @Expose()
    @Type(() => String)
    id: string;

    // @Expose()
    // @Type(() => Number)
    // quizRating: number;

    @Expose()
    @Type(() => Number)
    rank: number;

    @Expose()
    @Type(() => Number)
    rating: number;

    @Expose()
    @Type(() => String)
    type: string;

    @Expose()
    @Type(() => UserRating)
    user: UserRating;
}

export class UserRating {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    login: string;

    @Expose()
    @Type(() => String)
    avatarId: string;

    @Expose()
    @Type(() => String)
    firstName: string;

    @Expose()
    @Type(() => String)
    lastName: string;

    @Expose()
    @Type(() => String)
    middleName: string;

    @Expose()
    @Type(() => String)
    email: string;

    @Expose()
    @Type(() => JobTitle)
    jobTitle: JobTitle;

    @Expose()
    @Type(() => Number)
    rating: number;

    @Expose()
    @Type(() => String)
    defaultAvatarColor: string;

    @Expose()
    @Type(() => Number)
    courseCount: number;

    // @Expose()
    // @Type(() => Number)
    // quizCount: number;

    @Expose()
    @Type(() => Number)
    exerciseCount: number;

    @Expose()
    @Type(() => Number)
    rank: number;

    @Expose()
    @Type(() => Team)
    teams: Team[];
}
