import React from "react";
import { useParams, Outlet } from "react-router-dom";
import { ContentContainer } from "Containers/ContentContainer";
import { Breadcrumbs } from "Uikit/index";
import { useGetUICourseMaterial, useGetUICourse } from "./Course.hooks";
import Skeleton from "react-loading-skeleton";
import { Preloader } from "Components/Preloader/Preloader";
import { NoAccess } from "Components/Stub/NoAccess";
import classNames from "classnames";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";

export const Course = () => {
    const { courseId = "", resourceId = "" } = useParams<{ courseId: string; resourceId: string }>();

    const { data: course, isLoading: isCourseLoading, isFetched, error } = useGetUICourse(courseId);
    const { data: courseMaterial } = useGetUICourseMaterial(resourceId, { enabled: false });

    const isNoPermissionError =
        error && error instanceof BadRequestResponse && error.errorCode === ErrorCode.NO_RESOURCE_ACCESS;

    const showNoAccess = isFetched && isNoPermissionError;

    return (
        <div className={classNames("relative min-h-full", showNoAccess && "h-full")}>
            <div className="flex flex-col pt-3 sm:pt-0 h-full mx-auto sm:max-w-[1216px] 2xl:max-w-[1506px]">
                <Preloader className="pt-6 pl-4 pr-6 h-[calc(100vh-72px)]" isShow={isCourseLoading}>
                    <Skeleton className="mb-7 rounded-2xl" width="100%" height="244px" />
                    {Array.from(Array(16).keys()).map((p) => {
                        return (
                            <div key={`card-skeleton__${p}`} className="mb-1 h-20">
                                <Skeleton className="rounded-2xl" width="100%" height="100%" />
                            </div>
                        );
                    })}
                </Preloader>
                {showNoAccess && <NoAccess className="flex-grow" />}
                {!showNoAccess && course && (
                    <>
                        <div className="hidden sm:block px-3 sm:pl-4 sm:pr-6.5 pb-3">
                            <Breadcrumbs id="userCourseBreadcrumbs">
                                <Breadcrumbs.Link title={"Моё обучение"} url="/training" />
                                {course && (
                                    <Breadcrumbs.Link
                                        url={resourceId ? `/training/course/${courseId}` : undefined}
                                        title={course.title}
                                    />
                                )}
                                {courseMaterial?.title && <Breadcrumbs.Link title={courseMaterial.title} />}
                            </Breadcrumbs>
                        </div>
                        <ContentContainer>{<Outlet />}</ContentContainer>
                    </>
                )}
            </div>
        </div>
    );
};
