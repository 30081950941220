import { useRef, useState } from "react";
import axios from "axios";
import Api from "Api/index";
import { ErrorCode } from "Api/BaseResponse";
import { secondsToHms } from "helpers/secondsToHms";
import { FileUploadType } from "Enums";

export const useUpload = () => {
    const [uploadingFiles, setUploadingFiles] = useState<any[]>([]);
    const uploadingFilesRef = useRef<any[]>(uploadingFiles);

    const _updateFile = (id: number, file: any) => {
        const f = uploadingFilesRef.current.find((_f) => _f.id === id);
        const i = uploadingFilesRef.current.findIndex((_f) => _f.id === id);

        const updFile = { ...f, ...file };
        const newFiles = [...uploadingFilesRef.current.slice(0, i), updFile, ...uploadingFilesRef.current.slice(i + 1)];

        setUploadingFiles(newFiles);
    };

    const upload = ({
        file,
        identifier = null,
        onUpdate = null,
        onFinished = null,
        type = "new",
        fileUploadType = FileUploadType.RESOURCE_IMAGE,
        onError,
        socketId,
    }: any) => {
        let block = false;
        const newFileId = uploadingFiles.length;
        const cancelToken = axios.CancelToken.source();
        let cancelled = false;
        let useCallbacks = true;
        const startTime = new Date().getTime();
        const _file = {
            id: newFileId,
            fileId: null,
            status: "loading",
            file: file,
            uploaded: 0,
            uploadedPercent: 0,
            size: file.size,
            identifier: identifier,
            start: async () => {
                const progressEventHandler = async (progressEvent: { loaded: number; total: number }) => {
                    if (block) {
                        return;
                    }
                    block = true;
                    const elapsedTime = new Date().getTime() - startTime;
                    const chunksPerTime = progressEvent.loaded / elapsedTime;
                    const estimatedTotalTime = progressEvent.total / chunksPerTime;
                    const timeLeftInSeconds = (estimatedTotalTime - elapsedTime) / 1000;

                    const fileUpd = {
                        uploaded: progressEvent.loaded,
                        total: progressEvent.total,
                        timeLeft: secondsToHms(timeLeftInSeconds) || "0 мин. 0 c.",
                        uploadedPercent: parseInt(
                            String(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
                            10,
                        ),
                    };
                    _updateFile(newFileId, fileUpd);
                    if (useCallbacks && onUpdate) {
                        await onUpdate({ ..._file, ...fileUpd });
                    }
                    setTimeout(() => (block = false), 1000);
                };

                const formData = new FormData();
                formData.append("file", _file.file);

                let method;
                switch (type) {
                    case "new":
                        method = async (data: any, progressHandler: any, token: any) =>
                            await Api.File.UploadFile(
                                _file.file,
                                progressHandler,
                                token,
                                true,
                                fileUploadType,
                                socketId,
                            );
                        break;
                    // case "replace":
                    //     method = async (data: any, progressHandler: any, token: any) =>
                    //     await Api.File.UploadFile(fileId, data, progressHandler, token);
                    //     break;
                    // case "scorm":
                    //     method = async (data: any, progressHandler: any, token: any) =>
                    //         await Api.ScormFile.UploadFile(_file.file, progressHandler, token, true, fileUploadType);
                    //     break;
                    default:
                        method = () => Promise.resolve(() => console.log("won't happen, but warning"));
                }

                return await method(
                    formData,
                    async (progressEvent: any) => await progressEventHandler(progressEvent),
                    cancelToken.token,
                )
                    .then((res) => {
                        if (cancelled) {
                            throw new Error("cancelled");
                        } else {
                            return res;
                        }
                    })
                    .then(async (res: any) => {
                        const upd = {
                            ...uploadingFiles.find((f) => f.id === newFileId),
                            status: "done",
                            fileId: res.id,
                            serverData: res,
                        };
                        if (useCallbacks && onUpdate) {
                            await onUpdate(upd);
                        }
                        if (useCallbacks && onFinished) {
                            await onFinished(upd);
                        }
                        _updateFile(newFileId, { status: "done", fileId: res.id, serverData: res });
                        return {
                            ...uploadingFiles.find((f) => f.id === newFileId),
                            status: "done",
                            fileId: res.id,
                            serverData: res,
                        };
                    })
                    .catch((error) => {
                        onError?.(error);
                        if (
                            error.message !== "cancelled" ||
                            [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                                String(error.errorCode) as ErrorCode,
                            )
                        ) {
                            throw error;
                        }
                    });
            },
            cancel: () => {
                cancelled = true;
                cancelToken.cancel();
                _updateFile(newFileId, { status: "cancel" });
            },
            removeCallbacks: () => {
                useCallbacks = false;
            },
        };

        uploadingFiles.push(_file);
        return _file;
    };

    return { upload };
};
