import React, { useState, ChangeEvent } from "react";
import InputMask from "react-input-mask";

import { Button, Icon, Icons, Input, FormGroup, Label, Tooltip } from "Uikit";
import { passwordRegexp } from "Uikit/Forms/Input";
import { TVoidFunction } from "types";
// import Api from "Api";
// import { UserLoginValidationRequest } from "Api/Requests/UserRequest";
// import { ErrorCode } from "Api/BaseResponse";

import { IAuthorizationInviteSettingsFormProps } from "./AuthorizationInviteForm";

interface IAuthorizationInviteFormAccountProps extends IAuthorizationInviteSettingsFormProps {
    onLoginValidate: TVoidFunction;
    onPhoneValidate: TVoidFunction;
    onEmailValidate: TVoidFunction;
}

export const AuthorizationInviteFormAccount = ({
    registrationRequest,
    onChange,
    onSubmit,
    errors,
    isValid,
    onLoginValidate,
    onPhoneValidate,
    onEmailValidate,
}: IAuthorizationInviteFormAccountProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const onLoginBlur = () => {
        onLoginValidate();
    };
    const onPhoneBlur = () => {
        onPhoneValidate();
    };
    const onEmailBlur = () => {
        onEmailValidate();
    };

    const onLoginChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/_{2,}/, "_").replace(/\.{2,}/, ".");
        onChange({ ...registrationRequest, login: value });
    };

    // const emailError = (() => {
    //     const error = errors["email"];

    //     if (!error) {
    //         return null;
    //     }

    //     if (String(error).startsWith(ErrorCode.BLOCKED_USER_ALREADY_EXISTS)) {
    //         const id = error.split("#")[1];
    //         return (
    //             <span>
    //                 Почта уже используется для <Link to={`/admin/member/${id}`}>заблокированного пользователя</Link>
    //             </span>
    //         );
    //     }

    //     return error;
    // })();

    let errMsg = "";
    if ([registrationRequest.login, registrationRequest.password].some((item) => !item)) {
        errMsg = "Заполните обязательные поля";
    } else {
        if (errors?.login) {
            errMsg = `Логин: ${errors?.login}`;
        }
        if (errors?.password) {
            errMsg = `Пароль: ${errors?.password}`;
        }
        if (errors?.email) {
            errMsg = `Почта: ${errors?.email}`;
        }
    }

    return (
        <>
            <FormGroup>
                <Label isRequired={true}>Логин</Label>
                <Input
                    isRequired={true}
                    placeholder="Введите логин"
                    value={registrationRequest.login}
                    onChange={onLoginChange}
                    onBlur={onLoginBlur}
                    minLength={3}
                    maxLength={128}
                    error={errors?.["login"]}
                    cutRegExp={new RegExp(/[^a-zA-Z0-9_.]/g)}
                    id="adminNewMemberInputlogin"
                />
            </FormGroup>
            <FormGroup>
                <Label isRequired={!registrationRequest.inviteId}>Пароль</Label>
                <div className="relative">
                    <span className="z-20 absolute inset-y-0 right-2 flex items-center pl-2 top-1/2 -translate-y-1/2">
                        <button
                            className="z-20 p-1 focus:outline-none focus:shadow-outline"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {!showPassword && <Icon icon={Icons.EyeSlash} width="22px" height="22px" />}
                            {showPassword && <Icon icon={Icons.Eye} width="22px" height="22px" />}
                        </button>
                    </span>
                    <Input
                        isRequired={!registrationRequest.inviteId}
                        placeholder="Введите пароль"
                        value={registrationRequest.password}
                        onChange={(e) => onChange({ ...registrationRequest, password: e.target.value })}
                        minLength={8}
                        maxLength={16}
                        // error={errors["password"]}
                        cutRegExp={passwordRegexp}
                        id="adminNewMemberInputPassword"
                        type={showPassword ? "text" : "password"}
                    />
                </div>
            </FormGroup>
            <FormGroup>
                <Label>Почта</Label>
                <Input
                    placeholder="Введите вашу почту"
                    value={registrationRequest.email as string | number | undefined}
                    onChange={(e) => onChange({ ...registrationRequest, email: e.target.value })}
                    minLength={5}
                    maxLength={256}
                    error={errors?.["email"]}
                    // eslint-disable-next-line no-useless-escape
                    cutRegExp={new RegExp(/[^a-zA-Z0-9!#$%&'*+\-\/=?^_`{|}~.@]/g)}
                    id="adminNewMemberInputEmail"
                    onBlur={onEmailBlur}
                />
            </FormGroup>
            <FormGroup>
                <Label>Номер телефона</Label>
                <InputMask
                    mask="+7 999 999-99-99"
                    type="text"
                    placeholder="+7 999 999-99-99"
                    value={registrationRequest.phone as string | number | undefined}
                    onChange={(e) => {
                        if (e.type === "focus" || e.type === "blur") {
                            return;
                        }
                        onChange({ ...registrationRequest, phone: e.target.value });
                    }}
                    beforeMaskedStateChange={({ nextState, previousState }) => {
                        return nextState.value.includes("_") && nextState.selection?.end === 16
                            ? previousState ?? nextState
                            : nextState;
                    }}
                    onBlur={onPhoneBlur}
                >
                    <Input
                        id="adminNewMemberInputTel"
                        className="!text-black"
                        // isRequired={true}
                        error={errors?.["phone"]}
                        tooltipIntercative={typeof errors?.["phone"] !== "string"}
                    />
                </InputMask>
            </FormGroup>
            <Tooltip
                className="w-full max-w-[700px] flex rounded-md cursor-pointer"
                content={errMsg}
                disabled={isValid}
            >
                <Button
                    className="w-full h-12 sm:!mt-1 sm:!mb-0 sm:h-10"
                    onClick={() => {
                        onSubmit();
                    }}
                    disabled={!isValid}
                >
                    Далее
                </Button>
            </Tooltip>
        </>
    );
};
