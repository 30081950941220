import BaseApi from "../BaseApi";
import { BaseRequest } from "../BaseRequest";
import { DisposableLinkResponse, FileReadResponse, FileInfoResponse } from "../Responses/FileResponse";
import { FileUploadType } from "Enums";

export default class FileApi extends BaseApi {
    UploadFile = async (
        data: File,
        progressHandler?: any,
        token?: any,
        isDisposableLink = false,
        fileUploadType?: FileUploadType,
        socketId?: string,
    ): Promise<FileReadResponse> => {
        const formData = new FormData();
        formData.append("file", data);

        const type = fileUploadType ? `type=${fileUploadType}` : "";
        const link = isDisposableLink ? "&link=true" : "";
        const socketIdParam = socketId ? `&socketId=${socketId}` : "";

        return await this.Upload(
            new FileReadResponse(),
            `/upload?${type}${link}${socketIdParam}`,
            formData,
            progressHandler,
            token,
        );
    };

    DisposableLink = async (id: string): Promise<DisposableLinkResponse> => {
        return await this.Get(new DisposableLinkResponse(), `/get-link/${id}`);
    };

    DownloadFile = async (fileId: string): Promise<any> => {
        return await this.Get("", `/download/${fileId}`, new BaseRequest(), "blob");
    };

    GetFileInfo = async (fileId: string): Promise<FileInfoResponse> => {
        return await this.Get(FileInfoResponse, `/info/${fileId}`);
    };
}
