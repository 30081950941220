import React, { useMemo, useState } from "react";
import { ColumnDef, PaginationState, SortingState } from "@tanstack/react-table";
import { UIRatingListResponse } from "Api/Responses/RatingResponse";
import { Table } from "Uikit";
import { ID } from "types/ID";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { useNavigate } from "react-router-dom";
import { useGetUIRating } from "./Rating.hooks";
import Skeleton from "react-loading-skeleton";
import { Preloader } from "Components/Preloader/Preloader";
import { IOption } from "types";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";

interface RatingTableProps {
    search?: string;
    teamId?: ID;
    period?: IOption;
}
export const RatingTable = ({ search, teamId, period }: RatingTableProps) => {
    const navigate = useNavigate();
    const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });
    const [sorting, setSorting] = useState<SortingState>([{ id: "ratingSum", desc: true }]);

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);

    const { data, isFetching } = useGetUIRating(pagination, sorting, search, teamId, period);

    const currentUserTableIndex = useMemo(() => {
        const userIdx = data?.users?.Content.slice(1).findIndex((user) => user.id === data.currentUser.id);

        return userIdx && userIdx !== -1 ? [userIdx + 1] : [];
    }, [data]);

    const columns = useMemo<ColumnDef<UIRatingListResponse>[]>(
        () => [
            {
                header: "место",
                accessorKey: "rank",
                enableResizing: true,
                cell: ({ row: { original: rating } }) => {
                    return <div className="sm:min-w-20 2xl:min-w-30">{rating.rank ?? "-"}</div>;
                },
            },
            {
                header: "участник",
                accessorKey: "user.firstName,user.lastName",
                enableResizing: true,
                size: 300,
                cell: ({
                    row: {
                        original: { user },
                    },
                }) => {
                    return (
                        <div
                            className="group inline-flex items-center space-x-3 2xl:space-x-3.75 min-w-75"
                            onClick={() => navigate(`/personal/${user.id}`)}
                        >
                            <UserAvatar
                                avatarId={user.avatarId}
                                color={user.defaultAvatarColor}
                                userInitials={`${user.firstName?.slice(0, 1)}${user.lastName?.slice(0, 1)}`}
                                size={window.screen.width >= 1600 ? 45 : 36}
                            />
                            <MultiClumpTooltip
                                key={user.id}
                                clamp={1}
                                label={`${user.firstName} ${user.lastName}`}
                                textClassName="group-hover:text-blue"
                            />
                        </div>
                    );
                },
            },
            {
                header: "всего баллов",
                accessorKey: "ratingSum",
                enableResizing: true,
                cell: ({ row: { original: rating } }) => {
                    return <div className="font-medium sm:min-w-25 2xl:min-w-40">{rating.rating}</div>;
                },
            },
            // {
            //     // header: "за курсы / программы",
            //     header: "за курсы",
            //     accessorKey: "courseRating",
            //     enableResizing: true,
            //     cell: ({ row: { original: rating } }) => {
            //         return <div className="sm:min-w-40 2xl:min-w-60">{rating.courseRating}</div>;
            //     },
            // },
            // {
            //     header: "за тесты",
            //     accessorKey: "quizRating",
            //     enableResizing: true,
            //     cell: ({ row: { original: rating } }) => {
            //         return <div className="sm:min-w-25 2xl:min-w-40">{rating.quizRating}</div>;
            //     },
            // },
            // {
            //     header: "за задания",
            //     accessorKey: "exerciseRating",
            //     enableResizing: true,
            //     cell: ({ row: { original: rating } }) => {
            //         return <div className="sm:min-w-25 2xl:min-w-40">{rating.exerciseRating}</div>;
            //     },
            // },
            // {
            //     header: "за ачивки",
            //     accessorKey: "achieveRating",
            //     enableResizing: true,
            //     cell: ({ row: { original: rating } }) => {
            //         return <div className="sm:min-w-25 2xl:min-w-40">{rating.achieveRating}</div>;
            //     },
            // },
        ],
        [navigate],
    );

    return (
        <div className="relative pl-3">
            <Preloader className="mt-5 2xl:mt-6.25" isShow={!data?.users || isFetching}>
                <div className="pb-1 h-3.5 2xl:h-4.5">
                    <Skeleton className="rounded-none" width="100%" height="100%" />
                </div>
                {Array.from(Array(16).keys()).map((p) => {
                    return (
                        <div key={p} className="py-1 h-16 2xl:h-20">
                            <Skeleton className="rounded-none" width="100%" height="100%" />
                        </div>
                    );
                })}
            </Preloader>
            {data?.users && !isFetching && (
                <Table
                    columns={columns}
                    data={data?.users.Content ?? []}
                    isFetching={isFetching}
                    rowSelection={rowSelection}
                    onRowSelectionChange={setRowSelection}
                    pagination={pagination}
                    highlightRowsIndex={[0]}
                    hoverRowsIndex={currentUserTableIndex}
                    pageCount={data?.users.TotalPages}
                    onPaginationChange={setPagination}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    withoutLines={true}
                    id="userRatingTable"
                    defaultSortOrder={{
                        "user_firstName,user.lastName": "asc",
                    }}
                />
            )}
        </div>
    );
};
