export enum ArchiveType {
    Program = 0,
    Course = 1,
    Material = 2,
    // Test = 3,
    // Task = 4,
    Interview = 5,
    // News = 6,
}

export enum AttachmentType {
    File = 0,
    Image = 1,
}

export enum InterviewQuestionType {
    Open = 0,
    Number = 1,
    One = 2,
    Multiply = 3,
}

export enum MaterialType {
    Video = "VIDEO",
    // Document = "DOCUMENT",
    Article = "ARTICLE",
    // SCORM = "SCORM",
    HTML = "HTML",
}

export enum MaterialQuestionType {
    One = 0,
    Multiply = 1,
    Number = 2,
}

export enum MaterialInteractiveType {
    One = 0,
    Multiply = 1,
    Number = 2,
}

export enum TaskItemType {
    Text = "TEXT",
    Number = "NUMBER",
    SingleChoice = "SINGLE_CHOICE",
    MultipleChoice = "MULTIPLE_CHOICE",
    File = "FILE",
    Image = "IMAGE",
}

export enum HistoryType {
    // Task = 0,
    // Test = 1,
    Remark = 2,
}

export enum Progress {
    NOT_STARTED = 0,
    IN_PROGRESS = 1,
    DONE = 2,
}

export enum ValidationStatus {
    Approved = 0,
    Fixed = 1,
    Declined = 2,
    In_Progress = 4,
}

export enum TestQuestionStatus {
    Approved = 0,
    Declined = 1,
    In_Progress = 2,
}

export enum Complexity {
    NONE = "NONE",
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
}

export enum ResourceState {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    HIDDEN = "HIDDEN",
    DELETED = "DELETED",
    ARCHIVED = "ARCHIVED",
    // DRAFT = "DRAFT",
}

export enum ResourceType {
    // QUIZ = "QUIZ",
    POLL = "POLL",
    EXERCISE = "EXERCISE",
    COURSE = "COURSE",
    PROGRAM = "PROGRAM",
    VIDEO = "VIDEO",
    // DOCUMENT = "DOCUMENT",
    // SCORM = "SCORM",
    HTML = "HTML",
    ARTICLE = "ARTICLE",
    NEWS = "NEWS",
    MATERIAL = "MATERIAL",
    ACHIEVEMENT = "ACHIEVEMENT",
    REPORT = "REPORT",
    CALENDAR = "CALENDAR",
    CERTIFICATE = "CERTIFICATE",
}

export enum ProgressStatus {
    ALL = "ALL", // Любой
    NOT_STARTED = "NOT_STARTED", // Не начато
    IN_PROGRESS = "IN_PROGRESS", // В процессе
    PASSED = "PASSED", // Выполнено
    FAILED = "FAILED", // Не выполнено
    ON_REVIEW = "ON_REVIEW", // Проверка
    RE_WORK = "RE_WORK", // Требует перепрохождения
    BLOCKED = "BLOCKED", // Заблокировано
}
export enum ProgressStatusTranslate {
    ALL = "Любой",
    NOT_STARTED = "Не начато",
    IN_PROGRESS = "В процессе",
    PASSED = "Пройден",
    FAILED = "Провален",
    ON_REVIEW = "На проверке",
    RE_WORK = "Требует перепрохождения",
    BLOCKED = "Заблокирован",
}

export enum QuizType {
    LINEAR = "LINEAR", // линейное прохождение теста
    LOOSE = "LOOSE", // произвольное прохождение теста
}

export enum TestQuestionType {
    TEXT = "TEXT", // Текстовое поле
    NUMBER = "NUMBER", // Числовое поле
    ONE_OPTION = "ONE_OPTION", // Один из списка
    MULTIPLE_OPTIONS = "MULTIPLE_OPTIONS", // Несколько из списка
}

export enum StatisticsReportAccess {
    ALL = "ALL", // Любой
    HAS_ACCESS = "HAS_ACCESS", // Без доступа
    NO_ACCESS = "NO_ACCESS", // С доступом
}

export enum StatisticsAttempts {
    FIRST = "FIRST", // По первой попытке
    LAST = "LAST", // По последней попытке
}

export enum StatisticsUsersStatus {
    ALL = "ALL", // Любой
    ACTIVE = "ACTIVE", // Активные
    BLOCKED = "BLOCKED", // Заблокированные
}

export enum RoleName {
    ROOT = "ROOT", // Рут
    SUPER_ADMIN = "SUPER_ADMIN", // Супер-администратор
    // ADMIN = "ADMIN", // Администратор
    USER = "USER", // Пользователь
}

export enum RoleNameTranslate {
    ROOT = "Рут",
    SUPER_ADMIN = "Супер-администратор",
    // ADMIN = "Администратор",
    USER = "Пользователь",
}

// Типы загружаемых файлов - для загрузки обложек и вложений
export enum FileUploadType {
    RESOURCE_IMAGE = "RESOURCE_IMAGE", // используется при загрузке изображений в тестах в вопросах и ответах
    RESOURCE_LOGO = "RESOURCE_LOGO", // используется при загрузке обложек ресурсов
    RESOURCE_ATTACHMENT = "RESOURCE_ATTACHMENT", // используется при загрузке прикреплённых файлов к материалам типа "Статья"
    USER_AVATAR = "USER_AVATAR", // используется при загрузке аватаров пользователей
    COMPANY_LOGO = "COMPANY_LOGO", // используется при загрузке логотипов компаний
    ACHIEVEMENT_LOGO = "ACHIEVEMENT_LOGO", // используется при загрузке логотипов ачивок
    // MATERIAL_DOCUMENT = "MATERIAL_DOCUMENT", // используется при загрузке документов в материал типа "Документ"
    MATERIAL_VIDEO = "MATERIAL_VIDEO", // используется при загрузке видео в материал типа "Видео"
    EXERCISE_IMAGE = "EXERCISE_IMAGE", // используется при загрузке изображения в качестве ответа пользователя на задание
    EXERCISE_FILE = "EXERCISE_FILE", // используется при загрузке иного файла в качестве ответа пользователя на задание
    // USER_IMPORT_FILE = "USER_IMPORT_FILE", //используется при загрузке эксель файла при импорте или обновлении участников
}

export enum MaterialTypesTranslate {
    VIDEO = "Видео",
    // DOCUMENT = "Документ",
    ARTICLE = "Статья",
    // SCORM = "SCORM",
    HTML = "HTML",
    // QUIZ = "Тест",
    NEWS = "Новость",
    POLL = "POLL",
    EXERCISE = "Задание",
    COURSE = "Курс",
    PROGRAM = "Программа",
    MATERIAL = "Материал",
    ACHIEVEMENT = "Ачивка",
    REPORT = "Отчёт",
    CALENDAR = "Календарь",
    CERTIFICATE = "Сертификат",
}

// export enum NotificationActionType {
//     CHECK = "CHECK",
//     ASSIGN = "ASSIGN",
//     EDIT = "EDIT",
//     GENERATE_START = "GENERATE_START",
//     GENERATE_FINISH = "GENERATE_FINISH",
//     EXPIRED = "EXPIRED",
//     CERTIFICATE_ISSUED = "CERTIFICATE_ISSUED",
//     USER_IMPORT_STARTED = "USER_IMPORT_STARTED",
//     USER_IMPORT_FINISHED = "USER_IMPORT_FINISHED",
// }

export enum UIErrorMessages {
    FILE_LOADING_ERROR = "Ошибка при загрузке, загрузите другой файл",
    FILE_EXTENSION_ERROR = "Ошибка при загрузке, выбран недопустимый формат файла",
    FILE_SIZE_ERROR = "Превышен максимальный размер файла",
}

export enum FileProcessingStatuses {
    NOT_REQUIRED = "NOT_REQUIRED",
    AWAITING_STATUS = "AWAITING_STATUS",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETE = "COMPLETE",
}

export enum WSEventVideoActionTypes {
    AWAITING_STATUS = "AWAITING_STATUS",
    PROCESSING_IN_PROGRESS = "PROCESSING_IN_PROGRESS",
    PROCESSING_COMPLETE = "PROCESSING_COMPLETE",
}

// export enum UserImportTypes {
//     CREATE = "CREATE",
//     UPDATE = "UPDATE",
// }
