import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useQuery } from "react-query";
import { AccessTable } from "./AccessTable";
import { Toggle, Tooltip, TreeSearch } from "Uikit";
import { ID } from "types/ID";
import Api from "Api";
import { TeamTree, flatten } from "Components/Common/TeamTree";
import { getCheckedTreeNodes } from "helpers/TreeUtils";
import { useDebounceValue } from "hooks/useDebounceValue";
import { toggleTreeNodes } from "Uikit/Tree/Tree";
import { ResourceType } from "Enums";
import { UserRole } from "types/User";

interface IAccessDummyProps {
    resourceId?: ID;
    resourceType?: ResourceType;
    setIsAccessFormDirty?: (isAccessFormDirty: boolean) => void;
    setTeamIds?: (teamIds: any[]) => void;
    allTeamsAccess?: boolean;
    setAllTeamsAccess?: (allTeamsAccess: boolean) => void;
    onNodeAddComponentClick?: (arg?: string) => void;
    nodeAddComponentLink?: string;
}

export interface AccessRef {
    resetAccess: (data: any) => void;
    validateAccess: () => string | undefined;
}

export const AccessDummy = forwardRef(
    (
        {
            resourceId,
            setIsAccessFormDirty,
            resourceType,
            setTeamIds,
            setAllTeamsAccess,
            allTeamsAccess = false,
            onNodeAddComponentClick,
            nodeAddComponentLink,
        }: IAccessDummyProps,
        ref,
    ) => {
        const teamTreeRef = useRef<any>();

        const [isInit, setIsInit] = useState(false);
        const [allTeams, setAllTeams] = useState(allTeamsAccess);
        const [checkedTeams, setCheckedTeams] = useState<string[]>([]);
        const [isSubmitting, setIsSubmitting] = useState(false);

        const { data: currentUser } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());

        const [query, setQuery] = useState("");
        const [debouncedQuery] = useDebounceValue(query, 300);

        const { data: teamAccess, refetch } = useQuery(
            ["teamAccess", "collection", resourceId],
            () => Api.LMSRoles.getTeamAccess(resourceId!),
            {
                enabled: !!resourceId,
            },
        );

        const validateAccess = useCallback(() => {
            const { tree } = teamTreeRef.current;
            const parentNodes = teamTreeRef.current?.tree.nodes.filter((n: any) => !n.parent.id);
            const isValid =
                parentNodes.every((n: any) => {
                    return n.state.checked || n.state.indeterminate;
                }) ||
                parentNodes.every((n: any) => {
                    return !n.state.checked && !n.state.indeterminate;
                });

            parentNodes.forEach((parentNode: any) => {
                const flattenNodes = flatten(parentNode.children, [parentNode]);
                if (!parentNode.state.checked || (!parentNode.state.indeterminate && !parentNode.state.checked)) {
                    flattenNodes.forEach((n) => teamTreeRef.current?.tree.updateNode(n, { error: true }));
                } else {
                    flattenNodes.forEach((n) => teamTreeRef.current?.tree.updateNode(n, { error: false }));
                }
            });

            const teamIds = Array.from(
                new Set(getCheckedTreeNodes(tree).map((p: any) => p.id /* .replace("root:", "") */)),
            );

            setCheckedTeams?.(teamIds);

            return isValid ? undefined : "Выбор во всех корневых командах обязателен";
        }, []);

        const handleCheckedChange = useCallback(() => {
            setIsAccessFormDirty?.(true);
            const { tree } = teamTreeRef.current;
            const teamIds = Array.from(new Set(getCheckedTreeNodes(tree).map((p: any) => p.id)));

            setTeamIds?.(teamIds);
            if (isSubmitting) {
                validateAccess();
            }
        }, [setIsAccessFormDirty, setTeamIds, isSubmitting, validateAccess]);

        useImperativeHandle<unknown, AccessRef>(ref, () => ({
            resetAccess: () => {
                refetch().then();
            },
            validateAccess: () => {
                const validate = validateAccess();
                setIsSubmitting(!!validate);

                return validate;
            },
            getTree: () => {
                const { tree } = teamTreeRef.current;
                return tree;
            },
        }));

        useEffect(() => {
            const { tree } = teamTreeRef.current ?? {};

            if (tree) {
                toggleTreeNodes(tree, debouncedQuery.trim().length === 0, debouncedQuery.trim());
            }
        }, [debouncedQuery, teamTreeRef]);

        useEffect(() => {
            if (teamAccess) {
                const { allTeams, teams } = teamAccess;
                setAllTeams(allTeams);
                setAllTeamsAccess?.(allTeams);

                setTeamIds?.(teams);
                setCheckedTeams(teams);
                // Отключаем чекбоксы, если установлен общий доступ
                const { tree } = teamTreeRef.current ?? {};
                if (tree && tree.setAllNodesDisabled) {
                    tree.setAllNodesDisabled(allTeams);
                }

                setIsInit(true);

                // При сбросе выделенных опций (e.g. команд) после отмены внесённых изменений, сбрасываем флаг ошибки со всех нод
                if (!teams.length && teamTreeRef.current?.tree?.nodes) {
                    const parentNodes = teamTreeRef.current?.tree.nodes.filter((n: any) => !n.parent.id);
                    parentNodes.forEach((parentNode: any) => {
                        const flattenNodes = flatten(parentNode.children, [parentNode]);
                        flattenNodes.forEach((n) => teamTreeRef.current?.tree.updateNode(n, { error: false }));
                    });
                }
            }
        }, [teamAccess, teamTreeRef, setAllTeams, setTeamIds, setAllTeamsAccess]);

        return (
            <div className="space-y-7.5 h-full">
                <div className="space-y-5.5">
                    <div className="flex justify-between items-center">
                        <div className="flex justify-between items-center gap-10">
                            <h2>Командный доступ</h2>
                            <Tooltip
                                content={"Чтобы изменить доступ, обратитесь к суперадминистратору"}
                                disabled={currentUser?.role === `ROOT` || currentUser?.role === `SUPER_ADMIN`}
                                maxWidth={500}
                            >
                                <Toggle
                                    label="Общий доступ"
                                    enabled={allTeams}
                                    onChange={(p: boolean) => {
                                        setAllTeams(p);
                                        setAllTeamsAccess?.(p);

                                        const { tree } = teamTreeRef.current;
                                        // console.log(tree, tree.checkAllNodes, tree.setAllNodesDisabled);

                                        tree.checkAllNodes(p);
                                        tree.setAllNodesDisabled(p);

                                        handleCheckedChange();
                                        setIsAccessFormDirty?.(true);
                                    }}
                                    disabled={currentUser?.role === `ADMIN`}
                                    id="accessDummyToggleGeneralAccess"
                                />
                            </Tooltip>
                        </div>
                        <div className="flex justify-between items-center gap-4" id="accessDummyTreeSearch">
                            <TreeSearch query={query} setQuery={setQuery} classNames={{ container: "w-90" }} />
                        </div>
                    </div>

                    <div className="h-100">
                        {isInit && (
                            <TeamTree
                                id="library-access-tree"
                                outerRef={teamTreeRef}
                                checkable={true}
                                isAllDisabled={teamAccess?.allTeams}
                                checkedChange={handleCheckedChange}
                                onSelectAll={handleCheckedChange}
                                checkedTeams={checkedTeams}
                                searchable={false}
                                onNodeAddComponentClick={onNodeAddComponentClick}
                                nodeAddComponentLink={nodeAddComponentLink}
                                hideMembers={currentUser?.role === UserRole.ADMIN}
                                isRoleTeams={true}
                            />
                        )}
                    </div>
                </div>

                <AccessTable resourceId={resourceId!} resourceType={resourceType as ResourceType} />
            </div>
        );
    },
);
