import "reflect-metadata";
import { Expose, Exclude, Type, Transform } from "class-transformer";

export class BaseResponse {}

export enum ErrorCode {
    EMAIL_EXISTS_IN_COMPANY = "EMAIL_EXISTS_IN_COMPANY",
    PHONE_EXISTS_IN_COMPANY = "PHONE_EXISTS_IN_COMPANY",
    NO_USER_ACCESS = "NO_USER_ACCESS",
    TOO_MANY_ROOT_TEAMS_ERROR = "TOO_MANY_ROOT_TEAMS_ERROR",
    TEAM_ALREADY_EXISTS = "TEAM_ALREADY_EXISTS",
    ACHIEVEMENT_LIMIT_EXCEEDED = "ACHIEVEMENT_LIMIT_EXCEEDED",
    WRONG_TEAM_TRANSITION = "WRONG_TEAM_TRANSITION",
    // EXERCISE_WAS_CHANGED = "EXERCISE_WAS_CHANGED",
    USER_INVALID_PASSWORD = "USER_INVALID_PASSWORD",
    // CONTENT_NOT_FINISHED = "CONTENT_NOT_FINISHED",
    ACHIEVEMENT = "ACHIEVMENT", // achievement?
    TITLE_ALREADY_EXISTS = "TITLE_ALREADY_EXISTS",
    CORRUPT_FILE_ERROR = "CORRUPT_FILE_ERROR",
    FILE_EXTENSION_ERROR = "FILE_EXTENSION_ERROR",
    EXERCISE_WAS_CHANGED = "EXERCISE_WAS_CHANGED",
    NO_PERMISSION = "NO_PERMISSION",
    // QUIZ_TIME_EXCEEDED = "QUIZ_TIME_EXCEEDED",
    FILE_CONTENT_TYPE_ERROR = "FILE_CONTENT_TYPE_ERROR",
    NO_RESOURCE_ACCESS = "NO_RESOURCE_ACCESS",
    INVALID_TEAM_SELECTION = "INVALID_TEAM_SELECTION",
    PHONE_EXISTS = "PHONE_EXISTS",
    EMAIL_EXISTS = "EMAIL_EXISTS",
    LOGIN_EXISTS = "LOGIN_EXISTS",
    JOB_TITLE_NOT_FOUND = "JOB_TITLE_NOT_FOUND",
    OFFICE_NOT_FOUND = "OFFICE_NOT_FOUND",
    INVITE_NOT_FOUND = "INVITE_NOT_FOUND",
    USER_IMPORT_VALIDATION_ERROR = "USER_IMPORT_VALIDATION_ERROR",
}

export class BadRequestResponse extends BaseResponse {
    public errorCode: ErrorCode;
    public message: string;
    public status: number;
    public errorParams?: { userId: string };
}

export class BaseArrayResponse<T> extends BaseResponse {
    @Exclude()
    // eslint-disable-next-line @typescript-eslint/ban-types
    private type: Function;

    @Expose({ name: "content" })
    @Type((options) => {
        return (options!.newObject as BaseArrayResponse<T>).type;
    })
    public Content: T[];

    constructor(type: new () => T) {
        super();
        this.type = type;
    }
}

export class BaseObjectToArrayResponse<T> extends BaseResponse {
    @Expose({ name: "content" })
    @Transform((value: any) => {
        return Object.keys(value.value).map((key) => value.value[key]);
    })
    public Content: T[];
}

export class BasePaginationResponse<T> extends BaseArrayResponse<T> {
    @Expose({ name: "totalElements" })
    @Type(() => Number)
    public Total: number;

    @Expose({ name: "totalPages" })
    @Type(() => Number)
    public TotalPages: number;

    @Expose({ name: "size" })
    @Type(() => Number)
    public Size: number;

    @Expose({ name: "number" })
    @Type(() => Number)
    public Page: number;

    @Expose({ name: "last" })
    @Type(() => Boolean)
    public Last: boolean;
}
