import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import { UIErrorMessages, FileUploadType } from "Enums";
import { Breadcrumbs, flash, Icon, Icons } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import { Empty } from "Uikit/Page/Empty";
import { AvatarEditor } from "Uikit/AvatarEditor/AvatarEditor";
import Api from "Api";
import { InviteRegistrationRequest, PhoneValidationRequest } from "Api/Requests/InviteRequest";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { generatePassword } from "helpers/password";
import { ValidateLogin, ValidateName, ValidatePassword } from "helpers/Validator";
import { TVoidFunction } from "types";
import { userAvatarBgColors } from "utils/defaultAvatarColors";
import { IOption } from "types";

import { AuthorizationInviteFormPrivate as PrivateSettings } from "./AuthorizationInviteFormPrivate";
import { AuthorizationInviteFormAccount as AccountSettings } from "./AuthorizationInviteFormAccount";
import { AuthorizationInviteFormJob as JobSettings } from "./AuthorizationInviteFormJob";

export interface IAuthorizationInviteSettingsFormProps {
    registrationRequest: InviteRegistrationRequest;
    onChange: (request: React.SetStateAction<InviteRegistrationRequest>) => void;
    onSubmit: TVoidFunction;
    isValid: boolean;
    errors?: Record<string, string | undefined>;
}

interface IAuthorizationInviteFormProps {
    inviteId: string;
    setInviteId: (inviteId: string) => void;
}

const plugsData = {
    changed: {
        title: "Приглашение изменено",
        description: "Администратор изменил ссылку, для возобновления регистрации обновите страницу.",
    },
    disabled: {
        title: "Приглашение неактивно",
        description: "Регистрация по этой ссылке приостановлена. Обратитесь к администратору.",
    },
    deleted: {
        title: "Приглашение удалено",
        description: "Администратор запретил регистрацию по этой ссылке. Обратитесь к администратору.",
    },
    notFound: {
        title: "Приглашение не найдено",
        description: "Проверьте правильность ссылки или обратитесь к администратору.",
    },
};

export const AuthorizationInviteForm = ({ inviteId, setInviteId }: IAuthorizationInviteFormProps) => {
    const [inviteState, setInviteState] = useState<string | null>(null);
    const [jobOptions, setJobOptions] = useState<IOption[]>([]);
    const [officeOptions, setOfficeOptions] = useState<IOption[]>([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [avatarEditor, setAvatarEditor] = useState<string | undefined>(undefined);
    const [avatar, setAvatar] = useState<File | null>(null);
    const [userName, setUserName] = useState<{ firstName: string; lastName: string } | null>(null);
    const [isPrivateDataValid, setIsPrivateDataValid] = useState(false);
    const [isAccountDataValid, setIsAccountDataValid] = useState(false);
    const [isJobsDataValid, setIsJobsDataValid] = useState(false);
    const navigate = useNavigate();
    // Для тестирования вёрстки заглушек:
    const inviteExistData = useQuery(["invite-exist"], async () => await Api.Invites.InviteExists(inviteId ?? ""), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (!inviteExistData.data && !inviteExistData.isFetching) {
            setInviteState("notFound");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inviteExistData.data]);

    const [registrationRequest, setRegistrationRequest] = useState<InviteRegistrationRequest>(
        Object.assign(new InviteRegistrationRequest(), {
            avatarId: "",
            firstName: "",
            lastName: "",
            login: "",
            password: generatePassword(8),
            defaultAvatarColor: userAvatarBgColors[Math.floor(Math.random() * userAvatarBgColors.length)],
            inviteId,
        }),
    );

    const [activeFormStep, setActiveFormStep] = useState(0);

    const [errors, setErrors] = useState<any>({});

    const onChangeAvatar = (data: File | null, imgBase64?: string) => {
        setAvatar(data);
        setRegistrationRequest({ ...registrationRequest, avatarId: imgBase64 });
    };

    const onSave = async (loginIndex: number) => {
        try {
            try {
                if (avatar) {
                    const uploadFileResponse = await Api.File.UploadFile(
                        avatar,
                        undefined,
                        undefined,
                        false,
                        FileUploadType.USER_AVATAR,
                    );
                    registrationRequest.avatarId = uploadFileResponse.id;
                }
            } catch (e: unknown) {
                const knownError = e as BadRequestResponse;
                let message = "Размер обложки слишком большой!";
                if (
                    [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                        String(knownError.errorCode) as ErrorCode,
                    )
                ) {
                    message = UIErrorMessages.FILE_LOADING_ERROR;
                }
                flash.error(message);
                return;
            }

            await Api.Invites.Registration({
                ...registrationRequest,
                login: loginIndex ? registrationRequest.login + "_" + loginIndex : registrationRequest.login,
            });
            flash.success("Вы зарегистрированы!");
            setInviteId("");

            navigate(`/`);
        } catch (e) {
            let errorMessage = "Неизвестная ошибка";

            if (e instanceof BadRequestResponse) {
                const { errorCode } = e;

                if ([ErrorCode.EMAIL_EXISTS].includes(errorCode)) {
                    setErrors((errors: any) => ({
                        ...errors,
                        email: "Данный адрес электронной почты уже используется",
                    }));
                }
                if ([ErrorCode.PHONE_EXISTS].includes(errorCode)) {
                    setErrors((errors: any) => ({
                        ...errors,
                        phone: "Данный номер телефона уже используется",
                    }));
                }
                if ([ErrorCode.LOGIN_EXISTS].includes(errorCode)) {
                    setErrors((errors: any) => ({
                        ...errors,
                        phone: "Данный логин уже используется",
                    }));
                }
                if ([ErrorCode.INVITE_NOT_FOUND].includes(errorCode)) {
                    errorMessage = "Приглашение не найдено";
                }
                if ([ErrorCode.JOB_TITLE_NOT_FOUND].includes(errorCode)) {
                    setErrors((errors: any) => ({
                        ...errors,
                        jobTitleId: "Должность не найдена",
                    }));
                }
                // if ([ErrorCode.OFFICE_NOT_FOUND].includes(errorCode)) {
                //     setErrors((errors: any) => ({
                //         ...errors,
                //         officeId: "Офис не найден",
                //     }));
                // }
                // if (errorCode === ErrorCode.BLOCKED_USER_ALREADY_EXISTS) {
                //     setErrors((errors: any) => ({
                //         ...errors,
                //         email: (
                //             <>
                //                 Почта прикреплена к аккаунту{" "}
                //                 <a className="text-white font-semibold" href="/admin/members">
                //                     заблокированного пользователя
                //                 </a>
                //             </>
                //         ),
                //     }));
                // }

                errorMessage = "Не все поля формы заполнены правильно";
            }

            flash.error(errorMessage);
        }
    };

    const validatePrivate = useCallback(() => {
        const errorsMsgs: any = {};
        errorsMsgs["firstName"] = ValidateName(registrationRequest.firstName, true);
        errorsMsgs["lastName"] = ValidateName(registrationRequest.lastName, true);
        // errorsMsgs["middleName"] = ValidateName(registrationRequest.middleName, false);

        if (Object.keys(errorsMsgs).filter((p) => errorsMsgs[p]).length !== 0) {
            // setErrors(errorsMsgs);
            return false;
        }

        // setErrors({});
        return true;
    }, [registrationRequest.firstName, registrationRequest.lastName]);

    const validateJobs = useCallback(() => {
        const errorsMsgs: any = {};
        errorsMsgs["jobTitleId"] = !registrationRequest.jobTitleId ? "Поле обязательно для заполнения" : undefined;

        if (Object.keys(errorsMsgs).filter((p) => errorsMsgs[p]).length !== 0) {
            // setErrors(errorsMsgs);
            return false;
        }

        // setErrors({});
        return true;
    }, [registrationRequest.jobTitleId]);

    const validateAccount = useCallback(async () => {
        const errorsMsgs: any = {};

        errorsMsgs["login"] = ValidateLogin(registrationRequest.login);
        errorsMsgs["password"] = ValidatePassword(registrationRequest.password, !registrationRequest.inviteId);
        errorsMsgs["phone"] = (registrationRequest.phone as string)?.includes("_")
            ? "Поле заполнено некорректно"
            : undefined;

        // if (registrationRequest.login) {
        //     const existLogin = await Api.User.UserLoginValidation(new UserLoginValidationRequest(registrationRequest.login));
        //     errors["login"] = existLogin !== registrationRequest.login ? "Такой логин уже существует" : undefined;
        // }

        if (!errorsMsgs["phone"] && !!registrationRequest.phone) {
            const userPhoneValidationRequest: PhoneValidationRequest = {
                phone: registrationRequest.phone /* .replaceAll(/[\s-]/g, "") */,
            };

            try {
                await Api.Invites.PhoneValidation(userPhoneValidationRequest);
            } catch (e) {
                if (e instanceof BadRequestResponse) {
                    const { errorCode } = e;

                    if ([ErrorCode.PHONE_EXISTS].includes(errorCode)) {
                        setErrors((errors: any) => ({
                            ...errors,
                            phone: "Данный номер телефона уже используется",
                        }));
                    }
                }
            }
        }

        if (registrationRequest.email) {
            // errorsMsgs["email"] = ValidateEmail(registrationRequest.email);
        }

        if (Object.keys(errorsMsgs).filter((p) => errorsMsgs[p]).length !== 0) {
            setErrors(errorsMsgs);
            return false;
        }

        setErrors({});
        return true;
    }, [
        registrationRequest.email,
        registrationRequest.inviteId,
        registrationRequest.login,
        registrationRequest.phone,
        registrationRequest.password,
    ]);

    // Валидация
    useEffect(() => {
        const validateAsync = async () => {
            const valid = await validateAccount();
            setIsAccountDataValid(valid);
        };
        validateAsync();

        setIsPrivateDataValid(validatePrivate());

        setIsJobsDataValid(validateJobs());
    }, [registrationRequest, validateAccount, validatePrivate, validateJobs]);

    const breadcrumbsLinks = [
        {
            title: "Личные данные",
            onClick: () => {
                setActiveFormStep(0);
            },
        },
        {
            title: "Данные аккаунта",
            onClick: () => {
                setActiveFormStep(1);
            },
            validate: () => isPrivateDataValid,
        },
        {
            title: "Рабочие данные",
            onClick: () => {
                setActiveFormStep(2);
            },
            validate: () => isAccountDataValid,
        },
    ];

    const onLoginValidate = async () => {
        if (registrationRequest.login.length > 0) {
            const existLogin = await Api.Invites.LoginValidation({ login: registrationRequest.login });
            setRegistrationRequest((prev) => {
                const newRequest = Object.assign({}, prev);

                if (prev.login !== registrationRequest.login) {
                    return prev;
                }

                newRequest.login = existLogin;
                return newRequest;
            });
        }
    };

    const onPhoneValidate = async () => {
        if (registrationRequest.phone && (registrationRequest.phone as string).length > 0) {
            try {
                await Api.Invites.PhoneValidation({ phone: registrationRequest.phone /* .replaceAll(/[\s-]/g, "") */ });
            } catch (e) {
                if (e instanceof BadRequestResponse) {
                    const { errorCode } = e;

                    if ([ErrorCode.PHONE_EXISTS].includes(errorCode)) {
                        setErrors((errors: any) => ({
                            ...errors,
                            phone: "Данный номер телефона уже используется",
                        }));
                    }
                }
            }
        }
    };

    const onEmailValidate = async () => {
        if (registrationRequest.email) {
            try {
                await Api.Invites.EmailValidation({ email: registrationRequest.email, inviteId });
            } catch (e) {
                if (e instanceof BadRequestResponse) {
                    const { errorCode } = e;

                    if ([ErrorCode.EMAIL_EXISTS].includes(errorCode)) {
                        setErrors((errors: any) => ({
                            ...errors,
                            email: "Данная электронная почта уже используется",
                        }));
                    }
                }
            }
        }
    };

    return (
        <>
            {inviteState ? (
                <>
                    <Empty
                        className="bg-white !p-8.5 max-w-142 rounded-lg fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                        topElement={
                            <div className="flex-center mb-4">
                                <div className="flex-center w-16 h-16 rounded-full bg-blue-10">
                                    <Icon icon={Icons.EmojiSad} width={"36px"} height={"36px"} color={"fill-primary"} />
                                </div>
                            </div>
                        }
                        title={plugsData[inviteState as keyof typeof plugsData].title}
                        description={plugsData[inviteState as keyof typeof plugsData].description}
                    />
                </>
            ) : (
                <>
                    <AvatarEditor
                        isOpen={avatarEditor !== undefined}
                        type="circle"
                        title="Загрузка аватара"
                        img={avatarEditor ?? ""}
                        onDismiss={() => setAvatarEditor(undefined)}
                        onSubmit={(img: string, blob: Blob) => onChangeAvatar(new File([blob], "avatar.jpg"), img)}
                    />
                    <div className="w-full sm:max-w-125 sm:bg-white sm:rounded-2xl md:mt-0 xl:p-0">
                        <div
                            className={`sm:pt-10 sm:pb-7.5 sm:px-10 ${
                                inviteExistData.isFetching
                                    ? "min-h-[600px] flex flex-column items-center justify-center"
                                    : ""
                            }`}
                        >
                            {inviteExistData.isFetching ? (
                                <Loader />
                            ) : (
                                <>
                                    <h1 className="mb-4 sm:mb-2.5 sm:text-[32px] text-black">Регистрация</h1>
                                    <p className="mb-8 sm:mb-5 p1 sm:p2 text-blue-drk sm:text-gray">
                                        Пожалуйста, введите ваши данные для регистрации в системе обучения
                                    </p>
                                    <Breadcrumbs
                                        className="mb-2.5"
                                        id="adminTasksBreadcrumbs"
                                        activeItemIndex={activeFormStep}
                                        isLastActive={true}
                                    >
                                        {breadcrumbsLinks.map(({ title, validate, onClick }, index) => {
                                            const isEnabled =
                                                index < activeFormStep || (index > activeFormStep && validate?.());
                                            return (
                                                <span
                                                    key={title}
                                                    className={`text-blue-drk ${isEnabled ? "cursor-pointer" : ""} ${
                                                        index === activeFormStep ? "!text-black !cursor-default" : ""
                                                    }`}
                                                    onClick={() => {
                                                        if (index !== activeFormStep && isEnabled) {
                                                            onClick();
                                                        }
                                                    }}
                                                >
                                                    {title}
                                                </span>
                                            );
                                        })}
                                    </Breadcrumbs>
                                    <div className="mb-4 sm:mb-7.5 space-y-4 sm:space-y-6">
                                        {activeFormStep === 0 && (
                                            <PrivateSettings
                                                registrationRequest={registrationRequest}
                                                onChange={(request) => {
                                                    setRegistrationRequest(request);

                                                    setUserName({
                                                        firstName: (request as InviteRegistrationRequest).firstName,
                                                        lastName: (request as InviteRegistrationRequest).lastName,
                                                    });
                                                }}
                                                setAvatar={setAvatar}
                                                userName={userName}
                                                onSubmit={() => {
                                                    setActiveFormStep(1);
                                                }}
                                                // validate={validatePrivate}
                                                isValid={isPrivateDataValid}
                                                onLoginValidate={onLoginValidate}
                                                // errors={errors}
                                            />
                                        )}
                                        {activeFormStep === 1 && (
                                            <AccountSettings
                                                registrationRequest={registrationRequest}
                                                onChange={setRegistrationRequest}
                                                onSubmit={() => {
                                                    setActiveFormStep(2);
                                                }}
                                                // validate={validateAccount}
                                                isValid={isAccountDataValid}
                                                onLoginValidate={onLoginValidate}
                                                onPhoneValidate={onPhoneValidate}
                                                onEmailValidate={onEmailValidate}
                                                errors={errors}
                                            />
                                        )}
                                        {activeFormStep === 2 && (
                                            <JobSettings
                                                registrationRequest={registrationRequest}
                                                onChange={setRegistrationRequest}
                                                onSubmit={() => {
                                                    onSave(0);
                                                }}
                                                // validate={validateJobs}
                                                errors={errors}
                                                isValid={isJobsDataValid}
                                                inviteId={inviteId}
                                                jobOptions={jobOptions}
                                                setJobOptions={setJobOptions}
                                                officeOptions={officeOptions}
                                                setOfficeOptions={setOfficeOptions}
                                                isFetched={isFetched}
                                                setIsFetched={setIsFetched}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
