import { useState, useEffect } from "react";
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { BaseIdRequest1 } from "Api/BaseRequest";
import { CompleteMaterialRequest, StartMaterialRequest } from "Api/Requests/CourseProgressRequest";
import { CourseReadResponse } from "Api/Responses/CourseResponse";
import { CurrentUserResponse } from "Api/Responses/UserResponse";
import { MaterialReadResponse } from "Api/Responses/MaterialResponse";
import { CourseProgressResponse } from "Api/Responses/CourseProgressResponse";
import { CommonsAddFavoritesRequest, CommonsRemoveFavoritesRequest } from "Api/Requests/CommonsRequest";
import { setBackUrl, setIsFavoriteSelected } from "../../../slices/headerSlice";
import { useDispatch } from "react-redux";
import Api from "Api/index";
import { ResourceState } from "Enums";

const COURSE_KEY = "ui/course";

export const courseKeys = {
    course: (courseId: string) => [COURSE_KEY, courseId],
    courseProgress: (courseId: string, userId: string) => [COURSE_KEY, "progress", courseId, userId],
    courseMaterial: (materialId: string) => [COURSE_KEY, "material", materialId],
};

export function useGetUICourse<T = CourseReadResponse>(courseId: string, config?: UseQueryOptions<T, Error, T, any>) {
    const dispatch = useDispatch();

    return useQuery<T, Error, T, any>({
        queryKey: courseKeys.course(courseId),
        queryFn: async () => {
            const res = await Api.Course.ReadUI(courseId);
            dispatch(setIsFavoriteSelected(res.isFavorite));

            res.sections.forEach((section) => {
                section.components = section.components.sort((a, b) => {
                    if (a.state !== ResourceState.DELETED && b.state === ResourceState.DELETED) {
                        return -1;
                    } else if (b.state !== ResourceState.DELETED && a.state === ResourceState.DELETED) {
                        return 1;
                    }

                    return 0;
                });
            });

            return res;
        },
        enabled: !!courseId,
        ...config,
    });
}

export function useGetUICourseProgress<T = CourseProgressResponse>(
    courseId: string,
    config?: UseQueryOptions<T, Error, T, any>,
) {
    const queryClient = useQueryClient();
    const currentUser: CurrentUserResponse | undefined = queryClient.getQueryData(["users", "current"]);

    return useQuery<T, Error, T, any>({
        queryKey: courseId,
        queryFn: async () => {
            if (currentUser?.id && courseId) {
                return await Api.CourseProgress.Progress(courseId);
            }
        },
        enabled: !!currentUser?.id && !!courseId,
        ...config,
    });
}

export function useGetUICourseMaterial<T = MaterialReadResponse>(
    materialId: string,
    config?: UseQueryOptions<T, Error, T, any>,
) {
    const dispatch = useDispatch();

    return useQuery<T, Error, T, any>({
        queryKey: courseKeys.courseMaterial(materialId),
        queryFn: async () => {
            if (!materialId) {
                return;
            }

            const res = await Api.Material.Read(new BaseIdRequest1(materialId));
            dispatch(setIsFavoriteSelected(res.isFavorite));
            return res;
        },
        enabled: !!materialId,
        ...config,
    });
}

export function useStartMaterial() {
    const queryClient = useQueryClient();

    return useMutation(
        async function ({ courseId, componentId }: StartMaterialRequest) {
            return await Api.CourseProgress.StartMaterial({
                courseId,
                componentId,
            });
        },
        {
            onSuccess: async (data, variables) => {
                const currentUser: CurrentUserResponse | undefined = queryClient.getQueryData(["users", "current"]);

                await queryClient.invalidateQueries(courseKeys.course(variables.courseId));
                queryClient.setQueryData<CourseProgressResponse>(
                    courseKeys.courseProgress(variables.courseId, currentUser!.id),
                    (prevProgress) => {
                        return Object.assign(new CourseProgressResponse(), {
                            ...prevProgress,
                        });
                    },
                );
            },
        },
    );
}

export function useCompleteMaterial() {
    const [urlToNavigate, setUrlToNavigate] = useState("");
    const dispatch = useDispatch();

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    useEffect(() => {
        navigate(urlToNavigate, { replace: true });
    }, [urlToNavigate, navigate]);

    return useMutation(
        async function ({ courseId, componentId, timeSpent }: CompleteMaterialRequest) {
            return await Api.CourseProgress.CompleteMaterial({
                courseId,
                componentId,
                timeSpent: timeSpent,
            });
        },
        {
            onSuccess: async (data, variables) => {
                const currentUser: CurrentUserResponse | undefined = queryClient.getQueryData(["users", "current"]);

                await queryClient.invalidateQueries(courseKeys.course(variables.courseId));
                queryClient.setQueryData<CourseProgressResponse>(
                    courseKeys.courseProgress(variables.courseId, currentUser!.id),
                    (prevProgress) => {
                        return Object.assign(new CourseProgressResponse(), {
                            ...prevProgress,
                            timeSpent:
                                +(prevProgress ? prevProgress.completionTime - prevProgress?.startTime : 0) +
                                +variables.timeSpent,
                        });
                    },
                );

                if (data?.next?.resourceId) {
                    if (data.next.type === "QUIZ") {
                        dispatch(setBackUrl(`/training/course/${variables.courseId}`));
                        setUrlToNavigate(`/training/${variables.courseId}/${data.next.resourceId}`);
                    } else {
                        await Api.CourseProgress.StartMaterial({
                            courseId: variables.courseId,
                            componentId: data.next.id,
                        });
                        setUrlToNavigate(
                            variables.programId
                                ? `/training/program/${variables.programId}/${variables.courseId}/${data.next.id}/${data.next.resourceId}`
                                : `/training/course/${variables.courseId}/${data.next.id}/${data.next.resourceId}`,
                        );
                    }
                } else {
                    setUrlToNavigate(
                        variables.programId
                            ? `/training/program/${variables.programId}/${variables.courseId}/complete`
                            : `/training/course/${variables.courseId}/complete`,
                    );
                }
            },
        },
    );
}

export function useChangeCourseFavoriteStatus() {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(
        async function (course: CourseReadResponse) {
            if (!course?.isFavorite) {
                return await Api.Commons.addFavorites(
                    Object.assign(new CommonsAddFavoritesRequest(), {
                        resourceId: course.resourceId,
                        resourceSolutionId: course.progressId,
                        title: course?.title,
                        logoId: course?.logoId,
                        state: course?.state,
                        type: "COURSE",
                        progressStatus: course?.progressStatus,
                        ratingPoints: course?.maxRatingPoints,
                        deadlineTimestamp: course?.deadlineTimestamp ?? 0,
                    }),
                );
            } else if (course?.isFavorite) {
                return await Api.Commons.removeFavorites(
                    Object.assign(new CommonsRemoveFavoritesRequest(), {
                        resourceId: course.resourceId,
                        type: "COURSE",
                    }),
                );
            } else {
                return null;
            }
        },
        {
            onSuccess: (data, variables) => {
                queryClient.setQueryData<CourseReadResponse>(courseKeys.course(variables.resourceId), (prevCourse) => {
                    dispatch(setIsFavoriteSelected(!prevCourse?.isFavorite));

                    return Object.assign(new CourseReadResponse(), {
                        ...prevCourse,
                        isFavorite: !prevCourse?.isFavorite,
                    });
                });
            },
        },
    );
}

export function useChangeMaterialFavoriteStatus() {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(
        async function ({
            courseId,
            componentId,
            resourceId,
            materialType,
            // type,
            fileType,
        }: {
            courseId: string;
            componentId: string;
            resourceId: string;
            materialType: string;
            type?: string;
            fileType?: string;
        }) {
            const course = queryClient.getQueryData<CourseReadResponse>(courseKeys.course(courseId));
            const material = queryClient.getQueryData<MaterialReadResponse>(courseKeys.courseMaterial(resourceId));

            // let preview;

            // if (type === "QUIZ" && !material?.isFavorite) {
            //     preview = await Api.Test.UserRead(resourceId);
            // }

            let resource;

            for (const section of course!.sections) {
                for (const component of section.components) {
                    if (component.resourceId === resourceId) {
                        resource = component;
                    }
                }
            }

            if (!material?.isFavorite) {
                return await Api.Commons.addFavorites(
                    Object.assign(new CommonsAddFavoritesRequest(), {
                        resourceId,
                        componentId,
                        courseId,
                        materialType,
                        // resourceSolutionId: preview?.progressId,
                        resourceSolutionId: undefined,
                        materialFileType: materialType !== "VIDEO" ? fileType : null,
                        title: resource?.title,
                        logoId: resource?.logoId,
                        state: resource?.state,
                        type: resource?.type,
                        progressStatus: course?.progressStatus,
                        ratingPoints: resource?.ratingPoints,
                        deadlineTimestamp: course?.deadlineTimestamp ?? null,
                    }),
                );
            } else if (material?.isFavorite) {
                return await Api.Commons.removeFavorites(
                    Object.assign(new CommonsRemoveFavoritesRequest(), {
                        resourceId: resourceId,
                        type: resource?.type,
                    }),
                );
            } else {
                return null;
            }
        },
        {
            onSuccess: (data, variables) => {
                queryClient.setQueryData<MaterialReadResponse>(
                    courseKeys.courseMaterial(variables.resourceId),
                    (prevMaterial) => {
                        dispatch(setIsFavoriteSelected(!prevMaterial?.isFavorite));
                        return Object.assign(new MaterialReadResponse(), {
                            ...prevMaterial,
                            isFavorite: !prevMaterial?.isFavorite,
                        });
                    },
                );
            },
            onError: (error) => {
                console.log(error);
            },
        },
    );
}

export function useChangeSectionItemFavoriteStatus() {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(
        async function ({
            courseId,
            componentId,
            resourceId,
            materialType,
            // type,
            fileType,
        }: {
            courseId: string;
            componentId: string;
            resourceId: string;
            materialType: string;
            type?: string;
            fileType?: string;
        }) {
            const course = queryClient.getQueryData<CourseReadResponse>(courseKeys.course(courseId));
            let resource;

            for (const section of course!.sections) {
                for (const component of section.components) {
                    if (component.resourceId === resourceId) {
                        resource = component;
                    }
                }
            }

            // let preview = null;
            //
            // if (!resource?.isFavorite) {
            //     // preview = await Api.Test.UserRead(resourceId);
            //     preview = undefined;
            // }

            if (!resource?.isFavorite) {
                return await Api.Commons.addFavorites(
                    Object.assign(new CommonsAddFavoritesRequest(), {
                        resourceId,
                        componentId,
                        courseId,
                        materialType,
                        // resourceSolutionId: preview?.progressId,
                        resourceSolutionId: undefined,
                        materialFileType: materialType !== "VIDEO" ? fileType : null,
                        title: resource ? resource.title : undefined,
                        logoId: resource?.logoId,
                        state: resource?.state,
                        type: resource?.type,
                        progressStatus: course?.progressStatus,
                        ratingPoints: resource?.ratingPoints,
                        deadlineTimestamp: course?.deadlineTimestamp ?? null,
                    }),
                );
            } else if (resource?.isFavorite) {
                return await Api.Commons.removeFavorites(
                    Object.assign(new CommonsRemoveFavoritesRequest(), {
                        resourceId: resourceId,
                        type: resource?.type,
                    }),
                );
            } else {
                return null;
            }
        },
        {
            onSuccess: (data, variables) => {
                queryClient.setQueryData<CourseReadResponse>(courseKeys.course(variables.courseId), (prev) => {
                    for (const section of prev!.sections) {
                        for (const component of section.components) {
                            if (component.resourceId === variables.resourceId) {
                                component.isFavorite = !component.isFavorite;
                            }
                        }
                    }

                    dispatch(setIsFavoriteSelected(!prev?.isFavorite));
                    return prev || new CourseReadResponse();
                });
            },
            onError: (error) => {
                console.log(error);
            },
        },
    );
}

export function useChangePlayback() {
    const queryClient = useQueryClient();

    return useMutation(Api.Material.Playback, {
        onMutate: (variables) => {
            queryClient.setQueryData<MaterialReadResponse>(
                courseKeys.courseMaterial(variables.materialId),
                (oldMaterial) => {
                    return Object.assign(new MaterialReadResponse(), {
                        ...(oldMaterial ?? {}),
                        playback: {
                            ...oldMaterial?.playback,
                            playbackTimeInSeconds: variables.playbackTimeInSeconds,
                        },
                    });
                },
            );
        },
    });
}
