import React, {
    // ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { pickBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import { useDebounceValue } from "hooks/useDebounceValue";
import { Card } from "Components/Card";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { Filter } from "Components/Filter/Filter";
import { Button, Icon, Icons } from "Uikit/index";
// import { SearchInput } from "Uikit/Forms/SearchInput";
import { Empty } from "Uikit/Page/Empty";
import dayjs from "dayjs";
import { TrainingCardListMobile } from "./TrainingCardListMobile";
import Skeleton from "react-loading-skeleton";
import {
    trainingKeys,
    useGetTrainingAvailable,
    useGetTrainingRequired,
    useMutateTrainingFavoriteStatus,
} from "./training.hooks";
import { UiCoursesList, UiCoursesCategoryList } from "Api/Responses/CourseResponse";
import { ResourceType, MaterialTypesTranslate } from "Enums";
import { Preloader } from "Components/Preloader/Preloader";
import Api from "Api";
import { IOption } from "types";
import { useScreenSize } from "hooks/useMediaQuery";

const pageSize = 8;

const typeOptions = [
    {
        label: MaterialTypesTranslate.PROGRAM,
        value: ResourceType.PROGRAM,
    },
    {
        label: MaterialTypesTranslate.COURSE,
        value: ResourceType.COURSE,
    },
];

export const TrainingTabAll = ({ categoryFilterOptions }: { categoryFilterOptions: IOption[] }) => {
    const navigate = useNavigate();
    const observerElem = useRef<HTMLDivElement>(null);
    const queryClient = useQueryClient();

    const [
        query,
        // , setQuery
    ] = useState("");
    const [debounceValue] = useDebounceValue(query, 500);

    const [filters, setFilters] = useState<{ [id: string]: any }>({});
    const [isFilterShow, setIsFilterShow] = useState(false);

    const parsedFiltersData = useMemo(() => {
        const filtersData = {
            ...pickBy(filters, (a) => a !== null && a !== undefined && a !== ""),
        };
        const filtersKeys = Object.keys(filters);

        for (const element of filtersKeys) {
            if (element === "categoryTitle.in") {
                filtersData[element] = filtersData[element].map((c: any) => c.value);
            } else if (["ratingPoints", "averageReviewRating"].includes(element)) {
                const ratingPoints = [];

                console.log("minValue", filters[element]["minValue"]);
                console.log("maxValue", filters[element]["maxValue"]);
                if (filters[element]["minValue"]) {
                    ratingPoints.push(filters[element]["minValue"]);
                }
                if (filters[element]["maxValue"]) {
                    ratingPoints.push(filters[element]["maxValue"]);
                }
                if (ratingPoints.length) {
                    filtersData[`${element}.in`] = ratingPoints.join(",");
                }
                delete filtersData[element];
            } else if (element === "deadlineTimestamp.isNull") {
                filtersData[element] = !filtersData[element];
                // Без текстового отзыва
            } else {
                filtersData[element] = filters[element];
            }
        }

        // TODO: Добавить фильтр по типу на BE
        if (filtersData["type.equal"]) {
            filtersData["type.equal"] = filtersData["type.equal"].value;
        }

        filtersData["progressStatus.notEqual"] = "PASSED";

        return filtersData;
    }, [filters]);

    // const handleOnSearch = (e: ChangeEvent<HTMLInputElement> | null) => {
    //     setQuery(e ? e.target.value : "");
    // };

    const loadMore = async ({
        categoryTitle,
        page,
    }: {
        categoryTitle: string;
        page: number;
        [key: string]: string | number;
    }) => {
        try {
            const courses = await Api.Course.TrainingMore(page, pageSize, [], {
                ...filters,
                "progressStatus.notEqual": "PASSED",
                "categoryTitle.in": categoryTitle,
            });

            return courses.Content;
        } catch (error) {
            console.log(error);
        }
    };

    const {
        data: categories,
        isFetched: isCategoriesFetched,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useGetTrainingAvailable(parsedFiltersData);
    // const dataLength = categories?.pages[0]?.length ?? 0;

    const { data: requiredCourses, isFetched: isRequiredCoursesFetched } = useGetTrainingRequired(parsedFiltersData);
    const trainingFavoriteStatusMutation = useMutateTrainingFavoriteStatus(parsedFiltersData);

    const categoryCoursesMutation = useMutation(loadMore, {
        onSuccess: async (data: any, variables: any) => {
            queryClient.setQueryData(["training", parsedFiltersData], (/* oldData: any */) => {
                if (categories) {
                    const category = (categories?.pages as unknown as UiCoursesCategoryList[][])[
                        variables.pageIdx
                    ].find((c: any) => c.categoryTitle === variables.categoryTitle);
                    category?.items.push(...data);
                }

                return {
                    // ...oldData,
                    // pages: categories?.pages,
                    pages: variables.page,
                };
            });
        },
    });

    const onFavoriteChange = (resource: UiCoursesList, isFavorite: boolean, list: keyof typeof trainingKeys) => {
        trainingFavoriteStatusMutation.mutate({ resource, isFavorite, list });
    };

    const filtersConfigAll = [
        {
            label: "Тип",
            fields: [
                {
                    accessor: "type.equal",
                    type: "select",
                    placeholder: "Выберите тип",
                    options: typeOptions,
                    defaultValue: "",
                },
            ],
        },
        {
            label: "Категория",
            fields: [
                {
                    accessor: "categoryTitle.in",
                    type: "multi-select",
                    placeholder: "Выберите категорию",
                    options: categoryFilterOptions,
                },
            ],
        },
        {
            label: "Награда, баллы",
            fields: [
                {
                    accessor: "ratingPoints",
                    type: "range",
                    asyncLoadLimitsFunction: async () => {
                        const { minRatingPoints: minLimit, maxRatingPoints: maxLimit } =
                            await Api.Course.TrainingFilterInfo();

                        return {
                            minLimit,
                            maxLimit,
                        };
                    },
                },
            ],
        },
        // {
        //     label: "Оценка",
        //     fields: [
        //         {
        //             accessor: "averageReviewRating",
        //             type: "range",
        //             asyncLoadLimitsFunction: async () => {
        //                 const { minAverageReviewRating: minLimit, maxAverageReviewRating: maxLimit } =
        //                     await Api.Course.TrainingFilterInfo();
        //
        //                 return {
        //                     minLimit,
        //                     maxLimit,
        //                 };
        //             },
        //         },
        //     ],
        // },
        {
            label: "Дополнительно",
            fields: [
                {
                    accessor: "isRequired.equal",
                    label: "Обязательные",
                    type: "checkbox",
                },
                {
                    accessor: "deadlineTimestamp.isNull",
                    label: "С дедлайном",
                    type: "checkbox",
                },
            ],
        },
    ];

    const handleObserver = useCallback(
        (entries: any) => {
            const [target] = entries;
            if (target.isIntersecting && hasNextPage) {
                fetchNextPage().then();
            }
        },
        [fetchNextPage, hasNextPage],
    );

    useEffect(() => {
        const element = observerElem.current;
        const option = { threshold: 0 };

        const observer = new IntersectionObserver(handleObserver, option);
        if (element) {
            observer.observe(element);

            return () => observer.unobserve(element);
        }
    }, [fetchNextPage, hasNextPage, handleObserver]);
    useEffect(() => {
        setFilters((prevState) => {
            return {
                ...prevState,
                "resourceTitle.contains": debounceValue,
            };
        });
    }, [debounceValue]);

    const { size } = useScreenSize();
    const isSmall = size === "small";

    return (
        <>
            {isSmall && (
                <div
                    className="absolute top-3.5 right-10 block sm:hidden z-[100]"
                    onClick={() => setIsFilterShow(true)}
                >
                    <Icon icon={Icons.Filter} width={18} height={18} color="stroke-blue-drk" />
                </div>
            )}
            <Filter
                isActive={isFilterShow}
                setIsActive={setIsFilterShow}
                configuration={filtersConfigAll}
                filters={filters}
                onChange={setFilters}
            />
            {/*{(dataLength || Object.keys(filters).length !== 0 || query) && (*/}
            {/*    <div className="hidden sm:flex justify-between pb-5 2xl:pb-6.25 w-full">*/}
            {/*        <div>*/}
            {/*            <SearchInput value={query} onChange={handleOnSearch} />*/}
            {/*        </div>*/}
            {/*        <Button*/}
            {/*            variant="outline"*/}
            {/*            color="secondary"*/}
            {/*            size="medium"*/}
            {/*            className="border-[#E6E9ED] rounded-lg font-medium"*/}
            {/*            icon={*/}
            {/*                <Icon*/}
            {/*                    icon={Icons.Filter}*/}
            {/*                    width={20}*/}
            {/*                    height={20}*/}
            {/*                    color="stroke-blue"*/}
            {/*                    className="2xl:!w-6.25 2xl:!h-6.25"*/}
            {/*                />*/}
            {/*            }*/}
            {/*            iconPlacement={"left"}*/}
            {/*            onClick={() => setIsFilterShow(true)}*/}
            {/*            id="userTrainingBtnFilter"*/}
            {/*        >*/}
            {/*            Фильтры*/}
            {/*        </Button>*/}
            {/*    </div>*/}
            {/*)}*/}
            {(requiredCourses?.length ||
                categories?.pages.some((p: any) => p?.some((item: UiCoursesCategoryList) => item?.items?.length))) && (
                <div className="pb-24">
                    {!!requiredCourses?.length && (
                        <div
                            id="userTrainingRequired"
                            className="flex flex-wrap sm:gap-x-6.5 2xl:gap-x-8 gap-y-8 2xl:gap-y-9 pb-8 2xl:pb-9 mb-8 2xl:mb-9 border-b border-gray-blue"
                        >
                            {requiredCourses.map((i) => {
                                return (
                                    <div
                                        key={i.resourceId}
                                        className="w-full sm:w-[274px] 2xl:w-[342px] cursor-pointer"
                                        onClick={() => {
                                            let url = `/training/course/${i.resourceId}`;
                                            if (i.type === ResourceType.PROGRAM) {
                                                url = `/training/program/${i.resourceId}`;
                                            }

                                            navigate(url);
                                        }}
                                    >
                                        <Card
                                            className="w-full h-[60vw] sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51"
                                            points={i.ratingPoints}
                                            type={i.type}
                                            deadline={
                                                i.deadlineTimestamp ? dayjs(i.deadlineTimestamp * 1000) : undefined
                                            }
                                            required={true}
                                            logoId={i.logoId}
                                            isFavorite={i.isFavorite}
                                            onFavoriteChange={(isFavorite) =>
                                                onFavoriteChange(i, isFavorite, "listRequired")
                                            }
                                            score={i.averageReviewRating}
                                        />
                                        <MultiClumpTooltip
                                            className="pt-3"
                                            label={i.title}
                                            textClassName="2xl:text-2md"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {categories?.pages?.map((page, idx) => {
                        return page?.map((c, i) => {
                            const isLoading =
                                categoryCoursesMutation.isLoading &&
                                categoryCoursesMutation.variables?.categoryTitle === c.categoryTitle;

                            if (
                                c.items.every(
                                    (p) => requiredCourses?.findIndex((p1) => p1.resourceId === p.resourceId) !== -1,
                                )
                            ) {
                                return null;
                            }

                            return (
                                <div key={c.categoryTitle} className="sm:pb-8 2xl:pb-9">
                                    <div className="hidden sm:block">
                                        <div className="flex items-center pb-5">
                                            <h2
                                                className="pr-5 2xl:pr-6.25 2xl:!text-2xl 2xl:!leading-[35px]"
                                                id={`userTrainingTitle__${i}`}
                                            >
                                                {c.categoryTitle}
                                            </h2>
                                            <div className="p4 text-disabled-stroke 2xl:text-sm">{`Всего ${c.totalElements}`}</div>
                                        </div>
                                        <div
                                            className="flex flex-wrap sm:gap-x-6.5 2xl:gap-x-8 gap-y-9 pb-0"
                                            id={`userTrainingSection__${i}`}
                                        >
                                            {c.items.map((i: UiCoursesList) => {
                                                const {
                                                    // id,
                                                    resourceId,
                                                    ratingPoints,
                                                    type,
                                                    title,
                                                    logoId,
                                                    deadlineTimestamp,
                                                    isFavorite,
                                                    hasNewLabel,
                                                    averageReviewRating,
                                                } = i;

                                                if (
                                                    requiredCourses?.findIndex((p) => p.resourceId === i.resourceId) !==
                                                    -1
                                                ) {
                                                    return null;
                                                }

                                                return (
                                                    <div
                                                        key={resourceId}
                                                        className="w-60 sm:w-[274px] 2xl:w-[342px] cursor-pointer"
                                                        onClick={() => {
                                                            let url = `/training/course/${resourceId}`;
                                                            if (type === ResourceType.PROGRAM) {
                                                                url = `/training/program/${resourceId}`;
                                                            }

                                                            navigate(url);
                                                        }}
                                                    >
                                                        <Card
                                                            className="w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51"
                                                            points={ratingPoints}
                                                            type={type}
                                                            deadline={
                                                                deadlineTimestamp
                                                                    ? dayjs(deadlineTimestamp * 1000)
                                                                    : undefined
                                                            }
                                                            logoId={logoId}
                                                            isFavorite={isFavorite}
                                                            onFavoriteChange={(isFavorite) =>
                                                                onFavoriteChange(i, isFavorite, "listAvailable")
                                                            }
                                                            hasNewLabel={hasNewLabel}
                                                            score={averageReviewRating}
                                                        />
                                                        <MultiClumpTooltip
                                                            className="pt-3"
                                                            label={title}
                                                            textClassName="2xl:text-2md"
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    {isSmall && (
                                        <div className="block sm:hidden">
                                            <TrainingCardListMobile
                                                key={c.categoryTitle}
                                                id={`userTests__${i}`}
                                                title={c.categoryTitle}
                                                data={c.items}
                                                showStatusChangeTime={false}
                                            />
                                        </div>
                                    )}
                                    {c.totalElements > pageSize && c.items.length < c.totalElements && (
                                        <Button
                                            className="hidden sm:block w-full mt-9"
                                            color="secondary"
                                            variant="outline"
                                            loading={isLoading}
                                            onClick={() => {
                                                if (isLoading) {
                                                    return;
                                                }
                                                categoryCoursesMutation.mutate({
                                                    categoryTitle: c.categoryTitle,
                                                    page: Math.floor(c.items.length / pageSize),
                                                    pageIdx: idx,
                                                });
                                            }}
                                        >
                                            {isLoading ? "" : "Посмотреть ещё"}
                                        </Button>
                                    )}
                                </div>
                            );
                        });
                    })}
                </div>
            )}
            <div className="relative h-full" ref={observerElem}>
                <Preloader
                    className="sm:grid sm:grid-cols-[repeat(4,274px)] 2xl:grid-cols-[repeat(4,342px)] gap-y-5 sm:gap-x-6.5 2xl:gap-x-8 sm:gap-y-9"
                    isShow={!requiredCourses || !categories || (isFetchingNextPage && !!hasNextPage)}
                >
                    {Array.from(Array(16).keys()).map((p) => {
                        return (
                            <div key={p}>
                                <div className="w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 rounded-2xl overflow-hidden leading-0">
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                                <div className="leading-5 line-clamp-2 pt-3">
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                            </div>
                        );
                    })}
                </Preloader>
                {!(!requiredCourses || !categories || (isFetchingNextPage && !!hasNextPage)) &&
                    !(
                        requiredCourses?.length ||
                        categories?.pages.some((p: any) =>
                            p?.some((item: UiCoursesCategoryList) => item?.items?.length),
                        )
                    ) && (
                        <div className="h-full flex justify-center">
                            <Empty
                                title="Ничего не найдено"
                                description="По заданным параметрам результатов нет"
                                topElement={
                                    <div className="flex-center mb-4 2xl:mb-5">
                                        <div className="flex-center w-16.5 2xl:w-20.5 h-16.5 2xl:h-20.5 rounded-full bg-blue-10">
                                            <Icon
                                                icon={Icons.EmojiSad}
                                                width={"36px"}
                                                height={"36px"}
                                                color={"fill-primary"}
                                                className="2xl:!w-11.25 2xl:!h-11.25"
                                            />
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    )}
                {isRequiredCoursesFetched &&
                    isCategoriesFetched &&
                    requiredCourses?.length === 0 &&
                    !categories?.pages.some((p: any) => p?.length) && (
                        <>
                            {Object.keys(filters).length === 0 && !query && (
                                <div className="h-full flex justify-center">
                                    <Empty
                                        title="Всё пройдено"
                                        description={
                                            <span>
                                                Вы прошли все назначенные на вас
                                                <br />
                                                {/*программы и курсы*/}
                                                курсы
                                            </span>
                                        }
                                        topElement={
                                            <div className="flex-center mb-4 2xl:mb-5">
                                                <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                                    <Icon
                                                        icon={Icons.Check}
                                                        width={48}
                                                        height={48}
                                                        color={"fill-primary"}
                                                        className="2xl:!w-15 2xl:!h-15"
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                            )}
                            {(Object.keys(filters).length > 0 || query) && (
                                <div className="h-full flex justify-center">
                                    <Empty
                                        title="Ничего не найдено"
                                        description="По заданным параметрам результатов нет"
                                        topElement={
                                            <div className="flex-center mb-4 2xl:mb-5">
                                                <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                                    <Icon
                                                        icon={Icons.EmojiSad}
                                                        width={"36px"}
                                                        height={"36px"}
                                                        color={"fill-primary"}
                                                        className="2xl:!w-11.25 2xl:!h-11.25"
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                            )}
                        </>
                    )}
            </div>
        </>
    );
};
