import { MaterialInteractiveType, MaterialQuestionType } from "Enums";
import { BaseIdRequest, BasePaginationRequest, BaseRequest } from "../BaseRequest";

export class MaterialQuestionAnswer {
    public Id: number;
    public Title: string;
    public IsRight: boolean;
}

export class MaterialQuestion {
    public Id: number;
    public Image: string;
    public Title: string;
    public Type: MaterialQuestionType;
    public Answers: MaterialQuestionAnswer[];
}

export class MaterialInteractive {
    public Id: number;
    public Time: number;
    public Title: string;
    public Type: MaterialInteractiveType;
    public Answers: MaterialQuestionAnswer[];
}

export class MaterialCreateRequest extends BaseRequest {
    public type:
        | "ARTICLE"
        // "DOCUMENT" |
        | "VIDEO"
        // | "SCORM"
        | "HTML";
    public state: string;
    public categoryId: string;
    public logoId: string;
    public managerUserId: string;
    public complexity: string;
    public scoreRewriteLimit: number | null;
    public hasQuestions: boolean;
    public questionsRequired: boolean;
    public allowComments: boolean;
    public allowRewind: boolean;
    public approxCompletionMinutes: number | null;
    public isApproxCompletionMinutesManuallyChanged?: boolean;
    public articleBody: string;
    public attachedFileIds: string[];
    public title: string;
    public description: string;
    public content: string | null;
    public fileId: string;
    public fileType?: string;
    // public isTraining: boolean;

    public Questions: MaterialQuestion[];
    public Interactives: MaterialInteractive[];
}

export class MaterialEditRequest extends BaseIdRequest {
    public id: string;
    public type:
        | "ARTICLE"
        // | "DOCUMENT"
        | "VIDEO"
        // | "SCORM"
        | "HTML";
    public state: string;
    public categoryId: string;
    public logoId: string;
    public managerUserId: string;
    public complexity: string;
    public scoreRewriteLimit: number | null;
    public hasQuestions: boolean;
    public questionsRequired: boolean;
    public allowComments: boolean;
    public allowRewind: boolean;
    public approxCompletionMinutes: number | null;
    public isApproxCompletionMinutesManuallyChanged?: boolean;
    public articleBody: string;
    public attachedFileIds: string[];
    public title: string;
    public description: string;
    public content: string | null;
    public fileId: string;
    public fileType?: string;
    public isTraining: boolean;

    public Questions: MaterialQuestion[];
    public Interactives: MaterialInteractive[];
}

export class MaterialListRequest extends BasePaginationRequest {
    public "categoryId.equal": string | null;
    public "categoryId.in": string[] | null;
    public "managerUserId.equal": string | null;
}

export class MaterialChangeStateRequest extends BaseRequest {
    public resourceIds: string[];
    public state: string;
}

export class MaterialReorderRequest extends BaseRequest {
    public categoryIds: string[];
}

export class MaterialUnArchiveRequest extends BaseRequest {
    public categoryId: string;
    public ids: string[];
}

export class PlaybackRequest extends BaseRequest {
    public materialId: string;
    public playbackTimeInSeconds: number;
}
