import React from "react";
import { Button } from "Uikit/Button/Button";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { CourseRequestSectionItem } from "Api/Requests/CourseRequest";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";
import { MaterialTypesTranslate, ResourceState, ResourceType } from "Enums";
import { TVoidFunction } from "types";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";

interface ICourseContentItem {
    item: CourseRequestSectionItem;
    treeItem: any;
    onRemove: TVoidFunction;
    isLast: boolean;
}
export const CourseContentItem = ({ item, treeItem, onRemove, isLast }: ICourseContentItem) => {
    const { listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
        id: item.resourceId,
    });

    const style = {
        transform: CSS.Transform.toString(
            transform ? { x: transform?.x, y: transform?.y, scaleX: transform?.scaleX, scaleY: 1 } : null,
        ),
        transition,
    };

    const getLink = () => {
        if (treeItem.type === ResourceType.ARTICLE) {
            return `/admin/material/ARTICLE/${treeItem.id}`;
        } else if (treeItem.type === ResourceType.VIDEO) {
            return `/admin/material/VIDEO/${treeItem.id}`;
        }
        // else if (treeItem.type === ResourceType.DOCUMENT) {
        //     return `/admin/material/DOCUMENT/${treeItem.id}`;
        // }
        // else if (treeItem.type === ResourceType.SCORM) {
        //     return `/admin/material/SCORM/${treeItem.id}`;
        // }
        // else if (treeItem.type === ResourceType.QUIZ) {
        //     return `/admin/test/${treeItem.id}`;
        // }
    };

    return (
        <div className="flex items-center cursor-default group" ref={setNodeRef} style={style}>
            {treeItem.logoId && (
                <img
                    className={clsx(
                        "rounded-md w-[42px] h-7.5 object-cover cursor-pointer",
                        treeItem.state === ResourceState.HIDDEN ? "!opacity-50" : "",
                    )}
                    src={Api.Upload.GetLogo(treeItem.logoId, LogoSize.THUMB_MICRO)}
                    alt={treeItem.name}
                    onClick={() => window.open(getLink())}
                />
            )}
            {!treeItem.logoId && <div className="rounded-md w-[42px] h-7.5 bg-gray-blue cursor-pointer" />}
            <div
                className={`flex items-center justify-between ml-4 py-2 w-full border-b border-[#EAEDF3] gap-4 ${
                    isLast ? "border-b-0" : ""
                }`}
            >
                <MultiClumpTooltip
                    label={treeItem.name}
                    clamp={1}
                    className={clsx(
                        "cursor-pointer group-hover:text-blue",
                        item.state === ResourceState.HIDDEN ? "!opacity-50" : "",
                    )}
                    onClick={() => window.open(getLink())}
                ></MultiClumpTooltip>
                <div className="flex items-center h-7.5">
                    <span className="mr-20 text-[#939393]">
                        {treeItem.type === ResourceType.ARTICLE && MaterialTypesTranslate.ARTICLE}
                        {treeItem.type === ResourceType.VIDEO && MaterialTypesTranslate.VIDEO}
                        {/*{treeItem.type === ResourceType.DOCUMENT && String(treeItem.file?.extension).toUpperCase()}*/}
                        {/*{treeItem.type === ResourceType.QUIZ && MaterialTypesTranslate.QUIZ}*/}
                        {/*{treeItem.type === ResourceType.SCORM && MaterialTypesTranslate.SCORM}*/}
                    </span>
                    <Button
                        variant="outline"
                        color="common"
                        className="mr-4 !p-0 border-0 focus:!ring-0 !bg-transparent"
                        onClick={onRemove}
                    >
                        <Icon icon={Icons.Close} width={20} height={20} />
                    </Button>
                    <div ref={setActivatorNodeRef} {...listeners}>
                        <Button
                            variant="outline"
                            color="common"
                            className="!p-0 border-0 focus:!ring-0 cursor-grab !bg-transparent"
                        >
                            <Icon icon={Icons.Grid} width={20} height={20} />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
