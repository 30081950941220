import { faker } from "@faker-js/faker";
import { UserActiveListResponse, UserResponse, UserAccessResponse } from "Api/Responses/UserResponse";
import {
    CommentListResponse,
    HistoryListResponse,
    RemarkListResponse,
    ReviewListResponse,
    TaskListTestResponse,
    TestListResponse,
    TestListResponseField,
} from "Api/Responses/VerificationResponse";
import { makeData } from "./fetchData";
import { MaterialListResponse } from "Api/Responses/MaterialResponse";
import {
    HistoryType,
    MaterialType,
    TestQuestionStatus,
    ValidationStatus,
    Progress as Progress2,
    ResourceState,
} from "Enums";
import { CourseListResponse } from "Api/Responses/CourseResponse";
import { AccessGetUserResponse, AccessItem } from "Api/Responses/AccessResponse";
import { ArchiveListResponse } from "Api/Responses/ArchiveResponse";
import { InviteListResponse } from "Api/Responses/InviteResponse";
import { BasePaginationResponse } from "Api/BaseResponse";
import { JobResponse } from "Api/Responses/JobResponse";
import { OfficeResponse } from "Api/Responses/OfficeResponse";

const compareFn = (a: any, b: any, sort: any) => {
    if (!sort) return 0;
    const { id, desc } = sort;
    if (a[id] < b[id]) {
        return desc ? 1 : -1;
    } else if (a[id] > b[id]) {
        return desc ? -1 : 1;
    }
    return 0;
};

export type Person = {
    key: string;
    firstName: string;
    lastName: string;
    avatar: string;
    age: number;
    position: string;
    role: string;
    teams: string[];
    visits: number;
    progress: number;
    lastSeen: string;
    status: "active" | "blocked" | "removed";
    subRows?: Person[];
    courses: Course[];
};

export type User = {
    key: string;
    Id: number;
    Image: string;
    FirstName: string;
    LastName: string;
    MiddleName: string;
    Login: string;
    Email: string;
};

export type Course = {
    key: string;
    title: string;
};

export type Achievement = {
    key: string;
    id: string;
    image: string;
    title: string;
    points: number;
    issued: number;
    limit: number;
};

export type NewsItem = {
    key: string;
    title: string;
    image: string;
    status: EStatus;
    priority: NewsItemPriority;
    date: Date;
};

export type InterviewsItem = {
    key: string;
    title: string;
    image: string;
    status: EStatus;
    questionsCount: number;
    date: Date;
};

export type LibraryItem = {
    key: string;
    title: string;
    isFolder: boolean;
    type: "folder" | "file";
    extention?: string;
    accessCount?: number;
    size?: number;
    date: Date;
};

export type Task = {
    Id: number;
    User: UserResponse;
    Title: string;
    Description: string;
    Image: string;
    Fields: TaskField[];
    SentDate: Date;
    Comment: string;
};

export type Test = {
    Id: number;
    User: UserResponse;
    Title: string;
    Description: string;
    Image: string;
    Status: ValidationStatus;
    Fields: TestField[];
    SentDate: Date;
    Comment: string;
};

export type TaskFieldOption = {
    Value: string;
    Title: string;
};

export type TaskField = {
    Id: number;
    Type: "text" | "oneOf" | "severalOf" | "file";
    Title: string;
    Description: string;
    Answer: TaskFieldAnswer;
    Comment: string;
};

export type TestField = {
    Id: number;
    Type: "text" | "number" | "oneOf" | "severalOf";
    Title: string;
    Points: number;
    Answer: TaskFieldAnswer;
    Comment: string;
};

export type Inviteteam = {
    id: string;
    name: string;
};

export type Invite = {
    id: string;
    createdAt: number;
    state: string;
    requiresAction: boolean;
    title: string;
    registeredUserCount: number;
    teams: Inviteteam[];
};

export enum EStatus {
    ACTIVE = "active",
    HIDDEN = "hidden",
}

export enum NewsItemPriority {
    REGULAR = "regular",
    URGENT = "urgent",
}

export enum Progress {}
// DONE = "Пройден",
// IN_PROCESS = "В процессе",

interface TaskFieldAnswer {
    Value: any;
}

interface TaskFieldAnswerText extends TaskFieldAnswer {
    Value: string;
}

interface TaskFieldAnswerOneOf extends TaskFieldAnswer {
    Value: TaskFieldOption;
    Options: TaskFieldOption[];
}

interface TaskFieldAnswerSeveralOf extends TaskFieldAnswer {
    Value: TaskFieldOption[];
    Options: TaskFieldOption[];
}

interface TaskFieldAnswerFile extends TaskFieldAnswer {
    Value: {
        Name: string;
        Size: number;
        Type: string;
        Extension: string;
        TumbUrl: string;
        FullSizeUrl: string;
    };
}

const range = (len: number) => {
    const arr = [];
    for (let i = 0; i < len; i++) {
        arr.push(i);
    }
    return arr;
};

const newActiveUser = (): UserActiveListResponse => {
    return {
        Id: faker.datatype.number({ min: 1, max: 5000000 }),
        Image: faker.image.abstract(54, 36, true),
        FirstName: faker.name.firstName(),
        LastName: faker.name.lastName(),
        MiddleName: faker.name.middleName(),
        Position: faker.helpers.shuffle([
            "Начальник отдела маркетинга",
            "Оператор call-центра",
            "Наставник по обучению",
            "Менеджер продаж",
        ])[0]!,
        Role: faker.helpers.shuffle<Person["role"]>([
            "Участник",
            // "Администратор",
            "Наставник",
            "Контент-менеджер",
        ])[0]!,
        Commands: faker.helpers.shuffle(["Кураторы", "Руководители отделов", "Менеджеры"]),
        Online: faker.date.past(),
    };
};

const newAccessItem = (): AccessItem => {
    return {
        Id: faker.datatype.number({ min: 1, max: 5000000 }),
        Image: faker.image.abstract(54, 36, true),
        Title: faker.helpers.arrayElement([
            "Как говорить на языке клиента",
            "Бухгалтерский учет и налогообложение",
            "Онлайн-курс WEB разработка Full-stack",
            "Авторский стиль в фотографии",
        ]),
        Evaluation: faker.datatype.number({ min: 1, max: 5 }),
        Points: faker.datatype.number({ min: 25, max: 100 }),
        Update: faker.date.past(),
        Progress: faker.helpers.arrayElement([Progress2.IN_PROGRESS, Progress2.NOT_STARTED, Progress2.DONE]),
        IsHidden: faker.datatype.boolean(),
    };
};

const newUserAccess = (): AccessGetUserResponse => {
    return {
        Programs: makeData(newAccessItem, 4),
        Courses: makeData(newAccessItem, 4),
        // Tests: makeData(newAccessItem, 4),
        Tasks: makeData(newAccessItem, 4),
        Polls: makeData(newAccessItem, 4),
    };
};

const newUserMemberAccess = (): UserAccessResponse => {
    return {
        Id: faker.datatype.uuid(),
        Image: faker.image.abstract(54, 36, true),
        Title: faker.lorem.words(),
        Grade: faker.datatype.number({ min: 0, max: 10, precision: 0.1 }),
        Score: faker.datatype.number(100),
        Updated: faker.date.past(),
        Progress: faker.helpers.shuffle(["Пройден", "В процессе", "Не начат"])[0]!,
        TargetItemId: faker.datatype.uuid(),
    };
};

const newUser = (): User => {
    return {
        key: faker.datatype.uuid(),
        Id: faker.datatype.number(50000000),
        Image: faker.image.avatar(),
        FirstName: faker.name.firstName(),
        LastName: faker.name.lastName(),
        MiddleName: faker.name.middleName(),
        Login: "login",
        Email: faker.helpers.fake("{{internet.email}}"),
    };
};

const newNewsItem = (): NewsItem => {
    return {
        key: faker.datatype.uuid(),
        image: faker.image.abstract(54, 36),
        title: faker.lorem.words(),
        status: [EStatus.ACTIVE, EStatus.HIDDEN][Math.floor(Math.random() * 2)],
        priority: [NewsItemPriority.REGULAR, NewsItemPriority.URGENT][Math.floor(Math.random() * 2)],
        date: faker.date.past(),
    };
};

const interviewsItem = (): InterviewsItem => {
    return {
        key: faker.datatype.uuid(),
        image: faker.image.abstract(54, 36),
        title: faker.lorem.words(),
        status: [EStatus.ACTIVE, EStatus.HIDDEN][Math.floor(Math.random() * 2)],
        questionsCount: Math.floor(Math.random() * 10),
        date: faker.date.past(),
    };
};

function typeMime(extension: string) {
    switch (extension) {
        case "pdf":
            return "application/pdf";
        case "doc":
            return "application/msword";
        case "png":
            return "image/png";
        case "rtf":
            return "application/rtf";
        case "txt":
            return "text/plain";
        default:
            return extension;
    }
}

const newTaskField = (): TaskField => {
    const type = faker.helpers.arrayElement(["text", "oneOf", "severalOf", "file"]);
    let answer: TaskFieldAnswer;

    if (type === "text") {
        answer = {
            Value: faker.helpers.arrayElement([
                "Lessons and insights from 8 years of Pixelgrade",
                "How to choose the right colors when creating a website?",
                "The unseen of spending three years at Pixelgrade",
                "Start a blog to reach your creative peak",
                "How to optimize images in WordPress for faster load",
                "How to choose the right customer for your photo bus",
                "Why choose a theme that looks good with WooCom",
                "Caring is the new marketing",
                "Where to grow your business as a photographer: site",
                "How a visual artist redefines success in graphic design",
                "How to build a loyal community online and offline",
                "Starting your traveling blog with Vasco",
                "Travelling as a way of self-discovery and progress",
            ]),
        } as TaskFieldAnswerText;
    } else if (type === "oneOf") {
        const answerValue = { Value: faker.lorem.words(1), Title: faker.lorem.words(2) } as TaskFieldOption;
        const options = faker.helpers.shuffle([
            { Value: faker.lorem.words(1), Title: faker.lorem.words(2) } as TaskFieldOption,
            { Value: faker.lorem.words(1), Title: faker.lorem.words(2) } as TaskFieldOption,
            answerValue,
        ]);
        answer = { Value: answerValue, Options: options } as TaskFieldAnswerOneOf;
    } else if (type === "severalOf") {
        const answerValue = [
            { Value: faker.lorem.words(1), Title: faker.lorem.words(2) } as TaskFieldOption,
            { Value: faker.lorem.words(1), Title: faker.lorem.words(2) } as TaskFieldOption,
        ];
        const options = faker.helpers.shuffle([
            { Value: faker.lorem.words(1), Title: faker.lorem.words(2) } as TaskFieldOption,
            { Value: faker.lorem.words(1), Title: faker.lorem.words(2) } as TaskFieldOption,
            answerValue[0],
            answerValue[1],
        ]);
        answer = { Value: answerValue, Options: options } as TaskFieldAnswerSeveralOf;
    } else {
        const extension = faker.helpers.arrayElement(["pdf", "doc", "png", "rtf", "txt"]);
        const type = typeMime(extension);

        answer = {
            Value: {
                Name: faker.system.commonFileName(extension),
                Size: faker.datatype.number(5000),
                Extension: extension,
                Type: type,
                TumbUrl: faker.image.imageUrl(180, 180, "cat", true),
                FullSizeUrl: faker.image.imageUrl(1800, 1800, "cat", true),
            },
        } as TaskFieldAnswerFile;
    }

    return {
        Id: faker.datatype.number(5000000),
        Type: type as "text" | "severalOf" | "oneOf" | "file",
        Title: faker.helpers.arrayElement([
            "Lessons and insights from 8 years of Pixelgrade",
            "How to choose the right colors when creating a website?",
            "The unseen of spending three years at Pixelgrade",
            "Start a blog to reach your creative peak",
            "How to optimize images in WordPress for faster load",
            "How to choose the right customer for your photo bus",
            "Why choose a theme that looks good with WooCom",
            "Caring is the new marketing",
            "Where to grow your business as a photographer: site",
            "How a visual artist redefines success in graphic design",
            "How to build a loyal community online and offline",
            "Starting your traveling blog with Vasco",
            "Travelling as a way of self-discovery and progress",
        ]),
        Description: faker.lorem.paragraph(1),
        Answer: answer,
        Comment: faker.helpers.arrayElement([
            "Lessons and insights from 8 years of Pixelgrade",
            "Нет сомнений, что сторонники тоталитаризма в науке будут заблокированы в рамках своих собственных рациональных ограничений своих собственных рациональных ограничений",
            "How to choose the right colors when creating a website?",
            "The unseen of spending three years at Pixelgrade",
            "Start a blog to reach your creative peak",
            "How to optimize images in WordPress for faster load",
            "How to choose the right customer for your photo bus",
            "Why choose a theme that looks good with WooCom",
            "Caring is the new marketing",
            "Where to grow your business as a photographer: site",
            "How a visual artist redefines success in graphic design",
            "How to build a loyal community online and offline",
            "Starting your traveling blog with Vasco",
            "Travelling as a way of self-discovery and progress",
        ]),
    };
};

const newTestField = (testStatus: ValidationStatus): TestListResponseField => {
    const type = faker.helpers.arrayElement(["text", "number", "oneOf", "severalOf"]);

    let status;
    if (
        testStatus === ValidationStatus.Approved ||
        testStatus === ValidationStatus.Declined ||
        testStatus === ValidationStatus.Fixed
    ) {
        status = faker.helpers.arrayElement([TestQuestionStatus.Approved, TestQuestionStatus.Declined]);
    } else {
        status = TestQuestionStatus.In_Progress;
    }

    let answer: TaskFieldAnswer;
    if (type === "text") {
        answer = {
            Value: faker.helpers.arrayElement([
                "Lessons and insights from 8 years of Pixelgrade",
                "How to choose the right colors when creating a website?",
                "The unseen of spending three years at Pixelgrade",
                "Start a blog to reach your creative peak",
                "How to optimize images in WordPress for faster load",
                "How to choose the right customer for your photo bus",
                "Why choose a theme that looks good with WooCom",
                "Caring is the new marketing",
                "Where to grow your business as a photographer: site",
                "How a visual artist redefines success in graphic design",
                "How to build a loyal community online and offline",
                "Starting your traveling blog with Vasco",
                "Travelling as a way of self-discovery and progress",
            ]),
        } as TaskFieldAnswerText;
    } else if (type === "number") {
        answer = {
            Value: faker.datatype.number(100),
        };
    } else if (type === "oneOf") {
        const answerValue = { Value: faker.lorem.words(1), Title: faker.lorem.words(2) } as TaskFieldOption;
        const options = faker.helpers.shuffle([
            { Value: faker.lorem.words(1), Title: faker.lorem.words(2) } as TaskFieldOption,
            { Value: faker.lorem.words(1), Title: faker.lorem.words(2) } as TaskFieldOption,
            answerValue,
        ]);
        answer = { Value: answerValue, Options: options } as TaskFieldAnswerOneOf;
    } else {
        const answerValue = [
            { Value: faker.lorem.words(1), Title: faker.lorem.words(2) } as TaskFieldOption,
            { Value: faker.lorem.words(1), Title: faker.lorem.words(2) } as TaskFieldOption,
        ];
        const options = faker.helpers.shuffle([
            { Value: faker.lorem.words(1), Title: faker.lorem.words(2) } as TaskFieldOption,
            { Value: faker.lorem.words(1), Title: faker.lorem.words(2) } as TaskFieldOption,
            answerValue[0],
            answerValue[1],
        ]);
        answer = { Value: answerValue, Options: options } as TaskFieldAnswerSeveralOf;
    }

    return {
        Id: faker.datatype.number(5000000),
        Type: type as "text" | "number" | "oneOf" | "severalOf", // , "severalOf", "file", "image"
        Title: faker.helpers.arrayElement([
            "Lessons and insights from 8 years of Pixelgrade",
            "How to choose the right colors when creating a website?",
            "The unseen of spending three years at Pixelgrade",
            "Start a blog to reach your creative peak",
            "How to optimize images in WordPress for faster load",
            "How to choose the right customer for your photo bus",
            "Why choose a theme that looks good with WooCom",
            "Caring is the new marketing",
            "Where to grow your business as a photographer: site",
            "How a visual artist redefines success in graphic design",
            "How to build a loyal community online and offline",
            "Starting your traveling blog with Vasco",
            "Travelling as a way of self-discovery and progress",
        ]),
        Points: faker.datatype.number({
            min: 1,
            max: 100,
        }),
        Answer: answer,
        Status: status,
        Comment: faker.helpers.arrayElement([
            "Lessons and insights from 8 years of Pixelgrade",
            "Нет сомнений, что сторонники тоталитаризма в науке будут заблокированы в рамках своих собственных рациональных ограничений своих собственных рациональных ограничений",
            "How to choose the right colors when creating a website?",
            "The unseen of spending three years at Pixelgrade",
            "Start a blog to reach your creative peak",
            "How to optimize images in WordPress for faster load",
            "How to choose the right customer for your photo bus",
            "Why choose a theme that looks good with WooCom",
            "Caring is the new marketing",
            "Where to grow your business as a photographer: site",
            "How a visual artist redefines success in graphic design",
            "How to build a loyal community online and offline",
            "Starting your traveling blog with Vasco",
            "Travelling as a way of self-discovery and progress",
        ]),
    };
};

const newValidationTask = (): TaskListTestResponse => {
    const status = faker.helpers.arrayElement([
        ValidationStatus.Approved,
        ValidationStatus.Fixed,
        ValidationStatus.Declined,
        ValidationStatus.In_Progress,
    ]);
    return {
        Id: faker.datatype.number(5000000),
        User: newUser(),
        Supervisor: newUser(),
        Title: faker.helpers.arrayElement([
            "Lessons and insights from 8 years of Pixelgrade",
            "How to choose the right colors when creating a website?",
            "The unseen of spending three years at Pixelgrade",
            "Start a blog to reach your creative peak",
            "How to optimize images in WordPress for faster load",
            "How to choose the right customer for your photo bus",
            "Why choose a theme that looks good with WooCom",
            "Caring is the new marketing",
            "Where to grow your business as a photographer: site",
            "How a visual artist redefines success in graphic design",
            "How to build a loyal community online and offline",
            "Starting your traveling blog with Vasco",
            "Travelling as a way of self-discovery and progress",
        ]),
        Description: faker.lorem.paragraph(2),
        Image: faker.image.abstract(40, 40, true),
        Points: status !== ValidationStatus.In_Progress ? faker.datatype.number(100) : 100,
        Status: status,
        Fields: makeData(newTaskField, 4),
        SentDate: faker.date.between("2020-01-01T00:00:00.000Z", "2022-01-01T00:00:00.000Z"),
        Comment:
            "Нет сомнений, что сторонники тоталитаризма в науке будут заблокированы в рамках своих собственных рациональных ограничений. Ясность нашей позиции очевидна: сплочённость команды профессионалов является качественно новой ступенью распределения внутренних резервов и ресурсов. Высокий уровень вовлечения представителей целевой аудитории является четким доказательством простого факта: постоянное информационно-пропагандистское обеспечение нашей деятельности однозначно определяет каждого участника как способного принимать собственные решения касаемо инновационных методов управления процессами",
    };
};

const newTest = (): TestListResponse => {
    const status = faker.helpers.arrayElement([
        ValidationStatus.Approved,
        ValidationStatus.Fixed,
        ValidationStatus.Declined,
        ValidationStatus.In_Progress,
    ]);
    return {
        Id: faker.datatype.number(5000000),
        User: newUser(),
        Supervisor: newUser(),
        Title: faker.helpers.arrayElement([
            "Lessons and insights from 8 years of Pixelgrade",
            "How to choose the right colors when creating a website?",
            "The unseen of spending three years at Pixelgrade",
            "Start a blog to reach your creative peak",
            "How to optimize images in WordPress for faster load",
            "How to choose the right customer for your photo bus",
            "Why choose a theme that looks good with WooCom",
            "Caring is the new marketing",
            "Where to grow your business as a photographer: site",
            "How a visual artist redefines success in graphic design",
            "How to build a loyal community online and offline",
            "Starting your traveling blog with Vasco",
            "Travelling as a way of self-discovery and progress",
        ]),
        Description: faker.lorem.paragraph(2),
        Status: status,
        Image: faker.image.abstract(40, 40, true),
        Fields: makeData(() => newTestField(status), 4),
        SentDate: faker.date.between("2020-01-01T00:00:00.000Z", "2022-01-01T00:00:00.000Z"),
        Comment:
            "Нет сомнений, что сторонники тоталитаризма в науке будут заблокированы в рамках своих собственных рациональных ограничений. Ясность нашей позиции очевидна: сплочённость команды профессионалов является качественно новой ступенью распределения внутренних резервов и ресурсов. Высокий уровень вовлечения представителей целевой аудитории является четким доказательством простого факта: постоянное информационно-пропагандистское обеспечение нашей деятельности однозначно определяет каждого участника как способного принимать собственные решения касаемо инновационных методов управления процессами",
    };
};

const newMaterial = (): MaterialListResponse => {
    return {
        id: faker.datatype.uuid(),
        category: {
            id: faker.datatype.uuid(),
            title: "",
        },
        managerUserId: faker.datatype.uuid(),
        // User: newUser(),
        state: "ACTIVE",
        title: faker.helpers.arrayElement([
            "Lessons and insights from 8 years of Pixelgrade",
            "How to choose the right colors when creating a website?",
            "The unseen of spending three years at Pixelgrade",
            "Start a blog to reach your creative peak",
            "How to optimize images in WordPress for faster load",
            "How to choose the right customer for your photo bus",
            "Why choose a theme that looks good with WooCom",
            "Caring is the new marketing",
            "Where to grow your business as a photographer: site",
            "How a visual artist redefines success in graphic design",
            "How to build a loyal community online and offline",
            "Starting your traveling blog with Vasco",
            "Travelling as a way of self-discovery and progress",
        ]),
        description: faker.helpers.arrayElement([
            "Lessons and insights from 8 years of Pixelgrade",
            "How to choose the right colors when creating a website?",
            "The unseen of spending three years at Pixelgrade",
            "Start a blog to reach your creative peak",
            "How to optimize images in WordPress for faster load",
            "How to choose the right customer for your photo bus",
            "Why choose a theme that looks good with WooCom",
            "Caring is the new marketing",
            "Where to grow your business as a photographer: site",
            "How a visual artist redefines success in graphic design",
            "How to build a loyal community online and offline",
            "Starting your traveling blog with Vasco",
            "Travelling as a way of self-discovery and progress",
        ]),
        logoId: faker.datatype.uuid(),
        complexity: faker.helpers.arrayElement(["EASY", "MEDIUM", "HARD"]),
        ratingPoints: faker.datatype.number(100),
        type: faker.helpers.arrayElement([
            MaterialType.Article,
            // MaterialType.Document,
            MaterialType.Video,
            // MaterialType.SCORM,
            MaterialType.HTML,
        ]),
        createTimestamp: Date.now(),
        modifyTimestamp: Date.now(),
        file: {
            contentType: "",
            extension: "",
            disposableLink: "",
            id: "",
            name: "",
            size: 0,
        },
        approxCompletionMinutes: 1,
    };
};

const newCourse = (): CourseListResponse => {
    return {
        id: faker.datatype.string(50),
        category: {
            id: "",
            title: "",
        },
        logoId: "",
        managerUserId: "",
        state: ResourceState.ACTIVE,
        publicAccess: false,
        title: faker.helpers.arrayElement([
            "Lessons and insights from 8 years of Pixelgrade",
            "How to choose the right colors when creating a website?",
            "The unseen of spending three years at Pixelgrade",
            "Start a blog to reach your creative peak",
            "How to optimize images in WordPress for faster load",
            "How to choose the right customer for your photo bus",
            "Why choose a theme that looks good with WooCom",
            "Caring is the new marketing",
            "Where to grow your business as a photographer: site",
            "How a visual artist redefines success in graphic design",
            "How to build a loyal community online and offline",
            "Starting your traveling blog with Vasco",
            "Travelling as a way of self-discovery and progress",
        ]),
        description: "",
        publicationDelayTimestamp: 0,
        expirationTimestamp: 0,
        deadlineTimestamp: 0,
        isRequired: false,
        issueCertificate: false,
        hideUserReviews: false,
        hideAvgReviewScore: false,
        createTimestamp: 0,
        modifyTimestamp: 0,
        ratingPoints: faker.datatype.number(400),
        componentCount: 10,
        approxCompletionMinutes: 10,
        averageReviewRating: 0,
    };
};

const newRemark = (): RemarkListResponse => {
    return {
        Id: faker.datatype.number(5000000),
        User: newUser(),
        Supervisor: newUser(),
        Title: faker.helpers.arrayElement([
            "Lessons and insights from 8 years of Pixelgrade",
            "How to choose the right colors when creating a website?",
            "The unseen of spending three years at Pixelgrade",
            "Start a blog to reach your creative peak",
            "How to optimize images in WordPress for faster load",
            "How to choose the right customer for your photo bus",
            "Why choose a theme that looks good with WooCom",
            "Caring is the new marketing",
            "Where to grow your business as a photographer: site",
            "How a visual artist redefines success in graphic design",
            "How to build a loyal community online and offline",
            "Starting your traveling blog with Vasco",
            "Travelling as a way of self-discovery and progress",
        ]),
        Image: faker.image.abstract(40, 40, true),
        Source: newMaterial(),
        Status: faker.helpers.arrayElement([
            ValidationStatus.Approved,
            ValidationStatus.Fixed,
            ValidationStatus.Declined,
            ValidationStatus.In_Progress,
        ]),
        SentDate: faker.date.between("2020-01-01T00:00:00.000Z", "2022-01-01T00:00:00.000Z"),
        Comment:
            "Нет сомнений, что сторонники тоталитаризма в науке будут заблокированы в рамках своих собственных рациональных ограничений. Ясность нашей позиции очевидна: сплочённость команды профессионалов является качественно новой ступенью распределения внутренних резервов и ресурсов. Высокий уровень вовлечения представителей целевой аудитории является четким доказательством простого факта: постоянное информационно-пропагандистское обеспечение нашей деятельности однозначно определяет каждого участника как способного принимать собственные решения касаемо инновационных методов управления процессами",
    };
};

const newHistoryItem = (): HistoryListResponse => {
    return {
        Id: faker.datatype.uuid(),
        User: newUser(),
        Title: faker.helpers.arrayElement([
            "Lessons and insights from 8 years of Pixelgrade",
            "How to choose the right colors when creating a website?",
            "The unseen of spending three years at Pixelgrade",
            "Start a blog to reach your creative peak",
            "How to optimize images in WordPress for faster load",
            "How to choose the right customer for your photo bus",
            "Why choose a theme that looks good with WooCom",
            "Caring is the new marketing",
            "Where to grow your business as a photographer: site",
            "How a visual artist redefines success in graphic design",
            "How to build a loyal community online and offline",
            "Starting your traveling blog with Vasco",
            "Travelling as a way of self-discovery and progress",
        ]),
        Status: faker.helpers.arrayElement([
            ValidationStatus.Approved,
            ValidationStatus.Fixed,
            ValidationStatus.Declined,
            ValidationStatus.In_Progress,
        ]),
        Type: faker.helpers.arrayElement([
            // HistoryType.Test,
            // HistoryType.Task,
            HistoryType.Remark,
        ]),
        Source: faker.datatype.number(5000),
        Image: faker.image.abstract(40, 40, true),
        VerificationDate: faker.date.between("2020-01-01T00:00:00.000Z", "2022-01-01T00:00:00.000Z"),
    };
};

const newReview = (): ReviewListResponse => {
    return {
        Id: faker.datatype.number(5000000),
        User: newUser(),
        Supervisor: newUser(),
        Text: faker.helpers.arrayElement([
            "Прекрасный материал. Много полезной практической информации. Спасибо огромное за удобную платформу и за и за экономию времени, при нашей загруженности, этот дистанционный метод обучения самый лучший выход. Получила много знаний и удовольствия от обучения.",
            "Удобная платформа для обучения онлайн. Материал доступно изложен",
            "Выражаю большую благодарность создателям статьи! Оказался очень",
            "Курсы интересные, познавательные. Стоит отметить хорошую, чётко ...",
            "Обучалась дистанционно. Очень понравился представленный ...",
            "Удобная платформа для обучения онлайн. Материал доступно изложен...",
            "Видео интересные, познавательные. Стоит отметить хорошую, чётко ...",
            "Прекрасный материал. Много полезной практической информации. Спасибо ...",
            "Прекрасный материал. Много полезной практической информац...",
            "Выражаю большую благодарность создателям курса! Он оказался очен ...",
        ]),
        Course: newCourse(),
        Rating: faker.datatype.number({ min: 2, max: 5, precision: 0.01 }),
        Date: faker.date.between("2020-01-01T00:00:00.000Z", "2022-01-01T00:00:00.000Z"),
    };
};

const newComment = (): CommentListResponse => {
    return {
        Id: faker.datatype.number(5000000),
        User: newUser(),
        Supervisor: newUser(),
        Text: faker.helpers.arrayElement([
            "Прекрасный материал. Много полезной практической информации. Спасибо огромное за удобную платформу и за и за экономию времени, при нашей загруженности, этот дистанционный метод обучения самый лучший выход. Получила много знаний и удовольствия от обучения.",
            "Удобная платформа для обучения онлайн. Материал доступно изложен",
            "Выражаю большую благодарность создателям статьи! Оказался очень",
            "Курсы интересные, познавательные. Стоит отметить хорошую, чётко ...",
            "Обучалась дистанционно. Очень понравился представленный ...",
            "Удобная платформа для обучения онлайн. Материал доступно изложен...",
            "Видео интересные, познавательные. Стоит отметить хорошую, чётко ...",
            "Прекрасный материал. Много полезной практической информации. Спасибо ...",
            "Прекрасный материал. Много полезной практической информац...",
            "Выражаю большую благодарность создателям курса! Он оказался очен ...",
        ]),
        Source: newMaterial(),
        Date: faker.date.between("2020-01-01T00:00:00.000Z", "2022-01-01T00:00:00.000Z"),
    };
};

const newArchiveItem = (): ArchiveListResponse => {
    return {
        id: faker.datatype.uuid(),
        categoryId: faker.datatype.uuid(),
        managerUserId: faker.datatype.uuid(),
        // User: newUser(),
        state: "ACTIVE",
        title: faker.helpers.arrayElement([
            "Lessons and insights from 8 years of Pixelgrade",
            "How to choose the right colors when creating a website?",
            "The unseen of spending three years at Pixelgrade",
            "Start a blog to reach your creative peak",
            "How to optimize images in WordPress for faster load",
            "How to choose the right customer for your photo bus",
            "Why choose a theme that looks good with WooCom",
            "Caring is the new marketing",
            "Where to grow your business as a photographer: site",
            "How a visual artist redefines success in graphic design",
            "How to build a loyal community online and offline",
            "Starting your traveling blog with Vasco",
            "Travelling as a way of self-discovery and progress",
        ]),
        description: faker.helpers.arrayElement([
            "Lessons and insights from 8 years of Pixelgrade",
            "How to choose the right colors when creating a website?",
            "The unseen of spending three years at Pixelgrade",
            "Start a blog to reach your creative peak",
            "How to optimize images in WordPress for faster load",
            "How to choose the right customer for your photo bus",
            "Why choose a theme that looks good with WooCom",
            "Caring is the new marketing",
            "Where to grow your business as a photographer: site",
            "How a visual artist redefines success in graphic design",
            "How to build a loyal community online and offline",
            "Starting your traveling blog with Vasco",
            "Travelling as a way of self-discovery and progress",
        ]),
        logoId: faker.datatype.uuid(),
        complexity: faker.helpers.arrayElement(["EASY", "MEDIUM", "HARD"]),
        type: faker.helpers.arrayElement([
            MaterialType.Article,
            // MaterialType.Document,
            MaterialType.Video,
            // MaterialType.SCORM,
            MaterialType.HTML,
        ]),
        createTimestamp: Date.now(),
        modifyTimestamp: Date.now(),
    };
};

const newInviteteam = (): Inviteteam => {
    return {
        id: faker.datatype.uuid(),
        name: faker.lorem.words(1),
    };
};

const newInvite = (): InviteListResponse => {
    return {
        id: faker.datatype.uuid(),
        createdAt: faker.datatype.datetime().getTime(),
        state: faker.helpers.arrayElement([ResourceState.ACTIVE, ResourceState.INACTIVE]),
        requiresAction: faker.datatype.boolean(),
        title: `Ссылка для проекта ${faker.lorem.words(1)}`,
        registeredUserCount: faker.datatype.number({ min: 0, max: 30 }),
        teams: makeData(() => newInviteteam(), faker.datatype.number({ min: 1, max: 20 })),
        // error: faker.datatype.boolean(),
    };
};

const newJob = (): JobResponse => {
    return {
        id: faker.datatype.uuid(),
        name: faker.lorem.words(1),
        userCount: faker.datatype.number({ min: 0, max: 30 }),
    };
};

const newOffice = (): OfficeResponse => {
    return {
        id: faker.datatype.uuid(),
        name: faker.lorem.words(1),
        region: faker.datatype.number({ min: 0, max: 30 }),
        address: faker.address.streetAddress(true),
        country: faker.address.country(),
        createDate: faker.date.past().getTime(),
        lastModificationDate: faker.date.past().getTime(),
        userList: [
            faker.datatype.uuid(),
            faker.datatype.uuid(),
            faker.datatype.uuid(),
            faker.datatype.uuid(),
            faker.datatype.uuid(),
        ],
    };
};

export function makeActiveUserData(...lens: number[]) {
    const makeDataLevel = (depth = 0): UserActiveListResponse[] => {
        const len = lens[depth]!;
        return range(len).map((_d): UserActiveListResponse => {
            return {
                ...newActiveUser(),
            };
        });
    };

    return makeDataLevel();
}

const activeUserData = makeActiveUserData(10000);

export async function fetchActiveUserData(options: { pageIndex: number; pageSize: number; sort: any }) {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    const fieldSort = options.sort?.at(0);
    const sortedData = fieldSort ? activeUserData.sort((a, b) => compareFn(a, b, fieldSort)) : activeUserData;

    return {
        rows: sortedData.slice(options.pageIndex * options.pageSize, (options.pageIndex + 1) * options.pageSize),
        pageCount: Math.ceil(sortedData.length / options.pageSize),
    };
}

export async function fetchUserAccessData() {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    return newUserAccess();
}

export function makeNewsItemData(...lens: number[]) {
    const makeDataLevel = (depth = 0): NewsItem[] => {
        const len = lens[depth]!;
        return range(len).map((_d): NewsItem => {
            return {
                ...newNewsItem(),
            };
        });
    };

    return makeDataLevel();
}

export function makeInterviewsData(...lens: number[]) {
    const makeDataLevel = (depth = 0): InterviewsItem[] => {
        const len = lens[depth]!;
        return range(len).map((_d): InterviewsItem => {
            return {
                ...interviewsItem(),
            };
        });
    };

    return makeDataLevel();
}

export function makeTasksValidationData(...lens: number[]) {
    const makeDataLevel = (depth = 0): TaskListTestResponse[] => {
        const len = lens[depth]!;
        return range(len).map((_d): TaskListTestResponse => {
            return {
                ...newValidationTask(),
            };
        });
    };

    return makeDataLevel();
}

export function makeTestsData(...lens: number[]) {
    const makeDataLevel = (depth = 0): TestListResponse[] => {
        const len = lens[depth]!;
        return range(len).map((_d): TestListResponse => {
            return {
                ...newTest(),
            };
        });
    };

    return makeDataLevel();
}

export function makeRemarksData(...lens: number[]) {
    const makeDataLevel = (depth = 0): RemarkListResponse[] => {
        const len = lens[depth]!;
        return range(len).map((_d): RemarkListResponse => {
            return {
                ...newRemark(),
            };
        });
    };

    return makeDataLevel();
}

export function makeHistoryData(...lens: number[]) {
    const makeDataLevel = (depth = 0): HistoryListResponse[] => {
        const len = lens[depth]!;
        return range(len).map((_d): HistoryListResponse => {
            return {
                ...newHistoryItem(),
            };
        });
    };

    return makeDataLevel();
}

export function makeReviewsData(...lens: number[]) {
    const makeDataLevel = (depth = 0): ReviewListResponse[] => {
        const len = lens[depth]!;
        return range(len).map((_d): ReviewListResponse => {
            return {
                ...newReview(),
            };
        });
    };

    return makeDataLevel();
}

export function makeCommentsData(...lens: number[]) {
    const makeDataLevel = (depth = 0): CommentListResponse[] => {
        const len = lens[depth]!;
        return range(len).map((_d): CommentListResponse => {
            return {
                ...newComment(),
            };
        });
    };

    return makeDataLevel();
}

export function makeArchiveData(...lens: number[]) {
    const makeDataLevel = (depth = 0): ArchiveListResponse[] => {
        const len = lens[depth]!;
        return range(len).map((_d): ArchiveListResponse => {
            return {
                ...newArchiveItem(),
            };
        });
    };

    return makeDataLevel();
}

export function makeInvitesData(...lens: number[]) {
    const makeDataLevel = (depth = 0): InviteListResponse[] => {
        const len = lens[depth]!;
        return range(len).map((_d): InviteListResponse => {
            return {
                ...newInvite(),
            };
        });
    };

    return makeDataLevel();
}

export function makeJobsData(...lens: number[]) {
    const makeDataLevel = (depth = 0): JobResponse[] => {
        const len = lens[depth]!;
        return range(len).map((_d): JobResponse => {
            return {
                ...newJob(),
            };
        });
    };

    return makeDataLevel();
}

export function makeOfficeData(...lens: number[]) {
    const makeDataLevel = (depth = 0): OfficeResponse[] => {
        const len = lens[depth]!;
        return range(len).map((_d): OfficeResponse => {
            return {
                ...newOffice(),
            };
        });
    };

    return makeDataLevel();
}

const newsItemsData = makeNewsItemData(200);

const interviewsData = makeInterviewsData(200);

export async function fetchNewsItemsData(options: { pageIndex: number; pageSize: number; sort: any }) {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    const fieldSort = options.sort?.at(0);
    const sortedData = fieldSort ? newsItemsData.sort((a, b) => compareFn(a, b, fieldSort)) : newsItemsData;

    return {
        rows: sortedData.slice(options.pageIndex * options.pageSize, (options.pageIndex + 1) * options.pageSize),
        pageCount: Math.ceil(sortedData.length / options.pageSize),
    };
}

export async function fetchInterviewsData(options: { pageIndex: number; pageSize: number; sort: any }) {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    const fieldSort = options.sort?.at(0);
    const sortedData = fieldSort ? interviewsData.sort((a, b) => compareFn(a, b, fieldSort)) : interviewsData;

    return {
        rows: sortedData.slice(options.pageIndex * options.pageSize, (options.pageIndex + 1) * options.pageSize),
        pageCount: Math.ceil(sortedData.length / options.pageSize),
    };
}

const tasksValidationData = makeTasksValidationData(200);

const testsData = makeTestsData(200);

const remarksData = makeRemarksData(200);

const historyData = makeHistoryData(200);

const reviewsData = makeReviewsData(200);

const commentsData = makeCommentsData(200);

const archiveData = makeArchiveData(200);

export async function fetchTest(id: number | string, filter: { status: ValidationStatus[] }) {
    await new Promise((r) => setTimeout(r, 500));

    const filteredData = testsData.filter((task) => {
        return filter.status.includes(task.Status);
    });

    console.log(id);
    return filteredData[0];
}

export async function fetchRemark(id: number | string, filter: { status: ValidationStatus[] }) {
    await new Promise((r) => setTimeout(r, 500));

    const filteredData = remarksData.filter((task) => {
        return filter.status.includes(task.Status);
    });

    console.log(id);
    return filteredData[0];
}

export async function fetchValidationTask(id: number | string, filter: { status: ValidationStatus[] }) {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    const filteredData = tasksValidationData.filter((task) => {
        return filter.status.includes(task.Status);
    });

    console.log(id);
    return filteredData[0];
}

export async function fetchRemarksData(
    options: { pageIndex: number; pageSize: number },
    filter: { status: ValidationStatus },
    sort: any,
) {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    const filteredData = remarksData.filter((task) => {
        return task.Status === filter.status;
    });

    const fieldSort = sort?.at(0);
    const sortedData = fieldSort ? filteredData.sort((a, b) => compareFn(a, b, fieldSort)) : filteredData;

    return {
        rows: sortedData.slice(options.pageIndex * options.pageSize, (options.pageIndex + 1) * options.pageSize),
        pageCount: Math.ceil(sortedData.length / options.pageSize),
    };
}

export async function fetchHistoryData(options: { pageIndex: number; pageSize: number; sort: any }) {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    const fieldSort = options.sort?.at(0);
    const sortedData = fieldSort ? historyData.sort((a, b) => compareFn(a, b, fieldSort)) : historyData;

    return {
        rows: sortedData.slice(options.pageIndex * options.pageSize, (options.pageIndex + 1) * options.pageSize),
        pageCount: Math.ceil(sortedData.length / options.pageSize),
    };
}

export async function fetchReviewsData(options: { pageIndex: number; pageSize: number; sort: any }) {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    const fieldSort = options.sort?.at(0);
    const sortedData = fieldSort ? reviewsData.sort((a, b) => compareFn(a, b, fieldSort)) : reviewsData;

    return {
        rows: sortedData.slice(options.pageIndex * options.pageSize, (options.pageIndex + 1) * options.pageSize),
        pageCount: Math.ceil(sortedData.length / options.pageSize),
    };
}

export async function fetchCommentsData(options: { pageIndex: number; pageSize: number; sort: any }) {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    const fieldSort = options.sort?.at(0);
    const sortedData = fieldSort ? commentsData.sort((a, b) => compareFn(a, b, fieldSort)) : commentsData;

    return {
        rows: sortedData.slice(options.pageIndex * options.pageSize, (options.pageIndex + 1) * options.pageSize),
        pageCount: Math.ceil(sortedData.length / options.pageSize),
    };
}

export async function fetchArchiveData(options: { pageIndex: number; pageSize: number; sort: any }) {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    const fieldSort = options.sort?.at(0);
    const sortedData = fieldSort ? archiveData.sort((a, b) => compareFn(a, b, fieldSort)) : archiveData;

    return {
        rows: sortedData.slice(options.pageIndex * options.pageSize, (options.pageIndex + 1) * options.pageSize),
        pageCount: Math.ceil(sortedData.length / options.pageSize),
    };
}

export async function fetchLibraryData(root = true) {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    // Корень
    if (root) {
        return {
            rows: [
                {
                    key: faker.datatype.uuid(),
                    title: "Пустая папка",
                    type: "folder",
                    accessCount: 0,
                    date: new Date(),
                },
                {
                    key: faker.datatype.uuid(),
                    title: "Бухгалтерский учет и налогообложение",
                    type: "folder",
                    accessCount: 10,
                    date: new Date(),
                },
                {
                    key: faker.datatype.uuid(),
                    title: "Бухгалтерский учет и налогообложение",
                    type: "folder",
                    accessCount: null,
                    date: new Date(),
                },
            ],
        };
    }
    // Папка
    return {
        rows: [
            {
                key: faker.datatype.uuid(),
                title: "Бухгалтерский учет и налогообложение",
                type: "folder",
                date: new Date(),
            },
            {
                key: faker.datatype.uuid(),
                title: "Бухгалтерский учет и налогообложение",
                type: "folder",
                date: new Date(),
            },
            {
                key: faker.datatype.uuid(),
                title: "Запись разговора директора",
                type: "file",
                extention: "mp4",
                size: 2.5,
                date: new Date(),
            },
            {
                key: faker.datatype.uuid(),
                title: "Ruby разработчик",
                type: "file",
                extention: "mp4",
                size: 1,
                date: new Date(),
            },
        ],
    };
}

export function makeUserAccessData(...lens: number[]) {
    const makeDataLevel = (depth = 0): UserAccessResponse[] => {
        const len = lens[depth]!;
        return range(len).map((_id): UserAccessResponse => {
            return {
                ...newUserMemberAccess(),
            };
        });
    };

    return makeDataLevel();
}

export const userAccessData = makeUserAccessData(10);

export async function fetchUserMemberAccessData(options: { pageIndex: number; pageSize: number; sort: any }) {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    const fieldSort = options.sort?.at(0);
    const sortedData = fieldSort ? userAccessData.sort((a, b) => compareFn(a, b, fieldSort)) : userAccessData;

    return {
        rows: sortedData.slice(options.pageIndex * options.pageSize, (options.pageIndex + 1) * options.pageSize),
        pageCount: Math.ceil(sortedData.length / options.pageSize),
    };
}

export const fetchInvites = (): BasePaginationResponse<InviteListResponse> => {
    // Simulate some network latency
    new Promise((r) => setTimeout(r, 500));

    const response = new BasePaginationResponse(InviteListResponse);
    response.Page = 0;
    response.Size = 15;
    response.Content = makeInvitesData(100);
    response.TotalPages = Math.ceil(response.Content.length / response.Size);
    response.Total = response.Content.length;

    return response;
};

export const fetchJobs = (): BasePaginationResponse<JobResponse> => {
    // Simulate some network latency
    new Promise((r) => setTimeout(r, 500));

    const response = new BasePaginationResponse(JobResponse);
    response.Page = 0;
    response.Size = 15;
    response.Content = makeJobsData(100);
    response.TotalPages = Math.ceil(response.Content.length / response.Size);
    response.Total = response.Content.length;

    return response;
};

export const fetchOffice = (): BasePaginationResponse<OfficeResponse> => {
    // Simulate some network latency
    new Promise((r) => setTimeout(r, 500));

    const response = new BasePaginationResponse(OfficeResponse);
    response.Page = 0;
    response.Size = 15;
    response.Content = makeOfficeData(100);
    response.TotalPages = Math.ceil(response.Content.length / response.Size);
    response.Total = response.Content.length;

    return response;
};
