import React, {
    FunctionComponent,
    MutableRefObject,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    DndContext,
    // DragOverlay,
    // closestCenter,
    // DndContext,
    // DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    // arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import isEmpty from "lodash/isEmpty";

import { ITreeProps } from "Uikit/Tree/Tree";
import { Icon, Icons } from "Uikit/Icon/Icon";

import { TreeWrapperContext } from "Containers";
import { SidebarListItem } from "./SidebarListItem";
import { TreeSearchProps } from "../Tree/TreeSearch";
import { Button } from "Uikit/Button/Button";
import { Checkbox } from "Uikit/Forms/Checkbox";
import { useDebounceValue } from "hooks/useDebounceValue";
import clsx from "clsx";
import { SidebarApi } from "Uikit/SidebarList/SidebarApi";
import { TVoidFunction } from "types";
// import { useLocation } from "react-router-dom";

const SidebarListWrapper = ({ children }: { children: ReactNode }) => {
    const treeRef = useRef();
    const sidebarApi = useRef({});
    const [treeProps, setTreeProps] = useState<ITreeProps>();

    const value = useMemo(() => ({ treeRef, setTreeProps, sidebarApi }), [treeRef, setTreeProps, sidebarApi]);

    return (
        <TreeWrapperContext.Provider value={value}>
            <div className="flex w-full h-full">
                {treeProps?.data && (
                    <div className="fixed top-0 bottom-0 left-30 flex flex-col flex-shrink-0 w-full max-w-65 h-full border-r border-gray-blue">
                        <SidebarList {...treeProps} outerRef={sidebarApi} />
                    </div>
                )}
                <div className={clsx("w-full h-full", treeProps?.data && "ml-65")}>{children}</div>
            </div>
        </TreeWrapperContext.Provider>
    );
};

interface IBrowseAll {
    link: string;
    title: string;
    onAddClick: TVoidFunction;
}

interface IFooter {
    title: string;
    action: TVoidFunction;
    render?: (title?: string, action?: TVoidFunction) => React.ReactElement;
}

export interface IRenderSelectAllHeadProps {
    selectAll?: (e: boolean) => void;
    onSeletectAll?: TVoidFunction;
}

interface SidebarListProps {
    id?: string;
    data: { id: string; name: string }[];
    searchable?: boolean;
    checkable?: boolean;
    editable?: boolean;
    browseAll?: null | IBrowseAll;
    nodeAddList?: { name: string }[];
    renderSearch?: FunctionComponent<TreeSearchProps>;
    footer?: IFooter;
    renderSelectAllHead?: ({ selectAll, onSeletectAll }: IRenderSelectAllHeadProps) => React.ReactElement;
    onSelectAll?: TVoidFunction;
    onOrderChange?: (items: { id: string; name: string }[]) => void;
    onNodeAddClick?: (event: any, nodeId: any) => void;
    onEditClick?: (value: string, id: string) => void;
    onDeleteClick?: (value: string, id: string) => void;
    onSelectNode?: (node: any) => void;
    onCheckedChange?: (itemIds: string[]) => void;
    getNodeLink?: (id: any) => string;
    selectedNode?: any;
    outerRef?: MutableRefObject<SidebarApi>;
}

const SidebarList = ({
    id,
    data,
    renderSearch: Search,
    footer,
    searchable = true,
    checkable = true,
    editable = false,
    nodeAddList,
    browseAll,
    onSelectNode,
    onNodeAddClick,
    onEditClick,
    onDeleteClick,
    // onOrderChange,
    onCheckedChange,
    onSelectAll,
    getNodeLink,
    renderSelectAllHead: SelectAllHead,
    selectedNode,
    outerRef,
}: SidebarListProps) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    // const location = useLocation();

    // const [activeId, setActiveId] = useState(null);
    // const [backupItems, setBackupItems] = useState(data);
    const [items, setItems] = useState(data);
    const [selectedItem, setSelectedItem] = useState<{ id: string; name: string } | null>(null);
    // const [selectedItem, setSelectedItem] = useState<{ id: string; name: string } | null>(selectedNode ?? null);
    const [query, setQuery] = useState("");
    const [debouncedValue] = useDebounceValue(query, 300);
    // const [draggable, setDraggable] = useState(false);
    const [checkedBucket, setCheckedBucket] = useState<{ [key: string]: boolean }>({});
    const [allNodesDisabled, setAllNodesDisabled] = useState(false);
    const [allNodesChecked, setAllNodesChecked] = useState(false);

    useEffect(() => {
        if (outerRef) {
            outerRef.current = {
                setCheckedBucket,
            };
        }
    }, [outerRef]);

    const onCheckItem = (itemId: any) => {
        setCheckedBucket((prev: { [key: string]: boolean }) => {
            let bucket = { ...prev };

            if (bucket[itemId]) {
                delete bucket[itemId];
            } else {
                bucket = { ...prev, [itemId]: true };
            }

            if (selectedItem?.id) {
                onSelectItem({ id: "", name: "" });
            }

            if (onCheckedChange) {
                onCheckedChange(Object.keys(bucket).map((key) => key));
            }

            return bucket;
        });
    };

    const checkAllNodes = (checked: boolean) => {
        setAllNodesChecked(checked);

        const checkedBucket: { [key: string]: boolean } = {};

        if (selectedItem?.id) {
            onSelectItem({ id: "", name: "" });
        }

        if (checked) {
            items.forEach((item) => {
                checkedBucket[item.id] = true;
            });
            setCheckedBucket(checkedBucket);
        } else {
            setCheckedBucket(checkedBucket);
        }

        if (onCheckedChange) {
            onCheckedChange(Object.keys(checkedBucket).map((key) => key));
        }
    };

    const onSelectItem = useCallback(
        (item: any) => {
            setSelectedItem(item);

            if (onSelectNode) {
                onSelectNode(item);
            }
        },
        [onSelectNode],
    );

    useEffect(() => {
        if (selectedItem === null) {
            const params = new URLSearchParams(window.location.search);
            const selectedItem = items.find((item) => item.id === params.get("categoryId"));

            setSelectedItem(selectedItem ?? { id: "", name: "" });
        }
    }, [items, selectedItem]);

    useEffect(() => {
        if (selectedNode) {
            setSelectedItem(selectedNode);
        }
    }, [onSelectItem, selectedNode]);

    useEffect(() => {
        if (selectedItem?.id && !data.some((item) => item.id === selectedItem?.id)) {
            onSelectItem({ id: "", name: "" });
        }

        setItems(data);
    }, [data, selectedItem, onSelectItem]);

    useEffect(() => {
        setQuery("");
    }, [id]);

    // useEffect(() => {
    //     setDraggable(false);
    // }, [location]);

    return (
        <>
            {searchable && renderSearch()}
            {browseAll && renderBrowseAll(browseAll)}

            {onSelectAll ? (
                <div className="flex pr-4 h-8">
                    <div className={"w-full h-full flex items-center"}>
                        <div className="w-4 h-4 mr-2">
                            <Checkbox
                                checked={allNodesChecked}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    checkAllNodes(e.target.checked);

                                    if (onSelectAll) {
                                        onSelectAll();
                                    }
                                }}
                                disabled={allNodesDisabled}
                            />
                        </div>
                        {renderSelectAllHead()}
                    </div>
                </div>
            ) : null}
            <ul id={id + "List"} className="flex flex-col justify-start grow overflow-auto">
                <DndContext
                    sensors={sensors}
                    // collisionDetection={closestCenter}
                    // onDragStart={handleDragStart}
                    // onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={items
                            .filter((item) => item.name.toLowerCase().includes(debouncedValue.toLowerCase()))
                            .map((item) => item.id)}
                    >
                        {items
                            .filter((item) => item.name.toLowerCase().includes(debouncedValue.toLowerCase()))
                            .map((item) => {
                                return (
                                    <SidebarListItem
                                        key={item.id}
                                        id={item.id}
                                        name={item.name}
                                        editable={editable}
                                        // draggable={draggable}
                                        checked={checkedBucket[item.id]}
                                        checkable={checkable}
                                        selected={selectedItem?.id === item.id}
                                        onCheckedChange={onCheckItem}
                                        onItemClick={() => {}}
                                        nodeAddList={nodeAddList}
                                        onNodeAddClick={onNodeAddClick}
                                        onSelectNode={onSelectItem}
                                        onDeleteClick={onDeleteClick}
                                        onEditClick={onEditClick}
                                        getNodeLink={getNodeLink}
                                    />
                                );
                            })}
                    </SortableContext>

                    {/*<DragOverlay>{activeId ? <div className="cursor-pointer h-9"></div> : null}</DragOverlay>*/}
                </DndContext>
            </ul>
            {renderFooter()}
        </>
    );

    // function handleDragStart(event: any) {
    //     const { active } = event;
    //
    //     setActiveId(active.id);
    // }
    //
    // function handleDragEnd(event: any) {
    //     const { active, over } = event;
    //
    //     if (active.id !== over.id) {
    //         setItems((prevItems) => {
    //             const oldIndex = prevItems.findIndex((i: any) => i.id === active.id);
    //             const newIndex = prevItems.findIndex((i: any) => i.id === over.id);
    //
    //             return arrayMove(prevItems, oldIndex, newIndex);
    //         });
    //     }
    //
    //     setActiveId(null);
    // }

    function renderSelectAllHead() {
        if (SelectAllHead) {
            return (
                <SelectAllHead
                    selectAll={checkAllNodes}
                    onSeletectAll={() => {
                        setAllNodesDisabled(!allNodesDisabled);
                    }}
                />
            );
        }

        return (
            <div className="flex items-center h-full">
                <div className="flex justify-center min-w-7 cursor-pointer"></div>
                Выбрать всё
            </div>
        );
    }

    function renderSearch() {
        if (Search) {
            return (
                <Search
                    query={query}
                    setQuery={setQuery}
                    // disabled={draggable}
                />
            );
        }

        return (
            <div className="flex items-center px-4 py-[6px]">
                <Icon icon={Icons.Search} width={20} height={20} color="fill-gray-text" />
                <input
                    className="w-full border-0 focus:ring-0"
                    type="text"
                    placeholder="Поиск по дереву"
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                    // disabled={draggable}
                    id={id + "SearchInput"}
                />
            </div>
        );
    }

    // Ссылка просмотра всех новостей, опросов и т.п.
    function renderBrowseAll({ _link, title }: any) {
        return (
            <div
                className={clsx(
                    "w-full flex justify-between hover:bg-background hover:no-underline",
                    !selectedItem?.id && isEmpty(checkedBucket) && "bg-background text-blue",
                )}
            >
                <div
                    onClick={() => checkAllNodes(false)}
                    className="flex items-center gap-2 py-2.5 pl-4 text-primary cursor-pointer"
                >
                    <Icon className="" color="fill-primary" icon={Icons.List} width={15} height={15} />
                    {title}
                </div>
                <div className={"flex items-center gap-2 pr-2"}>
                    {
                        //     (
                        //     <>
                        //         {items.length > 0 ? (
                        //             <Button
                        //                 className={"border-none w-3.5 h-3.5 focus:!ring-0"}
                        //                 shape={"round"}
                        //                 size={"medium"}
                        //                 icon={
                        //                     <Icon
                        //                         className=""
                        //                         color="fill-blue-drk hover:fill-blue-hover"
                        //                         icon={Icons.SortButton}
                        //                         width={15}
                        //                         height={15}
                        //                     />
                        //                 }
                        //                 iconPlacement={"center"}
                        //                 color={"common"}
                        //                 onClick={() => {
                        //                     setQuery("");
                        //                     // setDraggable(true);
                        //                     setBackupItems([...items]);
                        //                 }}
                        //             />
                        //         ) : null}
                        //     </>
                        // )
                        //     : (
                        //     <>
                        //         <Button
                        //             className={"border-none w-3.5 h-3.5 focus:!ring-0"}
                        //             shape={"round"}
                        //             size={"medium"}
                        //             icon={
                        //                 <Icon className="" color="fill-primary" icon={Icons.Check} width={16} height={12} />
                        //             }
                        //             iconPlacement={"center"}
                        //             color={"common"}
                        //             onClick={() => {
                        //                 if (onOrderChange) {
                        //                     onOrderChange(items);
                        //                 }
                        //
                        //                 setBackupItems([]);
                        //                 // setDraggable(false);
                        //             }}
                        //         />
                        //         <Button
                        //             className={"border-none w-3.5 h-3.5 focus:!ring-0"}
                        //             shape={"round"}
                        //             size={"medium"}
                        //             icon={<Icon className="" color="fill-red" icon={Icons.Close} width={16} height={16} />}
                        //             iconPlacement={"center"}
                        //             color={"common"}
                        //             onClick={() => {
                        //                 // setDraggable(false);
                        //                 setItems([...backupItems]);
                        //             }}
                        //         />
                        //     </>
                        // )
                    }
                </div>
            </div>
        );
    }

    function renderFooter() {
        // if (draggable) {
        //     return null;
        // }

        if (footer?.render) {
            return footer.render(footer.title, footer.action);
        }

        return (
            <Button
                variant="outline"
                icon={<Icon icon={Icons.PlusFilled} width={20} height={20} color="fill-blue" />}
                iconPlacement="left"
                color="secondary"
                className="mx-4 my-3 border-gray-input font-medium focus:ring-0 2xl:!px-5 2xl:!text-md"
                onClick={footer?.action}
                id={id + "BtnCreateCategory"}
            >
                {footer?.title}
            </Button>
        );
    }
};

export default SidebarListWrapper;
