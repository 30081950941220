import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ControlledAccordion, AccordionItem, useAccordionProvider } from "@szhsin/react-accordion";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import clsx from "clsx";
// import { useQuery } from "react-query";
// import { PaginationState } from "@tanstack/react-table";
import { useDispatch, useSelector } from "react-redux";

import { Button, Icon, Icons } from "Uikit";
import { ProgressStatus, ResourceType } from "Enums";
import { Card } from "Components/Card";
import { CourseRequestSection } from "Api/Requests/CourseRequest";
// import { FeedbackListResponse } from "Api/Responses/FeedbackResponse";
// import { FeedbackListRequest } from "Api/Requests/FeedbackRequest";
import Api from "Api";
import {
    // setBackUrl,
    setIsBack,
    setIsFavorite,
    setIsHidden,
    setTitle,
} from "slices/headerSlice";
import { setCurrentCourse, setCurrentMaterial } from "slices/programSlice";
import { IReducer } from "store";
// import { FeedbackList } from "Components/FeedbackList/FeedbackList";
import { CourseStartResponse } from "Api/Responses/CourseProgressResponse";
import { numCapEnd } from "helpers/numCapEnd";
import { formatLeadTime } from "../Tasks/utils";
// import { TVoidFunction } from "types";
import {
    useChangeCourseFavoriteStatus,
    useChangeSectionItemFavoriteStatus,
    useGetUICourse,
    useStartMaterial,
} from "./Course.hooks";
import { CourseMaterialCard } from "./CourseMaterialICard";
// import { useCurrentUser } from "hooks/useCurrentUser";

// const scrollToFeedback = () => {
//     const feedbackElement = document.getElementById("feedback");
//     feedbackElement?.scrollIntoView({ behavior: "smooth", block: "start" });
// };

// interface IActionButtonProps {
//     title: string;
//     onClick: TVoidFunction;
//     withIcon?: boolean;
//     variant?: "link" | "standard" | "outline" | undefined;
//     size?: "small" | "tiny" | "medium" | "large" | "xl" | undefined;
//     color?:
//         | "success"
//         | "common"
//         | "primary"
//         | "secondary"
//         | "warning"
//         | "caution"
//         | "danger"
//         | "gray"
//         | "background"
//         | undefined;
//     className?: string;
//     iconPlacement?: string;
// }

// const ActionButton = ({
//     title,
//     onClick,
//     withIcon = true,
//     variant = undefined,
//     size = "medium",
//     color = undefined,
//     className = "hidden sm:flex mt-3 sm:mt-0 w-full sm:w-auto rounded-lg whitespace-nowrap font-medium sm:mr-10 2xl:mr-12.5",
// }: IActionButtonProps) => {
//     return (
//         <Button
//             id="userCourseAddFeedbackBtn"
//             size={size}
//             variant={variant}
//             color={color}
//             className={className}
//             icon={
//                 withIcon ? (
//                     <Icon
//                         className="2xl:!w-6.25 2xl:!h-6.25"
//                         icon={Icons.PlayCircle}
//                         color="fill-white"
//                         width={20}
//                         height={20}
//                     />
//                 ) : null
//             }
//             iconPlacement={withIcon ? "left" : undefined}
//             onClick={onClick}
//         >
//             {title}
//         </Button>
//     );
// };

// interface IActionButtonsBlockProps {
//     title: string;
//     onClick: TVoidFunction;
//     withIcon?: boolean;
// }

// const ActionButtonsBlock = ({ title, onClick, withIcon = true }: IActionButtonsBlockProps) => {
//     return (
//         <>
//             <ActionButton onClick={onClick} title={title} withIcon={withIcon} variant={"standard"} color={"primary"} />
//             <div className="fixed bottom-14 left-0 flex sm:hidden p-2.5 w-full z-10 bg-white">
//                 <ActionButton onClick={onClick} title={title} withIcon={withIcon} size={"large"} className={"w-full"} />
//             </div>
//         </>
//     );
// };

interface ICourseMainProps {
    showStatistics?: boolean;
}

export const CourseMain = ({ showStatistics = false }: ICourseMainProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const currentUser = useCurrentUser();

    const isFavoriteSelected = useSelector((state: IReducer) => state.header.isFavoriteSelected);
    const { programId = "", courseId = "" } = useParams<{ courseId: string; programId: string }>();

    const { data: course, refetch } = useGetUICourse(courseId, { enabled: !!programId });

    const { mutate: mutateFavoriteStatus } = useChangeCourseFavoriteStatus();
    const { mutate: mutateMaterialFavoriteStatus } = useChangeSectionItemFavoriteStatus();

    const { mutate: startMaterial } = useStartMaterial();
    const [urlToNavigate, setUrlToNavigate] = useState<string | null>(null);

    // const [{ pageIndex, pageSize } /* setPagination */] = useState<PaginationState>({ pageIndex: 0, pageSize: 999 });
    // const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);

    // const dataQueryFeedbackList = useQuery(
    //     ["courseFeedback", "collection", pagination, courseId],
    //     async () => {
    //         const request = new FeedbackListRequest();
    //         request.resourceId = courseId;
    //         request.size = pageSize;
    //         request.page = pageIndex;
    //
    //         return await Api.Feedback.FeedbackListUser(request);
    //     },
    //     {
    //         keepPreviousData: true,
    //         refetchOnWindowFocus: false,
    //     },
    // );

    const [openedAccordion, setOpenedAccordion] = useState<string>("");

    const providerValue = useAccordionProvider({
        allowMultiple: true,
        transition: true,
        transitionTimeout: 250,
    });
    const [courseStart, setCourseStart] = useState<CourseStartResponse | undefined>();

    const completionTime = useMemo(() => {
        let t = 0;

        if (!course?.sections) {
            return t;
        }

        for (const section of course.sections) {
            for (const component of section.components) {
                t += component.approxCompletionMinutes;
            }
        }

        return t;
    }, [course?.sections]);

    const firstToOpen = useMemo(() => {
        const _sections = course?.sections ? cloneDeep(course.sections) : [];

        if (course?.progressStatus === ProgressStatus.PASSED) {
            const section = _sections.find((section) => {
                return section.components.some((el) => el.isMostRecent);
            });

            return section?.id;
        }

        const section = _sections.find((section) => {
            return section.components.some((el) =>
                el.progressStatus
                    ? [
                          ProgressStatus.IN_PROGRESS,
                          ProgressStatus.NOT_STARTED,
                          ProgressStatus.FAILED,
                          ProgressStatus.RE_WORK,
                          ProgressStatus.ON_REVIEW,
                      ].includes(el.progressStatus)
                    : false,
            );
        });

        return section?.id;
    }, [course?.sections, course?.progressStatus]);

    const onCourseStatusChange = useCallback(async () => {
        if (!course?.resourceId) {
            return;
        }

        try {
            const res = await Api.CourseProgress.StartCourse(course.resourceId);

            if (!res.next?.resourceId) {
                return;
            }

            await refetch();

            // if (res.next.type === ResourceType.QUIZ) {
            //     dispatch(
            //         setBackUrl(
            //             programId
            //                 ? `/training/program/${programId}/${course.resourceId}`
            //                 : `/training/course/${course.resourceId}`,
            //         ),
            //     );
            //
            //     setUrlToNavigate(
            //         programId
            //             ? `/training/program/${programId}/${course.resourceId}/${res.next.resourceId}`
            //             : `/training/${course.resourceId}/${res.next.resourceId}`,
            //     );
            // } else {
            // const nextComponent = course.sections
            //     .flatMap((section) => section.components)
            //     .find((component) => component.id === res.next.id);

            if (
                res.next.progressStatus !== ProgressStatus.IN_PROGRESS
                // && nextComponent?.materialType !== ResourceType.SCORM
            ) {
                startMaterial({ courseId: course.resourceId, componentId: res.next.id });
            }

            setUrlToNavigate(
                programId
                    ? `/training/program/${programId}/${course.resourceId}/${res.next.id}/${res.next.resourceId}`
                    : `/training/course/${course.resourceId}/${res.next.id}/${res.next.resourceId}`,
            );
            // }
        } catch (error) {
            console.log(error);
        }
    }, [
        course?.resourceId,
        // course?.sections,
        refetch,
        programId,
        // dispatch,
        startMaterial,
    ]);

    useEffect(() => {
        if (urlToNavigate) {
            navigate(urlToNavigate, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlToNavigate]);

    const toggleHandler = (section: CourseRequestSection) => {
        if (section.id === firstToOpen) {
            return;
        }

        if (openedAccordion === section.id) {
            setOpenedAccordion("");
        } else {
            providerValue.toggle(openedAccordion);
            setOpenedAccordion(section.id);
        }
    };

    const handleOnFavorite = useCallback(async () => {
        mutateFavoriteStatus(course!);
    }, [course, mutateFavoriteStatus]);

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Курс"));
        dispatch(setIsFavorite(true));
        dispatch(
            setCurrentCourse({
                id: course?.resourceId ?? "",
                title: course?.title ?? "",
            }),
        );
        dispatch(
            setCurrentMaterial({
                id: "",
                title: "",
            }),
        );
    }, [course, dispatch]);
    useEffect(() => {
        if (window.screen.width > 568 || !course || isFavoriteSelected === course.isFavorite) {
            return;
        }

        handleOnFavorite().then();
    }, [isFavoriteSelected, course, handleOnFavorite]);

    useEffect(() => {
        return () => {
            dispatch(
                setCurrentCourse({
                    id: "",
                    title: "",
                }),
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!course || (course && course.progressStatus !== ProgressStatus.IN_PROGRESS)) {
            return;
        }

        (async () => {
            setCourseStart(await Api.CourseProgress.StartCourse(course.resourceId));
        })();
    }, [course]);
    // useEffect(() => {
    //     if (dataQueryFeedbackList?.data && dataQueryFeedbackList?.data?.Content.length > 0) {
    //         const searchParams = new URL(window.location.href).searchParams;
    //
    //         const comment = searchParams.get("comment");
    //         if (comment) {
    //             const commentBlock = document.getElementById(`comment__${comment}`);
    //             commentBlock?.scrollIntoView({ behavior: "smooth", block: "start" });
    //         }
    //     }
    // }, [dataQueryFeedbackList?.data]);

    if (!course || (course && course.progressStatus === ProgressStatus.IN_PROGRESS && !courseStart)) {
        return null;
    }

    const isDisabled = !course.hasNext && course.progressStatus === ProgressStatus.IN_PROGRESS;

    return (
        <div className="pb-17.5 sm:pb-20 2xl:pb-30">
            <div className="flex flex-col-reverse sm:flex-row justify-between sm:mb-8 2xl:mb-10 rounded-2xl 2xl:rounded-3xl sm:bg-background">
                <div className="block sm:hidden mt-7 mb-4 w-full h-0.25 bg-gray-blue"></div>
                <div className="flex flex-col justify-between flex-grow py-3 sm:py-8 2xl:py-10 sm:px-10 2xl:px-12.5">
                    <div>
                        <h1
                            className="mb-2 2xl:mb-2.5 break-anywhere 2xl:!text-4xl 2xl:!leading-[41px]"
                            id="userCourseTitle"
                        >
                            {course.title}
                        </h1>
                        <div
                            id="userCourseDescription"
                            className="mb-8 2xl:mb-11 text-gray-dark 2xl:text-md break-anywhere"
                        >
                            {course.description}
                        </div>
                    </div>
                    <div className="flex flex-col-reverse sm:flex-row items-center">
                        {course.progressStatus !== ProgressStatus.PASSED &&
                            !showStatistics &&
                            (!courseStart ||
                                (courseStart &&
                                    (!courseStart.next ||
                                        (courseStart.next &&
                                            courseStart.next.progressStatus !== ProgressStatus.ON_REVIEW &&
                                            courseStart.next.progressStatus !== ProgressStatus.BLOCKED)))) && (
                                <Button
                                    size={"medium"}
                                    variant={"standard"}
                                    color={"primary"}
                                    className="hidden disabled:!bg-input-stroke sm:flex mt-3 sm:mt-0 w-full sm:w-auto rounded-lg whitespace-nowrap font-medium sm:mr-10 2xl:mr-12.5"
                                    icon={
                                        <Icon
                                            className="2xl:!w-6.25 2xl:!h-6.25"
                                            icon={Icons.PlayCircle}
                                            color={`fill-white ${isDisabled && "!fill-disabled-stroke"}`}
                                            width={20}
                                            height={20}
                                        />
                                    }
                                    iconPlacement={"left"}
                                    onClick={onCourseStatusChange}
                                    id="userCourseBtn"
                                    disabled={isDisabled}
                                >
                                    {course.progressStatus === ProgressStatus.IN_PROGRESS && "Продолжить"}
                                    {course.progressStatus === ProgressStatus.NOT_STARTED && "Начать прохождение"}
                                </Button>
                            )}
                        {course.progressStatus !== ProgressStatus.PASSED &&
                            (!courseStart ||
                                (courseStart &&
                                    (!courseStart.next ||
                                        (courseStart.next &&
                                            courseStart.next.progressStatus !== ProgressStatus.ON_REVIEW &&
                                            courseStart.next.progressStatus !== ProgressStatus.BLOCKED)))) && (
                                <div className="fixed bottom-14 left-0 flex sm:hidden p-2.5 w-full z-10 bg-white">
                                    <Button
                                        id="userCourseBtn"
                                        size="large"
                                        className="w-full"
                                        iconPlacement="left"
                                        onClick={onCourseStatusChange}
                                        icon={
                                            <Icon
                                                className="mr-1.5"
                                                icon={Icons.PlayCircle}
                                                color="fill-white"
                                                width={20}
                                                height={20}
                                            />
                                        }
                                    >
                                        {course.progressStatus === ProgressStatus.IN_PROGRESS && "Продолжить"}
                                        {course.progressStatus === ProgressStatus.NOT_STARTED && "Начать прохождение"}
                                    </Button>
                                </div>
                            )}
                        <div className="flex flex-col sm:flex-row sm:space-x-7.5 2xl:space-x-9.5 mt-3 sm:mt-0 w-full sm:w-auto">
                            {/*{course.progressStatus === ProgressStatus.PASSED &&*/}
                            {/*    !course.hideUserReviews &&*/}
                            {/*    !dataQueryFeedbackList?.data?.Content.find(({ user: { id } }: FeedbackListResponse) => {*/}
                            {/*        return id === currentUser?.data?.id;*/}
                            {/*    }) && (*/}
                            {/*        <ActionButtonsBlock*/}
                            {/*            title={`Оценить курс`}*/}
                            {/*            onClick={() => {*/}
                            {/*                navigate(`/training/course/${courseId}/complete`);*/}
                            {/*            }}*/}
                            {/*            withIcon={false}*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {course.progressStatus === ProgressStatus.PASSED && (
                                <div
                                    className="flex items-center sm:flex-col sm:items-start mb-2 sm:mb-0 sm:gap-1"
                                    id="userCourseStatus"
                                >
                                    <div className="w-25 sm:w-auto p4 2xl:p3 text-gray">Статус</div>
                                    <div className="rounded-2sm 2xl:rounded-lg bg-primary p3 2xl:p2 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 text-white">
                                        Пройдено
                                    </div>
                                </div>
                            )}
                            {course.progressStatus !== ProgressStatus.NOT_STARTED && (
                                <div
                                    className="flex items-center sm:flex-col sm:items-start mb-2 sm:mb-0 sm:gap-1"
                                    id="userCourseProgress"
                                >
                                    <div className="w-25 sm:w-auto p4 2xl:p3 text-gray">Прогресс</div>
                                    <div className="p3 2xl:p2 pt-[3px] 2xl:pt-1 text-gray-dark">
                                        {course.progressPercent}%
                                    </div>
                                </div>
                            )}
                            {course.progressStatus !== ProgressStatus.NOT_STARTED && (
                                <div
                                    className="flex items-center sm:flex-col sm:items-start mb-2 sm:mb-0 sm:gap-1"
                                    id="userCourseReceivedPoints"
                                >
                                    <div className="w-25 sm:w-auto p4 2xl:p3 text-gray">Получено</div>
                                    <div className="p3 2xl:p2 pt-[3px] 2xl:pt-1 text-gray-dark">
                                        {course.receivedPoints}{" "}
                                        {numCapEnd(
                                            { one: "балл", two: "балла", few: "баллов" },
                                            Number(course.receivedPoints),
                                        )}
                                    </div>
                                </div>
                            )}
                            {/*{!course.hideUserReviews && Number(course.averageReviewRating) > 0 && (*/}
                            {/*    <div*/}
                            {/*        className="flex items-center sm:flex-col sm:items-start mb-2 sm:mb-0 sm:gap-1"*/}
                            {/*        id="userCourseReviewRating"*/}
                            {/*    >*/}
                            {/*        <div className="w-25 sm:w-auto p4 2xl:p3 text-gray">Рейтинг</div>*/}
                            {/*        <div className="p3 2xl:p2 pt-[3px] 2xl:pt-1 text-gray-dark">*/}
                            {/*            {course.averageReviewRating} (*/}
                            {/*            <a*/}
                            {/*                className="cursor-pointer text-gray-dark underline hover:no-underline"*/}
                            {/*                onClick={() => scrollToFeedback()}*/}
                            {/*            >*/}
                            {/*                {`${dataQueryFeedbackList?.data?.Content.length} ${numCapEnd(*/}
                            {/*                    { one: "отзыв", two: "отзыва", few: "отзывов" },*/}
                            {/*                    Number(dataQueryFeedbackList?.data?.Content.length) ?? 0,*/}
                            {/*                )}`}*/}
                            {/*            </a>*/}
                            {/*            )*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            {((course.progressStatus === ProgressStatus.PASSED && !!course.completionTime) ||
                                (course.progressStatus === ProgressStatus.IN_PROGRESS && !!course.startTime)) && (
                                <div
                                    className="flex items-center sm:flex-col sm:items-start mb-2 sm:mb-0 sm:gap-1"
                                    id="userCourseTimeSpent"
                                >
                                    <div className="w-25 sm:w-auto p4 2xl:p3 text-gray">
                                        <span className="hidden sm:block">Время выполнения</span>
                                        <span className="block sm:hidden">Затрачено</span>
                                    </div>
                                    <div className="p3 2xl:p2 pt-[3px] 2xl:pt-1 text-gray-dark">
                                        {course.progressStatus === ProgressStatus.PASSED &&
                                            formatLeadTime(course.completionTime - course.startTime)}
                                        {course.progressStatus === ProgressStatus.IN_PROGRESS &&
                                            formatLeadTime(Date.now() / 1000 - course.startTime)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="rounded-2xl 2xl:rounded-3xl overflow-hidden flex-shrink-0" id="userCourseCover">
                    <Card
                        className="w-full h-[60vw] sm:w-100 2xl:w-125 sm:h-61 2xl:h-[305px]"
                        points={course.maxRatingPoints}
                        type={ResourceType.COURSE}
                        deadline={course.deadlineTimestamp ? dayjs(course.deadlineTimestamp * 1000) : undefined}
                        required={course.isRequired}
                        logoId={course.logoId}
                        progress={course.progressPercent}
                        duration={completionTime * 60}
                        isFavorite={course.isFavorite}
                        favoriteShowOnHover={false}
                        onFavoriteChange={handleOnFavorite}
                    />
                </div>
            </div>
            <ControlledAccordion
                // Forward the `providerValue` directly to `ControlledAccordion`
                providerValue={providerValue}
                id="userCourseMaterialBlock"
            >
                {course.sections.map((section) => {
                    const completeCount = section.components?.filter(
                        (material) => material.progressStatus === ProgressStatus.PASSED,
                    )?.length;
                    const isSectionComplete = completeCount === section.components?.length;

                    return (
                        <div key={section.id} className="border-b border-blue-gray">
                            <AccordionItem
                                itemKey={section.id}
                                initialEntered={section.id === firstToOpen}
                                header={(value) => {
                                    return (
                                        <div
                                            className={clsx(
                                                "flex gap-6.5 py-6 items-center",
                                                section.id !== firstToOpen && "cursor-pointer",
                                            )}
                                            onClick={() => toggleHandler(section)}
                                        >
                                            <div className="flex flex-col sm:flex-row sm:gap-6.5 w-full">
                                                <h2 className="hidden sm:block mb-1 sm:mb-0 light col-span-9 light 2xl:!text-2xl 2xl:!leading-[35px] text-left break-anywhere grow">
                                                    {section.title}
                                                </h2>
                                                <h3 className="flex sm:hidden items-center mb-1 sm:mb-0 light col-span-9 light text-left break-anywhere">
                                                    {section.title}
                                                </h3>
                                                <div
                                                    className={`flex items-center col-span-2 p2 2xl:text-md sm:shrink-0 ${
                                                        isSectionComplete ? "text-gray" : "text-gray-dark"
                                                    }`}
                                                >
                                                    Пройдено {completeCount} из {section.components?.length}
                                                </div>
                                            </div>
                                            <div className={`flex justify-end`}>
                                                <Button
                                                    icon={
                                                        <Icon
                                                            icon={
                                                                value.state.isEnter
                                                                    ? Icons.ChevronUp
                                                                    : Icons.ChevronDown
                                                            }
                                                            color="fill-blue-drk"
                                                            width={15}
                                                            height={15}
                                                            className="2xl:!w-4.5 2xl:!h-4.5"
                                                        />
                                                    }
                                                    iconPlacement="center"
                                                    shape="round"
                                                    size="small"
                                                    color="background"
                                                    variant="standard"
                                                    className="2xl:w-[35px] 2xl:h-[35px]"
                                                />
                                            </div>
                                        </div>
                                    );
                                }}
                                contentProps={{
                                    className: "transition-all",
                                }}
                                buttonProps={{
                                    className: "w-full",
                                }}
                            >
                                <div className="flex flex-wrap gap-x-6.5 gap-y-7 2xl:gap-x-8 2xl:gap-y-9 pb-6">
                                    {section.components?.map((material) => {
                                        return (
                                            <CourseMaterialCard
                                                key={material.resourceId}
                                                material={material}
                                                courseId={course.resourceId}
                                                programId={programId}
                                                refetch={refetch}
                                                isFavorite={material.isFavorite}
                                                state={material.state}
                                                onFavoriteChange={() =>
                                                    mutateMaterialFavoriteStatus({
                                                        courseId,
                                                        componentId: material.id,
                                                        resourceId: material.resourceId,
                                                        materialType: material.materialType,
                                                        type: material.type,
                                                        fileType: material.materialFileType ?? "NULL",
                                                    })
                                                }
                                                onClick={setUrlToNavigate}
                                            ></CourseMaterialCard>
                                        );
                                    })}
                                </div>
                            </AccordionItem>
                        </div>
                    );
                })}
            </ControlledAccordion>
            {/* Отзывы */}
            {/*{!course.hideUserReviews && (*/}
            {/*    <div id="feedback">*/}
            {/*        <FeedbackList data={dataQueryFeedbackList?.data?.Content} title="Отзывы о курсе" />*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
};
