import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeCookie, setCookie } from "typescript-cookie";
import { RoleName } from "Enums";
import { IReducer } from "store";
import { setIsAuth, setIsRoot } from "slices/authSlice";
import { Button, ComboBox, Icon, Icons, Input } from "Uikit";
import { passwordRegexp } from "Uikit/Forms/Input";
import Api from "Api";
import { IOption } from "types";

export const AuthorizationForm = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const isRoot = useSelector((state: IReducer) => state.auth.isRoot);

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [selectedCompany, setSelectedCompany] = useState<any>("");
    const [companyOpt, setCompanyOpt] = useState<IOption[]>([]);

    const onLogin = async () => {
        removeCookie("USER_SESSION");
        let session: string;

        try {
            session = await Api.Auth.GetSession({ username: login, password: password });
        } catch (error: any) {
            if (error.status === 423) {
                setError("Доступ запрещен, обратитесь к администратору");
            } else {
                setError("Неправильный логин или пароль");
            }

            return;
        }

        if (!session || session.length === 0) {
            setError("Неправильный логин или пароль");
            return;
        }

        setCookie("USER_SESSION", session, {
            path: "/",
            domain: window.location.hostname,
            expires: new Date(new Date().getTime() + 240 * 60 * 1000),
            secure: window.location.hostname !== "localhost" && window.location.hostname.indexOf("192.168.") === -1,
        });

        const currentUser = await Api.User.GetCurrentUser();

        if (currentUser.role !== RoleName.ROOT) {
            localStorage.setItem("companyId", currentUser.companyId as string);
            dispatch(setIsAuth(true));
        } else {
            dispatch(setIsRoot(true));
        }
    };
    const onRootEnter = () => {
        dispatch(setIsAuth(true));
        localStorage.setItem("companyId", selectedCompany.value);
        Api.User.setAdminCompany(selectedCompany.value).then(() => {
            /* no implementation */
        });

        if (window.screen.width <= 568) {
            navigate("/");
        } else {
            navigate("/admin/settings");
        }
    };

    useEffect(() => {
        const fetch = async () => {
            const companies = await Api.CompanyApi.List();
            setCompanyOpt(companies.Content.map((c) => ({ label: c.name, value: c.id })));
        };

        if (isRoot) {
            fetch().then();
        }
    }, [isRoot]);

    return (
        <div className="w-full sm:max-w-105 sm:bg-white sm:rounded-2xl md:mt-0 xl:p-0">
            {!isRoot && (
                <div className="sm:pt-10 sm:pb-7.5 sm:px-10" onKeyUp={(e) => e.key === "Enter" && onLogin()}>
                    <h1 className="mb-4 sm:mb-2.5 sm:text-[32px] text-black">Добро пожаловать</h1>
                    <p className="mb-8 sm:mb-10 p1 sm:p2 text-blue-drk sm:text-gray">
                        Пожалуйста, введите свои данные для входа в систему обучения
                    </p>
                    <div className="mb-4 sm:mb-7.5 space-y-4 sm:space-y-6">
                        {error && (
                            <div
                                className="hidden sm:flex items-center justify-between -mt-4 p-4 bg-red rounded-lg"
                                onClick={() => setError(null)}
                            >
                                <div className="flex items-center space-x-2">
                                    <Icon icon={Icons.Ban} width={24} height={24} color="fill-white" />
                                    <span className="p2 text-white">{error}</span>
                                </div>
                            </div>
                        )}
                        <Input
                            id="auth-login"
                            name="auth-login"
                            rootClassName="!bg-transparent"
                            className="h-12 bg-white sm:h-10"
                            placeholder="Логин"
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                            tabIndex={1}
                            onFocus={() => setError(null)}
                        />
                        <div className="relative text-gray-600 focus-within:text-gray-400">
                            <span className="z-20 absolute inset-y-0 right-2 flex items-center pl-2">
                                <button
                                    className="z-20 p-1 focus:outline-none focus:shadow-outline"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {!showPassword && <Icon icon={Icons.EyeSlash} width="22px" height="22px" />}
                                    {showPassword && <Icon icon={Icons.Eye} width="22px" height="22px" />}
                                </button>
                            </span>
                            <Input
                                id="auth-password"
                                name="auth-password"
                                rootClassName="!bg-transparent"
                                className="h-12 bg-white sm:h-10"
                                type={showPassword ? "text" : "password"}
                                placeholder="Пароль"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                tabIndex={2}
                                onFocus={() => setError(null)}
                                cutRegExp={passwordRegexp}
                            />
                        </div>
                        {error && <div className="block sm:hidden text-danger">Неверный логин или пароль</div>}
                        <Button className="w-full h-12 sm:!mt-7.5 sm:!mb-0 sm:h-10" onClick={onLogin} tabIndex={2}>
                            Войти
                        </Button>
                    </div>
                    <p className="mb-4 p2 text-blue-drk sm:text-gray-light">
                        Нет аккаунта? Обратитесь к администратору
                    </p>
                    <Link className="block p2 text-blue-drk sm:text-gray-light" to="/privacy-policy">
                        Политика конфиденциальности
                    </Link>
                    <Link className="block p2 text-blue-drk sm:text-gray-light" to="/terms-of-use">
                        Условия использования
                    </Link>
                </div>
            )}
            {isRoot && (
                <div className="sm:pt-10 sm:pb-7.5 sm:px-10">
                    <h1 className="sm:text-[32px] mb-8 sm:mb-10 text-black">Выберите компанию</h1>
                    <ComboBox
                        className="h-12 sm:h-10"
                        placeholder="Выберите компанию"
                        onChange={(value) => {
                            setSelectedCompany(value);
                        }}
                        value={selectedCompany}
                        options={companyOpt as any}
                        isSearchable={true}
                        isCreatable={false}
                        isClearable={false}
                        styles={{
                            control: (baseStyles: any) => ({
                                ...baseStyles,
                                height: "100%",
                            }),
                        }}
                    />
                    <Button
                        className="w-full h-12 mt-4 sm:!mt-7.5 sm:!mb-0 sm:h-10"
                        disabled={!selectedCompany}
                        onClick={onRootEnter}
                    >
                        Продолжить
                    </Button>
                    <Button
                        className="w-full h-12 mt-1.5 sm:!mb-0 sm:h-10"
                        color="gray"
                        onClick={async () => {
                            await Api.Auth.Logout();
                            queryClient.clear();
                            dispatch(setIsRoot(false));
                            dispatch(setIsAuth(false));
                            localStorage.removeItem("companyId");
                            removeCookie("USER_SESSION", { path: "/", domain: window.location.hostname });
                        }}
                    >
                        Выйти
                    </Button>
                    <Link
                        className="hidden sm:block p2 mt-7.5 !text-blue cursor-pointer"
                        to="/admin/settings/licences/create"
                        onClick={() => dispatch(setIsAuth(true))}
                    >
                        Или создать новую компанию
                    </Link>
                </div>
            )}
        </div>
    );
};
