import React from "react";
import { Switch } from "@headlessui/react";
import clsx from "clsx";

import { Icon, Icons, Tooltip } from "../";
import { Placement } from "tippy.js";

interface ToggleProps {
    label?: string;
    enabled: boolean;
    onChange: (value: boolean) => void;
    className?: string;
    tip?: string;
    tipPlacement?: Placement;
    disabled?: boolean;
    id?: string;
    labelClassName?: string;
}

export const Toggle = ({
    label,
    enabled,
    onChange,
    className,
    tip,
    tipPlacement,
    disabled,
    id,
    labelClassName = "",
}: ToggleProps) => {
    return (
        <div className={clsx("flex items-center", className)}>
            <Switch
                id={id}
                checked={enabled}
                onChange={onChange}
                disabled={disabled}
                className={clsx(
                    enabled ? "bg-[#1380CE]" : "bg-[#EAEDF3]",
                    "inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent",
                    "transition-colors duration-200 ease-in-out",
                    disabled && "!cursor-default opacity-50",
                )}
            >
                <span className="sr-only">Use setting</span>
                <span
                    aria-hidden="true"
                    className={clsx(
                        enabled ? "translate-x-6" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 ",
                        "transition duration-200 ease-in-out",
                    )}
                ></span>
            </Switch>
            <div className={clsx("table ml-3", { "!flex items-center gap-1": !!tip }, labelClassName)}>
                <span className={clsx("table-cell align-middle", { "opacity-50": !!disabled })}>{label}</span>
                {tip ? (
                    <Tooltip placement={tipPlacement} content={tip} arrow={true}>
                        <Icon
                            icon={Icons.Question}
                            color="fill-gray-light -translate-y-1/4"
                            width="14px"
                            height="14px"
                        />
                    </Tooltip>
                ) : null}
            </div>
        </div>
    );
};
