import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import Api from "Api";
import { useEventListener } from "hooks/useEventListener";
import { Icon, Icons } from "Uikit";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchRequest } from "Api/Requests/SearchRequest";
import { SearchResource } from "Api/Responses/SearchResponse";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { highlightSubstring } from "helpers/highlightSubstring";
import { useDebounceValue } from "hooks/useDebounceValue";
import {
    // ProgressStatus,
    ResourceType,
} from "Enums";
import { LogoSize } from "Api/Services/UploadApi";

const resourceType: { [key: string]: string } = {
    MATERIAL: "Материал",
    // PROGRAM: "Программа",
    COURSE: "Курс",
    // QUIZ: "Тест",
    // EXERCISE: "Задание",
    USER: "Пользователь",
    POLL: "Опрос",
    NEWS: "Новость",
};

const userResourceLink: { [key: string]: string } = {
    PROGRAM: "/training/program/",
    COURSE: "/training/course/",
    QUIZ: "/test/",
    EXERCISE: "/task/",
    USER: "/member/",
    POLL: "/task/",
    NEWS: "/news/",
};
const adminResourceLink: { [key: string]: string } = {
    MATERIAL: "/admin/material/",
    PROGRAM: "/admin/program/",
    COURSE: "/admin/course/",
    QUIZ: "/admin/test/",
    EXERCISE: "/admin/task/",
    USER: "/admin/member/",
    POLL: "/admin/task/",
    NEWS: "/admin/news/",
};

interface ISearchList extends SearchResource {
    isActive?: boolean;
}

interface ISearchProps {
    onInputFocusHandler?: (isFocused: boolean) => void;
}

export const Search = ({ onInputFocusHandler }: ISearchProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    const location = useLocation();

    const [query, setQuery] = useState("");
    const [debounceQuery] = useDebounceValue(String(query).trim(), 500);

    const [onSearch, setOnSearch] = useState(false);
    const [searchResult, setSearchResult] = useState<ISearchList[]>([]);
    const [total, setTotal] = useState<number>(0);

    const GetAutocomplete = useCallback(
        async (query: string) => {
            const searchRequest = new SearchRequest();
            searchRequest.query = String(query).trim();
            searchRequest.size = 10;

            const searchResponse = location.pathname.includes("/admin")
                ? await Api.Search.SearchList(searchRequest)
                : await Api.Search.PublicSearchList(searchRequest);

            setSearchResult(
                searchResponse.resources.map((resource) => ({
                    ...resource,
                    type: resource.type.replace("USER_", ""),
                })),
            );
            setTotal(searchResponse.total);
        },
        [location.pathname],
    );

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        const result = searchResult;

        if (e.key === "Enter") {
            e.preventDefault();

            const activeIndex = result.findIndex((item) => item.isActive === true);

            if (activeIndex === -1) {
                search();
                inputRef.current?.blur();
            } else {
                search(result[activeIndex]);
            }
        }

        if (result.length > 0) {
            let itemIdx = -1;

            if (e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "Tab") {
                e.preventDefault();
                itemIdx = result.findIndex((item: any) => item.isActive);
            }

            if (e.key === "ArrowDown" || (!e.shiftKey && e.key === "Tab")) {
                if (itemIdx === -1) {
                    result[0].isActive = true;
                } else if (itemIdx < result.length - 1) {
                    result[itemIdx].isActive = false;
                    result[itemIdx + 1].isActive = true;
                } else if (itemIdx === result.length - 1) {
                    result[itemIdx].isActive = false;
                    result[0].isActive = true;
                }

                setSearchResult(Object.assign([], result));
            } else if (e.key === "ArrowUp" || (e.shiftKey && e.key == "Tab")) {
                if (itemIdx === -1) {
                    result[result.length - 1].isActive = true;
                } else if (itemIdx > 0) {
                    result[itemIdx].isActive = false;
                    result[itemIdx - 1].isActive = true;
                } else if (itemIdx === 0) {
                    result[0].isActive = false;
                    result[result.length - 1].isActive = true;
                }

                setSearchResult(Object.assign([], result));
            }
        }
    };

    const onBlur = () => {
        const isFocused = false;
        setTimeout(() => {
            //setQuery("");
            //setSearchResult([]);
            setOnSearch(isFocused);
            onInputFocusHandler?.(isFocused);
        }, 200);
    };

    const search = (item?: SearchResource) => {
        const isAdminPage = location.pathname.includes("/admin");

        if (item) {
            const pageRoute = (isAdminPage ? adminResourceLink : userResourceLink)[item.type];

            let linkId = "";

            if (
                isAdminPage ||
                item.type === ResourceType.COURSE ||
                item.type === ResourceType.PROGRAM
                // ||
                // item.type === ResourceType.QUIZ
            ) {
                linkId = item.resourceId;
            } else {
                linkId = item.userResourceId ?? item.id;
            }

            const link = `${pageRoute}${item.type === "MATERIAL" ? item.type + "/" : ""}${linkId}`;

            // if (
            //     (item.type === ResourceType.EXERCISE
            //         // || item.type === ResourceType.QUIZ
            //     ) &&
            //     (item.progressStatus === ProgressStatus.PASSED ||
            //         item.progressStatus === ProgressStatus.ON_REVIEW ||
            //         item.progressStatus === ProgressStatus.FAILED)
            // ) {
            //     link += "/statistics";
            // }

            navigate(link);
        } else {
            navigate((isAdminPage ? "/admin" : "") + `/search?query=${encodeURIComponent(query)}`, {
                replace: true,
            });
        }
        setOnSearch(false);
    };

    const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const value = String(e.target.value.replace(/^\s+/, "")).replace(/\s{2,}/gi, " ");

        setQuery(value);
        setOnSearch(true);
    };

    const inputOnFocus = () => {
        const isFocused = true;
        setOnSearch(isFocused);
        onInputFocusHandler?.(isFocused);
    };
    const renderSearchItem = (item: ISearchList) => {
        const searchQuery = String(query).trim();

        // case ResourceType.PROGRAM:
        // case ResourceType.QUIZ:
        // case ResourceType.EXERCISE:
        switch (item.type) {
            case ResourceType.COURSE:
            case ResourceType.POLL:
            case ResourceType.MATERIAL:
            case ResourceType.NEWS:
            case ResourceType.CALENDAR:
                return (
                    <div className="flex items-center">
                        <div className="flex-shrink-0 w-13.5 h-9 mr-2 rounded bg-gray overflow-hidden">
                            {item.logoId && (
                                <img
                                    className="w-full h-full object-cover"
                                    src={Api.Upload.GetLogo(item.logoId, LogoSize.THUMB_MICRO)}
                                    alt=""
                                />
                            )}
                        </div>
                        <div className="pr-6">
                            <div className="flex gap-3">
                                <div
                                    className="p1 line-clamp-1"
                                    dangerouslySetInnerHTML={{
                                        __html: highlightSubstring(item.title, searchQuery).text,
                                    }}
                                ></div>
                                {item.passingNumber ? (
                                    // && item.type !== ResourceType.QUIZ
                                    <div className="flex items-center px-1 leading-4 bg-blue-50 rounded text-xxs text-white">
                                        Прохождение {item.passingNumber}
                                    </div>
                                ) : null}
                            </div>
                            {highlightSubstring(item.description, searchQuery).find ? (
                                <div
                                    className="p4 text-gray line-clamp-1"
                                    dangerouslySetInnerHTML={{
                                        __html: highlightSubstring(item.description, searchQuery).text,
                                    }}
                                ></div>
                            ) : null}
                        </div>
                    </div>
                );
            case "USER":
                return (
                    <div className="flex items-center">
                        <UserAvatar
                            avatarId={item.logoId}
                            userInitials={`${item.firstName?.slice(0, 1)}${item.lastName?.slice(0, 1)}`}
                            size={36}
                        />
                        <div
                            className="p1 ml-2"
                            dangerouslySetInnerHTML={{
                                __html:
                                    highlightSubstring(item.firstName ?? "", searchQuery).text +
                                    " " +
                                    highlightSubstring(item.lastName ?? "", searchQuery).text,
                            }}
                        ></div>
                    </div>
                );
            case "LIBRARY":
                return <div></div>;

            default:
                return null;
        }
    };

    useEffect(() => {
        if (!debounceQuery) {
            setSearchResult([]);

            return;
        }

        GetAutocomplete(debounceQuery).then();
    }, [debounceQuery, GetAutocomplete]);
    useEffect(() => {
        if (location.pathname.indexOf("/search") !== -1) {
            setQuery(decodeURI(location.search.replace("?query=", "")));
            return;
        }

        setQuery("");
    }, [location]);

    useEventListener("click", (e: Event) => {
        if (onSearch && !e.composedPath()?.find?.((item: any) => item.id === "searchWrapper")) {
            setOnSearch(false);
        }
    });

    useEventListener("keydown", (e: KeyboardEvent) => {
        if (onSearch && e.key === "Escape") {
            setOnSearch(false);
            setQuery("");
            setSearchResult([]);
        }
    });

    return (
        <div id="searchWrapper" className="relative w-full py-2 2xl:py-2.5">
            {onSearch ? <div className="fixed inset-0 z-10 bg-black opacity-10"></div> : null}
            <div className="relative z-20 h-10 2xl:h-12.5">
                <Icon
                    className="absolute top-2/4 -translate-y-2/4 left-4.5 2xl:left-5.5 pointer-events-none 2xl:!w-6.25 2xl:!h-6.25"
                    icon={Icons.Search}
                    width={20}
                    height={20}
                />
                <input
                    id="searchInput"
                    name="searchInput"
                    ref={inputRef}
                    type="search"
                    className="w-full h-full pl-12 2xl:pl-15 pr-5.5 bg-background-light rounded-lg 2xl:rounded-2lg border-0 focus:ring-blue placeholder-gray text-black 2xl:text-md"
                    placeholder="Глобальный поиск по системе"
                    value={query}
                    autoComplete="off"
                    // ref={searchInputRef}
                    onFocus={inputOnFocus}
                    onBlur={onBlur}
                    onChange={onChangeSearch}
                    onKeyDown={(e) => onKeyDown(e)}
                />
                {query && (
                    <div
                        id="searchClearIcon"
                        className="absolute top-2/4 -translate-y-2/4 right-5.5 cursor-pointer bg-gray-blue pointer-events-none"
                    >
                        <Icon icon={Icons.Close} width={20} height={20} className="2xl:!w-6.25 2xl:!h-6.25" />
                    </div>
                )}
            </div>

            {onSearch && (
                <div
                    id={"autocompleteWindow"}
                    className="absolute z-20 w-full max-h-175 overflow-hidden top-15 2xl:top-[70px] border-0 py-2 border-gray-blue rounded-lg bg-white shadow-[0_8px_20px_0px_rgba(0,0,0,0.12)]"
                >
                    <div className="flex px-2 space-x-7">
                        <div className="flex items-center">
                            <div className="pr-1.5">
                                <p className="p2 bg-[#F5F7F9] px-2 py-0.5 rounded">ENTER</p>
                            </div>
                            <p className="p3 text-gray">перейти</p>
                        </div>
                        <div className="flex items-center">
                            <div className="pr-1">
                                <div className="bg-[#F5F7F9] p-1 rounded">
                                    <Icon icon={Icons.ArrowUp} width={16} height={16} />
                                </div>
                            </div>
                            <div className="pr-2">
                                <div className="bg-[#F5F7F9] p-1 rounded">
                                    <Icon icon={Icons.ArrowDown} width={16} height={16} />
                                </div>
                            </div>
                            <p className="p3 text-gray">навигация</p>
                        </div>
                        <div className="flex items-center">
                            <div className="pr-1.5">
                                <p className="p2 bg-[#F5F7F9] px-2 py-0.5 rounded">ESC</p>
                            </div>
                            <p className="p3 text-gray">отменить</p>
                        </div>
                    </div>
                    {query && !!searchResult.length && (
                        <div className="overflow-auto">
                            <div className=" pt-2">
                                {searchResult.map((item) => {
                                    return (
                                        <div
                                            key={item.id ?? item.userResourceId ?? item.resourceId}
                                            className={clsx(
                                                "flex items-center justify-between px-2 py-1.5 cursor-pointer hover:bg-background",
                                                item.isActive && "!bg-background",
                                            )}
                                            onClick={(e: any) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                search(item);
                                            }}
                                        >
                                            {renderSearchItem(item)}
                                            <span className="p3 text-gray">{resourceType[item.type]}</span>
                                        </div>
                                    );
                                })}
                                <div className="p2 text-blue px-2 pt-2 cursor-pointer" onClick={() => search()}>
                                    Перейти ко всем {total} результатам
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
