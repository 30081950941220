import React, { useState } from "react";
import { Button, Icon, Icons, PopoverList } from "Uikit";
import { Empty } from "Uikit/Page/Empty";
import { MaterialType } from "Enums";
import { TVoidFunction } from "types";

interface EmptyMaterialListProps {
    isCategories?: boolean;
    onCreateCategory: TVoidFunction;
    onCreateMaterial: (e: MouseEvent, name: string) => void;
}

export const EmptyMaterialList = ({ isCategories, onCreateCategory, onCreateMaterial }: EmptyMaterialListProps) => {
    const [isCreateOpen, setIsCreateOpen] = useState(false);

    return (
        <Empty
            title={isCategories ? "В категории пока нет материалов" : "Созданных материалов пока нет"}
            description={
                isCategories
                    ? "Создайте материалы и поместите их в эту категорию, чтобы они здесь появились"
                    : `Материал без категории существовать не может. Сначала создайте категорию, затем материал. 
                    Название категории НЕ отображается в пользовательском интерфейсе`
            }
        >
            <>
                {!isCategories && (
                    <Button
                        icon={<Icon icon={Icons.PlusFilled} width={20} height={20} color="fill-white" />}
                        iconPlacement={"left"}
                        onClick={() => onCreateCategory()}
                        id="emptyPageBtnCreateMaterialCategory"
                    >
                        Создать категорию
                    </Button>
                )}
                <PopoverList
                    offset={[0, 18]}
                    visible={isCreateOpen}
                    onClickOutside={() => setIsCreateOpen(false)}
                    className=""
                >
                    <Button
                        size={"medium"}
                        variant={isCategories ? "standard" : "outline"}
                        color={isCategories ? "primary" : "secondary"}
                        className="rounded-lg whitespace-nowrap font-medium"
                        icon={
                            <Icon
                                icon={Icons.PlusFilled}
                                color={isCategories ? "fill-white" : "fill-primary"}
                                width={20}
                                height={20}
                            />
                        }
                        iconPlacement={"left"}
                        onClick={() => setIsCreateOpen(true)}
                        id="emptyPageBtnCreateMaterial"
                    >
                        Создать материал
                    </Button>
                    <PopoverList.Item name={String(MaterialType.Article)} onClickItem={onCreateMaterial}>
                        Статья
                    </PopoverList.Item>
                    <PopoverList.Item name={String(MaterialType.Video)} onClickItem={onCreateMaterial}>
                        Видео
                    </PopoverList.Item>
                    {/*<PopoverList.Item name={String(MaterialType.Document)} onClickItem={onCreateMaterial}>*/}
                    {/*    Документ*/}
                    {/*</PopoverList.Item>*/}
                    {/*<PopoverList.Item name={String(MaterialType.SCORM)} onClickItem={onCreateMaterial}>*/}
                    {/*    SCORM*/}
                    {/*</PopoverList.Item>*/}
                    {/* <PopoverList.Item name={String(MaterialType.SCORM)} onClickItem={onCreateMaterial}>
                            SCORM
                        </PopoverList.Item>
                        <PopoverList.Item name={String(MaterialType.HTML)} onClickItem={onCreateMaterial}>
                            HTML
                        </PopoverList.Item> */}
                </PopoverList>
            </>
        </Empty>
    );
};
