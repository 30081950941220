import React, { ChangeEvent /* , useCallback */ } from "react";
import { MaterialType, UIErrorMessages } from "Enums";
import { SingleValue } from "react-select";

import {
    FormGroup,
    Label,
    Input,
    Textarea,
    ComboBox,
    CoverInput,
    Toggle,
    // Icon, Icons,
    flash,
} from "Uikit";
import { Section } from "Uikit/Forms/Section";
import { FormikErrors } from "formik";
import { CategoryMenuList, UsersOption, UsersSingleValue } from "Uikit/Forms/SelectComponents";
import Api from "Api/index";
import { CategoryModal } from "Uikit/CategoryModalNew/CategoryModal";
import { IOption, IAdministratorsOption } from "types";

interface MaterialSettingsProps {
    type: MaterialType | undefined;
    material: {
        image?: File;
        logoId: string;
        title: string;
        description: string;
        category: IOption;
        complexity: IOption;
        scoreRewriteLimit: number | null;
        userId: string;
        isAllowRewind: boolean;
        isAllowComments: boolean;
    };
    categories: IOption[];
    complexity: IOption[];
    users: IAdministratorsOption[];
    defaultLogos: string[];
    isSubmitted: boolean;
    errors?: FormikErrors<{
        title: string;
        category: IOption;
        complexity: IOption;
        user: IOption;
    }>;
    onChange: (field: string, value: any, shouldValidate?: boolean) => void;
    // isTraining: boolean;
}

export const MaterialSettings = ({
    type,
    material,
    categories,
    complexity,
    users,
    defaultLogos,
    errors,
    isSubmitted,
    onChange,
}: // isTraining,
MaterialSettingsProps) => {
    const [categoryOptions, setCategoryOptions] = React.useState(categories);
    const [isCategoryModalOpen, setIsCategoryModalOpen] = React.useState(false);

    const onImageChange = (data: File | null) => {
        onChange("settings.image", data);
    };

    const onDefaultImageChange = (id: string) => {
        onChange("settings.logoId", id);
    };

    const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => onChange("settings.title", e.target.value);

    const onDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => onChange("settings.description", e.target.value);

    const onComplexityChange = (e: SingleValue<any>) => onChange("settings.complexity", e);

    // const onScoreRewriteLimitChange = (e: SingleValue<any>) => onChange("settings.scoreRewriteLimit", e);

    const onCategoryChange = (e: SingleValue<any>) => onChange("settings.category", e);

    const onUserChange = (e: SingleValue<any>) => {
        onChange("settings.userId", e.payload.id);
    };

    // не в MVP
    // const handleTrainingChange = useCallback(
    //     (value: boolean) => {
    //         onChange("article.isTraining", value);
    //     },
    //     [onChange],
    // );

    const onAllowRewindChange = (isAllow: boolean) => onChange("settings.isAllowRewind", isAllow);

    const onAddCategoryClick = () => {
        setIsCategoryModalOpen(true);
    };

    const onCategorySubmit = async (title: string) => {
        try {
            const response = await Api.MaterialCategory.Create({ title });
            setCategoryOptions((prev) => [...prev, { label: title, value: response.id }]);
            onChange("settings.category", { label: title, value: response.id });

            return response;
        } catch (error) {
            return error;
        }
    };

    const coverInputErrorHandler = () => {
        flash.error(UIErrorMessages.FILE_LOADING_ERROR);
    };

    return (
        <>
            <Section label="Внешний вид">
                <FormGroup>
                    <Label>Обложка материала</Label>
                    <CoverInput
                        btn={false}
                        logo={(material.image ?? material?.logoId) || null}
                        onChange={onImageChange}
                        onChangeDefaultLogo={onDefaultImageChange}
                        defaultLogoIds={defaultLogos}
                        id="adminNewMaterial"
                        onError={coverInputErrorHandler}
                    />
                </FormGroup>
                <FormGroup>
                    <Label isRequired={true}>Заголовок материала</Label>
                    <Input
                        isRequired={true}
                        value={material?.title}
                        error={isSubmitted ? errors?.title : ""}
                        placeholder="Введите заголовок"
                        onChange={onTitleChange}
                        maxLength={128}
                        id="adminNewMaterialInputName"
                    />
                </FormGroup>

                <FormGroup>
                    <Label>Краткое описание</Label>
                    <Textarea
                        value={material?.description}
                        onChange={onDescriptionChange}
                        placeholder="Введите описание"
                        maxLength={1024}
                        id="adminNewMaterialInputDescription"
                    />
                </FormGroup>
            </Section>
            <Section label="Награда">
                <FormGroup>
                    <Label isRequired={true}>Сложность материала</Label>
                    <ComboBox
                        defaultValue={material.complexity.value && material.complexity}
                        value={material.complexity.value && material.complexity}
                        options={complexity}
                        isSearchable={false}
                        placeholder="Выберите сложность"
                        error={isSubmitted ? errors?.complexity?.value : ""}
                        onChange={onComplexityChange}
                        id="adminNewMaterialComboBoxComplexity"
                    />
                </FormGroup>

                {/*{material.complexity.value === "SCORM" && (*/}
                {/*    <FormGroup className="flex items-center !mb-3.5 h-10">*/}
                {/*        <Toggle*/}
                {/*            className="mr-auto min-h-[40px]"*/}
                {/*            enabled={material.scoreRewriteLimit !== null}*/}
                {/*            onChange={(p) => onChange("settings.scoreRewriteLimit", p ? 1 : null)}*/}
                {/*            label="Установить лимит перезаписи баллов"*/}
                {/*            tip="Пользователь сможет перезаписать баллы новым прохождением SCORM указанное количество раз"*/}
                {/*            id="adminMaterialToggleScoreRewriteLimit"*/}
                {/*        />*/}
                {/*        {material.scoreRewriteLimit && (*/}
                {/*            <div className="max-w-30">*/}
                {/*                <Input*/}
                {/*                    type="number"*/}
                {/*                    className="text-center"*/}
                {/*                    value={material.scoreRewriteLimit}*/}
                {/*                    onChange={(e) => {*/}
                {/*                        const val = Number(e.target.value);*/}
                {/*                        const limit = 99;*/}
                {/*                        const min = 1;*/}
                {/*                        if (val <= min) {*/}
                {/*                            onScoreRewriteLimitChange(min);*/}
                {/*                        }*/}
                {/*                        if (val > limit) {*/}
                {/*                            onScoreRewriteLimitChange(limit);*/}
                {/*                        }*/}
                {/*                        if (val > min && val <= limit) {*/}
                {/*                            onScoreRewriteLimitChange(val);*/}
                {/*                        }*/}
                {/*                    }}*/}
                {/*                    before={*/}
                {/*                        <div*/}
                {/*                            className={`cursor-pointer ${*/}
                {/*                                material.scoreRewriteLimit === 1 &&*/}
                {/*                                " pointer-events-none cursor-default opacity-50"*/}
                {/*                            }`}*/}
                {/*                            onClick={() =>*/}
                {/*                                onScoreRewriteLimitChange(*/}
                {/*                                    material.scoreRewriteLimit && material.scoreRewriteLimit > 1*/}
                {/*                                        ? material.scoreRewriteLimit - 1*/}
                {/*                                        : 1,*/}
                {/*                                )*/}
                {/*                            }*/}
                {/*                            id="adminMaterialToggleScoreRewriteBtnDecrementLimit"*/}
                {/*                        >*/}
                {/*                            <Icon icon={Icons.Minus} width={20} height={20} color="fill-[#878E9C]" />*/}
                {/*                        </div>*/}
                {/*                    }*/}
                {/*                    after={*/}
                {/*                        <div*/}
                {/*                            className={`cursor-pointer ${*/}
                {/*                                material.scoreRewriteLimit === 99 &&*/}
                {/*                                " pointer-events-none cursor-default opacity-50"*/}
                {/*                            }`}*/}
                {/*                            onClick={() =>*/}
                {/*                                onScoreRewriteLimitChange(*/}
                {/*                                    material.scoreRewriteLimit ? material.scoreRewriteLimit + 1 : 1,*/}
                {/*                                )*/}
                {/*                            }*/}
                {/*                            id="adminNewMaterialBtnIncrementLimit"*/}
                {/*                        >*/}
                {/*                            <Icon icon={Icons.Plus} width={20} height={20} color="fill-[#878E9C]" />*/}
                {/*                        </div>*/}
                {/*                    }*/}
                {/*                    id="adminMaterialToggleScoreRewriteBtnIncrementLimit"*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*    </FormGroup>*/}
                {/*)}*/}
            </Section>
            <Section label="Месторасположение">
                <FormGroup>
                    <Label isRequired={true}>Категория</Label>
                    <ComboBox
                        defaultValue={material.category.value && material.category}
                        value={material.category.value && material.category}
                        options={categoryOptions}
                        isSearchable
                        placeholder="Выберите категорию"
                        error={isSubmitted ? errors?.category?.value : ""}
                        onChange={onCategoryChange}
                        addOptionClick={onAddCategoryClick}
                        components={{ MenuList: CategoryMenuList, NoOptionsMessage: () => <div></div> }}
                        id="adminNewMaterialComboBoxCategory"
                    />
                </FormGroup>
            </Section>
            <Section label="Контроль">
                <FormGroup>
                    <Label isRequired={true}>Ответственный</Label>
                    <ComboBox
                        options={users}
                        isSearchable
                        placeholder="Выберите ответственного"
                        error={isSubmitted ? errors?.user?.value : ""}
                        getOptionValue={(option) => option.payload.id}
                        value={
                            material.userId
                                ? users[users.findIndex((p) => material.userId === p.payload.id)]
                                : undefined
                        }
                        onChange={onUserChange}
                        components={{
                            SingleValue: UsersSingleValue,
                            Option: UsersOption,
                        }}
                        id="adminNewMaterialComboBoxManager"
                    />
                </FormGroup>
            </Section>
            {/* 
            // не в MVP
            <Section label="Отработка навыков">
                <Toggle enabled={isTraining} onChange={handleTrainingChange} label="Тренажёр" />
            </Section>
             */}
            {type === MaterialType.Video && (
                <Section label="Прочее" className="!border-0">
                    {type === MaterialType.Video && (
                        <Toggle
                            enabled={material.isAllowRewind}
                            onChange={onAllowRewindChange}
                            label="Запретить перемотку видео"
                            className="mr-4 mb-7"
                            id="adminNewMaterialToggleRewindRestricted"
                        />
                    )}
                    {/* <Toggle
                    enabled={material.isAllowComments}
                    onChange={onAllowCommentsChange}
                    label="Запретить участникам оставлять комментарии"
                    className="mr-4"
                /> */}
                </Section>
            )}

            <CategoryModal
                isOpen={isCategoryModalOpen}
                onClose={() => setIsCategoryModalOpen(false)}
                onSubmit={onCategorySubmit}
            />
        </>
    );
};
