import React, { useState } from "react";
import { Modal } from "Uikit/Modal/Modal";
import { SecondaryButton } from "Uikit/Button/SecondaryButton";
import { Except } from "type-fest";
import Api from "Api/index";
import { Search } from "Uikit/Search";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { formatSendTime } from "helpers/dateHelper";
import clsx from "clsx";
import { numCapEnd } from "helpers/numCapEnd";
import { TVoidFunction } from "types";
import { LogoSize } from "Api/Services/UploadApi";
import { CurrentUserResponse } from "Api/Responses/UserResponse";

interface AchievementsOwnModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    user: CurrentUserResponse;
}

export const AchievementsOwnModal = ({ isOpen, onClose, user }: AchievementsOwnModalProps) => {
    return (
        <Modal title="Полученные ачивки" isOpen={isOpen} onClose={onClose}>
            <ModalContent onClose={onClose} user={user} />
        </Modal>
    );
};

const ModalContent = ({ onClose, user }: Except<AchievementsOwnModalProps, "isOpen">) => {
    const [search, setSearch] = useState("");

    const getAchievementRating = (rating: number) => {
        if (rating > 0) {
            return `+ ${rating} ${numCapEnd({ one: "балл", two: "балла", few: "баллов" }, Number(rating))}`;
        } else if (rating < 0) {
            return `- ${Math.abs(rating)} ${numCapEnd(
                { one: "балл", two: "балла", few: "баллов" },
                Number(Math.abs(rating)),
            )}`;
        } else {
            return "";
        }
    };

    if (!user) {
        return null;
    }

    const achievements = user.achievements?.filter((a) => a.name.includes(search));

    return (
        <>
            <Modal.Body className="mb-6.5 pt-6.5">
                <Search
                    className="max-w-full w-full"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                />
                <div className="h-114 overflow-auto">
                    {achievements?.length === 0 && (
                        <div className="flex items-center justify-center h-full text-gray">Нет ачивок</div>
                    )}
                    {achievements?.map((achievement) => {
                        return (
                            <div
                                key={achievement.id + achievement.givenAt}
                                className="flex py-3.5 first:pt-4 w-full border-b border-gray-blue"
                            >
                                {achievement.logoId && (
                                    <img
                                        className="rounded-full w-20 h-20 object-cover"
                                        src={Api.Upload.GetLogo(achievement.logoId, LogoSize.THUMB_MICRO)}
                                        alt={achievement.name}
                                    />
                                )}
                                {!achievement.logoId && <div className="rounded-full w-20 h-20 bg-gray-blue" />}
                                <div className="flex flex-col items-start ml-2.5 w-full">
                                    <div className="flex justify-between mb-1.25 w-full">
                                        <h4 className="text-black">{achievement.name}</h4>
                                        <div
                                            className={clsx(
                                                "p1-accent",
                                                achievement.rating < 0 ? "text-red" : "text-blue",
                                            )}
                                        >
                                            {getAchievementRating(achievement.rating)}
                                        </div>
                                    </div>
                                    <p className="mb-2 text-gray p3">Выдана {formatSendTime(achievement.givenAt)}</p>
                                    <div className="flex flex-col w-full">
                                        <div className="flex items-center mb-1.5">
                                            <UserAvatar
                                                avatarId={achievement.givenBy.avatarId}
                                                color={achievement.givenBy.defaultAvatarColor || undefined}
                                                userInitials={`${achievement.givenBy.firstName?.slice(
                                                    0,
                                                    1,
                                                )}${achievement.givenBy.lastName?.slice(0, 1)}`}
                                                size={24}
                                            />
                                            <span className="ml-2 text-black p3 leading-0">
                                                {achievement.givenBy.firstName} {achievement.givenBy.lastName}
                                            </span>
                                        </div>
                                        {achievement.comment && (
                                            <div className="flex">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="25"
                                                    height="22"
                                                    viewBox="0 0 25 22"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M12 0C12 0 12 7.29851 12 12.3731C12 17.4478 15.25 21 18.5 21C21.75 21 25 21 25 21"
                                                        stroke="#C1C6CA"
                                                    />
                                                </svg>
                                                <div className="ml-1.5 py-2.5 px-3.5 bg-background-light rounded-lg text-black p3">
                                                    {achievement.comment}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <SecondaryButton onClick={onClose}>Закрыть</SecondaryButton>
            </Modal.Footer>
        </>
    );
};
