import React, { useContext, useEffect, useMemo, useState } from "react";
import { SortingState } from "@tanstack/react-table";
import { useNavigate, useParams } from "react-router-dom";
import { ContentLayout, TreeWrapperContext } from "Containers";
import { Breadcrumbs, Content, Tabs, TabsWrapper } from "Uikit";
import { ArchiveTable } from "./ArchiveTable";
import { ArchiveCountResponse } from "Api/Responses/CourseResponse";
import Api from "Api";
import { useQuery } from "react-query";
import { IOption } from "types";

type RoleKeys =
    // "programs" |
    "courses" | "materials";
    // | "tests"
    // | "exercise"

export const Archive = () => {
    const navigate = useNavigate();

    const { tab } = useParams();
    const { setTreeProps } = useContext(TreeWrapperContext);

    const [selectedTab, setSelectedTab] = useState(0);
    const [count, setCount] = useState<ArchiveCountResponse>();

    const [categories, setCategories] = useState<IOption[]>([]);

    const { data: user } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());
    const { data: role } = useQuery(["role", "get", user?.id], () => Api.Role.Id(user!.id), { enabled: !!user });

    const fetchCount = async () => setCount(await Api.Course.ArchiveCount());

    const availableTabs = useMemo(() => {
        return [
            // {
            //     roleKey: "programs",
            //     path: "/admin/archive/programms",
            //     title: "Программы",
            //     categoryApi: Api.Program.CategoryGet,
            // },
            {
                roleKey: "courses",
                path: "/admin/archive/courses",
                title: "Курсы",
                categoryApi: Api.Course.CategoryGet,
            },
            {
                roleKey: "materials",
                path: "/admin/archive/materials",
                title: "Материалы",
                categoryApi: Api.MaterialCategory.List,
            },
            // {
            //     roleKey: "tests",
            //     path: "/admin/archive/tests",
            //     title: "Тесты",
            //     categoryApi: Api.Test.CategoryGet,
            // },
            // {
            //     roleKey: "exercise",
            //     path: "/admin/archive/tasks",
            //     title: "Задания",
            //     categoryApi: Api.TaskCategoryApi.List,
            // },
        ].filter((tab) => role?.[tab.roleKey as RoleKeys]);
    }, [role]);

    const onChangeTab = (index: number) => {
        const route = availableTabs[index]?.path;
        if (route) {
            navigate(route);
        }
    };

    const onCourseRequest = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ) => {
        return await Api.Course.ArchiveList(page, size, sort, filter);
    };

    const onCourseRestore = async (categoryId: string, ids: string | string[]) => {
        await Api.Course.UnArchive({ categoryId, ids: Array.isArray(ids) ? ids : [ids] });
        await fetchCount();
    };

    const onMaterialRequest = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ) => {
        return Api.Material.ArchiveList(page, size, sort, filter);
    };

    const onMaterialRestore = async (categoryId: string, ids: string | string[]) => {
        await Api.Material.UnArchive({ categoryId, ids: Array.isArray(ids) ? ids : [ids] });
        await fetchCount();
    };

    // const onTestRequest = async (page: number, size: number, sort: SortingState, filter: { [id: string]: string }) => {
    //     return Api.Test.ArchiveList(page, size, sort, filter);
    // };
    //
    // const onTestRestore = async (categoryId: string, ids: string | string[]) => {
    //     await Api.Test.UnArchive({ categoryId, ids: Array.isArray(ids) ? ids : [ids] });
    //     await fetchCount();
    // };

    // const onTaskRequest = async (page: number, size: number, sort: SortingState, filter: { [id: string]: string }) => {
    //     return Api.Task.ArchiveList(page, size, sort, filter);
    // };
    //
    // const onTaskRestore = async (categoryId: string, ids: string | string[]) => {
    //     await Api.Task.UnArchive({ categoryId, ids: Array.isArray(ids) ? ids : [ids] });
    //     await fetchCount();
    // };

    // const onProgramsRequest = async (
    //     page: number,
    //     size: number,
    //     sort: SortingState,
    //     filter: { [id: string]: string },
    // ) => {
    //     return Api.Program.ArchiveList(page, size, sort, filter);
    // };
    //
    // const onProgramsRestore = async (categoryId: string, ids: string | string[]) => {
    //     await Api.Program.UnArchive({ categoryId, programIds: Array.isArray(ids) ? ids : [ids] });
    //     await fetchCount();
    // };

    useEffect(() => {
        if (!role) {
            return;
        }

        (async () => {
            const tabName = tab ? "/" + tab : "";
            const tabIndex = availableTabs.findIndex((t) => t.path.endsWith(tabName));

            const currentTabIndex = tabIndex !== -1 ? tabIndex : 0;
            setSelectedTab(currentTabIndex);

            const currentTab = availableTabs[currentTabIndex];

            if (currentTab) {
                const categoriesData = await currentTab.categoryApi();
                setCategories(categoriesData.Content.map((p) => ({ label: p.title, value: p.id })));
            }
        })().then();
    }, [tab, role, availableTabs]);

    useEffect(() => {
        if (!setTreeProps) {
            return;
        }

        setTreeProps(undefined);
    }, [setTreeProps]);

    useEffect(() => {
        const fetch = () => {
            fetchCount().then();
        };

        fetch();
    }, []);

    return (
        <ContentLayout className="h-full">
            <div className="flex flex-col w-full">
                <Breadcrumbs className="py-2" id="adminArchiveBreadcrumbs">
                    <Breadcrumbs.Link title="Администратор" />
                    <Breadcrumbs.Link title="Архив" url="/admin/archive" />
                    {availableTabs[selectedTab] && (
                        <Breadcrumbs.Link
                            title={availableTabs[selectedTab].title}
                            url={availableTabs[selectedTab].path}
                        />
                    )}
                </Breadcrumbs>
            </div>
            <TabsWrapper selectedIndex={selectedTab} onChange={onChangeTab} className="flex flex-col grow">
                <TabsWrapper.Tabs classname="flex justify-between relative pt-1 mb-3" id="adminArchiveTabs">
                    {/*{role?.programs && (*/}
                    {/*    <Tabs.Tab title={"Программы (" + (count ? count.trainingProgramCount : 0) + ")"} />*/}
                    {/*)}*/}
                    {role?.courses && <Tabs.Tab title={"Курсы (" + (count ? count.courseCount : 0) + ")"} />}
                    {role?.materials && <Tabs.Tab title={"Материалы (" + (count ? count.materialsCount : 0) + ")"} />}
                    {/*{role?.tests && <Tabs.Tab title={"Тесты (" + (count ? count.quizCount : 0) + ")"} />}*/}
                    {/*{role?.exercise && <Tabs.Tab title={"Задания (" + (count ? count.exerciseCount : 0) + ")"} />}*/}
                </TabsWrapper.Tabs>
                <TabsWrapper.Content className="flex flex-col grow">
                    {/*{role?.programs && (*/}
                    {/*    <Content.Body className="w-full h-full">*/}
                    {/*        <ArchiveTable*/}
                    {/*            queryId="archivePrograms"*/}
                    {/*            emptyTitle="Программ в архиве пока нет"*/}
                    {/*            emptyDescription="Все программы которые будут архивированы, попадут в эту вкладку"*/}
                    {/*            categories={categories}*/}
                    {/*            onRequest={onProgramsRequest}*/}
                    {/*            onRestore={onProgramsRestore}*/}
                    {/*        />*/}
                    {/*    </Content.Body>*/}
                    {/*)}*/}
                    {role?.courses && (
                        <Content.Body className="w-full h-full">
                            <ArchiveTable
                                queryId="archiveCourses"
                                emptyTitle="Курсов в архиве пока нет"
                                emptyDescription="Все курсы которые будут архивированы, попадут в эту вкладку"
                                categories={categories}
                                onRequest={onCourseRequest}
                                onRestore={onCourseRestore}
                            />
                        </Content.Body>
                    )}
                    {role?.materials && (
                        <Content.Body className="w-full h-full">
                            <ArchiveTable
                                queryId="archiveMaterials"
                                emptyTitle="Материалов в архиве пока нет"
                                emptyDescription="Все материалы которые будут архивированы, попадут в эту вкладку"
                                categories={categories}
                                onRequest={onMaterialRequest}
                                onRestore={onMaterialRestore}
                            />
                        </Content.Body>
                    )}
                    {/*{role?.tests && (*/}
                    {/*    <Content.Body className="w-full h-full">*/}
                    {/*        <ArchiveTable*/}
                    {/*            queryId="archiveTests"*/}
                    {/*            emptyTitle="Тестов в архиве пока нет"*/}
                    {/*            emptyDescription="Все тесты которые будут архивированы, попадут в эту вкладку"*/}
                    {/*            categories={categories}*/}
                    {/*            onRequest={onTestRequest}*/}
                    {/*            onRestore={onTestRestore}*/}
                    {/*        />*/}
                    {/*    </Content.Body>*/}
                    {/*)}*/}
                    {/*{role?.exercise && (*/}
                    {/*    <Content.Body className="w-full h-full">*/}
                    {/*        <ArchiveTable*/}
                    {/*            queryId="archiveTasks"*/}
                    {/*            emptyTitle="Заданий в архиве пока нет"*/}
                    {/*            emptyDescription="Все задания которые будут архивированы, попадут в эту вкладку"*/}
                    {/*            categories={categories}*/}
                    {/*            onRequest={onTaskRequest}*/}
                    {/*            onRestore={onTaskRestore}*/}
                    {/*        />*/}
                    {/*    </Content.Body>*/}
                    {/*)}*/}
                </TabsWrapper.Content>
            </TabsWrapper>
        </ContentLayout>
    );
};
