import React, { Fragment, useCallback, useEffect, useState, MutableRefObject, useRef } from "react";
import clsx from "clsx";
import { Transition } from "react-transition-group";
import SelectComponents from "react-select";
import { Button, Icon, Icons, SelectMultiValueRemove, SelectAvatarMultiValueLabel } from "Uikit";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import { RangeField } from "./Fields/RangeField";
import { DateRangeField } from "./Fields/DateRangeField";
import { SelectField } from "./Fields/SelectField";
import { CheckboxField } from "./Fields/CheckboxField";
import { AsyncSelectField } from "./Fields/AsyncSelectField";
import "react-datepicker/dist/react-datepicker.css";
import "./Filter.css";
import { TeamMultiSelect } from "Components/Common/TeamMultiSelect";
import { TrainingMultiSelect, ITrainingMultiSelectValue } from "Components/Common/TrainingMultiSelect";
// import { ProgramMultiSelect, IProgramMultiSelectValue } from "Components/Common/ProgramMultiSelect";
import { isEmpty } from "lodash";
import { AccountableUser, UserListResponse } from "Api/Responses/UserResponse";
import { useScreenSize } from "hooks/useMediaQuery";
import { ResourceType } from "Enums";

interface IFilter {
    accessor: string;
    type: string;
    label?: string;
    placeholder?: string;
    options?: any[];
    default?: any;
    isMulti?: boolean;
    disabled?: boolean;
    components?: SelectComponents;
    menuIsOpen?: boolean;
    min?: number | string;
    max?: number | string;
    isClearable?: boolean;
    loadOptions?: (inputValue: string) => Promise<any>;
    formatOptionLabel?: (label: string, value: AccountableUser | UserListResponse | undefined) => React.ReactElement;
    onClick?: (e: React.SyntheticEvent<Element, Event>) => void;
    lazy?: boolean;
    stacked?: boolean;
    allPlaceholder?: boolean;
    onChange?: (name: string, value: any) => void;
    componentResourceType?: ResourceType;
    isModalOpen?: boolean;
    asyncLoadLimitsFunction?: () => Promise<{ minLimit: number; maxLimit: number }>;
}
export interface IFilterRow {
    label?: string;
    resetButton?: boolean;
    className?: string;
    fields: IFilter[];
    condition?: any;
}
interface IFilterProps {
    isActive: boolean;
    setIsActive: (show: boolean) => void;
    configuration: IFilterRow[];
    filters: { [id: string]: any };
    onChange?: (filter: any) => void;
    onFilterChangeRef?: MutableRefObject<((name: string, value: any) => void) | null>;
    hideTeamMembers?: boolean;
}

const duration = 150;

const specialFields = ["role.in", "sourceType"];

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease-in-out`,
    opacity: 0,
};

const transitionStyles = {
    entering: { opacity: 1, transform: "translateX(0)" },
    entered: { opacity: 1, transform: "translateX(0)" },
    exiting: { opacity: 0, transform: "translateX(100%)" },
    exited: { opacity: 0, transform: "translateX(100%)" },
    unmounted: { opacity: 0, transform: "translateX(100%)" },
};

interface IFilterSelectFieldProps {
    field: IFilter;
    filter: any;
    onChange: (name: string, value: any) => void;
}

const FilterSelectField = ({ field, filter, onChange }: IFilterSelectFieldProps) => {
    const components: any = {
        MultiValueRemove: SelectMultiValueRemove,
        MultiValueLabel: SelectAvatarMultiValueLabel({
            withAvatar: false,
        }),
        ...field.components,
    };

    if (field.type === "multi-select") {
        components.ValueContainer = StackedValueContainer;
    }

    return (
        <SelectField
            isMulti={field.type === "multi-select"}
            placeholder={field.placeholder}
            options={field.options}
            accessor={field.accessor}
            value={filter[field.accessor] ?? ""}
            onChange={onChange}
            components={components}
            defaultValue={field.default}
            isClearable={field.isClearable ?? false}
            menuIsOpen={field.menuIsOpen}
            onClick={field.onClick}
            isSearchable={false}
            isModalOpen={field.isModalOpen}
        />
    );
};

export const Filter = ({
    isActive,
    setIsActive,
    configuration,
    onChange,
    filters = {},
    onFilterChangeRef,
    hideTeamMembers = false,
}: IFilterProps) => {
    const [filter, setFilter] = useState<any>(filters);
    const nodeRef = useRef(null);

    const onFilterChange = useCallback((name: string, value: any) => {
        setFilter((prevState: any) => {
            if (value === undefined) {
                if (specialFields.includes(name)) {
                    return { ...prevState, [name]: { label: "Все", value: "ALL" } };
                }
                const newState = Object.assign(prevState);
                delete newState[name];

                return { ...newState };
            } else {
                const newState = { ...prevState, [name]: value };
                if (
                    name === "sourceType" &&
                    [ResourceType.PROGRAM, ResourceType.COURSE].includes(newState["sourceType"]?.value)
                ) {
                    delete newState["program.in"];
                    delete newState["course.in"];
                }
                return { ...newState };
            }
        });
    }, []);

    if (onFilterChangeRef) {
        onFilterChangeRef.current = onFilterChange;
    }

    useEffect(() => {
        if (!onChange) {
            return;
        }

        onChange(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, onChange]);

    useEffect(() => {
        if (isActive) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isActive]);

    const { size } = useScreenSize();
    const isLarge = size === "large";

    return (
        <>
            {isActive && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-[rgba(26,26,26,0.1)] z-[100] !mt-0"
                    onClick={() => setIsActive(false)}
                ></div>
            )}
            <Transition nodeRef={nodeRef} in={isActive} timeout={duration}>
                {(state) => (
                    <div
                        className="fixed top-0 right-0 flex flex-col w-full sm:w-105 2xl:w-[524px] h-full bg-white shadow-[0px_8px_20px_rgba(0,0,0,0.12)] rounded-tl-md rounded-bl-md z-[101] isolate !mt-0"
                        onClick={(e) => e.stopPropagation()}
                        id="filterWindow"
                        ref={nodeRef}
                        style={{
                            ...defaultStyle,
                            ...transitionStyles[state],
                        }}
                    >
                        <div className="flex items-center justify-between px-3 sm:px-6 pt-1.5 sm:pt-6 pb-1.5 sm:pb-6">
                            <p className="hidden sm:block text-[#262626] text-xl 2xl:text-3xl font-medium">Фильтры</p>
                            <p className="block sm:hidden p1-accent text-blue" onClick={() => setFilter({})}>
                                Сброс
                            </p>
                            <Button
                                shape="round"
                                color="common"
                                icon={
                                    <Icon
                                        icon={Icons.Close}
                                        width={isLarge ? 40 : 32}
                                        height={isLarge ? 40 : 32}
                                        color="fill-[#878E9C]"
                                    />
                                }
                                iconPlacement="center"
                                onClick={() => setIsActive(false)}
                            />
                        </div>
                        <div className="flex flex-col grow py-5 sm:py-6 gap-5 sm:gap-6 px-3 sm:px-6 sm:pr-2 sm:mr-2 sm:pt-0 overflow-auto custom-scrollbar js-scrollbar">
                            {configuration.map((item) => {
                                return (
                                    (!item.condition || item.condition?.(filter)) && (
                                        <div
                                            key={`${item.label}`}
                                            className={clsx(
                                                "pb-6 border-b border-gray-blue last:border-0 last:pb-0",
                                                item.className,
                                            )}
                                        >
                                            <div className="flex justify-between items-center">
                                                {item.label && (
                                                    <p className="text-[#262626] font-medium 2xl:text-[18px]">
                                                        {item.label}
                                                    </p>
                                                )}
                                                {item.resetButton !== false &&
                                                    item.fields.filter(
                                                        (field) =>
                                                            !isEmpty(filter[field.accessor]) &&
                                                            (!specialFields.includes(field.accessor) ||
                                                                specialFields.some((item) => {
                                                                    return (
                                                                        filter[item] && filter[item]["value"] !== "ALL"
                                                                    );
                                                                })),
                                                    ).length !== 0 && (
                                                        <Button
                                                            variant="link"
                                                            className="!p-0 !h-0 !font-medium active:!ring-0 focus:!ring-0"
                                                            onClick={() => {
                                                                item.fields.forEach((field) => {
                                                                    if (field.accessor === "sourceType") {
                                                                        onFilterChange("sourceType", {
                                                                            label: "Все",
                                                                            value: "ALL",
                                                                        });
                                                                        onFilterChange("resourceId.in", undefined);
                                                                        return;
                                                                    }
                                                                    onFilterChange(field.accessor, undefined);
                                                                });
                                                            }}
                                                        >
                                                            Сбросить
                                                        </Button>
                                                    )}
                                            </div>
                                            <div>
                                                {item.fields.map((field) => {
                                                    return (
                                                        <Fragment key={field.accessor}>
                                                            {field.type === "range" && (
                                                                <RangeField
                                                                    minLimit={field.min}
                                                                    maxLimit={field.max}
                                                                    accessor={field.accessor}
                                                                    value={filter[field.accessor]}
                                                                    onChange={onFilterChange}
                                                                    disabled={field.disabled}
                                                                    asyncLoadLimitsFunction={
                                                                        field.asyncLoadLimitsFunction
                                                                    }
                                                                />
                                                            )}
                                                            {field.type === "date-range" && (
                                                                <DateRangeField
                                                                    accessor={field.accessor}
                                                                    value={filter[field.accessor]}
                                                                    onChange={onFilterChange}
                                                                />
                                                            )}
                                                            {["multi-select", "select"].includes(field.type) && (
                                                                <FilterSelectField
                                                                    field={field}
                                                                    filter={filter}
                                                                    onChange={onFilterChange}
                                                                />
                                                            )}
                                                            {["async-multi-select", "async-select"].includes(
                                                                field.type,
                                                            ) && (
                                                                <AsyncSelectField
                                                                    isMulti={field.type === "async-multi-select"}
                                                                    placeholder={field.placeholder}
                                                                    accessor={field.accessor}
                                                                    value={filter[field.accessor]}
                                                                    onChange={onFilterChange}
                                                                    components={field.components}
                                                                    loadOptions={field.loadOptions}
                                                                    isClearable={field.isClearable ?? false}
                                                                    formatOptionLabel={field.formatOptionLabel}
                                                                    lazy={field.lazy}
                                                                />
                                                            )}
                                                            {field.type === "team-multi-select" && (
                                                                <TeamMultiSelect
                                                                    value={filter[field.accessor]}
                                                                    onChange={(v) => {
                                                                        onFilterChange(
                                                                            field.accessor,
                                                                            v.length ? v : undefined,
                                                                        );
                                                                    }}
                                                                    isClearable={field.isClearable ?? false}
                                                                    stacked={field.stacked ?? true}
                                                                    allPlaceholder={field.allPlaceholder ?? false}
                                                                    placeholder={
                                                                        field.placeholder ?? "Выберите команды"
                                                                    }
                                                                    hideMembers={hideTeamMembers}
                                                                    className={"mt-3 sm:mt-4"}
                                                                    checkable
                                                                    showTeams
                                                                />
                                                            )}
                                                            {field.type === "training-multi-select" && (
                                                                <>
                                                                    <TrainingMultiSelect
                                                                        value={filter[field.accessor]}
                                                                        onChange={(v, action) => {
                                                                            let value: ITrainingMultiSelectValue[] = [];
                                                                            if (action && action.action === "clear") {
                                                                                value = [];
                                                                            }
                                                                            if (
                                                                                action &&
                                                                                action.action === "remove-value"
                                                                            ) {
                                                                                value = filter[field.accessor].filter(
                                                                                    ({ id }: { id: string }) => {
                                                                                        return (
                                                                                            id !==
                                                                                            action.removedValue.id
                                                                                        );
                                                                                    },
                                                                                );
                                                                            }

                                                                            onFilterChange(field.accessor, value);
                                                                        }}
                                                                        onSubmiTreeSection={(selectedItems) => {
                                                                            onFilterChange(
                                                                                field.accessor,
                                                                                selectedItems.map(
                                                                                    ({ id, name, logoId, state }) => {
                                                                                        return {
                                                                                            label: name,
                                                                                            value: id,
                                                                                            name,
                                                                                            id,
                                                                                            logoId,
                                                                                            state,
                                                                                        };
                                                                                    },
                                                                                ),
                                                                            );
                                                                        }}
                                                                        placeholder={
                                                                            field.placeholder ?? "Выберите команды"
                                                                        }
                                                                        components={field.components}
                                                                        resourceType={field.componentResourceType}
                                                                    />
                                                                </>
                                                            )}
                                                            {field.type === "checkbox" && (
                                                                <CheckboxField
                                                                    label={field.label}
                                                                    accessor={field.accessor}
                                                                    value={filter[field.accessor]}
                                                                    onChange={onFilterChange}
                                                                />
                                                            )}
                                                        </Fragment>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )
                                );
                            })}
                        </div>
                        <div className="border-t border-[#EAEDF3] px-3 sm:px-6 py-2.5 sm:py-4 2xl:py-5 2xl:px-7.5">
                            <Button
                                className="hidden sm:block w-full bg-[#F5F7F9] rounded-lg !text-primary font-medium text-center"
                                color="gray"
                                onClick={() => {
                                    if (filter["role.in"]) {
                                        setFilter({ "role.in": { label: "Все", value: "ALL" } });
                                    } else if (filter["sourceType"]) {
                                        setFilter({ sourceType: { label: "Все", value: "ALL" } });
                                    } else {
                                        setFilter({});
                                    }
                                }}
                            >
                                Сбросить все фильтры
                            </Button>
                            <Button
                                className="block sm:hidden w-full bg-[#F5F7F9] rounded-lg !text-primary font-medium text-center"
                                color="gray"
                                onClick={() => setIsActive(false)}
                            >
                                Применить
                            </Button>
                        </div>
                    </div>
                )}
            </Transition>
        </>
    );
};
