import React, { useCallback, useContext, useEffect, useState } from "react";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { Breadcrumbs, Button, flash, Icon, Icons, Toggle, Section, FormGroup, Label, Input, ComboBox } from "Uikit";
import { TreeWrapperContext } from "Containers/TreeWrapper/TreeWrapperContext";
import { ContentLayout } from "Containers/ContentLayout";
import Api from "Api";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { InviteRequest } from "Api/Requests/InviteRequest";
import { InviteResponse } from "Api/Responses/InviteResponse";
import { CancelModal } from "Components/CancelModal/CancelModal";
import { ParentTeamChoiceModal } from "Pages/Admin/Settings/Group/Teams/ParentTeamChoiceModal";
import { ResourceState } from "Enums";
import { IOption } from "types";
import { getInviteLink } from "helpers/urlUtils";

export interface IInvite {
    isEdit?: boolean;
}

export const Invite = ({ isEdit }: IInvite) => {
    const navigate = useNavigate();

    const teams = useQuery(
        ["teams", "tree", "basic-info", "collection"],
        async () => await Api.Teams.TreeAllBasicInfo([]),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );
    const [teamId, setTeamId] = useState<string | undefined>(undefined);
    const [selectedTeams, setSelectedTeams] = useState<any>({});

    const [isChanged, setIsChanged] = useState(false);
    const [errors, setErrors] = useState<any>({});

    const { setTreeProps } = useContext(TreeWrapperContext);
    const { id } = useParams();

    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [isEditCancel, setIsEditCancel] = useState<boolean>(false);

    const [initTitle, setInitTitle] = useState("Новое приглашение");

    const blocker = useBlocker((params) => {
        if (isChanged && params?.historyAction !== "REPLACE") {
            setIsCancel(true);
        } else if (params?.historyAction === "REPLACE") {
            return false;
        }

        return isChanged;
    });

    const [invite, setInvite] = useState<InviteRequest>({
        id: "",
        title: "",
        state: ResourceState.INACTIVE,
        teamIds: [],
    });

    const onChange = (p: InviteRequest) => {
        setIsChanged(true);
        setInvite(p);
    };

    const onValidate = () => {
        const errors: any = {};

        errors["title"] = !invite.title ? "Поле обязательно для заполнения" : undefined;

        errors["teamIds"] =
            invite.teamIds.length !== teams.data?.length ? "Поле обязательно для заполнения" : undefined;

        const errorsKeys = Object.keys(errors).filter((p) => errors[p]);

        if (errorsKeys.length !== 0) {
            setErrors(errors);
            const message = "Ошибка, не все поля формы заполнены правильно";

            flash.error(message);
            return false;
        }

        setErrors({});
        return true;
    };

    const onCancelChange = () => {
        invite.id ? setIsEditCancel(true) : navigate("/admin/settings/users?tab=invites");
    };

    const onSave = async () => {
        if (!onValidate()) {
            return;
        }

        try {
            let response: InviteResponse;

            if (invite.id) {
                response = await Api.Invites.Edit(invite);

                flash.success("Приглашение успешно сохранено!");
            } else {
                response = await Api.Invites.Create(invite);
                setInvite({ ...invite, id: response.id });

                flash.success("Приглашение успешно создано!");
            }
            navigate(`/admin/settings/invite/${response.id}`, { replace: true });

            setInitTitle(invite.title);
            setIsChanged(false);
        } catch (error) {
            if (error instanceof BadRequestResponse) {
                switch (error.errorCode) {
                    case ErrorCode.ACHIEVEMENT:
                        flash.error("Приглашение с таким названием уже существует.");
                        break;
                    case ErrorCode.INVALID_TEAM_SELECTION:
                        flash.error("Необходимо выбрать корневую команду.");
                        break;
                    default:
                        console.log(error);
                        break;
                }
            } else {
                console.log("Is not BadRequestResponse");
            }
        }
    };

    const fetchInvite = useCallback(async () => {
        if (!id) {
            return;
        }

        setInvite((prevState) => {
            return {
                ...prevState,
                id,
            };
        });

        const response = await Api.Invites.Read(id);

        setInvite({
            id: response.id,
            title: response.title,
            teamIds: response.teams.map(({ id }) => id),
            state: response.state as ResourceState,
        });

        // setInitTitle(response.title);
    }, [id]);

    useEffect(() => {
        fetchInvite().then();
    }, [fetchInvite]);

    useEffect(() => {
        if (!setTreeProps) {
            return;
        }

        setTreeProps(undefined);
    }, [setTreeProps]);

    useEffect(() => {
        setIsChanged(!!isEdit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeInviteState = () => {
        setInvite((invite) => ({
            ...invite,
            state: invite.state === ResourceState.ACTIVE ? ResourceState.INACTIVE : ResourceState.ACTIVE,
        }));
        setIsChanged(true);
    };

    const onCancelModalSubmit = useCallback(async () => {
        setIsChanged(false);

        if (isCancel && blocker.state === "blocked") {
            blocker.proceed();
        } else {
            setIsEditCancel(false);

            await fetchInvite();

            navigate(`/admin/settings/invite/${invite.id}`, { replace: true });
        }
    }, [blocker, fetchInvite, isCancel, navigate, invite.id]);

    const getTeamOptions = useCallback(
        (teamId: string) => {
            const parentTeam = teams.data?.find((p) => p.id === teamId);

            const getTeams = (team: TeamTreeAllBasicInfoResponse) => {
                let response: IOption[] = [{ label: team.name, value: team.id }];

                for (const element of team.subTeams) {
                    response = response.concat(getTeams(element));
                }

                return response;
            };

            return getTeams(parentTeam!);
        },
        [teams],
    );

    useEffect(() => {
        if (invite.id && !Object.keys(selectedTeams).length) {
            const data = teams.data?.map((p: TeamTreeAllBasicInfoResponse) => {
                const option = getTeamOptions(p.id).find(
                    (p1) => invite.teamIds.find((p2) => p2 === p1.value) !== undefined,
                );

                return { id: p.id, option };
            });

            const obj: any = {};

            if (data) {
                for (const { id, option } of data) {
                    if (option === undefined) {
                        continue;
                    }

                    obj[id] = option;
                }
                setSelectedTeams(obj);
            }
        }
    }, [teams, invite, getTeamOptions, selectedTeams]);

    const isActive = invite.state === ResourceState.ACTIVE;

    if (teams.isFetching) {
        return null;
    }

    return (
        <ContentLayout className="h-full">
            <ParentTeamChoiceModal
                isOpen={!!teamId}
                title="Выбор команд"
                isMulti={false}
                teamIds={teams.data?.filter((p1) => p1.id !== teamId).map((p1) => p1.id)}
                onSelect={(selectedTeamId) => {
                    if (!teamId) {
                        return;
                    }

                    setSelectedTeams((prev: any) => {
                        prev[teamId!] = getTeamOptions(teamId!).find(
                            (p) => p.value === selectedTeamId[0].replace("root:", ""),
                        );

                        return prev;
                    });

                    setTeamId(undefined);
                    onChange({ ...invite, teamIds: Object.keys(selectedTeams).map((p) => selectedTeams[p].value) });
                }}
                onClose={() => setTeamId(undefined)}
                selectable
                // hideMembers={currentUser?.data?.role === RoleName.ADMIN}
            />
            <CancelModal
                id="InvitePageCancelModal"
                isEdit={isEditCancel}
                isOpen={isCancel || isEditCancel}
                setIsOpen={isEditCancel ? setIsEditCancel : setIsCancel}
                onSubmit={onCancelModalSubmit}
            />
            <div className="w-full px-4 pb-4" id="SettingsAchievementPage">
                <Breadcrumbs className="mb-3" id="adminSettingsNewAchievementBreadcrumbs">
                    <Breadcrumbs.Link title="Администратор" />
                    <Breadcrumbs.Link title="Настройки" />
                    <Breadcrumbs.Link title="Участники" url="/admin/settings/users" />
                    <Breadcrumbs.Link title={initTitle} />
                </Breadcrumbs>
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-black" id="adminSettingsNewAchievementTitle">
                        {initTitle}
                    </h1>
                    <div className="flex items-center">
                        <Toggle
                            className="mr-7.5"
                            label={isActive ? "Активное" : "Неактивное"}
                            enabled={isActive}
                            onChange={handleChangeInviteState}
                            id="adminSettingsNewAchievementToggleCommit"
                            labelClassName="w-21"
                        />
                        {(isChanged || !invite.id) && (
                            <Button
                                className="mr-4"
                                variant="outline"
                                color="secondary"
                                onClick={onCancelChange}
                                id="adminSettingsNewAchievementBtnCancel"
                            >
                                Отменить
                            </Button>
                        )}
                        {(isChanged || !invite.id) && (
                            <Button className="mr-4" onClick={onSave} id="adminSettingsNewAchievementBtnOk">
                                Сохранить
                            </Button>
                        )}
                        {!isChanged && invite.id && (
                            <Button
                                className="w-10 h-10 !p-0"
                                variant="outline"
                                color="secondary"
                                shape="square"
                                onClick={() => navigate("/admin/settings/users?tab=invites")}
                                id="adminSettingsNewAchievementBtn"
                            >
                                <Icon icon={Icons.Close} width={24} height={24} color="fill-[#939393]" />
                            </Button>
                        )}
                    </div>
                </div>
                <div className="max-w-[1000px] mx-auto">
                    <FormGroup>
                        <Label isRequired={true}>Название</Label>
                        <Input
                            isRequired={true}
                            placeholder="Введите название"
                            value={invite.title}
                            onChange={(e) => onChange({ ...invite, title: e.target.value })}
                            error={errors["title"]}
                            id="adminSettingsNewAchievementInputName"
                            maxLength={128}
                        />
                    </FormGroup>
                    {invite.id && (
                        <FormGroup>
                            <Label>Ссылка</Label>
                            <Input
                                isRequired={true}
                                placeholder={getInviteLink(invite.id)}
                                maxLength={128}
                                disabled
                                after={
                                    <div
                                        className={`cursor-pointer`}
                                        onClick={() => {
                                            navigator.clipboard.writeText(getInviteLink(invite.id)).then(
                                                function () {
                                                    flash.success("Ссылка скопирована в буфер обмена");
                                                },
                                                function () {
                                                    flash.success("Ошибка при копировании ссылки в буфер обмена");
                                                },
                                            );
                                        }}
                                    >
                                        <Icon icon={Icons.Copy} width={20} height={20} color="fill-[#878E9C]" />
                                    </div>
                                }
                            />
                        </FormGroup>
                    )}
                    <Section
                        label="Список корневых команд"
                        id="adminNewMemberRootTeamsList"
                        rightColumnClassName="m-0 min-w-0"
                    >
                        {teams.data?.map((p, index) => {
                            return (
                                <FormGroup
                                    key={p.id}
                                    className="last:mb-0"
                                    onClick={() => setTeamId(p.id)}
                                    id={`adminNewMemberRootTeamGroup${index}`}
                                >
                                    <Label isRequired className="flex text-ellipsis overflow-hidden">
                                        {p.name}
                                    </Label>
                                    <ComboBox
                                        openMenuOnClick={false}
                                        menuPlacement="auto"
                                        placeholder="Выберите команду"
                                        components={{
                                            DropdownIndicator: () => (
                                                <Icon
                                                    className="mr-3 ml-2 cursor-pointer"
                                                    icon={Icons.List}
                                                    width={20}
                                                    height={20}
                                                    color="fill-blue-drk"
                                                />
                                            ),
                                        }}
                                        options={getTeamOptions(p.id)}
                                        value={selectedTeams[p.id]}
                                        error={errors["teamIds"] && !selectedTeams[p.id] ? errors["teamIds"] : ""}
                                        id={"adminSettingsCommandsTabComboBox" + p.id}
                                        isModalOpen={teamId === p.id}
                                    />
                                </FormGroup>
                            );
                        })}
                    </Section>
                </div>
            </div>
        </ContentLayout>
    );
};
