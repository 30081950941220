import React, { useEffect, useState } from "react";
import { SingleValue } from "react-select";

import { FormGroup, Label, ComboBox } from "Uikit";
import { IOption, IAdministratorsOption } from "types";
import Api from "Api";
import { UsersOption, UsersSingleValue } from "Uikit/Forms/SelectComponents";
import { ProgramListResponse } from "Api/Responses/ProgramResponse";
import { CourseListResponse } from "Api/Responses/CourseResponse";

export type TOpenCopyingToCompanyDialogArg = {
    ids: string[];
    isMultiple: boolean;
    data: CourseListResponse | ProgramListResponse | string[];
    isNotEnoughSpace: boolean;
    companyId: string | null;
    companyResponsible: IAdministratorsOption | null;
    companies: IOption[];
};

interface IModalCopyToCompanyFormProps {
    companyId: string | null;
    setCompanyId: (copyingCompanyId: string | null) => void;
    companyResponsible: IAdministratorsOption | null;
    setCompanyResponsible: (copyingCompanyResponsible: IAdministratorsOption | null) => void;
    isNotEnoughSpace: boolean;
    companies: IOption[];
}

export const ModalCopyToCompanyForm = ({
    companyId,
    setCompanyId,
    companyResponsible,
    setCompanyResponsible,
    isNotEnoughSpace = false,
    companies = [],
}: IModalCopyToCompanyFormProps) => {
    const [copyingCompanyId, setCopyingCompanyId] = useState<string | null>(companyId);
    const [copyingCompanyResponsible, setCopyingCompanyResponsible] = useState<IAdministratorsOption | null>(
        companyResponsible,
    );
    const [copyingCompanyResponsibleList, setCopyingCompanyResponsibleList] = useState<IAdministratorsOption[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [companyOpt, setCompanyOpt] = useState<IOption[]>([]);

    useEffect(() => {
        if (copyingCompanyId) {
            const fetch = async () => {
                // TODO: Переделать на эндпоинт получения списка участников компании по её Id
                if (!isNotEnoughSpace) {
                    setCopyingCompanyResponsible(null);
                    setCompanyResponsible(null);
                }
                setIsLoading(true);
                const response = await Api.Role.GetResponsibleList(/* copyingCompanyId */);
                setCopyingCompanyResponsibleList(
                    response.map((p) => ({
                        label: p.lastName + " " + p.firstName,
                        value: p.id,
                        payload: p,
                    })),
                );
                setIsLoading(false);
            };

            fetch().then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [copyingCompanyId]);

    // useEffect(() => {
    //     const fetch = async () => {
    //         setIsLoading(true);
    //         const companies = await Api.CompanyApi.List();
    //         setCompanyOpt(companies.Content.map((c) => ({ label: c.name, value: c.id })));
    //         setIsLoading(false);
    //     };

    //     fetch().then();
    // }, []);

    return (
        <>
            <div className={`mb-3.5 ${isNotEnoughSpace && "text-red"}`}>
                {isNotEnoughSpace
                    ? "В выбранной компании недостаточно свободной памяти"
                    : "При копировании курса " +
                      // "или программы " +
                      "все содержимое тоже будет скопировано"}
            </div>
            <FormGroup>
                <Label isRequired={true}>Компания</Label>
                <ComboBox
                    isSearchable
                    isCreatable={false}
                    isClearable={false}
                    placeholder="Выберите компанию"
                    value={companies[companies.findIndex((p) => p.value === copyingCompanyId)]}
                    options={companies}
                    components={{ NoOptionsMessage: () => <div></div> }}
                    onChange={({ value }: SingleValue<any>) => {
                        setCopyingCompanyId(value);
                        setCompanyId(value);
                        setCopyingCompanyResponsible(null);
                        setCompanyResponsible(null);
                    }}
                    isDisabled={isLoading}
                    id="adminNewProgramComboBoxCategory"
                />
            </FormGroup>
            <FormGroup>
                <Label isRequired={true}>Ответственный</Label>
                <ComboBox
                    isSearchable
                    placeholder="Выберите ответственного"
                    // value={copyingCompanyResponsible ?? null}
                    value={
                        copyingCompanyResponsibleList[
                            copyingCompanyResponsibleList.findIndex((p) => p.value === copyingCompanyResponsible?.value)
                        ]
                    }
                    options={copyingCompanyResponsibleList}
                    onChange={(option: SingleValue<any>) => {
                        setCopyingCompanyResponsible(option);
                        setCompanyResponsible(option);
                    }}
                    components={{
                        SingleValue: UsersSingleValue,
                        Option: UsersOption,
                    }}
                    isDisabled={!copyingCompanyId || isLoading}
                    id="adminNewProgramComboBoxManager"
                />
            </FormGroup>
        </>
    );
};
