import { BaseRequest } from "../BaseRequest";
import { ResourceState } from "Enums";

export class CreateInviteRequest extends BaseRequest {
    public state: ResourceState;
    public title: string;
    public teamIds: string[];
}

export class InviteRequest extends CreateInviteRequest {
    public id: string;
}

export class InviteRegistrationRequest extends BaseRequest {
    public inviteId: string;
    public avatarId?: string;
    public defaultAvatarColor: string;
    public firstName: string;
    public lastName: string;
    public middleName: string | null = null;
    public login: string;
    public password: string;
    public email: string | null = null;
    public phone: string | null = null;
    public jobTitleId: string | null = null;
    public officeId: string | null = null;
}

export class ChangeInviteStateRequest extends BaseRequest {
    public inviteIds: string[];
    public state: ResourceState;
}

export class LoginValidationRequest {
    public login: string;
}

export class PhoneValidationRequest {
    public phone: string;
}

export class EmailValidationRequest {
    public email: string;
    public inviteId: string;
}
