import { MaterialType, ResourceType, ProgressStatus } from "Enums";
import { Expose, Type } from "class-transformer";

export class Exercises {
    @Expose()
    @Type(() => Number)
    total: number;

    @Expose()
    @Type(() => Resource)
    content: Resource[];
}

export class Resource {
    @Expose()
    @Type(() => String)
    id: string;

    @Expose()
    @Type(() => String)
    resourceId?: string;

    @Expose()
    @Type(() => String)
    courseId?: string;

    @Expose()
    @Type(() => String)
    componentId?: string;

    @Expose()
    @Type(() => String)
    progressId?: string;

    @Expose()
    @Type(() => String)
    title: string;

    @Expose()
    @Type(() => String)
    logoId?: null | string;

    @Expose()
    @Type(() => Number)
    ratingPoints: number;

    @Expose()
    @Type(() => Number)
    deadlineTimestamp?: number | null;

    @Expose()
    @Type(() => Boolean)
    isRequired: boolean;

    @Expose()
    @Type(() => Boolean)
    isFavorite: boolean;

    @Expose()
    @Type(() => String)
    public type: ResourceType;

    @Expose()
    @Type(() => String)
    materialType: MaterialType;

    @Expose()
    @Type(() => String)
    progressStatus: ProgressStatus;

    @Expose()
    @Type(() => Number)
    progressPercentage?: number;

    @Expose()
    @Type(() => Number)
    passingNumber?: number;

    @Expose()
    @Type(() => Number)
    averageReviewRating?: number;
}

export class MainPageResponse {
    @Expose()
    @Type(() => Exercises)
    exercises: Exercises;

    // @Expose()
    // @Type(() => Exercises)
    // quizzes: Exercises;

    @Expose()
    @Type(() => Exercises)
    myTraining: Exercises;

    @Expose()
    @Type(() => Exercises)
    favorites: Exercises;
}

export class SystemPropertyResponse {
    @Expose()
    @Type(() => String)
    systemProperty: string;

    @Expose()
    @Type(() => String)
    value: string;
}
