import React, { ReactNode, useEffect, useState } from "react";

import { Icon, Icons, flash } from "Uikit";
import { Tooltip } from "Uikit/Tooltip";
import { humanFileSize } from "helpers/humanFileSize";
import { FILES_TYPE_ICONS, FILES_TYPE_SHORT_NAME } from "constants/attachments";
import { getFileViewType } from "helpers/file";
import { UIErrorMessages, WSEventVideoActionTypes } from "Enums";
import NotificationConnect, { NotificationSubscribe, WssMessage, WssMessageType } from "Api/Wss/Notifications";
import { fetchFileInfo } from "helpers/fetchFileInfo";
import { ProgressBar } from "Uikit/ProgressBar";
import Api from "Api";
import { useDialog } from "hooks/useDialog";
import { Confirmation } from "Components/Confirmation/Confirmation";

interface ISelectedFile {
    type: string;
    id: string;
    size: string;
    name: string;
    extension: string;
    disposableLink: string;
}

interface IMaterialArticleAttachmentProps {
    attachment: any;
    onClick: (selectedFile: ISelectedFile) => void;
    removeFile: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined, attachment: any) => void;
    onDiscardOptimizationClick: (file: any) => void;
}

const hostname = window.location.hostname.includes("localhost")
    ? "http://localhost:3000"
    : window.location.protocol + "//" + window.location.hostname;

const onError = () => {
    flash.error(UIErrorMessages.FILE_LOADING_ERROR);
};

const TextWrapper = ({ children }: { children: ReactNode }) => {
    return <div className="text-gray-text">{children}</div>;
};

export const MaterialArticleAttachment = ({
    attachment,
    onClick,
    removeFile,
    onDiscardOptimizationClick,
}: IMaterialArticleAttachmentProps) => {
    const [videoProcessingActionType, setVideoProcessingActionType] = useState<WSEventVideoActionTypes | null>(null);
    const id = attachment?.serverData?.id ?? attachment?.file?.id ?? attachment?.id;
    const name = attachment?.name ?? attachment?.file?.name ?? attachment?.serverData?.name;
    const disposableLink = attachment?.disposableLink ?? attachment?.serverData?.disposableLink;
    const fileType = attachment?.contentType ?? attachment?.file?.type ?? attachment?.serverData?.contentType;
    const extension = attachment?.extension ?? attachment?.file?.extension ?? attachment?.serverData?.extension;
    const isVideo = (attachment?.file?.type || attachment?.contentType)?.includes("video");
    const { dialogState, openDialog, closeDialog } = useDialog();

    const showOptimizationModal = (file: any) => {
        openDialog({
            title: "Оптимизация файла",
            content:
                "Файл в процессе оптимизации. Это может занять много времени. Можно оптимизировать файл самостоятельно по инструкции и загрузить заново или продолжить оптимизировать в системе.",
            closeBtnText: "Отмена",
            submitBtnText: "Продолжить",
            onRequestClose: () => {
                onDiscardOptimizationClick(file);

                closeDialog();
            },
            onRequestSubmit: () => {
                closeDialog();
            },
        });
    };

    useEffect(() => {
        if (attachment?.id && disposableLink && isVideo) {
            fetchFileInfo(attachment?.id, (isVideoProcessed) => {
                setVideoProcessingActionType(
                    isVideoProcessed
                        ? WSEventVideoActionTypes.PROCESSING_COMPLETE
                        : WSEventVideoActionTypes.PROCESSING_IN_PROGRESS,
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachment]);

    useEffect(() => {
        NotificationConnect(() => {
            Api.Upload.DiscardVideoFile(attachment.id);
        });
        NotificationSubscribe(async (message: WssMessage) => {
            if (message.messageType === WssMessageType.NOTIFICATION) {
                const { actionType } = message.body;
                if (actionType === WSEventVideoActionTypes.PROCESSING_IN_PROGRESS) {
                    setVideoProcessingActionType(WSEventVideoActionTypes.PROCESSING_IN_PROGRESS);
                    showOptimizationModal?.(attachment);
                }
                if (actionType === WSEventVideoActionTypes.PROCESSING_COMPLETE) {
                    setVideoProcessingActionType(WSEventVideoActionTypes.PROCESSING_COMPLETE);
                    closeDialog();
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Confirmation {...dialogState} />
            <div
                className="flex p-4 border border-gray-blue rounded-lg cursor-pointer group mb-4 last-of-type:mb-0 relative"
                onClick={() => {
                    if (
                        isVideo &&
                        disposableLink &&
                        videoProcessingActionType === WSEventVideoActionTypes.PROCESSING_IN_PROGRESS
                    ) {
                        showOptimizationModal?.(attachment);
                        return;
                    }
                    onClick({
                        type: fileType,
                        id: id,
                        size: attachment.size,
                        name,
                        extension,
                        disposableLink,
                    });
                }}
            >
                <div className="flex items-center mr-auto w-full">
                    <Icon
                        className="mr-5"
                        icon={FILES_TYPE_ICONS[extension] || Icons.FileOther}
                        width="32px"
                        height="32px"
                    />
                    <div className="grow">
                        <div className="flex items-center pb-2 group-hover:text-blue-light pr-18">
                            {((isVideo &&
                                String(videoProcessingActionType) === WSEventVideoActionTypes.PROCESSING_IN_PROGRESS) ||
                                (!isVideo && !disposableLink)) && (
                                <img className="mr-1" src={"/icons/loading.svg"} width={16} height={16} alt="" />
                            )}
                            {name}
                        </div>

                        {isVideo &&
                            disposableLink &&
                            String(videoProcessingActionType) === WSEventVideoActionTypes.PROCESSING_IN_PROGRESS && (
                                <Tooltip
                                    className="flex"
                                    content={
                                        "Файл в очереди на конвертацию, это может занять много времени. Можно конвертировать файл самостоятельно и загрузить заново. Для этого надо отменить текущую загрузку."
                                    }
                                    maxWidth={465}
                                >
                                    <TextWrapper>Видео обрабатывается...</TextWrapper>
                                </Tooltip>
                            )}

                        {((!isVideo && !attachment.status) ||
                            (isVideo &&
                                String(videoProcessingActionType) === WSEventVideoActionTypes.PROCESSING_COMPLETE)) && (
                            <TextWrapper>
                                {FILES_TYPE_SHORT_NAME[extension]} - {humanFileSize(attachment.size, true)}
                            </TextWrapper>
                        )}

                        {attachment.status === "loading" && (
                            <TextWrapper>
                                {`${humanFileSize(attachment.uploaded, true)} / ${humanFileSize(
                                    attachment.size,
                                    true,
                                )} – осталось ${attachment.timeLeft}`}
                            </TextWrapper>
                        )}

                        {attachment.status === "done" && (
                            <TextWrapper>{humanFileSize(attachment.size, true)} – Успешно загружено</TextWrapper>
                        )}

                        {attachment.status !== "done" && !!attachment.uploadedPercent && (
                            <div className="mt-2">
                                <ProgressBar value={attachment.uploadedPercent || 0} />
                            </div>
                        )}
                    </div>
                </div>
                {(attachment.status === "loading" ||
                    videoProcessingActionType === WSEventVideoActionTypes.PROCESSING_IN_PROGRESS) && (
                    <div className="flex space-x-3 absolute top-4 right-4">
                        <div onClick={(e) => removeFile(e, attachment)}>
                            <Icon icon={Icons.CloseBig} width={16} height={16} color="fill-blue-dark" />
                        </div>
                    </div>
                )}
                {attachment.status !== "loading" && (
                    <>
                        <div className="flex space-x-3 absolute top-4 right-4">
                            <a
                                href={`${hostname}/service/lms-upload/api/file/download/${id}`}
                                download={name}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <Icon icon={Icons.Download} width={20} height={20} color="fill-blue-drk" />
                            </a>
                            <div onClick={(e) => removeFile(e, attachment)}>
                                <Icon icon={Icons.Delete} width={20} height={20} color="fill-blue-drk" />
                            </div>
                        </div>
                        {getFileViewType({ type: attachment.contentType, extension: attachment.extension }) ===
                            "IMAGE" && (
                            <img
                                src={`/service/lms-upload/api/file/download/${attachment.id}`}
                                width="0"
                                height="0"
                                className="hidden"
                                onError={() => {
                                    onError();
                                    removeFile(undefined, attachment);
                                }}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};
