import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs } from "Uikit";
// import { Widget } from "Components/Widget";
// import { Confirmation } from "Components/Confirmation/Confirmation";
import { numWord } from "helpers/numWord";
// import { useDialog } from "hooks/useDialog";
import { useInvalidate } from "hooks/useInvalidate";
import Api from "Api";
import {
    // sortUsers,
    TeamsList,
} from "../Teams/TeamsList";
// import { TeamUsers } from "./TeamUsers";
import { TeamUsersTable } from "./TeamUsersTable";
// import { ModalUsersTable } from "./ModalUsersTable";
import { TeamWidget } from "./TeamWidget";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "../../../slices/headerSlice";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { Preloader } from "Components/Preloader/Preloader";
// import clsx from "clsx";

export const Team = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const invalidate = useInvalidate();

    const { id } = useParams();
    // const { dialogState, openDialog, closeDialog } = useDialog();

    const { data, isFetching } = useQuery(["team", "page-in-user-space", id], () => Api.Teams.getTeam(id as string), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
    });

    const onCardClick = (id: string) => {
        invalidate("team");
        navigate(`/team/${id}`);
    };

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Команда"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    return (
        <div className="flex flex-wrap justify-center min-h-full px-3 sm:px-0">
            <div className="relative flex flex-col sm:max-w-[1174px] 2xl:max-w-[1464px] w-full">
                <Breadcrumbs className="hidden sm:block" id="teamBreadcrumbs">
                    <Breadcrumbs.Link title="Команда" url="/teams" />
                    {data?.name && <Breadcrumbs.Link title={data?.name} />}
                </Breadcrumbs>
                <Preloader className="h-[calc(100vh-101px)]" isShow={isFetching}>
                    <div className="mb-2 w-full h-7.5 rounded-2xl overflow-hidden leading-0">
                        <Skeleton className="rounded-2xl" width="100%" height="100%" />
                    </div>
                    <div className="mb-3 w-full h-7.5 rounded-2xl overflow-hidden leading-0">
                        <Skeleton className="rounded-2xl" width="100%" height="100%" />
                    </div>
                    <div className="flex justify-between mb-12">
                        {Array.from(Array(4).keys()).map((p) => {
                            return (
                                <div
                                    key={p}
                                    className="w-60 sm:w-68.5 sm:h-41 2xl:w-[342px] 2xl:h-51 rounded-2xl overflow-hidden leading-0"
                                >
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                            );
                        })}
                    </div>
                    <Skeleton className="mb-2" width="100%" height="40px" />
                    <Skeleton width="100%" height="20px" />
                    {Array.from(Array(16).keys()).map((p) => {
                        return <Skeleton key={p} width="100%" height="64px" />;
                    })}
                </Preloader>
                {!isFetching && data && (
                    <div>
                        <h1 className="hidden sm:block 2xl:!text-4xl 2xl:!leading-[41px] mb-5 2xl:mb-6.25 break-anywhere">
                            {data.name}
                        </h1>
                        <h2 className="block sm:hidden mb-5">{data.name}</h2>
                        <div className="flex flex-col gap-4 sm:gap-7 2xl:gap-9">
                            <div className="flex flex-col sm:grid gap-4 sm:gap-6.5 2xl:gap-8 sm:grid-cols-4">
                                {/*<Widget*/}
                                {/*    title="Наставники команды"*/}
                                {/*    onClick={() => {*/}
                                {/*        // if (data.mentors?.length === 0) {*/}
                                {/*        //     return;*/}
                                {/*        // }*/}
                                {/*        if (window.screen.width <= 568) {*/}
                                {/*            navigate(`/team/${id}/mentors`);*/}
                                {/*        } else {*/}
                                {/*            openDialog({*/}
                                {/*                title: "Наставники",*/}
                                {/*                content: <ModalUsersTable data={data.mentors} />,*/}
                                {/*                closeBtnText: "Закрыть",*/}
                                {/*                submitBtnText: "Забрать доступ",*/}
                                {/*                submitBtnColor: "danger",*/}
                                {/*                onRequestClose: () => closeDialog(),*/}
                                {/*            });*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    classNames={clsx(*/}
                                {/*        "2xl:h-[195px]",*/}
                                {/*        data.mentors?.length === 0 ? "!cursor-default" : "",*/}
                                {/*    )}*/}
                                {/*>*/}
                                {/*    {data.mentors?.length > 0 ? (*/}
                                {/*        <TeamUsers*/}
                                {/*            usersCount={data.mentorsCount}*/}
                                {/*            users={sortUsers(data.mentors).slice(0, 5)}*/}
                                {/*            fullListLength={data.mentors.length}*/}
                                {/*            variant={"large"}*/}
                                {/*            avatarsListClass="w-full justify-start sm:justify-end"*/}
                                {/*            limit={4}*/}
                                {/*        />*/}
                                {/*    ) : (*/}
                                {/*        <p className="text-xs 2xl:text-base text-blue-dark">*/}
                                {/*            Здесь будет информация о наставниках команды*/}
                                {/*        </p>*/}
                                {/*    )}*/}
                                {/*</Widget>*/}
                                {data.statistics && (
                                    <>
                                        {/*<TeamWidget*/}
                                        {/*    title="Программы обучения"*/}
                                        {/*    stats={[*/}
                                        {/*        {*/}
                                        {/*            size: data.statistics.programStats.finishedPercent,*/}
                                        {/*            color: "stroke-primary",*/}
                                        {/*            zIndex: 30,*/}
                                        {/*        },*/}
                                        {/*        {*/}
                                        {/*            size:*/}
                                        {/*                data.statistics.programStats.inProgressPercent +*/}
                                        {/*                data.statistics.programStats.finishedPercent,*/}
                                        {/*            color: "stroke-gray-light",*/}
                                        {/*            zIndex: 10,*/}
                                        {/*        },*/}
                                        {/*    ]}*/}
                                        {/*    count={data.statistics.programStats.resourceCount}*/}
                                        {/*    stubText="Здесь будет информация о доступных программах. Попросите назначить первую программу для команды!"*/}
                                        {/*    legendLines={[*/}
                                        {/*        {*/}
                                        {/*            text: "Пройдено",*/}
                                        {/*            value: data.statistics.programStats.finishedPercent,*/}
                                        {/*            color: "blue",*/}
                                        {/*        },*/}
                                        {/*        {*/}
                                        {/*            text: "В процессе",*/}
                                        {/*            value: data.statistics.programStats.inProgressPercent,*/}
                                        {/*            color: "gray",*/}
                                        {/*        },*/}
                                        {/*        {*/}
                                        {/*            text: "Доступно",*/}
                                        {/*            value: data.statistics.programStats.availablePercent,*/}
                                        {/*            color: "light-gray",*/}
                                        {/*        },*/}
                                        {/*    ]}*/}
                                        {/*/>*/}
                                        <TeamWidget
                                            title="Курсы"
                                            stats={[
                                                {
                                                    size: data.statistics.courseStats.finishedPercent,
                                                    color: "stroke-primary",
                                                    zIndex: 30,
                                                },
                                                {
                                                    size:
                                                        data.statistics.courseStats.inProgressPercent +
                                                        data.statistics.courseStats.finishedPercent,
                                                    color: "stroke-gray-light",
                                                    zIndex: 10,
                                                },
                                            ]}
                                            count={data.statistics.courseStats.resourceCount}
                                            stubText="Здесь будет информация о доступных курсах. Попросите назначить первый курс для команды!"
                                            legendLines={[
                                                {
                                                    text: "Пройдено",
                                                    value: data.statistics.courseStats.finishedPercent,
                                                    color: "blue",
                                                },
                                                {
                                                    text: "В процессе",
                                                    value: data.statistics.courseStats.inProgressPercent,
                                                    color: "gray",
                                                },
                                                {
                                                    text: "Доступно",
                                                    value: data.statistics.courseStats.availablePercent,
                                                    color: "light-gray",
                                                },
                                            ]}
                                        />
                                        {/*<TeamWidget*/}
                                        {/*    title="Тесты"*/}
                                        {/*    stats={[*/}
                                        {/*        {*/}
                                        {/*            size: data.statistics.quizStats.finishedPercent,*/}
                                        {/*            color: "stroke-primary",*/}
                                        {/*            zIndex: 30,*/}
                                        {/*        },*/}
                                        {/*        {*/}
                                        {/*            size:*/}
                                        {/*                data.statistics.quizStats.failedPercent +*/}
                                        {/*                data.statistics.quizStats.finishedPercent,*/}
                                        {/*            color: "stroke-red",*/}
                                        {/*            zIndex: 10,*/}
                                        {/*        },*/}
                                        {/*    ]}*/}
                                        {/*    count={data.statistics.quizStats.resourceCount}*/}
                                        {/*    stubText="Здесь будет информация о доступных тестах. Попросите назначить первый тест для команды!"*/}
                                        {/*    legendLines={[*/}
                                        {/*        {*/}
                                        {/*            text: "Пройдено",*/}
                                        {/*            value: data.statistics.quizStats.finishedPercent,*/}
                                        {/*            color: "blue",*/}
                                        {/*        },*/}
                                        {/*        {*/}
                                        {/*            text: "Провалено",*/}
                                        {/*            value: data.statistics.quizStats.failedPercent,*/}
                                        {/*            color: "red",*/}
                                        {/*        },*/}
                                        {/*        {*/}
                                        {/*            text: "Доступно",*/}
                                        {/*            value: data.statistics.quizStats.availablePercent,*/}
                                        {/*            color: "light-gray",*/}
                                        {/*        },*/}
                                        {/*    ]}*/}
                                        {/*/>*/}
                                    </>
                                )}
                            </div>
                            {data.subTeams?.length > 0 && (
                                <div className="hidden sm:block">
                                    <TeamsList
                                        title="Вложенные команды"
                                        teams={data.subTeams}
                                        onClick={onCardClick}
                                        count={data.subTeams.length}
                                        countLabel={`${data.subTeams.length} ${numWord(data.subTeams.length, [
                                            "команда",
                                            "команды",
                                            "команд",
                                        ])}`}
                                        teamId={id}
                                        limitDisplayed={4}
                                    />
                                </div>
                            )}
                            <div className="block sm:hidden">
                                {data.subTeams?.length > 0 && (
                                    <TeamsList
                                        title="Вложенные команды"
                                        teams={data.subTeams}
                                        onClick={onCardClick}
                                        count={data.subTeams.length}
                                        countLabel={`${data.subTeams.length} ${numWord(data.subTeams.length, [
                                            "команда",
                                            "команды",
                                            "команд",
                                        ])}`}
                                        teamId={id}
                                        limitDisplayed={4}
                                        scrollable={true}
                                    />
                                )}
                            </div>
                            {data.usersCount > 0 && <TeamUsersTable id={id as string} />}
                            {/*{data.mentors && <Confirmation {...dialogState} />}*/}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
