import React, { useState } from "react";
import clsx from "clsx";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import {
    ProgressStatus,
    ResourceState,
    // , ResourceType
} from "Enums";
import { Tooltip } from "Uikit/Tooltip";
import { Button, Icon, Icons } from "Uikit/index";
import Api from "Api/index";
// import { setBackUrl } from "slices/headerSlice";
// import { useDispatch } from "react-redux";
import { CourseReadResponseSectionItem } from "Api/Responses/CourseResponse";
import { FavoriteButton } from "Uikit/FavoriteButton/FavoriteButton";
import { formatLeadTime } from "../Tasks/utils";
import { useStartMaterial } from "./Course.hooks";
import { LogoSize } from "Api/Services/UploadApi";

const materialTypes: { [key: string]: string } = {
    VIDEO: "Видео",
    // DOCUMENT: "Документ",
    ARTICLE: "Статья",
    // SCORM: "SCORM",
    HTML: "HTML",
    QUIZ: "Тест",
};

interface CourseMaterialCardProps {
    material: CourseReadResponseSectionItem;
    programId: string;
    courseId: string;
    refetch: any;
    isFavorite: boolean;
    state: ResourceState;
    onFavoriteChange: (isFavorite: boolean) => void;
    onClick?: (url: string) => void;
}

export const CourseMaterialCard = ({
    material,
    courseId,
    refetch,
    programId,
    isFavorite,
    state,
    onFavoriteChange,
    onClick,
}: CourseMaterialCardProps) => {
    // const dispatch = useDispatch();
    const [isTooltip, setIsTooltip] = useState(false);

    const { mutate: startMaterial } = useStartMaterial();
    const { progressStatus } = material;

    // const isQuiz = material.type === ResourceType.QUIZ;
    const blocked = progressStatus === ProgressStatus.BLOCKED;
    // Пройденный материал
    const passed = progressStatus === ProgressStatus.PASSED;
    const deleted = state === ResourceState.DELETED;
    // const isMaterialInActive = (!isQuiz && blocked) || (!isQuiz && deleted) || (isQuiz && blocked);
    const isMaterialInActive = blocked || deleted;

    // const blockedClass = isMaterialInActive ? "opacity-50" : "";
    const blockedClass = "";

    const handleClick = async () => {
        if (isMaterialInActive) {
            return;
        }
        if (!passed) {
            await Api.CourseProgress.StartCourse(courseId);
            refetch();
        }

        // if (isQuiz) {
        //     if (
        //         material.state === ResourceState.DELETED ||
        //         [ProgressStatus.ON_REVIEW, ProgressStatus.FAILED, ProgressStatus.PASSED].includes(
        //             material.progressStatus,
        //         ) ||
        //         (material.availableAttempts !== null && material.availableAttempts === 0)
        //     ) {
        //         onClick?.(`/training/${courseId}/${material.resourceId}/statistics`);
        //         return;
        //     }
        //
        //     dispatch(
        //         setBackUrl(programId ? `/training/program/${programId}/${courseId}` : `/training/course/${courseId}`),
        //     );
        //     onClick?.(
        //         programId
        //             ? `/training/program/${programId}/${courseId}/${material.resourceId}?componentId=${material.id}`
        //             : `/training/${courseId}/${material.resourceId}?componentId=${material.id}`,
        //     );
        // } else {
        if (!passed) {
            await Api.CourseProgress.StartCourse(courseId);
        }

        if (
            material.progressStatus !== ProgressStatus.IN_PROGRESS
            // && material.materialType !== ResourceType.SCORM
        ) {
            startMaterial({ courseId: courseId, componentId: material.id });
        }

        onClick?.(
            programId
                ? `/training/program/${programId}/${courseId}/${material.id}/${material.resourceId}?componentId=${material.id}`
                : `/training/course/${courseId}/${material.id}/${material.resourceId}?componentId=${material.id}`,
        );
        // }
    };

    return (
        <Tooltip
            visible={isMaterialInActive && isTooltip}
            content={
                !deleted ? "Будет доступно после прохождения предыдущих материалов" : "Материал был удален из курса"
            }
            maxWidth={274}
        >
            <div
                className={clsx(
                    "w-full sm:w-68.5 2xl:w-[342px]",
                    !isMaterialInActive && "cursor-pointer",
                    isMaterialInActive && "opacity-50",
                )}
                onClick={handleClick}
                onMouseEnter={() => deleted && setIsTooltip(true)}
                onMouseLeave={() => deleted && setIsTooltip(false)}
            >
                <div className="relative group h-[60vw] sm:h-41 2xl:h-[205px] mb-3 bg-green-light rounded-2xl 2xl:rounded-3xl">
                    <div
                        className={clsx(
                            "flex absolute left-3 top-3 2xl:top-3.75 2xl:left-3.75 space-x-1.5 2xl:space-x-2 w-full h-5.25 2xl:h-6.5",
                            blockedClass,
                        )}
                    >
                        {material.progressStatus === ProgressStatus.PASSED && (
                            <div className="flex items-center justify-center rounded-md 2xl:rounded-lg bg-primary py-0.5 2xl:py-0.75 px-1.5 2xl:px-2 text-white p3 2xl:text-2sm">
                                <Icon
                                    className="mr-1 2xl:mr-1.25"
                                    icon={Icons.TickAll}
                                    width={14}
                                    height={14}
                                    color="fill-white 2xl:!w-4.5 2xl:!h-4.5"
                                />
                                Пройдено
                            </div>
                        )}
                        {material.progressStatus === ProgressStatus.FAILED && (
                            <div className="flex items-center justify-center rounded-md 2xl:rounded-lg bg-red py-0.5 2xl:py-0.75 px-1.5 2xl:px-2 text-white p3 2xl:text-2sm">
                                Провален
                            </div>
                        )}
                        {material.progressStatus === ProgressStatus.ON_REVIEW && (
                            <div className="flex items-center justify-center rounded-md 2xl:rounded-lg bg-red py-0.5 2xl:py-0.75 px-1.5 2xl:px-2 text-white p3 2xl:text-2sm">
                                На проверке
                            </div>
                        )}
                        <div className="flex items-center rounded-md 2xl:rounded-lg bg-black-50 p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 text-white 2xl:text-2sm">
                            {materialTypes[material.materialType || material.type]}
                            {/* Материал */}
                        </div>
                    </div>

                    {!blocked && !deleted && (
                        // || (isQuiz && deleted)
                        <div className="hidden sm:block absolute top-3 2xl:top-3.75 right-3 2xl:right-3.75">
                            <FavoriteButton
                                onChange={onFavoriteChange}
                                isFavorite={isFavorite}
                                className={clsx("hidden", !isFavorite ? "group-hover:!inline-flex" : "!inline-flex")}
                            />
                        </div>
                    )}
                    {blocked && (
                        <Button
                            className="absolute top-3 2xl:top-3.75 right-3 2xl:right-3.75 !bg-black-50 py-0.5 px-0.5 2xl:w-8 2xl:h-8"
                            shape="round"
                            size="tiny"
                        >
                            <Icon
                                icon={Icons.LockClose}
                                width={19}
                                height={19}
                                color="fill-white"
                                onMouseLeave={() => setIsTooltip(false)}
                                onMouseEnter={() => setIsTooltip(true)}
                                className="2xl:!w-6 2xl:!h-6"
                            />
                        </Button>
                    )}
                    {material.logoId && (
                        <img
                            className={clsx("rounded-2xl 2xl:rounded-3xl w-full h-full object-cover", blockedClass)}
                            src={Api.Upload.GetLogo(material.logoId, LogoSize.THUMB_MINI)}
                            alt={material.title}
                        />
                    )}
                    <div
                        className={clsx(
                            "flex absolute left-3 2xl:left-3.75 bottom-3 2xl:bottom-3.75 right-3 2xl:right-3.75 h-5.25 2xl:h-6.5",
                            blockedClass,
                        )}
                    >
                        <div className="flex items-center rounded-md 2xl:rounded-lg bg-black-50 p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 text-white 2xl:text-2sm">
                            <Icon
                                icon={Icons.TrophyFilled}
                                width={14}
                                height={14}
                                className="mr-1 2xl:mr-1.25"
                                color="fill-white"
                            />
                            {material.ratingPoints}
                        </div>
                        <div className="flex items-center ml-auto rounded-md 2xl:rounded-lg bg-black-50 p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 text-white 2xl:text-2sm">
                            {formatLeadTime(material.approxCompletionMinutes * 60)}
                        </div>
                    </div>
                </div>
                <MultiClumpTooltip label={material.title} clamp={1}></MultiClumpTooltip>
            </div>
        </Tooltip>
    );
};
