import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { Breadcrumbs, Content, Tabs, TabsWrapper } from "Uikit/index";

import { TrainingTabAll } from "./TrainingTabAll";
import { TrainingTabPassed } from "./TrainingTabPassed";
import Api from "Api/index";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "slices/headerSlice";
import { useDispatch } from "react-redux";
import { IOption } from "types";

interface ICounter {
    count: number;
}
const Counter = ({ count }: ICounter) => {
    if (!count) {
        return null;
    }

    return (
        <div className="absolute top-1 left-full flex-center py-0.5 px-1 min-w-3.5 2xl:min-w-4.5 h-3.5 2xl:h-4.5 bg-red rounded-full text-xxs text-white">
            {count}
        </div>
    );
};

export const Training = () => {
    const dispatch = useDispatch();

    const [categoryFilterOptions, setCategoryFilterOptions] = useState<IOption[]>([]);
    const [selectedTab, setSelectedTab] = useState(0);

    const { data: trainingTabsCount /* , refetch: trainingCountRefetch */ } = useQuery(["training", "count"], () =>
        Api.Course.NewCountTabs(),
    );

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const res = await Api.Course.TrainingList(0, 99999, [], {});

                if (res) {
                    setCategoryFilterOptions(
                        res?.Content.map((c) => ({ label: c.categoryTitle, value: c.categoryTitle })),
                    );
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchCategories().then();
    }, []);

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Моё обучение"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    return (
        <div className="flex flex-wrap justify-center px-3 sm:px-0 h-full">
            <TabsWrapper
                className="sm:pl-4 sm:pr-6.5 sm:max-w-[1216px] 2xl:max-w-[1506px] w-full h-full flex flex-col"
                selectedIndex={selectedTab}
                onChange={setSelectedTab}
            >
                <Breadcrumbs className="hidden sm:block w-full" id="userTrainingBreadcrumbs">
                    <Breadcrumbs.Link title="Моё обучение" />
                </Breadcrumbs>
                <TabsWrapper.Tabs classname="" id="userTrainingTabsBlock">
                    <Tabs.Tab
                        className="relative"
                        // title="Все программы и курсы"
                        title="Все курсы"
                        additionalElement={<Counter count={trainingTabsCount ? trainingTabsCount.allTab : 0} />}
                    />
                    <Tabs.Tab
                        className="relative"
                        title="Пройдено"
                        additionalElement={<Counter count={trainingTabsCount ? trainingTabsCount.passedTab : 0} />}
                    />
                </TabsWrapper.Tabs>
                <TabsWrapper.Content className="flex flex-col flex-grow pt-5 2xl:pt-6.25">
                    <Content.Body className="h-full flex flex-col">
                        <TrainingTabAll categoryFilterOptions={categoryFilterOptions} />
                    </Content.Body>
                    <Content.Body className="h-full flex flex-col">
                        <TrainingTabPassed categoryFilterOptions={categoryFilterOptions} />
                    </Content.Body>
                </TabsWrapper.Content>
            </TabsWrapper>
        </div>
    );
};
