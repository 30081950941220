import React, { useState } from "react";

import { Icon, Icons } from "Uikit";

import { AuthorizationForm } from "./AuthorizationForm";
import { AuthorizationInviteForm } from "./AuthorizationInviteForm";

interface IAuthorizationProps {
    signup?: boolean;
}

export const Authorization = ({ signup = false }: IAuthorizationProps) => {
    const [inviteId, setInviteId] = useState(new URLSearchParams(window.location.search).get("inviteId") ?? "");
    // http://localhost:3000/admin/settings/invite/a35c510f-3dd9-4ff4-a59b-1cca322c409d

    return (
        <div className="sm:flex px-3 sm:px-0 h-full sm:bg-auth-background bg-cover bg-center">
            <div className="sm:flex sm:items-center sm:justify-end mb-13 pt-5 sm:mb-0 sm:pt-0 sm:pr-10 w-full sm:w-[50%]">
                <Icon className="hidden sm:block" icon={Icons.LogoWhiteBackground} width={60} height={60} />
                <Icon className="block sm:hidden" icon={Icons.Logo} width={45} height={45} />
                <span className="hidden sm:block my-auto ml-5 text-[30px] font-medium text-white">LMS GRAN</span>
            </div>
            <div className="flex items-center justify-center w-full sm:w-[50%]">
                {signup ? (
                    <AuthorizationInviteForm inviteId={inviteId} setInviteId={setInviteId} />
                ) : (
                    <AuthorizationForm />
                )}
            </div>
        </div>
    );
};
