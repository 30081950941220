import React from "react";
import { UIRatingListResponse } from "Api/Responses/RatingResponse";
import clsx from "clsx";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { Icon, Icons } from "Uikit/Icon/Icon";

interface RatingInfoBlockProps {
    className?: string;
    tableMode: boolean;
    rating: UIRatingListResponse;
}

export const RatingInfoBlock = ({ className, tableMode, rating }: RatingInfoBlockProps) => {
    return (
        <div
            className={clsx(
                "flex justify-between items-center py-3 px-4 rounded-2xl sm:justify-start sm:items-start sm:bg-background sm:rounded-xl sm:pl-6.5 sm:pr-13 sm:py-3.5",
                tableMode ? "bg-background-light" : "bg-primary",
                className,
            )}
        >
            <div className="flex">
                <h2 className="hidden sm:flex mr-12 items-center">{rating.rank}</h2>
                <div className="flex space-x-2 sm:space-x-3.5">
                    <UserAvatar
                        avatarId={rating.user.avatarId}
                        color={rating.user.defaultAvatarColor}
                        userInitials={`${rating.user.firstName?.slice(0, 1)}${rating.user.lastName?.slice(0, 1)}`}
                        size={36}
                    />
                    <div className="flex flex-col self-center">
                        <div className={clsx("p2-accent sm:p1 sm:text-black", tableMode ? "text-black" : "text-white")}>
                            {`${rating.user.firstName} ${rating.user.lastName}`}
                        </div>
                        <div className="flex items-center mt-1 sm:hidden">
                            <Icon
                                icon={Icons.TrophyFilled}
                                width={12}
                                height={12}
                                color={
                                    tableMode
                                        ? rating.rank === 1
                                            ? "fill-yellow"
                                            : rating.rank === 2
                                            ? "fill-gray-light"
                                            : rating.rank === 3
                                            ? "fill-yellow-dark"
                                            : "fill-blue-drk"
                                        : "fill-white"
                                }
                            />
                            <span className={clsx("ml-1 p3", tableMode ? "text-blue-drk" : "text-white")}>
                                {rating.rating}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className={clsx("block sm:hidden", tableMode ? "text-black" : "text-white")}>
                {rating.rank ? rating.rank : "99 999"}
            </h3>
            <div className="hidden sm:flex grow text-end items-center">
                <div className="basis-1/4">{rating.courseRating}</div>
                {/*<div className="basis-1/4">{rating.quizRating}</div>*/}
                {/*<div className="basis-1/4">{rating.exerciseRating}</div>*/}
                <h2 className="basis-1/4 text-blue">{rating.rating}</h2>
            </div>
        </div>
    );
};
