import { ID } from "./ID";
import { Job } from "./Job";

export interface UserTeam {
    id: string;
    name: string;
}

export interface User {
    id?: ID;
    login: string;
    password?: string;
    firstName: string;
    lastName: string;
    middleName?: string;
    email: string;
    jobTitle?: Job | null;
    jobTitleId?: ID;

    officeId: string;
    role: string;
    teams: UserTeam[];
    profileSettings: string[];

    // enabledNotifications?: string[];
    // mentorTeamIds?: string[];
    contentManagerTeamIds?: string[];
    avatarUuid?: string;
}

export enum UserState {
    ACTIVE = "ACTIVE",
    BLOCKED = "BLOCKED",
}

export enum UserRole {
    ROOT = "ROOT",
    SUPER_ADMIN = "SUPER_ADMIN",
    ADMIN = "ADMIN",
    USER = "USER",
}
