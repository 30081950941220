import React, { useEffect, useMemo, useState } from "react";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import clsx from "clsx";
import { Button, Checkbox, flash, Icon, Icons, Table, CustomClearIndicator } from "Uikit";
import { UserListResponse } from "Api/Responses/UserResponse";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { OfficeResponse } from "Api/Responses/OfficeResponse";
import { JobResponse } from "Api/Responses/JobResponse";
import { useInvalidate } from "hooks/useInvalidate";
import Api from "Api";
import { flattenTeams } from "models/Team";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { Empty } from "Uikit/Page/Empty";
import { RoleName } from "Enums";
import { formatStatusChangeTime } from "helpers/dateHelper";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { useDialog } from "hooks/useDialog";

interface ILicenseMembers {
    id: string;
}

const getFilterInitialParameter = (queryParams: string | null, teams: TeamTreeAllBasicInfoResponse[]) => {
    if (!queryParams) {
        return {};
    }

    const queryArr = queryParams.split(",");

    return {
        "teamId.in":
            teams.filter(({ id }) => queryArr.indexOf(id) !== -1).map((p) => ({ label: p.name, value: p.id })) ?? [],
    };
};

const getFilterInitialJobs = (queryParams: string | null, jobs: JobResponse[]) => {
    if (!queryParams) {
        return {};
    }

    return {
        "jobTitle.in": jobs.filter(({ id }) => id === queryParams).map((p) => ({ label: p.name, value: p.id })) ?? [],
    };
};

const getFilterInitialOffices = (queryParams: string | null, offices: OfficeResponse[]) => {
    if (!queryParams) {
        return {};
    }

    return {
        "office.in": offices.filter(({ id }) => id === queryParams).map((p) => ({ label: p.name, value: p.id })) ?? [],
    };
};

export const LicenseMembers = ({ id }: ILicenseMembers) => {
    const navigate = useNavigate();
    const location = useLocation();
    const invalidate = useInvalidate();
    const queryClient = useQueryClient();
    const [isMounted, setIsMounted] = useState(false);
    const { dialogState, openDialog, closeDialog } = useDialog();
    const { data: user } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const roles = useQuery(["roles", "collection"], () => Api.Role.List()).data?.Content ?? [];
    const teams = useQuery(
        ["teams", "tree", "basic-info", "collection"],
        async () => await Api.Teams.TreeAllBasicInfo([]),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    ).data!;
    const offices = useQuery(["offices", "collection"], () => Api.Office.List())?.data?.Content ?? [];
    const jobs = useQuery(["jobs", "collection"], () => Api.Job.GetJobs())?.data?.Content ?? [];

    const isRoot = user?.role === RoleName.ROOT;
    const isSuperAdmin = user?.role === RoleName.SUPER_ADMIN;

    const fetchUsers = async (page: number, size: number, sort: SortingState, filter: { [id: string]: string }) => {
        const filters = Object.keys(filter).reduce((acc: any, key) => {
            if (filter[key]) {
                acc[key] = filter[key];
            }

            return acc;
        }, {});

        return await Api.User.GetList(page, size, sort, filters);
    };

    const queryParams = new URLSearchParams(location.search);
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });
    const [sorting, setSorting] = useState<SortingState>([{ id: "licenseApplyDate", desc: true }]);

    const [selectedRows, setSelectedRows] = useState<any>();
    const [rowSelection, setRowSelection] = useState({});

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);

    const [isFilters, setIsFilters] = useState(false);
    const [filters, setFilters] = useState<{ [id: string]: any }>({
        "licenseStatus.equal": "VALID",
    });

    useEffect(() => {
        setIsMounted(true);
        invalidate("members");

        if (teams && jobs && offices) {
            setFilters({
                "licenseStatus.equal": "VALID",
                ...getFilterInitialParameter(queryParams.get("teamId.in"), flattenTeams(teams)),
                ...getFilterInitialJobs(queryParams.get("jobTitle.in"), jobs),
                ...getFilterInitialOffices(queryParams.get("office.in"), offices),
                "role.in": { label: "Все", value: "ALL" },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isFiltersEmpty = useMemo(() => {
        if (Object.keys(filters).indexOf("searchQuery.contains") !== -1) {
            return false;
        }

        return Object.keys(filters).every((f) => !filters[f]);
    }, [filters]);

    const onSearch = (searchQuery: string) => {
        setFilters((prevState) => ({ ...prevState, "searchQuery.contains": searchQuery }));
    };

    const { data: currentUser } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const dataQuery = useQuery(
        ["members", "collection", pagination, sorting, filters],
        async () => {
            const filtersKeys = Object.keys({
                ...filters,
            });
            const filtersData: any = {};

            for (const element of filtersKeys) {
                if (element === "jobTitle.in" || element === "office.in") {
                    filtersData[element] = filters[element].map((p: any) => p.value).join(",");
                } else if (element === "teamId.in") {
                    filtersData[element] = filters[element].join(",");
                } else if (element === "changeDateTime") {
                    filtersData[element + ".greaterThanOrEqual"] = filters[element]["date"]["startDate"].toISOString();
                    filtersData[element + ".lessThanOrEqual"] = filters[element]["date"]["endDate"].toISOString();
                } else if (element === "role.in") {
                    if (filters[element].value !== "ALL") {
                        filtersData[element] = filters[element].value;
                    }
                } else {
                    filtersData[element] = filters[element];
                }
            }

            return await fetchUsers(pageIndex, pageSize, sorting, filtersData);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: isMounted,
        },
    );

    const transformRolesList = () => {
        const rolesOptions = [];

        rolesOptions.push({
            label: "Все",
            value: "ALL",
        });

        for (const element of roles) {
            if (element.name !== RoleName.ROOT) {
                rolesOptions.push({
                    label: element.readableName,
                    value: element.name,
                });
            }
        }

        return rolesOptions;
    };

    const handleResetLicense = async (users: UserListResponse[]) => {
        try {
            const results = await Promise.allSettled(users.map((user) => Api.CompanyApi.resetLicense(user.login)));
            if (results.some((result) => result.status === "rejected")) {
                flash.error("Не удалось отвязать одну или несколько лицензий.");
            }
            await queryClient.invalidateQueries(["members", "collection", pagination, sorting, filters]);
            setSelectedRows(undefined);
            setRowSelection({});
        } catch {
            flash.error("Произошла ошибка.");
        }
    };

    const removeLicense = (users: UserListResponse[]) => {
        const isMultiple = users.length > 1;
        const selectedUser = users[0];
        openDialog({
            title: `Отвязать ${isMultiple ? "лицензии" : "лицензию"}`,
            description: isMultiple
                ? `Выбрано пользователей: ${users.length}`
                : `${selectedUser?.lastName} ${selectedUser?.firstName}`,
            content: isMultiple
                ? "Вы точно хотите отвязать лицензии? Пользователи выйдут из системы и не смогут авторизоваться, если не будет свободной лицензии"
                : "Вы точно хотите отвязать лицензию? Пользователь выйдет из системы и не сможет авторизоваться, если не будет свободной лицензии",
            closeBtnText: "Отмена",
            submitBtnText: "Отвязать",
            submitBtnColor: "danger",
            onRequestClose: () => closeDialog(),
            onRequestSubmit: () => {
                handleResetLicense(users).then(() => {
                    closeDialog();
                });
            },
        });
    };

    const columns = React.useMemo<ColumnDef<UserListResponse>[]>(() => {
        const result: ColumnDef<UserListResponse>[] = [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                        disabled={row.original.id === currentUser?.id}
                    />
                ),
            },
            {
                header: "участник",
                accessorKey: "firstName,lastName",
                cell: ({ row: { original: user } }) => {
                    return (
                        <div className="group flex items-center space-x-3 w-full overflow-hidden">
                            <UserAvatar
                                avatarId={user.avatarId}
                                color={user.defaultAvatarColor}
                                userInitials={`${user.firstName?.slice(0, 1)}${user.lastName?.slice(0, 1)}`}
                                size={36}
                            />
                            <div
                                className={clsx("w-full", "cursor-pointer")}
                                onClick={() => {
                                    navigate(`/admin/member/${user.id}`);
                                }}
                            >
                                <MultiClumpTooltip
                                    className="group-hover:text-blue"
                                    clamp={1}
                                    label={String(user.lastName + " " + user.firstName)}
                                />
                            </div>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "роль",
                accessorKey: "role",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({ row: { original: user } }) => {
                    const role = roles.find((role) => role.name === user.role);
                    return (
                        <div className="overflow-hidden whitespace-nowrap max-w-50">
                            {role ? <MultiClumpTooltip clamp={1} label={String(role.readableName)} /> : ""}
                        </div>
                    );
                },
            },
            {
                header: "дата получения лицензии",
                accessorKey: "licenseApplyDate",
                enableResizing: true,
                size: 300,
                footer: (props) => props.column.id,
                cell: ({ row: { original: user } }) => {
                    return (
                        <div className="overflow-hidden whitespace-nowrap max-w-50">
                            {formatStatusChangeTime(user.licenseApplyDate!, {
                                showTime: true,
                                showYear: true,
                                delimiter: ",",
                            })}
                        </div>
                    );
                },
            },
            {
                header: "",
                id: "buttons",
                accessorKey: "[row identifier to be passed to button]",
                enableResizing: true,
                size: 30,
                enableSorting: false,
                minSize: 40,
                cell: ({ row: { original: user } }) => {
                    return (
                        <div className="flex" id={"adminMembersTableGroupButton" + user.id}>
                            {isRoot ||
                            (isSuperAdmin &&
                                [
                                    RoleName.USER,
                                    // , RoleName.ADMIN
                                ].includes(user.role)) ? (
                                <Button
                                    shape={"round"}
                                    color={"common"}
                                    icon={
                                        <Icon
                                            icon={Icons.Close}
                                            width="18px"
                                            height="18px"
                                            color="fill-blue-drk hover:fill-blue-hover"
                                        />
                                    }
                                    iconPlacement={"center"}
                                    onClick={() => {
                                        removeLicense([user]);
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    );
                },
            },
        ];

        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, roles, currentUser]);

    // const controlButtons = (
    //     <div className="flex justify-end items-center space-x-4">
    //         <Button
    //             variant="outline"
    //             color="secondary"
    //             size="medium"
    //             className="border-[#E6E9ED] rounded-lg font-medium"
    //             icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
    //             iconPlacement="left"
    //             onClick={() => setIsFilters(!isFilters)}
    //             id="adminMembersBtnFilter"
    //         >
    //             Фильтры
    //         </Button>
    //     </div>
    // );

    const selectedRowButtons = (
        <div className="flex justify-end items-center space-x-5" id="membersTableSelectedRowButtons">
            <Button
                size="medium"
                color="danger"
                className="rounded-lg whitespace-nowrap font-medium"
                onClick={() => {
                    removeLicense(selectedRows);
                }}
                id="adminMembersBtnFreeze"
            >
                Отвязать лицензию
            </Button>
        </div>
    );

    const filtersConfig = [
        {
            label: "Должность",
            fields: [
                {
                    accessor: "jobTitle.in",
                    type: "multi-select",
                    placeholder: "Выберите должности",
                    options: jobs.map((p) => {
                        return { label: p.name, value: p.id };
                    }),
                },
            ],
        },
        {
            label: "Команды",
            fields: [
                {
                    accessor: "teamId.in",
                    type: "team-multi-select",
                },
            ],
        },
        // {
        //     label: "Офис",
        //     fields: [
        //         {
        //             accessor: "office.in",
        //             type: "multi-select",
        //             placeholder: "Выберите офисы",
        //             options: offices.map((p) => {
        //                 return { label: p.name, value: p.id };
        //             }),
        //         },
        //     ],
        // },
        {
            label: "Дата получения лицензии",
            fields: [
                {
                    accessor: "changeDateTime",
                    type: "date-range",
                    placeholder: "Выберите дату",
                },
            ],
        },
        {
            label: "Роль в системе",
            fields: [
                {
                    accessor: "role.in",
                    type: "select",
                    placeholder: "Выберите роли",
                    options: transformRolesList(),
                    components: {
                        ClearIndicator: CustomClearIndicator({}),
                    },
                },
            ],
        },
        // {
        //     label: "Дополнительно",
        //     fields: [
        //         {
        //             accessor: "isMentor.equal",
        //             // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //             // @ts-ignore
        //             label: "Только наставники",
        //             type: "checkbox",
        //             default: false,
        //         },
        //     ],
        // },
    ];

    return (
        <div id="licenseMembersTable">
            {isFiltersEmpty && dataQuery.data?.Content && dataQuery.data?.Content.length === 0 && (
                <Empty
                    topElement={
                        <div className="flex-center mb-5">
                            <div className="flex-center w-16 h-16 rounded-full bg-blue-10">
                                <Icon icon={Icons.FileEdit} width={"36px"} height={"36px"} color={"fill-primary"} />
                            </div>
                        </div>
                    }
                    title="Заблокированных участников нет"
                    description="На эту вкладку попадут все участники, которые были заблокированы. На данный момент в списке нет ни одного заблокированного участника"
                ></Empty>
            )}

            {teams && (!isFiltersEmpty || (dataQuery.data?.Content && dataQuery.data?.Content.length > 0)) ? (
                <>
                    <Filter
                        isActive={isFilters}
                        setIsActive={setIsFilters}
                        configuration={filtersConfig as unknown as IFilterRow[]}
                        filters={filters}
                        onChange={setFilters}
                    />
                    <Table
                        id={id}
                        columns={columns}
                        // controlButtons={controlButtons}
                        columnFilters={columnFilters}
                        pageCount={dataQuery.data?.TotalPages}
                        pagination={pagination}
                        emptyMessage="По заданным параметрам результатов нет"
                        searchTitle="Поиск по имени"
                        isFetching={dataQuery.isFetching}
                        data={dataQuery.data?.Content}
                        rowSelection={rowSelection}
                        onPaginationChange={setPagination}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        selectButtons={selectedRowButtons}
                        setSelectedRows={setSelectedRows}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        onSearch={onSearch}
                        defaultSortOrder={{
                            "firstName,lastName": "asc",
                        }}
                    />
                    <Confirmation {...dialogState} />
                </>
            ) : null}
        </div>
    );
};
