import React, {
    ReactNode,
    useMemo,
    // useEffect
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon, Icons } from "Uikit";
import clsx from "clsx";
import { useQuery } from "react-query";
import Api from "Api/index";
import { ScrollToTop } from "Components/ScrollToTop";
import { useDispatch } from "react-redux";
import { setIsNotifications, setTitle } from "slices/headerSlice";
// import NotificationConnect, { NotificationSubscribe, WssMessage } from "Api/Wss/Notifications";
// import { ResourceType, NotificationActionType } from "Enums";

export class MenuItem {
    Id: string;
    Title: string;
    Icon: React.ReactElement;
    Path: string;
    ActivePaths?: string[];
    Count: number;
    IsSeparator: boolean;
    IsBottom: boolean;
    Disabled?: boolean;
    Fill?: string;
}

interface IMenuProps {
    isInterfaceElementsHidden?: boolean;
    children: ReactNode;
}

export const Menu = ({ children, isInterfaceElementsHidden = false }: IMenuProps) => {
    const refetchInterval = 30000;
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { data: user } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());
    const { data: role } = useQuery(["role", "get", user?.id], () => Api.Role.Id(user!.id), { enabled: !!user });
    // const { data: testsCount, refetch: testsCountRefetch } = useQuery(["tests", "count"], () => Api.Test.UserCount(), {
    //     /* refetchInterval */
    // });
    // const { data: tasksCount } = useQuery(["tasks", "count"], () => Api.TaskPublic.NewCount(), {
    //     refetchInterval,
    // });
    // const { data: reviewCount } = useQuery(["review", "collection"], () => Api.UtilsApi.GetReviewCount(), {
    //     refetchInterval,
    // });
    const { data: companyData } = useQuery(["companyData", "collection", user], () => {
        if (!user || !user.companyId) {
            return;
        }

        return Api.CompanyApi.Read({ id: user.companyId! });
    });
    const { data: trainingCount } = useQuery(["training", "count"], () => Api.Course.NewCount(), {
        refetchInterval,
    });

    const userMenuItems = useMemo<MenuItem[]>(() => {
        return [
            {
                Id: "userMenuMainLink",
                Title: "Главная",
                Icon: Icons.UserMenuIconHome,
                Path: "/",
                Count: NaN,
                IsSeparator: false,
                IsBottom: false,
            },
            {
                Id: "userMenuTrainingLink",
                Title: "Обучение",
                Icon: Icons.UserMenuIconTraining,
                Path: "/training",
                // Count: NaN,
                Count: trainingCount ? parseInt(trainingCount) : 0,
                IsSeparator: false,
                IsBottom: false,
            },
            // {
            //     Id: "userMenuTestsLink",
            //     Title: "Тесты",
            //     Icon: Icons.UserMenuIconTest,
            //     Path: "/tests",
            //     ActivePaths: ["/test"],
            //     Count: testsCount ? testsCount.allTab + testsCount.resultsTab : 0,
            //     IsSeparator: false,
            //     IsBottom: false,
            // },
            // {
            //     Id: "userMenuTasksLink",
            //     Title: "Задания",
            //     Icon: Icons.UserMenuIconTask,
            //     Path: "/tasks",
            //     ActivePaths: ["/task"],
            //     Count: tasksCount ? tasksCount.allTab + tasksCount.resultsTab : 0,
            //     IsSeparator: false,
            //     IsBottom: false,
            // },
            // {
            //     Id: "userMenuTeamsLink",
            //     Title: "Команда",
            //     Icon: Icons.UserMenuIconTeam,
            //     Path: "/teams",
            //     ActivePaths: ["/teams", "/team"],
            //     Count: NaN,
            //     IsSeparator: true,
            //     IsBottom: false,
            // },
            {
                Id: "userMenuRatingLink",
                Title: "Рейтинг",
                Icon: Icons.UserMenuIconRating,
                Path: "/rating",
                Count: NaN,
                IsSeparator: false,
                IsBottom: false,
            },
            {
                Id: "userMenuAdminLink",
                Title: "Админ",
                Icon: Icons.UserMenuIconAdmin,
                Path: "/admin/courses",
                Count: NaN,
                IsSeparator: false,
                IsBottom: true,
                Disabled: !role?.admin,
            },
        ];
    }, [
        role,
        // , testsCount
        // , tasksCount
        trainingCount,
    ]);

    const adminMenuItems = useMemo<MenuItem[]>(() => {
        if (!role?.admin) {
            return userMenuItems;
        }

        return [
            // {
            //     Id: "adminMenuValidationsLink",
            //     Title: "Проверка",
            //     Icon: Icons.AdminMenuIconValidations,
            //     Path: "/admin/validations",
            //     ActivePaths: ["/admin/validations"],
            //     Count: reviewCount ? reviewCount.quizTab + reviewCount.exerciseTab : 0,
            //     IsSeparator: false,
            //     IsBottom: false,
            //     Disabled:
            //         // !role.tests &&
            //         !role.exercise,
            // },
            // {
            //     Id: "adminMenuProgramsLink",
            //     Title: "Программы",
            //     Icon: Icons.Programs,
            //     Path: "/admin/programs",
            //     ActivePaths: ["/admin/programs", "/admin/program"],
            //     Count: NaN,
            //     IsSeparator: false,
            //     IsBottom: false,
            //     Disabled: !role.programs,
            // },
            {
                Id: "adminMenuCoursesLink",
                Title: "Курсы",
                Icon: Icons.AdminMenuIconCourses,
                Path: "/admin/courses",
                ActivePaths: ["/admin/courses", "/admin/course"],
                Count: NaN,
                IsSeparator: false,
                IsBottom: false,
                Disabled: !role.courses,
            },
            {
                Id: "adminMenuMaterialsLink",
                Title: "Материалы",
                Icon: Icons.AdminMenuIconMaterials,
                Path: "/admin/materials",
                ActivePaths: ["/admin/materials", "/admin/material"],
                Count: NaN,
                IsSeparator: false,
                IsBottom: false,
                Disabled: !role.materials,
            },
            // {
            //     Id: "adminMenuTestsLink",
            //     Title: "Тесты",
            //     Icon: Icons.AdminMenuIconTests,
            //     Path: "/admin/tests",
            //     ActivePaths: ["/admin/tests", "/admin/test"],
            //     Count: NaN,
            //     IsSeparator: false,
            //     IsBottom: false,
            //     Disabled: !role.tests,
            // },
            // {
            //     Id: "adminMenuTasksLink",
            //     Title: "Задания",
            //     Icon: Icons.AdminMenuIconTasks,
            //     Path: "/admin/tasks",
            //     ActivePaths: ["/admin/tasks", "/admin/task"],
            //     Count: NaN,
            //     IsSeparator: false,
            //     IsBottom: false,
            //     Disabled: !role.exercise,
            // },
            // {
            //     Id: "adminMenuMainLink",
            //     Title: "Главная",
            //     Icon: Icons.UserMenuIconHome,
            //     Path: "/admin",
            //     Count: NaN,
            //     IsSeparator: true,
            //     IsBottom: false,
            // },
            // {
            //     Id: "adminMenuNewsLink",
            //     Title: "Новости",
            //     Icon: Icons.News2,
            //     Path: "/admin/news",
            //     Count: NaN,
            //     IsSeparator: false,
            //     IsBottom: false,
            // },
            // {
            //     Id: "adminMenuCalendarLink",
            //     Title: "Календарь",
            //     Icon: Icons.Calendar,
            //     Path: "/admin/calendar",
            //     Count: NaN,
            //     IsSeparator: false,
            //     IsBottom: false,
            // },
            // {
            //     Id: "adminMenuInterviewsLink",
            //     Title: "Опросы",
            //     Icon: Icons.Help,
            //     Path: "/admin/interviews",
            //     Count: NaN,
            //     IsSeparator: false,
            //     IsBottom: false,
            // },
            // {
            //     Id: "adminMenuLibraryLink",
            //     Title: "Библиотека",
            //     Icon: Icons.Folder,
            //     Path: "/admin/library",
            //     Count: NaN,
            //     IsSeparator: false,
            //     IsBottom: false,
            // },
            {
                Id: "adminMenuMembersLink",
                Title: "Участники",
                Icon: Icons.AdminMenuIconUsers,
                Path: "/admin/members",
                Count: NaN,
                IsSeparator: true,
                IsBottom: false,
                Disabled: !role.userControl,
                ActivePaths: ["/admin/members", "/admin/member", "/admin/members/import"],
            },
            {
                Id: "adminMenuArchiveLink",
                Title: "Архив",
                Icon: Icons.AdminMenuIconArchive,
                Path: "/admin/archive",
                Count: NaN,
                IsSeparator: false,
                IsBottom: false,
                Disabled:
                    !role.courses &&
                    // !role.tests &&
                    // !role.exercise &&
                    !role.materials,
            },
            {
                Id: "adminMenuStatisticsLink",
                Title: "Статистика",
                Icon: Icons.AdminMenuIconStatistics,
                Path: "/admin/statistics/programs",
                ActivePaths: [
                    "/admin/statistics/programs",
                    "/admin/statistics/program-user",
                    "/admin/statistics/courses",
                    "/admin/statistics/course-user",
                    "/admin/statistics/materials",
                    "/admin/statistics/tests",
                    "/admin/statistics/test-answers",
                    "/admin/statistics/test-user",
                    "/admin/statistics/test-teams",
                    "/admin/statistics/tasks",
                    "/admin/statistics/task-user",
                    "/admin/statistics/user-complexity",
                    "/admin/statistics/rating",
                ],
                Count: NaN,
                IsSeparator: false,
                IsBottom: false,
                Disabled: !role.statistic,
            },
            {
                Id: "adminMenuSettingsLink",
                Title: "Настройки",
                Icon: Icons.AdminMenuIconSettings,
                Path: "/admin/settings/teams",
                Count: NaN,
                IsSeparator: false,
                IsBottom: false,
                Disabled: user?.role !== "ROOT" && user?.role !== "SUPER_ADMIN",
                ActivePaths: [
                    "/admin/settings/teams",
                    "/admin/settings/users",
                    "/admin/settings/achievements",
                    "/admin/settings/achievement",
                    "/admin/settings/rating",
                    "/admin/settings/system",
                    "/admin/settings/licences",
                ],
            },
            {
                Id: "adminMenuUserLink",
                Title: "LMS ГРАН",
                Icon: Icons.AdminMenuIconLogout,
                Path: "/",
                Count: NaN,
                IsSeparator: false,
                IsBottom: true,
                Disabled: false,
                Fill: "fill-blue",
            },
        ];
    }, [
        user,
        role,
        userMenuItems,
        // , reviewCount
    ]);

    const isAdmin = location["pathname"].indexOf("admin") !== -1;

    const isActiveItem = (item: any) => {
        return (
            location["pathname"] === item.Path ||
            (item.ActivePaths &&
                item.ActivePaths.filter((p: any) => location["pathname"].indexOf(p) !== -1).length !== 0) ||
            (location["pathname"] !== "/" &&
                item.Path !== "/" &&
                location["pathname"] !== "/admin" &&
                item.Path !== "/admin" &&
                location.pathname.indexOf(item.Path) !== -1)
        );
    };

    const getAppLogo = () => {
        if (isAdmin && role && role.admin) {
            if (companyData?.logoRectangleId) {
                return (
                    <img
                        src={`/service/lms-upload/api/file/download/${companyData?.logoRectangleId}`}
                        className="w-[102px] h-[35px]"
                    />
                );
            } else {
                return (
                    <>
                        <Icon className="mr-1.5" icon={Icons.Logo} width={35} height={35} />
                        <span className="p3">
                            <span className="text-blue">L</span>MS ГРАН
                        </span>
                    </>
                );
            }
        } else if (companyData?.logoSquareId) {
            return (
                <img
                    src={`/service/lms-upload/api/file/download/${companyData?.logoSquareId}`}
                    className="w-[45px] 2xl:w-[56px] h-[45px] 2xl:h-[56px] object-cover"
                />
            );
        } else {
            return <Icon icon={Icons.Logo} width={45} height={45} className="2xl:!w-[56px] 2xl:!h-[56px]" />;
        }
    };

    const getIconsSize = () => {
        if (isAdmin && role && role.admin) {
            return 20;
        } else {
            if (window.screen.width >= 1600) {
                return 30;
            } else {
                return 24;
            }
        }
    };

    // Подписка на выдачу допуска к тесту и обновление счётчика
    // useEffect(() => {
    //     NotificationConnect();
    //     NotificationSubscribe(async (message: WssMessage) => {
    //         if (
    //             message.body.objectType === ResourceType.QUIZ &&
    //             message.body.actionType === NotificationActionType.ASSIGN
    //         ) {
    //             testsCountRefetch();
    //         }
    //     });
    // }, [testsCountRefetch]);

    return (
        <div className="flex h-screen" onClick={() => dispatch(setIsNotifications(false))}>
            {!isInterfaceElementsHidden && (
                <>
                    <div
                        className={clsx(
                            "fixed top-0 bottom-0 left-0 hidden flex-col w-full h-full sm:flex z-10",
                            isAdmin && role && role.admin
                                ? "max-w-30 border-r border-gray-blue"
                                : "max-w-16 2xl:max-w-20 items-center bg-background-light",
                        )}
                    >
                        <div
                            className={clsx(
                                "flex items-center px-1.5 cursor-pointer",
                                isAdmin && role && role.admin ? "py-2.5" : "pt-1.5 pb-5 2xl:pb-6.5 2xl:px-3",
                            )}
                            onClick={() => navigate("/")}
                            id="appLogo"
                        >
                            {getAppLogo()}
                        </div>
                        <div className="flex flex-col h-full" id="appMenu">
                            {(isAdmin && role && role.admin ? adminMenuItems : userMenuItems)
                                .filter((item) => !item.Disabled)
                                .map((item: MenuItem) => {
                                    const adminClassNames =
                                        isAdmin && role && role.admin
                                            ? "before:left-1.5 before:w-8"
                                            : "before:left-3.5 before:w-9 2xl:before:w-[45px]";
                                    const separator = item.IsSeparator
                                        ? `mt-[13px] 2xl:mt-4.5 before:content-[''] before:absolute before:-top-3 2xl:before:-top-[17px] before:h-0.25 before:bg-gray-blue before:rounded-[1px] ${adminClassNames}`
                                        : "";

                                    return (
                                        <div
                                            key={item.Id}
                                            id={item.Id}
                                            className={clsx(
                                                "group relative flex items-center px-1.5 cursor-pointer",
                                                isActiveItem(item) && "is-active",
                                                separator,
                                                item.IsBottom && "mt-auto",
                                                isAdmin && role && role.admin ? "mb-3" : "flex-col mb-3.5 2xl:mb-4.5",
                                            )}
                                            onClick={() => navigate(item.Path)}
                                        >
                                            <div
                                                className={clsx(
                                                    "relative flex justify-center items-center w-7 h-7 transition-all duration-300 ease-in-out group-hover:bg-primary group-hover:rounded-[100px] group-[.is-active]:bg-primary group-[.is-active]:rounded-[100px]",
                                                    isAdmin && role && role.admin
                                                        ? "mr-1.5 w-7 h-7"
                                                        : "mb-1.5 w-8 2xl:w-10 h-8 2xl:h-10",
                                                )}
                                            >
                                                {!isNaN(item.Count) && item.Count > 0 && (
                                                    <span className="absolute top-0 -right-1.5 px-1 h-3.5 2xl:h-4.5 2xl:min-w-4.5 flex-center bg-danger rounded-[100px] p4 text-white">
                                                        {item.Count}
                                                    </span>
                                                )}
                                                <Icon
                                                    className="group-hover:fill-white group-[.is-active]:fill-white transition-all ease-in-out duration-300"
                                                    icon={item.Icon}
                                                    color={item.Fill ? item.Fill : "fill-blue-drk"}
                                                    width={getIconsSize()}
                                                    height={getIconsSize()}
                                                />
                                            </div>
                                            <span
                                                className={clsx(
                                                    "group-hover:text-primary group-[.is-active]:text-primary text-gray-dark transition-all ease-in-out duration-300",
                                                    isAdmin && role && role.admin ? "p3" : "p4 2xl:text-sm",
                                                )}
                                            >
                                                {item.Title}
                                            </span>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <div className="fixed left-0 bottom-0 flex pt-1.5 pb-1 w-screen border-t border-gray-blue bg-white z-50 sm:hidden">
                        <div
                            id="mobileMenuMenu"
                            className="flex flex-col items-center w-[25%]"
                            onClick={() => {
                                navigate("/menu");
                                dispatch(setTitle("Меню"));
                            }}
                        >
                            <Icon
                                className="mb-1.5"
                                width={24}
                                height={24}
                                icon={Icons.List}
                                color={
                                    isActiveItem({ Path: "/menu", ActivePaths: [] }) ? "fill-primary" : "fill-blue-drk"
                                }
                            />
                            <span
                                className={`p4 ${
                                    isActiveItem({ Path: "/menu", ActivePaths: [] })
                                        ? "text-primary"
                                        : "text-gray-light"
                                }`}
                            >
                                Меню
                            </span>
                        </div>
                        <div
                            id="mobileMenuHome"
                            className="flex flex-col items-center w-[25%]"
                            onClick={() => {
                                navigate("/");
                                dispatch(setTitle("Главная"));
                            }}
                        >
                            <Icon
                                className="mb-1.5"
                                width={24}
                                height={24}
                                icon={Icons.UserMenuIconHome}
                                color={isActiveItem({ Path: "/", ActivePaths: [] }) ? "fill-primary" : "fill-blue-drk"}
                            />
                            <span
                                className={`p4 ${
                                    isActiveItem({ Path: "/", ActivePaths: [] }) ? "text-primary" : "text-gray-light"
                                }`}
                            >
                                Главная
                            </span>
                        </div>
                        <div
                            id="mobileMenuSearch"
                            className="flex flex-col items-center w-[25%]"
                            onClick={() => {
                                navigate("/search");
                                dispatch(setTitle("Поиск"));
                            }}
                        >
                            <Icon
                                className="mb-1.5"
                                width={24}
                                height={24}
                                icon={Icons.Search}
                                color={
                                    isActiveItem({ Path: "/search", ActivePaths: [] })
                                        ? "fill-primary"
                                        : "fill-blue-drk"
                                }
                            />
                            <span
                                className={`p4 ${
                                    isActiveItem({ Path: "/search", ActivePaths: [] })
                                        ? "text-primary"
                                        : "text-gray-light"
                                }`}
                            >
                                Поиск
                            </span>
                        </div>
                        <div
                            id="mobileMenuPersonal"
                            className="flex flex-col items-center w-[25%]"
                            onClick={() => {
                                navigate("/personal");
                                dispatch(setTitle("Профиль"));
                            }}
                        >
                            <Icon
                                className="mb-1.5"
                                width={24}
                                height={24}
                                icon={Icons.User}
                                color={
                                    isActiveItem({ Path: "/personal", ActivePaths: [] })
                                        ? "fill-primary"
                                        : "fill-blue-drk"
                                }
                            />
                            <span
                                className={`p4 ${
                                    isActiveItem({ Path: "/personal", ActivePaths: [] })
                                        ? "text-primary"
                                        : "text-gray-light"
                                }`}
                            >
                                Профиль
                            </span>
                        </div>
                    </div>
                </>
            )}
            <div
                className={clsx(
                    "pl-0 w-full h-full",
                    isAdmin && role && role.admin ? "sm:pl-30" : "sm:pl-16 2xl:pl-20",
                )}
            >
                {children}
            </div>
            <ScrollToTop />
        </div>
    );
};
