import React, { useEffect, useState } from "react";
import { Icon, Icons } from "Uikit";
import { FILES_TYPE_ICONS } from "constants/attachments";
import { humanFileSize } from "helpers/humanFileSize";
import NotificationConnect, { NotificationSubscribe, WssMessage, WssMessageType } from "Api/Wss/Notifications";
import { fetchFileInfo } from "helpers/fetchFileInfo";
import { WSEventVideoActionTypes } from "Enums";

interface ICourseMaterialFileProps {
    id: string;
    onClick: () => void;
    extension: string;
    fileName: string;
    size: number;
    status?: string;
    fileType: string;
}

export const CourseMaterialFile = ({
    onClick,
    extension,
    fileName,
    size,
    status,
    id,
    fileType,
}: ICourseMaterialFileProps) => {
    const [isVideoProcessed, setIsVideoProcessed] = useState(false);
    const isVideo = fileType.includes("video");

    useEffect(() => {
        fetchFileInfo(id, (isProcessed) => {
            setIsVideoProcessed(isProcessed);
        });

        NotificationConnect();
        NotificationSubscribe(async (message: WssMessage) => {
            if (message.messageType === WssMessageType.NOTIFICATION && isVideo) {
                const { actionType } = message.body;
                if (actionType === WSEventVideoActionTypes.PROCESSING_COMPLETE) {
                    setIsVideoProcessed(true);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="p-2.5 sm:p-4 2xl:p-5 border border-gray-blue rounded-lg 2xl:rounded-2lg cursor-pointer group"
            onClick={onClick}
        >
            <div className="flex items-center gap-2.5 sm:gap-6 2xl:gap-7.5">
                <div className="shrink-0 w-6 h-6 sm:w-8 sm:h-8 2xl:w-10 2xl:h-10">
                    <Icon className="!w-full !h-full" icon={FILES_TYPE_ICONS[extension] || Icons.FileOther} />
                </div>
                <div className="grow shrink min-w-0">
                    <div className="flex items-center pb-1 sm:pb-2 2xl:pb-2.5 w-full">
                        {isVideo && !isVideoProcessed && (
                            <img className="mr-1" src={"/icons/loading.svg"} width={16} height={16} alt="loading" />
                        )}
                        <div className="group-hover:text-blue-light truncate text-xs sm:text-base 2xl:text-md">
                            {fileName}
                        </div>
                    </div>
                    {!status && (
                        <div className="text-gray-text truncate text-xxs sm:text-base 2xl:text-md">
                            {(!isVideo || (isVideo && isVideoProcessed)) && humanFileSize(size, true)}
                            {isVideo && !isVideoProcessed && <>Видео обрабатывается...</>}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
