import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useDialog } from "hooks/useDialog";
import { Button, Icon, Icons, ModalTree, PopoverList } from "Uikit";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { CourseRequest, CourseRequestSection, CourseRequestSectionItem } from "Api/Requests/CourseRequest";
import { CourseSectionModal } from "./CourseSectionModal";
import Api from "Api";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CourseContentItem } from "./CourseContentItem";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { v4 as uuidv4 } from "uuid";
import { ResourceState } from "Enums";

interface ICourseContentProps {
    course: CourseRequest;
    onChange: (course: CourseRequest) => void;
    errors: any;
}

const SECTION = "DND_SECTION";

export const CourseContent = ({ course, onChange, errors }: ICourseContentProps) => {
    const { dialogState, openDialog, closeDialog } = useDialog();
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const [isSectionModal, setIsSectionModal] = useState(false);

    const [section, setSection] = useState<CourseRequestSection | undefined>(undefined);
    const [sectionType, setSectionType] = useState<string | undefined>(undefined);

    const [materialsTree, setMaterialsTree] = useState<any>([]);
    const [showMaterialsTree, setShowMaterialsTree] = useState<any>([]);

    const [testsTree, setTestsTree] = useState<any>([]);
    const [showTestsTree, setShowTestsTree] = useState<any>([]);

    const [selectedItems, setSelectedItems] = useState<any[]>([]);

    const [components, setComponents] = useState<CourseRequestSectionItem[]>(flattenDndItems(course));

    const updateCourse = (course: CourseRequest) => {
        onChange(course);
        setComponents(flattenDndItems(course));
    };

    const onSectionSubmit = ({ name }: { name: string }) => {
        const newSections: CourseRequestSection[] = Object.assign(course.sections);

        if (!section) {
            newSections.push({
                id: uuidv4(),
                title: name,
                components: [],
            });
        } else {
            newSections[newSections.indexOf(section)].title = name;
        }

        updateCourse({ ...course, sections: newSections });

        setSection(undefined);
        setIsSectionModal(false);
    };
    const onRemoveClick = (section: CourseRequestSection) => {
        if (section.components.length === 0) {
            const newSections = Object.assign(course.sections);
            const sectionIndex = newSections.indexOf(section);

            newSections.splice(sectionIndex, 1);
            updateCourse({ ...course, sections: newSections });
            return;
        }

        openDialog({
            title: "Удаление секции",
            description: "«" + section.title + "»",
            content:
                "Вы действительно хотите удалить секцию? Все вложенные в нее материалы " +
                // " и тесты " +
                "будут исключены из курса. Восстановить секцию невозможно, ее придется собирать заново",
            closeBtnText: "Отмена",
            submitBtnText: "Удалить",
            submitBtnColor: "danger",
            onRequestClose: () => closeDialog(),
            onRequestSubmit: () => {
                const newSections = Object.assign(course.sections);
                const sectionIndex = newSections.indexOf(section);

                newSections.splice(sectionIndex, 1);
                updateCourse({ ...course, sections: newSections });

                closeDialog();
            },
        });
    };

    const onUpClick = (section: CourseRequestSection) => {
        const newSections = Object.assign(course.sections);
        const sectionIndex = newSections.indexOf(section);

        newSections.splice(sectionIndex, 1);
        newSections.splice(sectionIndex - 1, 0, section);

        updateCourse({ ...course, sections: newSections });
    };
    const onDownClick = (section: CourseRequestSection) => {
        const newSections = Object.assign(course.sections);
        const sectionIndex = newSections.indexOf(section);

        newSections.splice(sectionIndex, 1);
        newSections.splice(sectionIndex + 1, 0, section);

        updateCourse({ ...course, sections: newSections });
    };

    const requestMaterialsTree = useCallback(async () => {
        const materials = await Api.Material.List(0, 2000, [], {
            // "state.in": `${ResourceState.HIDDEN}`,
            "state.in": `${ResourceState.ACTIVE},${ResourceState.HIDDEN}`,
        });

        const materialsTree: any[] = [];
        materials.Content.forEach((p) => {
            let categoryIndex = materialsTree.findIndex((p1) => p1.id === p.category.id);

            if (categoryIndex === -1) {
                materialsTree.push({
                    id: p.category.id,
                    nodeType: "PROJECT",
                    name: p.category.title,
                    children: [],
                    state: {
                        open: false,
                    },
                });

                categoryIndex = materialsTree.length - 1;
            }

            materialsTree[categoryIndex].children.push({
                id: p.id,
                logoId: p.logoId,
                type: p.type,
                name: p.title,
                nodeType: "SECTION",
                children: null,
                file: p.file,
                approxCompletionMinutes: p.approxCompletionMinutes,
                state: p.state,
            });
        });

        if (materialsTree.length !== 0) {
            materialsTree[0].state.open = true;
        }

        setMaterialsTree(materialsTree);
    }, []);
    const requestTestsTree = useCallback(async () => {
        // const tests = await Api.Test.List(0, 2000, [], {
        //     // "state.in": `${ResourceState.HIDDEN}`,
        //     "state.in": `${ResourceState.ACTIVE},${ResourceState.HIDDEN}`,
        // });

        const testsTree: any[] = [];
        // tests.Content.forEach((p) => {
        //     let categoryIndex = testsTree.findIndex((p1) => p1.id === p.category.id);
        //
        //     if (categoryIndex === -1) {
        //         testsTree.push({
        //             id: p.category.id,
        //             nodeType: "PROJECT",
        //             name: p.category.title,
        //             children: [],
        //             state: {
        //                 open: false,
        //             },
        //         });
        //         categoryIndex = testsTree.length - 1;
        //     }
        //
        //     testsTree[categoryIndex].children.push({
        //         id: p.id,
        //         logoId: p.logoId,
        //         type: "QUIZ",
        //         name: p.title,
        //         nodeType: "ARTICLE",
        //         children: null,
        //         approxCompletionMinutes: p.approxCompletionMinutes,
        //     });
        // });

        if (testsTree.length !== 0) {
            testsTree[0].state.open = true;
        }

        setTestsTree(testsTree);
    }, []);

    const onSelectSectionType = async (section: CourseRequestSection, type: string) => {
        setSection(section);
        setSectionType(type);

        const tree =
            type === "MATERIAL" ? JSON.parse(JSON.stringify(materialsTree)) : JSON.parse(JSON.stringify(testsTree));
        const showTree = [];
        const courseMaterials = course.sections.reduce((acc: CourseRequestSectionItem[], cur: CourseRequestSection) => {
            return [...acc, ...cur.components];
        }, [] as CourseRequestSectionItem[]);

        for (const element of tree) {
            const categoryTree = element.children.filter(
                (p: any) => !courseMaterials.find((p1) => p1.resourceId === p.id),
            );

            if (categoryTree.length === 0) {
                continue;
            }

            element.children = categoryTree;
            showTree.push(element);
        }

        if (type === "MATERIAL") {
            setShowMaterialsTree(showTree);
        } else if (type === "QUIZ") {
            setShowTestsTree(showTree);
        }
    };
    const onSubmitMaterials = () => {
        const sections = Object.assign(course.sections);
        const currentSection: CourseRequestSection = sections.find((p: CourseRequestSection) => p == section);

        for (const element of selectedItems) {
            currentSection.components.push({
                resourceId: element.id,
                type: sectionType!,
                approxCompletionMinutes: element.approxCompletionMinutes,
            });
        }

        updateCourse({ ...course, sections: sections });

        setSection(undefined);
        setSectionType(undefined);
    };

    const onRemoveSectionItem = (item: CourseRequestSectionItem) => {
        const sections: CourseRequestSection[] = Object.assign(course.sections);
        const currentSection = getItemSection(item, sections);

        if (currentSection) {
            currentSection.components.splice(
                currentSection.components.findIndex((p) => p.resourceId === item.resourceId),
                1,
            );

            updateCourse({ ...course, sections: sections });
            setSectionType(undefined);
        }
    };

    const sortedIds = components.map((component) => component.resourceId);

    const onDragOver = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id === over?.id) {
            return;
        }

        const oldIndex = sortedIds.findIndex((id) => id === active.id);
        const newIndex = sortedIds.findIndex((id) => id === over?.id);

        const sectionItems = arrayMove(components, oldIndex, newIndex);
        updateCourse({ ...course, sections: itemsToSections(sectionItems, course) });
    };

    useEffect(() => {
        const fetch = async () => {
            await requestMaterialsTree();
            await requestTestsTree();
        };
        fetch().then();
    }, [requestMaterialsTree, requestTestsTree]);
    useEffect(() => {
        setComponents(flattenDndItems(course));
    }, [course]);

    return (
        <>
            <Confirmation {...dialogState} />
            <CourseSectionModal
                isOpen={isSectionModal}
                section={section}
                onSubmit={onSectionSubmit}
                onClose={() => {
                    setSection(undefined);
                    setIsSectionModal(false);
                }}
            />
            <ModalTree
                isOpen={!!sectionType}
                title={
                    // "Выбор " + (sectionType === "MATERIAL" ? "материала" : sectionType === "QUIZ" ? "теста" : "опроса")
                    "Выбор " + "материала"
                }
                submitButtonTitle="Выбрать"
                setIsOpen={() => setSectionType(undefined)}
                treeData={sectionType === "MATERIAL" ? showMaterialsTree : sectionType === "QUIZ" ? showTestsTree : []}
                checkedChange={setSelectedItems}
                onSubmit={onSubmitMaterials}
            />
            <Button
                className="fixed bottom-6.5 right-6.5 flex justify-center items-center"
                onClick={() => {
                    setSection(undefined);
                    setIsSectionModal(true);
                }}
                id="adminNewCourseBtnAddSection"
                shape="round"
                size="xl"
            >
                <Icon icon={Icons.Plus} width={26} height={26} color="fill-white" />
            </Button>
            {course.sections.length === 0 && (
                <div className="flex-center h-[calc(100vh-233px)]">
                    <div className="flex flex-col items-center justify-center max-w-125">
                        <div className="flex-center w-16.5 h-16.5 mb-4 bg-blue-10 rounded-full">
                            <Icon icon={Icons.FileEdit} width={36} height={36} color="fill-blue" />
                        </div>
                        <h2 className="mb-2 text-[#262626]-600">В курсе пока ничего нет</h2>
                        <p className="text-[#939393] text-center">
                            Чтобы добавить материалы
                            {/*и тесты */}в курс, необходимо создать секцию. Секции — это блоки на которые разделен
                            курс. Чтобы ее создать, нажмите на плюс в правом нижнем углу экрана
                        </p>
                    </div>
                </div>
            )}
            {course.sections.length !== 0 && (
                <div id="adminNewCourseSections">
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragOver={(event: DragEndEvent) => onDragOver(event)}
                        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                    >
                        <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
                            <div className="flex flex-col">
                                {components.map((item, index) => {
                                    const isSectionElement = item.type === SECTION;
                                    let itemObject: any;

                                    if (!isSectionElement) {
                                        if (item.type === "MATERIAL") {
                                            const materials = materialsTree
                                                .map((p: any) =>
                                                    p.children.find((p1: any) => p1.id === item.resourceId),
                                                )
                                                .filter((p: any) => p !== undefined);

                                            if (materials.length !== 0) {
                                                itemObject = materials[0];
                                            }
                                        } else if (item.type === "QUIZ") {
                                            const tests = testsTree
                                                .map((p: any) =>
                                                    p.children.find((p1: any) => p1.id === item.resourceId),
                                                )
                                                .filter((p: any) => p !== undefined);

                                            if (tests.length !== 0) {
                                                itemObject = tests[0];
                                            }
                                        }

                                        if (!itemObject) {
                                            return null;
                                        }
                                    }

                                    const findSection = (id: string) =>
                                        course.sections.find((section) => section.id === id);

                                    const handleAddElement = (sectionId: string, materialType: string) => {
                                        onSelectSectionType(findSection(sectionId)!, materialType).then();
                                    };

                                    const handleEdit = (sectionId: string) => {
                                        setSection(findSection(sectionId));
                                        setIsSectionModal(true);
                                    };

                                    const handleRemove = (sectionId: string) => {
                                        onRemoveClick(findSection(sectionId)!);
                                    };

                                    const handleMoveDown = (sectionId: string) => {
                                        onDownClick(findSection(sectionId)!);
                                    };

                                    const handleMoveUp = (sectionId: string) => {
                                        onUpClick(findSection(sectionId)!);
                                    };

                                    const isLast =
                                        components.findIndex(
                                            (component, innerIndex) => innerIndex > index && component.type === SECTION,
                                        ) < 0;

                                    return (
                                        <Fragment key={item.resourceId}>
                                            {isSectionElement ? (
                                                <SectionElement
                                                    section={item}
                                                    onAddElement={handleAddElement}
                                                    onEdit={handleEdit}
                                                    onRemove={handleRemove}
                                                    onMoveDown={handleMoveDown}
                                                    onMoveUp={handleMoveUp}
                                                    isFirst={index === 0}
                                                    isLast={isLast}
                                                    errors={errors?.sections?.[item.resourceId as string]}
                                                />
                                            ) : (
                                                <CourseContentItem
                                                    item={item}
                                                    treeItem={itemObject}
                                                    onRemove={() => onRemoveSectionItem(item)}
                                                    isLast={
                                                        components[index + 1] === undefined ||
                                                        components[index + 1].type === SECTION
                                                    }
                                                />
                                            )}
                                        </Fragment>
                                    );
                                })}
                            </div>
                        </SortableContext>
                    </DndContext>
                </div>
            )}
        </>
    );
};

const getItemSection = (item: CourseRequestSectionItem, sections: CourseRequestSection[]) => {
    return sections.find((section) => section.components.includes(item));
};

const itemsToSections = (items: CourseRequestSectionItem[], course: CourseRequest) => {
    const sections: CourseRequestSection[] = course.sections.map((section) => ({ ...section, components: [] }));
    let currentSection: CourseRequestSection | undefined = undefined;
    for (const item of items) {
        if (item.type === SECTION) {
            currentSection = sections.find((section) => section.id === item.resourceId)!;
        } else if (currentSection) {
            currentSection.components.push(item);
        }
    }
    return sections;
};

const flattenDndItems = (course: CourseRequest) => {
    const result: CourseRequestSectionItem[] = [];
    for (const section of course.sections) {
        result.push(
            Object.assign(new CourseRequestSectionItem(), {
                resourceId: section.id,
                type: SECTION,
                title: section.title,
            }),
        );
        for (const component of section.components) {
            result.push(component);
        }
    }
    return result;
};

interface SectionElementProps {
    onEdit: (sectionId: string) => void;
    section: CourseRequestSectionItem;
    onAddElement: (sectionId: string, materialType: string) => void;
    isFirst: boolean;
    isLast: boolean;
    onMoveUp: (sectionId: string) => void;
    onMoveDown: (sectionId: string) => void;
    onRemove: (sectionId: string) => void;
    errors: any;
}

const SectionElement = ({
    onEdit,
    section,
    onAddElement,
    isLast,
    isFirst,
    onMoveUp,
    onMoveDown,
    onRemove,
    errors,
}: SectionElementProps) => {
    const [isSectionPopover, setIsSectionPopover] = useState(false);

    return (
        <div className="group flex justify-between items-center mt-2 mb-5 pt-6 h-6 border-t border-[#EAEDF3] first:border-t-0">
            <div className="flex items-center">
                <MultiClumpTooltip
                    clamp={1}
                    label={section.title}
                    className={`mr-2 text-md text-[#262626] font-semibold${errors ? " text-red" : ""}`}
                />
                <Button
                    variant="outline"
                    color="common"
                    className="!p-0 mr-2.5 border-0 focus:!ring-0 disabled:!bg-transparent opacity-0 group-hover:opacity-100"
                    onClick={() => onEdit(section.resourceId)}
                >
                    <Icon icon={Icons.Pencil} width={20} height={20} color="fill-blue-drk hover:fill-blue-hover" />
                </Button>
            </div>
            <div className="flex items-center">
                <PopoverList
                    offset={[0, 10]}
                    visible={isSectionPopover}
                    onClickOutside={() => setIsSectionPopover(false)}
                >
                    <Button
                        variant="outline"
                        color="common"
                        className="!p-0 mr-7.5 border-0 focus:!ring-0 min-w-36 !bg-transparent"
                        onClick={() => setIsSectionPopover(true)}
                    >
                        <Icon className="mr-1" icon={Icons.PlusFilled} width={16} height={16} color="fill-[#1280CE]" />
                        <span className="text-sm font-normal text-[#1280CE]">Добавить элемент</span>
                    </Button>
                    <PopoverList.Item name="material" onClickItem={() => onAddElement(section.resourceId, "MATERIAL")}>
                        Материал
                    </PopoverList.Item>
                    {/*<PopoverList.Item name="test" onClickItem={() => onAddElement(section.resourceId, "QUIZ")}>*/}
                    {/*    Тест*/}
                    {/*</PopoverList.Item>*/}
                </PopoverList>
                <Button
                    variant="outline"
                    color="common"
                    className="!p-0 mr-2.5 border-0 focus:!ring-0 disabled:!bg-transparent !bg-transparent"
                    disabled={isFirst}
                    onClick={() => onMoveUp(section.resourceId)}
                >
                    <Icon
                        className={isFirst ? "hover:fill-[#C9C9C9]" : "hover:fill-[#1280CE]"}
                        icon={Icons.ArrowUp}
                        width={22}
                        height={22}
                        color={isFirst ? "fill-[#C9C9C9]" : "fill-[#878E9C]"}
                    />
                </Button>
                <Button
                    variant="outline"
                    color="common"
                    className="!p-0 mr-5 border-0 focus:!ring-0 disabled:!bg-transparent"
                    disabled={isLast}
                    onClick={() => onMoveDown(section.resourceId)}
                >
                    <Icon
                        className={isLast ? "hover:fill-[#C9C9C9]" : "hover:fill-[#1280CE]"}
                        icon={Icons.ArrowDown}
                        width={22}
                        height={22}
                        color={isLast ? "fill-[#C9C9C9]" : "fill-[#878E9C]"}
                    />
                </Button>
                <Button
                    variant="outline"
                    color="common"
                    className="!p-0 border-0 focus:!ring-0 !bg-transparent"
                    onClick={() => onRemove(section.resourceId)}
                >
                    <Icon icon={Icons.Close} width={24} height={24} />
                </Button>
            </div>
        </div>
    );
};
