import React, { useState } from "react";
import { Button, Icon, Icons, Modal } from "Uikit";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { BaseIdPaginationRequest } from "Api/BaseRequest";
import Api from "../../../../../Api";
import { useQuery } from "react-query";
import clsx from "clsx";
import { TeamAccessModalTable } from "./TeamAccessModalTable";
import { AccessItem } from "Api/Responses/AccessResponse";

const Section = ({ defaultIsOpen, title, data, goNextStep, setSelectedAccessElement }: any) => {
    const [isOpen, setIsOpen] = useState(defaultIsOpen);

    return (
        <div className="py-4 border-b border-[#EAEDF3]">
            <div
                className={clsx(
                    "flex items-center overflow-hidden ",
                    "text-ellipsis whitespace-nowrap rounded",
                    "transition duration-300 ease-in-out cursor-pointer",
                )}
                onClick={() => setIsOpen(!isOpen)}
            >
                <h3 className="font-semibold flex-grow">{title}</h3>
                {isOpen && <Icon icon={Icons.ChevronUp} width={14} height={14} />}
                {!isOpen && <Icon icon={Icons.ChevronDown} width={14} height={14} />}
            </div>
            {isOpen && (
                <TeamAccessModalTable
                    data={data}
                    goNextStep={goNextStep}
                    setSelectedAccessElement={setSelectedAccessElement}
                />
            )}
        </div>
    );
};

interface TeamAccessModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    selectedTeam: TeamTreeAllBasicInfoResponse | undefined;
}

export const TeamAccessModal = ({ isOpen, setIsOpen, selectedTeam }: TeamAccessModalProps) => {
    const [selectedAccessElement, setSelectedAccessElement] = useState<AccessItem>();
    const [isRemoveAccessStep, setIsRemoveAccessStep] = useState(false);

    const fetchAccesses = async () => {
        const request = new BaseIdPaginationRequest();
        request.uuid = selectedTeam?.id ?? "uuid";

        return await Api.Access.GetCommand(request);
    };

    const data = useQuery(["accessData"], () => fetchAccesses(), {
        keepPreviousData: true,
    });

    const modalBody = (
        <div>
            {isRemoveAccessStep && (
                <div>
                    <div className="text-sm small leading-6 text-[#9B9B9B] mr-auto">{selectedAccessElement?.Title}</div>
                    <div className="mt-2">
                        <span>Вы действительно хотите забрать доступ к курсу у команды?</span>
                    </div>
                </div>
            )}
            {!isRemoveAccessStep && (
                <div>
                    {/*{data.data?.Programs.length !== 0 && (*/}
                    {/*    <Section*/}
                    {/*        defaultIsOpen={true}*/}
                    {/*        title="Программы обучения"*/}
                    {/*        data={data.data?.Programs}*/}
                    {/*        goNextStep={() => setIsRemoveAccessStep(true)}*/}
                    {/*        setSelectedAccessElement={setSelectedAccessElement}*/}
                    {/*    />*/}
                    {/*)}*/}
                    {data.data?.Courses.length !== 0 && (
                        <Section
                            title="Курсы"
                            data={data.data?.Courses}
                            goNextStep={() => setIsRemoveAccessStep(true)}
                            setSelectedAccessElement={setSelectedAccessElement}
                        />
                    )}
                    {/*{data.data?.Tests.length !== 0 && (*/}
                    {/*    <Section*/}
                    {/*        title="Тесты"*/}
                    {/*        data={data.data?.Tests}*/}
                    {/*        goNextStep={() => setIsRemoveAccessStep(true)}*/}
                    {/*        setSelectedAccessElement={setSelectedAccessElement}*/}
                    {/*    />*/}
                    {/*)}*/}
                    {/*{data.data?.Tasks.length !== 0 && (*/}
                    {/*    <Section*/}
                    {/*        title="Задания"*/}
                    {/*        data={data.data?.Tasks}*/}
                    {/*        goNextStep={() => setIsRemoveAccessStep(true)}*/}
                    {/*        setSelectedAccessElement={setSelectedAccessElement}*/}
                    {/*    />*/}
                    {/*)}*/}
                </div>
            )}
        </div>
    );

    const modalFooter = (
        <div className="space-x-2">
            <Button
                id="accessTeamModalBtnCancel"
                variant="outline"
                size="medium"
                color="secondary"
                className="border-[#EAEDF3]"
                onClick={() => {
                    setIsRemoveAccessStep(false);

                    if (!isRemoveAccessStep) {
                        setIsOpen(false);
                    }
                }}
            >
                {isRemoveAccessStep ? "Назад" : "Закрыть"}
            </Button>
            {isRemoveAccessStep && (
                <Button id="accessTeamModalBtnCancel" size="medium" color="danger" onClick={() => setIsOpen(false)}>
                    Забрать доступ
                </Button>
            )}
        </div>
    );

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={isRemoveAccessStep ? "Забрать доступ" : "Настройки доступа"}
            className="max-w-162.5 w-full"
        >
            <Modal.Body className="custom-scrollbar max-h-142 overflow-auto">{modalBody}</Modal.Body>
            <Modal.Footer>{modalFooter}</Modal.Footer>
        </Modal>
    );
};
