import React, { useState, useEffect, Fragment, useRef, ChangeEvent } from "react";
import { Breadcrumbs, Button, Input, Label, Notification, Icon, Icons } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import { RatingModal } from "./RatingModal";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import Api from "../../../../../Api";
import { RatingReadResponse } from "Api/Responses/RatingResponse";
import { RatingEditRequest } from "Api/Requests/RatingRequest";
import { wholePosNumberRegexp } from "Uikit/Forms/Input";

interface IRatingInputItemProps {
    name: string;
    value: string;
    onDecrease: (name: string) => void;
    onIncrease: (name: string) => void;
    onChange: (e: ChangeEvent) => void;
}

interface IRatingInputItemsRowProps {
    type: string;
    title: string;
    items: string[];
    decrRatingSettings: (name: string) => void;
    incrRatingSettings: (name: string) => void;
    ratingSettings: RatingReadResponse;
    setRatingSettings: (data: RatingReadResponse) => void;
    setRatingSettingsChanged: (p: boolean) => void;
}

const inputItemsRowKeys = [
    {
        type: "article",
        title: "Мат. статья",
        items: ["minArticle", "midArticle", "maxArticle"],
    },
    {
        type: "video",
        title: "Мат. видео",
        items: ["minVideo", "midVideo", "maxVideo"],
    },
    // {
    //     type: "document",
    //     title: "Мат. документ",
    //     items: ["minDocument", "midDocument", "maxDocument"],
    // },
    // {
    //     type: "scorm",
    //     title: "Мат. SCORM",
    //     items: ["minScorm", "midScorm", "maxScorm"],
    // },
    // Не в MVP
    // {
    //     type: "html",
    //     title: "Мат. HTML5",
    //     items: ["minHtml", "midHtml", "maxHtml"],
    // },
    // {
    //     type: "test",
    //     title: "Вопросы тестов",
    //     items: ["minTest", "midTest", "maxTest"],
    // },
    // {
    //     type: "exercise",
    //     title: "Задания",
    //     items: ["minExercise", "midExercise", "maxExercise"],
    // },
];

const RatingInputItemsRow = ({
    title,
    items,
    type,
    decrRatingSettings,
    incrRatingSettings,
    ratingSettings,
    setRatingSettings,
    setRatingSettingsChanged,
}: IRatingInputItemsRowProps) => {
    return (
        <>
            {type === "test" && (
                <>
                    <div className="col-span-2"></div>
                    <div className="col-span-3"></div>
                    <div className="col-span-3"></div>
                    <div className="col-span-3"></div>
                </>
            )}
            <div className="flex col-span-2">
                <span className="my-auto">{title}</span>
            </div>
            {items.map((name) => {
                return (
                    <Fragment key={name}>
                        <RatingInputItem
                            name={name}
                            value={ratingSettings[name as keyof typeof ratingSettings].toString()}
                            onChange={(e: ChangeEvent) => {
                                setRatingSettings({
                                    ...ratingSettings,
                                    [name]: (e.target as HTMLInputElement).value,
                                });
                                setRatingSettingsChanged(true);
                            }}
                            onDecrease={decrRatingSettings}
                            onIncrease={incrRatingSettings}
                        />
                    </Fragment>
                );
            })}
        </>
    );
};

const RatingInputItem = ({ name, value, onDecrease, onIncrease, onChange }: IRatingInputItemProps) => {
    return (
        <div className="col-span-3">
            <div className="relative flex-center gap-0">
                <Button
                    className="flex absolute left-0 top-1/2 -translate-y-1/2 z-10 !px-3"
                    color="common"
                    onClick={() => onDecrease(name)}
                >
                    <Icon icon={Icons.Minus} width="20px" height="20px" color="fill-blue-drk" />
                </Button>
                <Input
                    type="text"
                    className={`text-center ${value.length === 0 ? "border-red" : null}`}
                    name={name}
                    value={value}
                    onChange={(e) => {
                        if (e.target.value.length !== 0) {
                            e.target.value = (+e.target.value).toString();
                        }

                        onChange(e);
                    }}
                    cutRegExp={wholePosNumberRegexp}
                    isRequired={true}
                    min={0}
                    max={1000}
                />
                <Button
                    className="flex absolute right-0 top-1/2 -translate-y-1/2 z-10 !px-3"
                    color="common"
                    onClick={() => onIncrease(name)}
                >
                    <Icon icon={Icons.Plus} width="20px" height="20px" color="fill-blue-drk" />
                </Button>
            </div>
        </div>
    );
};

export const SettingsRating = () => {
    const dataBackupRef = useRef<RatingReadResponse | null>(null);
    const [dataUpdated, setDataUpdated] = useState(false);

    const [ratingModalOpen, setRatingModalOpen] = useState(false);
    const [isRatingSettingsChanged, setIsRatingSettingsChanged] = useState(false);

    const [ratingModalType, setRatingModalType] = useState<"save" | "cancel">("save");
    const [ratingSettings, setRatingSettings] = useState<RatingReadResponse | null>(null);

    const { isLoading, isSuccess, data, refetch } = useQuery(["minArticle"], () => Api.Rating.Read(), {
        refetchOnWindowFocus: false,
    });

    const writeData = (data: RatingReadResponse) => {
        dataBackupRef.current = data;
        setRatingSettings(data);

        setDataUpdated(true);
        setIsRatingSettingsChanged(false);
    };

    const decrRatingSettings = (name: string) => {
        const value = Number((ratingSettings as RatingReadResponse)[name as keyof typeof ratingSettings]);

        setRatingSettings({ ...(ratingSettings as RatingReadResponse), [name]: value > 0 ? value - 1 : 0 });
        setIsRatingSettingsChanged(true);
    };

    const incrRatingSettings = (name: string) => {
        const value = Number((ratingSettings as RatingReadResponse)[name as keyof typeof ratingSettings]);

        setRatingSettings({ ...(ratingSettings as RatingReadResponse), [name]: value < 1000 ? value + 1 : 1000 });
        setIsRatingSettingsChanged(true);
    };

    const isSaveDisabled = () => {
        if (ratingSettings === null) {
            return false;
        }

        let item: keyof RatingReadResponse;

        for (item in ratingSettings) {
            if (ratingSettings[item] === "") {
                return true;
            }
        }

        return false;
    };

    useEffect(() => {
        if (!isLoading && isSuccess && !dataUpdated) {
            writeData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading, isSuccess]);

    return (
        <div className="w-full h-full px-4 pb-4 sm:overflow-y-scroll custom-scrollbar">
            <Breadcrumbs className="mb-5" id="settingsRatingBreadcrumb">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Настройки" />
                <Breadcrumbs.Link title="Рейтинг" />
            </Breadcrumbs>
            <div id="settingsRatingForm">
                <div className="flex justify-between items-center h-10">
                    <div>
                        <h3 className="font-semibold">
                            Кол-во баллов за прохождение материалов
                            {/*,*/}
                            {/*тестов и*/}
                            {/*заданий за сложность*/}
                        </h3>
                    </div>
                    {isRatingSettingsChanged && (
                        <div className="space-x-4">
                            <Button
                                key={0}
                                onClick={() => {
                                    setRatingSettings(dataBackupRef.current as RatingReadResponse);
                                    setIsRatingSettingsChanged(false);
                                }}
                                variant="outline"
                                size={"medium"}
                                color={"common"}
                                className={"border-[#EAEDF3] "}
                            >
                                Отменить
                            </Button>
                            <Button
                                key={1}
                                onClick={() => {
                                    setRatingModalType("save");
                                    setRatingModalOpen(true);
                                }}
                                size={"medium"}
                                color={"primary"}
                                disabled={isSaveDisabled()}
                            >
                                Сохранить
                            </Button>
                        </div>
                    )}
                </div>
                {!isLoading && isSuccess && ratingSettings && (
                    <div className="grid grid-cols-11 gap-x-5 gap-y-5 w-4/5 mt-6">
                        <div className="col-span-2"></div>
                        <div className="col-span-3">
                            <Label className="!pb-0 text-base font-medium !text-gray-text">Низкая, баллы</Label>
                        </div>
                        <div className="col-span-3">
                            <Label className="!pb-0 text-base font-medium !text-gray-text">Средняя, баллы</Label>
                        </div>
                        <div className="col-span-3">
                            <Label className="!pb-0 text-base font-medium !text-gray-text">Высокая, баллы</Label>
                        </div>
                        {inputItemsRowKeys.map((item) => {
                            return (
                                <Fragment key={item.title}>
                                    <RatingInputItemsRow
                                        {...item}
                                        decrRatingSettings={decrRatingSettings}
                                        incrRatingSettings={incrRatingSettings}
                                        ratingSettings={ratingSettings}
                                        setRatingSettings={setRatingSettings}
                                        setRatingSettingsChanged={setIsRatingSettingsChanged}
                                    />
                                </Fragment>
                            );
                        })}
                    </div>
                )}
                {isLoading && (
                    <div className="min-h-[400px] flex-center">
                        <Loader />
                    </div>
                )}
            </div>
            <RatingModal
                id="settingsRatingModal"
                type={ratingModalType}
                isOpen={ratingModalOpen}
                setIsOpen={setRatingModalOpen}
                setRatingSettingsChanged={setIsRatingSettingsChanged}
                resetFunc={() => {
                    setRatingSettings(dataBackupRef.current as RatingReadResponse);
                }}
                saveFunc={async () => {
                    const edited = await Api.Rating.Edit(ratingSettings as RatingEditRequest);
                    toast(
                        ({ closeToast }) => {
                            return (
                                <Notification
                                    text={edited ? "Все изменения сохранены!" : "Не удалось сохранить изменения!"}
                                    type={edited ? "success" : "error"}
                                    onClose={closeToast}
                                />
                            );
                        },
                        {
                            position: "bottom-right",
                            toastId: "SettingsRating",
                        },
                    );

                    if (edited) {
                        setDataUpdated(false);
                        await refetch();
                    }
                }}
            />
        </div>
    );
};
