import BaseApi from "../BaseApi";
import { BaseRequest } from "../BaseRequest";
import { BasePaginationResponse } from "../BaseResponse";
import {
    StatisticResponse,
    StatisticCourseResponse,
    ProgressItem,
    // StatisticTestResponse,
    StatisticMaterialResponse,
    // StatisticTaskResponse,
    // StatisticProgramResponse,
    // StatisticTestTeamsResponse,
    // StatisticTestAnswersResponse,
    // StatisticTestUserResponse,
    // StatisticTaskUserResponse,
    StatisticCourseUserResponse,
    // StatisticProgramUserResponse,
    StatisticUserComplexityResponse,
    StatisticRatingResponse,
} from "Api/Responses/StatisticResponse";
import {
    StatisticCourseRequest,
    StatisticCourseUserReportFileRequest,
    StatisticCourseUserRequest,
    StatisticMaterialReportFileRequest,
    StatisticMaterialRequest,
    // StatisticProgramReportFileRequest,
    // StatisticProgramRequest,
    // StatisticProgramUserReportFileRequest,
    // StatisticProgramUserRequest,
    StatisticRatingReportFileRequest,
    StatisticRatingRequest,
    StatisticReportFileRequest,
    // StatisticTaskReportFileRequest,
    // StatisticTaskRequest,
    // StatisticTaskUserReportFileRequest,
    // StatisticTaskUserRequest,
    // StatisticTestAnswersReportFileRequest,
    // StatisticTestAnswersRequest,
    // StatisticTestReportFileRequest,
    // StatisticTestRequest,
    // StatisticTestTeamsReportFileRequest,
    // StatisticTestTeamsRequest,
    // StatisticTestUserReportFileRequest,
    // StatisticTestUserRequest,
    StatisticUserComplexityReportFileRequest,
    StatisticUserComplexityRequest,
} from "Api/Requests/StatisticRequest";
import { SortingState } from "@tanstack/react-table";
import { ResourceType } from "Enums";

export default class StatisticApi extends BaseApi {
    GetUserProgressActivity = (id: string): Promise<StatisticResponse> => {
        return this.Get(new StatisticResponse(), `/user-progress/activity${id ? "/" + id : ""}`);
    };

    GetUserAvailableResourceCount = async (resourceType: ResourceType, userId: string): Promise<string> => {
        return await this.Get("", `/user-progress/available-count/${resourceType}/${userId}`);
    };

    CreateCourseReport = async (
        data: StatisticCourseRequest,
        page: number,
        size: number,
        sort: SortingState,
    ): Promise<StatisticCourseResponse> => {
        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Post(
            new StatisticCourseResponse(),
            `/report/course/create?page=${page}&size=${size}${sorting}`,
            data,
        );
    };

    GetUserProgress = (
        id: string | null,
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<ProgressItem>> => {
        const filters = Object.keys(filter || {})
            .map((p) => `&${p}=${filter[p]}`)
            .join("");

        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        const idParam = id ? `/${id}` : "";

        return this.Get(
            new BasePaginationResponse(ProgressItem),
            `/user-progress/list${idParam}?page=${page}&size=${size}${sorting}${filters}`,
        );
    };

    GenerateCourseStatisticReportFile = async (data: StatisticReportFileRequest): Promise<string> => {
        return await this.Post("", `/report/course/generate`, data);
    };

    // CreateTestReport = async (
    //     data: StatisticTestRequest,
    //     page: number,
    //     size: number,
    //     sort: SortingState,
    // ): Promise<StatisticTestResponse> => {
    //     let sorting = "";
    //
    //     if (sort.length > 0) {
    //         sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
    //     }
    //
    //     return await this.Post(
    //         new StatisticTestResponse(),
    //         `/report/quiz/create?page=${page}&size=${size}${sorting}`,
    //         data,
    //     );
    // };

    // GenerateTestStatisticReportFile = async (data: StatisticTestReportFileRequest): Promise<string> => {
    //     return await this.Post("", `/report/quiz/generate`, data);
    // };

    // CreateTaskReport = async (
    //     data: StatisticTaskRequest,
    //     page: number,
    //     size: number,
    //     sort: SortingState,
    // ): Promise<StatisticTaskResponse> => {
    //     let sorting = "";
    //
    //     if (sort.length > 0) {
    //         sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
    //     }
    //
    //     return await this.Post(
    //         new StatisticTaskResponse(),
    //         `/report/exercise/create?page=${page}&size=${size}${sorting}`,
    //         data,
    //     );
    // };

    // GenerateTaskStatisticReportFile = async (data: StatisticTaskReportFileRequest): Promise<string> => {
    //     return await this.Post("", `/report/exercise/generate`, data);
    // };

    CreateMaterialReport = async (
        data: StatisticMaterialRequest,
        page: number,
        size: number,
        sort: SortingState,
    ): Promise<StatisticMaterialResponse> => {
        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Post(
            new StatisticMaterialResponse(),
            `/report/material/create?page=${page}&size=${size}${sorting}`,
            data,
        );
    };

    GenerateMaterialStatisticReportFile = async (data: StatisticMaterialReportFileRequest): Promise<string> => {
        return await this.Post("", `/report/material/generate`, data);
    };

    // CreateProgramReport = async (
    //     data: StatisticProgramRequest,
    //     page: number,
    //     size: number,
    //     sort: SortingState,
    // ): Promise<StatisticProgramResponse> => {
    //     let sorting = "";
    //
    //     if (sort.length > 0) {
    //         sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
    //     }
    //
    //     return await this.Post(
    //         new StatisticProgramResponse(),
    //         `/report/program/create?page=${page}&size=${size}${sorting}`,
    //         data,
    //     );
    // };

    // GenerateProgramStatisticReportFile = async (data: StatisticProgramReportFileRequest): Promise<string> => {
    //     return await this.Post("", `/report/program/generate`, data);
    // };

    // CreateTestTeamsReport = async (
    //     data: StatisticTestTeamsRequest,
    //     page: number,
    //     size: number,
    //     sort: SortingState,
    // ): Promise<StatisticTestTeamsResponse> => {
    //     let sorting = "";
    //
    //     if (sort.length > 0) {
    //         sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
    //     }
    //
    //     return await this.Post(
    //         new StatisticTestTeamsResponse(),
    //         `/report/quiz-team/create?page=${page}&size=${size}${sorting}`,
    //         data,
    //     );
    // };

    // GenerateTestTeamsStatisticReportFile = async (data: StatisticTestTeamsReportFileRequest): Promise<string> => {
    //     return await this.Post("", `/report/quiz-team/generate`, data);
    // };

    // CreateTestAnswersReport = async (
    //     data: StatisticTestAnswersRequest,
    //     page: number,
    //     size: number,
    //     sort: SortingState,
    // ): Promise<StatisticTestAnswersResponse> => {
    //     let sorting = "";
    //
    //     if (sort.length > 0) {
    //         sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
    //     }
    //
    //     return await this.Post(
    //         new StatisticTestAnswersResponse(),
    //         `/report/quiz-answer/create?page=${page}&size=${size}${sorting}`,
    //         data,
    //     );
    // };

    // GenerateTestAnswersStatisticReportFile = async (data: StatisticTestAnswersReportFileRequest): Promise<string> => {
    //     return await this.Post("", `/report/quiz-answer/generate`, data);
    // };

    // GenerateTestAnswersStatisticSummaryReportFile = async (
    //     data: StatisticTestAnswersReportFileRequest,
    // ): Promise<string> => {
    //     return await this.Post("", `/report/quiz-answer/generate-summary`, data);
    // };

    DownloadReportFile = (reportId: string): Promise<string> => {
        return this.Get("", `/report/download/${reportId}`, new BaseRequest(), "blob");
    };

    // CreateTestUserReport = async (
    //     data: StatisticTestUserRequest,
    //     page: number,
    //     size: number,
    //     sort: SortingState,
    // ): Promise<StatisticTestUserResponse> => {
    //     let sorting = "";
    //
    //     if (sort.length > 0) {
    //         sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
    //     }
    //
    //     return await this.Post(
    //         new StatisticTestUserResponse(),
    //         `/report/quiz-user/create?page=${page}&size=${size}${sorting}`,
    //         data,
    //     );
    // };

    // GenerateTestUserStatisticReportFile = async (data: StatisticTestUserReportFileRequest): Promise<string> => {
    //     return await this.Post("", `/report/quiz-user/generate`, data);
    // };

    // CreateTaskUserReport = async (
    //     data: StatisticTaskUserRequest,
    //     page: number,
    //     size: number,
    //     sort: SortingState,
    // ): Promise<StatisticTaskUserResponse> => {
    //     let sorting = "";
    //
    //     if (sort.length > 0) {
    //         sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
    //     }
    //
    //     return await this.Post(
    //         new StatisticTaskUserResponse(),
    //         `/report/exercise-user/create?page=${page}&size=${size}${sorting}`,
    //         data,
    //     );
    // };

    // GenerateTaskUserStatisticReportFile = async (data: StatisticTaskUserReportFileRequest): Promise<string> => {
    //     return await this.Post("", `/report/exercise-user/generate`, data);
    // };

    CreateCourseUserReport = async (
        data: StatisticCourseUserRequest,
        page: number,
        size: number,
        sort: SortingState,
    ): Promise<StatisticCourseUserResponse> => {
        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Post(
            new StatisticCourseUserResponse(),
            `/report/course-user/create?page=${page}&size=${size}${sorting}`,
            data,
        );
    };

    GenerateCourseUserStatisticReportFile = async (data: StatisticCourseUserReportFileRequest): Promise<string> => {
        return await this.Post("", `/report/course-user/generate`, data);
    };

    // CreateProgramUserReport = async (
    //     data: StatisticProgramUserRequest,
    //     page: number,
    //     size: number,
    //     sort: SortingState,
    // ): Promise<StatisticProgramUserResponse> => {
    //     let sorting = "";
    //
    //     if (sort.length > 0) {
    //         sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
    //     }
    //
    //     return await this.Post(
    //         new StatisticProgramUserResponse(),
    //         `/report/program-user/create?page=${page}&size=${size}${sorting}`,
    //         data,
    //     );
    // };

    // GenerateProgramUserStatisticReportFile = async (data: StatisticProgramUserReportFileRequest): Promise<string> => {
    //     return await this.Post("", `/report/program-user/generate`, data);
    // };

    CreateUserComplexityReport = async (
        data: StatisticUserComplexityRequest,
        page: number,
        size: number,
        sort: SortingState,
    ): Promise<StatisticUserComplexityResponse> => {
        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Post(
            new StatisticUserComplexityResponse(),
            `/report/user/create?page=${page}&size=${size}${sorting}`,
            data,
        );
    };

    GenerateUserComplexityStatisticReportFile = async (
        data: StatisticUserComplexityReportFileRequest,
    ): Promise<string> => {
        return await this.Post("", `/report/user/generate`, data);
    };

    CreateRatingReport = async (
        data: StatisticRatingRequest,
        page: number,
        size: number,
        sort: SortingState,
    ): Promise<StatisticRatingResponse> => {
        let sorting = "";

        if (sort.length > 0) {
            sorting = `&sort=${sort[0].id},${sort[0].desc ? "desc" : "asc"}`;
        }

        return await this.Post(
            new StatisticRatingResponse(),
            `/report/user-rating/create?page=${page}&size=${size}${sorting}`,
            data,
        );
    };

    GenerateRatingStatisticReportFile = async (data: StatisticRatingReportFileRequest): Promise<string> => {
        return await this.Post("", `/report/user-rating/generate`, data);
    };
}
