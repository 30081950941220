import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { Button, Checkbox, Icon, Icons, SelectMultiValueRemove, SelectValueContainer, Table } from "Uikit";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { ArchiveRestoreModal } from "./ArchiveRestoreModal";
import { ArchiveResponse } from "Api/Responses/ArchiveResponse";
import { BasePaginationResponse } from "Api/BaseResponse";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { Empty } from "Uikit/Page/Empty";
import { useInvalidate } from "hooks/useInvalidate";
import { AccountableUser, UserListResponse } from "Api/Responses/UserResponse";
import Api from "Api";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { IOption } from "types";
import { LogoSize } from "Api/Services/UploadApi";

interface IArchiveTable {
    queryId: string;
    emptyTitle: string;
    emptyDescription: string;
    categories: IOption[];
    onRequest: (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ) => Promise<BasePaginationResponse<ArchiveResponse>>;
    onRestore: (categoryId: string, itemIds: string | string[]) => Promise<void>;
}
export const ArchiveTable = ({
    queryId,
    emptyTitle,
    emptyDescription,
    categories,
    onRequest,
    onRestore,
}: IArchiveTable) => {
    const [restore, setRestore] = useState<string | { id: string; title: string; categoryId: string }[] | undefined>(
        undefined,
    );
    const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false);

    const invalidate = useInvalidate();
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });
    const [sorting, setSorting] = useState<SortingState>([{ id: "archivingTime", desc: true }]);

    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const [isFilters, setIsFilters] = useState(false);
    const [filters, setFilters] = useState<{ [id: string]: any }>({});

    const isFiltersEmpty = useMemo(() => {
        if (Object.keys(filters).indexOf("searchQuery.contains") !== -1) {
            return false;
        }

        return Object.keys(filters).every((f) => !filters[f]);
    }, [filters]);

    const pagination = React.useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const dataQuery = useQuery(
        [queryId, "collection", pagination, sorting, filters],
        async () => {
            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            for (const element of filtersKeys) {
                if (element === "archivingTime") {
                    filtersData[element + ".greaterThanOrEqual"] = Math.round(
                        filters[element]["date"]["startDate"].getTime() / 1000,
                    );
                    filtersData[element + ".lessThanOrEqual"] = Math.round(
                        filters[element]["date"]["endDate"].getTime() / 1000,
                    );
                } else if (element === "archivingUserId.in") {
                    filtersData[element] = filters[element].map((p: any) => p.value.id).join(",");
                } else {
                    filtersData[element] = filters[element];
                }
            }

            return await onRequest(pageIndex, pageSize, sorting, filtersData);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const onSearch = (searchQuery: string) => {
        setFilters((prevState) => ({ ...prevState, "searchQuery.contains": searchQuery }));
    };

    const loadActiveUserOptions = async (
        inputValue: string,
        prevOptions: unknown,
        { page }: { page: number },
    ): Promise<any> => {
        if (inputValue === "") {
            return {
                options: [],
                hasMore: false,
            };
        }

        const users = await Api.User.GetActiveListOptions(inputValue, page);
        const usersSelectOptions: { label: string; value: AccountableUser | UserListResponse | undefined }[] =
            users.Content.map((p) => ({ label: p.lastName + " " + p.firstName, value: p }));

        return {
            options: usersSelectOptions,
            hasMore: users.TotalPages > page,
            additional: {
                page: page + 1,
            },
        };
    };

    const formatUserOptionsLabel = ({
        label,
        value,
    }: {
        label: string;
        value: AccountableUser | UserListResponse | undefined;
    }): React.ReactElement => {
        return (
            <div className="flex gap-2">
                {value?.avatarId ? (
                    <img
                        src={`/service/lms-upload/api/file/download/${value.avatarId}`}
                        className="w-5 h-5 rounded-full"
                    />
                ) : (
                    <div
                        className={`flex-center w-5 h-5 rounded-full`}
                        style={{ backgroundColor: value?.defaultAvatarColor }}
                    >
                        <span className="text-white text-xxs">
                            {value?.firstName[0]}
                            {value?.lastName[0]}
                        </span>
                    </div>
                )}
                <div>{label}</div>
            </div>
        );
    };

    const columns = useMemo<ColumnDef<ArchiveResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "Название",
                accessorKey: "title",
                cell: (info) => {
                    return (
                        <div className="group flex items-center space-x-3">
                            <img
                                className="rounded-md w-[54px] h-9 object-cover"
                                src={
                                    info.row.original.logoId
                                        ? Api.Upload.GetLogo(info.row.original.logoId, LogoSize.THUMB_MICRO)
                                        : "/img/article-card-cover.jpg"
                                }
                                alt={String(info.getValue())}
                            />
                            <span className="text-ellipsis overflow-hidden whitespace-nowrap max-w-100 group-hover:text-blue">
                                {String(info.getValue())}
                            </span>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Архивировал(а)",
                accessorKey: "archivingUser.lastName,archivingUser.firstName",
                enableResizing: true,
                size: 250,
                cell: (info) => {
                    if (!info.row.original.archivingUser) {
                        return null;
                    }

                    return (
                        <div className="flex items-center space-x-3">
                            <UserAvatar
                                avatarId={info.row.original.archivingUser.avatarId}
                                color={info.row.original.archivingUser.defaultAvatarColor}
                                userInitials={`${info.row.original.archivingUser.firstName?.slice(
                                    0,
                                    1,
                                )}${info.row.original.archivingUser.lastName?.slice(0, 1)}`}
                                size={36}
                            />
                            <span>
                                {info.row.original.archivingUser.lastName +
                                    " " +
                                    info.row.original.archivingUser.firstName}
                            </span>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Архивирован",
                accessorKey: "archivingTime",
                enableResizing: true,
                size: 200,
                cell: (info) => {
                    return formatDate(new Date(Number(info.getValue()) * 1000), DateFormat.DATE_TIME_LONG);
                },
                footer: (props) => props.column.id,
            },
            {
                id: "buttons",
                header: "",
                accessor: "[row identifier to be passed to button]",
                size: 30,
                enableResizing: true,
                cell: ({ row }) => {
                    const { id, title, category } = dataQuery.data!.Content.find(
                        ({ id }) => id === row.original.id,
                    ) as ArchiveResponse;

                    return (
                        <Button
                            shape="round"
                            color="common"
                            icon={
                                <Icon
                                    icon={Icons.Refresh}
                                    width={20}
                                    height={20}
                                    color="fill-blue-drk hover:fill-blue-hover"
                                />
                            }
                            iconPlacement="center"
                            onClick={() => {
                                setRestore([{ id, title, categoryId: category?.id ?? null }]);
                                setIsRestoreModalOpen(true);
                            }}
                        />
                    );
                },
            },
        ],
        [dataQuery.data],
    );
    const filtersConfig = [
        {
            label: "Дата архивации",
            fields: [
                {
                    accessor: "archivingTime",
                    type: "date-range",
                },
            ],
        },
        {
            label: "Архивировал",
            fields: [
                {
                    accessor: "archivingUserId.in",
                    type: "async-multi-select",
                    placeholder: "Выберите пользователя",
                    loadOptions: loadActiveUserOptions,
                    formatOptionLabel: formatUserOptionsLabel,
                    components: {
                        MultiValueRemove: SelectMultiValueRemove,
                        ValueContainer: SelectValueContainer({}),
                    },
                    lazy: true,
                },
            ],
        },
    ];

    // const controlButtons = (
    //     <Button
    //         variant="outline"
    //         color="secondary"
    //         size="medium"
    //         className="border-[#E6E9ED] rounded-lg font-medium"
    //         icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
    //         iconPlacement="left"
    //         onClick={() => setIsFilters(true)}
    //         id="adminArchiveBtnFilter"
    //     >
    //         Фильтры
    //     </Button>
    // );
    const selectButtons = (
        <Button
            onClick={() => {
                setRestore(
                    Object.keys(rowSelection).map((id) => {
                        const item = dataQuery.data!.Content.find((i) => i.id === id);
                        return { id: item!.id, title: item!.title, categoryId: item!.category?.id ?? null };
                    }),
                );
                setRowSelection({});
                setIsRestoreModalOpen(true);
            }}
            id="adminArchiveBtnRestore"
        >
            Восстановить
        </Button>
    );

    return (
        <>
            <ArchiveRestoreModal
                isOpen={isRestoreModalOpen}
                items={restore}
                categories={categories}
                onRestore={async (category) => {
                    await onRestore(
                        category!.value,
                        (restore as { id: string; title: string }[])?.map((item) => item.id),
                    );
                    setIsRestoreModalOpen(false);
                    invalidate(queryId);
                }}
                onClose={() => setIsRestoreModalOpen(false)}
            />

            {isFiltersEmpty && dataQuery.data?.Content && dataQuery.data?.Content.length === 0 && (
                <div className="h-full flex justify-center">
                    <Empty className="" title={emptyTitle} description={emptyDescription} />
                </div>
            )}

            {!isFiltersEmpty || (dataQuery.data?.Content && dataQuery.data?.Content.length > 0) ? (
                <>
                    <Filter
                        isActive={isFilters}
                        setIsActive={setIsFilters}
                        configuration={filtersConfig as IFilterRow[]}
                        filters={filters}
                        onChange={setFilters}
                    />
                    <Table
                        id="adminArchive"
                        columns={columns}
                        // controlButtons={controlButtons}
                        columnFilters={columnFilters}
                        pageCount={dataQuery.data?.TotalPages}
                        pagination={pagination}
                        emptyMessage="По заданным параметрам результатов нет"
                        searchTitle="Поиск по названию"
                        isFetching={dataQuery.isFetching}
                        data={dataQuery.data?.Content}
                        rowSelection={rowSelection}
                        onPaginationChange={setPagination}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        selectButtons={selectButtons}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        onSearch={onSearch}
                        defaultSortOrder={{
                            "archivingUser_lastName,archivingUser.firstName": "asc",
                        }}
                    />
                </>
            ) : null}
        </>
    );
};
