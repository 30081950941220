import React, { useEffect, useState } from "react";
import SelectComponents from "react-select";

import { Breadcrumbs, Button, ComboBox, FormGroup, Label, SelectAvatarMultiValueLabel } from "Uikit";
import Api from "Api";
import { StatisticMaterialRequest } from "Api/Requests/StatisticRequest";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import { numWord } from "helpers/numWord";
import { MaterialType, ProgressStatus, ResourceState, ResourceType, StatisticsUsersStatus } from "Enums";
import { TrainingMultiSelect } from "Components/Common/TrainingMultiSelect";

import { IRequestSettingsItem, ReportSettings } from "../../ReportSettings/ReportSettings";
import { ReportUserSelect } from "../../ReportUserSelect/ReportUserSelect";
import { IStatisticValue } from "../utils";
import { SingleValueContainer } from "../SingleValueContainer";
import { ReportTable } from "./ReportTable";

export type TTaskFilterValue = ResourceState | "ALL";

export const reportSettings = [
    {
        title: "Участник",
        key: "user",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Фамилия, Имя участника",
                value: "name",
                checked: true,
            },
            {
                title: "Логин",
                value: "login",
                checked: true,
            },
            {
                title: "Электронная почта",
                value: "email",
                checked: true,
            },
            {
                title: "Должность",
                value: "job",
                checked: true,
            },
            // {
            //     title: "Офис",
            //     value: "office",
            //     checked: true,
            // },
            {
                title: "Статус участника",
                value: "status",
                checked: true,
            },
            {
                title: "Команда",
                value: "command",
                checked: true,
            },
            {
                title: "Путь к команде",
                value: "commandPath",
                checked: true,
            },
            // {
            //     title: "Наставник",
            //     value: "mentor",
            //     checked: true,
            // },
        ],
    },
    {
        title: "Материал",
        key: "material",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Дата завершения",
                value: "endDate",
                checked: true,
            },
            {
                title: "Статус материала",
                value: "status",
                checked: true,
            },
            {
                title: "Затрачено времени",
                value: "spendTime",
                checked: true,
            },
        ],
    },
];

// Тип материала
export const materialTypeOptions = [
    {
        value: MaterialType.Article,
        label: "Статья",
    },
    {
        value: MaterialType.Video,
        label: "Видео",
    },
    // {
    //     value: MaterialType.Document,
    //     label: "Документ",
    // },
    // {
    //     value: MaterialType.SCORM,
    //     label: "SCORM",
    // },
];

// Статус прохождения материала
export const progressOptions = [
    {
        value: ProgressStatus.ALL,
        label: "Любой",
    },
    {
        value: ProgressStatus.PASSED,
        label: "Пройден",
    },
    {
        value: ProgressStatus.IN_PROGRESS,
        label: "В процессе",
    },
    {
        value: ProgressStatus.NOT_STARTED,
        label: "Не начат",
    },
];

// Статус участников
export const usersStatusOptions = [
    {
        value: StatisticsUsersStatus.ALL,
        label: "Любой",
    },
    {
        value: StatisticsUsersStatus.ACTIVE,
        label: "Активные",
    },
    {
        value: StatisticsUsersStatus.BLOCKED,
        label: "Заблокированные",
    },
];

export const Materials = () => {
    const [materialsValues, setMaterialsValues] = useState<IStatisticValue[]>([]);
    const [showReportTable, setShowReportTable] = useState(false);
    const [allUsers, setAllUsers] = useState<MembersListResponse[]>([]);
    const [users, setUsers] = useState<MembersListResponse[]>([]);
    const [allUsersCount, setAllUsersCount] = useState(0);
    const [materialType, setMaterialType] = useState(materialTypeOptions[0]);
    const [progress, setProgress] = useState<{ value: ProgressStatus; label: string }>(progressOptions[0]);
    const [usersStatus, setUsersStatus] = useState(usersStatusOptions[0]);
    const [showReportSettings, setShowReportSettings] = useState(false);
    const [isUsersLoaded, setIsUsersLoaded] = useState(false);

    const onSubmitMaterialSection = (selectedItems: any[]) => {
        setMaterialsValues(
            selectedItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        setShowReportTable(false);
    };

    const createReport = () => {
        setShowReportTable(true);
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateMaterialStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    const createPayloadParams = () => {
        const payload: StatisticMaterialRequest = {
            materialIds: materialsValues.map(({ id }) => id),
            userIds: users.map(({ id }) => id),
            teamsIds: Array.from(
                new Set(
                    users.reduce((prev, { teams }) => {
                        return [...prev, ...teams.map(({ id }) => id)];
                    }, [] as string[]),
                ),
            ),
            officeIds: users.map(({ officeId }) => officeId).filter((item) => !!item),
            jobIds: users.map(({ jobTitle }) => jobTitle?.id).filter((item) => !!item) as string[],
            status: progress.value,
            userStatus: usersStatus.value,
            materialType: materialType.value,
        };

        return payload;
    };

    useEffect(() => {
        setMaterialsValues([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [materialType]);

    useEffect(() => {
        if (materialsValues.length === 0) {
            setUsers(allUsers);
            setProgress(progressOptions[0]);
            setUsersStatus(usersStatusOptions[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [materialsValues]);

    return (
        <div className="px-6 w-full h-full sm:overflow-y-scroll custom-scrollbar">
            <Breadcrumbs className="mb-1">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Статистика" />
                <Breadcrumbs.Link title="Материалы" />
                <Breadcrumbs.Link title="Отчет по материалам" />
            </Breadcrumbs>
            <div>
                <h1>Отчет по материалам</h1>
                <div className="w-full h-full mt-5">
                    <div className="grid items-center gap-6.5 grid-cols-2 relative z-[75]">
                        <FormGroup className="!mb-0">
                            <Label>Тип материала</Label>
                            <ComboBox
                                onChange={(value) => {
                                    setMaterialType(value as { value: MaterialType; label: string });
                                }}
                                value={materialType}
                                options={materialTypeOptions}
                                isSearchable={false}
                                isCreatable={false}
                                isClearable={false}
                            />
                        </FormGroup>
                        <FormGroup className="!mb-0">
                            <Label>Материал</Label>
                            {/* Выбор материала */}
                            <TrainingMultiSelect
                                className="!mt-0"
                                value={materialsValues[0] ?? null}
                                onSubmiTreeSection={onSubmitMaterialSection}
                                placeholder="Выберите материал"
                                components={
                                    {
                                        ValueContainer: SingleValueContainer,
                                    } as unknown as SelectComponents
                                }
                                checkable={false}
                                selectable={true}
                                checkOnNameClick={true}
                                showSelectedCounter={false}
                                isMulti={false}
                                fetchDataFilters={{ "type.equal": materialType.value }}
                                fetchDataDependencies={materialType.value}
                                resourceType={ResourceType.MATERIAL}
                            />
                        </FormGroup>
                    </div>
                    {materialsValues.length > 0 && (
                        <>
                            <div className="mt-7 flex-wrap relative z-[70]">
                                <div className="grid items-center gap-6.5 grid-cols-2">
                                    {/* Выбор участников */}
                                    <FormGroup className="!mb-0">
                                        <Label>Участники</Label>
                                        <ReportUserSelect
                                            value={users}
                                            placeholder="Выберите одного или нескольких участников"
                                            onSubmit={(users) => {
                                                setUsers(
                                                    users.map((user) => {
                                                        const { firstName, lastName, avatarId, defaultAvatarColor } =
                                                            user;
                                                        return {
                                                            ...user,
                                                            value: {
                                                                avatarId,
                                                                firstName,
                                                                lastName,
                                                                defaultAvatarColor,
                                                            },
                                                            label: `${lastName} ${firstName}`,
                                                        };
                                                    }),
                                                );
                                                setShowReportTable(false);
                                            }}
                                            onChange={(options, action) => {
                                                if (action && action.action === "clear") {
                                                    setUsers([]);
                                                }
                                                if (action && action.action === "remove-value") {
                                                    setUsers(options as unknown as MembersListResponse[]);
                                                }
                                                setShowReportTable(false);
                                            }}
                                            components={
                                                {
                                                    ValueContainer: StackedValueContainer,
                                                    MultiValueLabel: SelectAvatarMultiValueLabel({}),
                                                } as unknown as SelectComponents
                                            }
                                            onDataLoaded={(users) => {
                                                if (!allUsers.length && !isUsersLoaded) {
                                                    setUsers(users);
                                                    setAllUsers(users);
                                                    setAllUsersCount(users.length);
                                                    setIsUsersLoaded(true);
                                                }
                                            }}
                                            isDataLoaded={isUsersLoaded}
                                            allPlaceholder={users.length > 0 && allUsersCount === users.length}
                                            selectedUsersDefault={users}
                                            showStatusFilter={true}
                                            isSingleSelect={false}
                                            isMulti={true}
                                        />
                                    </FormGroup>
                                    <FormGroup className="!mb-0">
                                        <Label>Статус прохождения материала</Label>
                                        <ComboBox
                                            onChange={(value) => {
                                                setProgress(value as { value: ProgressStatus; label: string });
                                                setShowReportTable(false);
                                            }}
                                            value={progress}
                                            options={progressOptions}
                                            isSearchable={false}
                                            isCreatable={false}
                                            isClearable={false}
                                        />
                                    </FormGroup>
                                    <FormGroup className="!mb-0">
                                        <Label>Статус участников</Label>
                                        <ComboBox
                                            onChange={(value) => {
                                                setUsersStatus(
                                                    value as { value: StatisticsUsersStatus; label: string },
                                                );
                                                setShowReportTable(false);
                                            }}
                                            value={usersStatus}
                                            options={usersStatusOptions}
                                            isSearchable={false}
                                            isCreatable={false}
                                            isClearable={false}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="mt-9">
                                {showReportTable ? (
                                    <>
                                        <ReportTable
                                            onClickLoadReportFile={() => setShowReportSettings(true)}
                                            queryPayload={createPayloadParams()}
                                        />
                                        <ReportSettings
                                            isActive={showReportSettings}
                                            setIsActive={setShowReportSettings}
                                            settings={reportSettings}
                                            onSubmit={onSubmit}
                                            isReportFileFetching={false}
                                        />
                                    </>
                                ) : (
                                    <Button
                                        key={1}
                                        onClick={() => createReport()}
                                        variant="standard"
                                        size={"medium"}
                                        color={"primary"}
                                        className="w-full"
                                        disabled={!users.length}
                                    >
                                        {`Сформировать отчет по ${users.length} ${numWord(users.length, [
                                            "участнику",
                                            "участникам",
                                            "участникам",
                                        ])}`}
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
