import React, { useEffect, useRef, useState } from "react";

import { VideoPlayer } from "Components/VideoPlayer";
import { useVideoPlayer } from "hooks/useVideoPlayer";
import { PlaybackRequest } from "Api/Requests/MaterialRequest";
import { useChangePlayback } from "./Course.hooks";
import { Icon, Icons } from "Uikit/Icon/Icon";
import NotificationConnect, { NotificationSubscribe, WssMessage, WssMessageType } from "Api/Wss/Notifications";
// import Api from "Api";
import { fetchFileInfo } from "helpers/fetchFileInfo";
import { WSEventVideoActionTypes } from "Enums";

interface ICourseMaterialVideoProps {
    materialId: string;
    fileId: string;
    rewind: boolean;
    watchedTime?: number;
    onDurationVideoChange: (duration: number, currentTime: number) => void;
    onVideoProcessed: (isVideoProcessed: boolean) => void;
}

export const CourseMaterialVideo = ({
    materialId,
    fileId,
    rewind,
    watchedTime = 0,
    onDurationVideoChange,
    onVideoProcessed,
}: ICourseMaterialVideoProps) => {
    const watchedTimeRef = useRef(watchedTime);
    const { mutate: mutatePlayback } = useChangePlayback();
    const [isVideoProcessed, setIsVideoProcessed] = useState(false);

    useEffect(() => {
        if (fileId) {
            NotificationConnect();
            NotificationSubscribe(async (message: WssMessage) => {
                if (message.messageType === WssMessageType.NOTIFICATION) {
                    const { actionType } = message.body;
                    if (actionType === WSEventVideoActionTypes.PROCESSING_COMPLETE) {
                        setIsVideoProcessed(true);
                        onVideoProcessed(true);
                    }
                }
            });

            fetchFileInfo?.(fileId, (isVideoProcessed: boolean) => {
                setIsVideoProcessed(isVideoProcessed);
                onVideoProcessed(isVideoProcessed);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getPlayerProps, player } = useVideoPlayer({
        fluid: true,
        controls: true,
        rewind,
        startTime: rewind ? undefined : watchedTime,
        src: `/service/lms-upload/api/video/get-range/${fileId}`,
    });

    useEffect(() => {
        if (player) {
            player.on("timeupdate", () => {
                onDurationVideoChange(player.duration(), player.currentTime());
                if (player.currentTime() - watchedTimeRef.current >= 10) {
                    watchedTimeRef.current = player.currentTime();
                    const playbackRequest = new PlaybackRequest();

                    playbackRequest.materialId = materialId;
                    playbackRequest.playbackTimeInSeconds = Math.ceil(player.currentTime());

                    mutatePlayback(playbackRequest);
                }
            });
            player.one("durationchange", () => {
                onDurationVideoChange(player.duration(), player.currentTime());
            });
            player.one("waiting", () => {
                console.log("waiting");
            });
        }
    }, [player, mutatePlayback, materialId, onDurationVideoChange]);

    return (
        <>
            {isVideoProcessed ? (
                <VideoPlayer {...getPlayerProps()} />
            ) : (
                <div className="flex-center flex-col max-w-[1000px] w-full h-[562px] bg-gray-dark rounded-lg grow-1 my-auto m-auto">
                    <Icon icon={Icons.Loader} width={36} height={36} className="!fill-gray-light" />
                    <p className="text-white text-2xl mt-4">Видео обрабатывается...</p>
                    <p className="text-white p1 mt-2">Пожалуйста, дождитесь завершения обработки видео</p>
                </div>
            )}
        </>
    );
};
