import React, { useMemo, useRef, useState } from "react";
import { removeCookie } from "typescript-cookie";
import { Icon, Icons } from "Uikit";
import { Search } from "../Search/Search";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import Api from "Api/index";
import { AchievementIcons } from "Components/Achievement/AchievementIcons";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "store";
import {
    setBackUrl,
    setIsFavoriteSelected,
    // , setIsNotifications
} from "slices/headerSlice";
// import { Notifications } from "Components/Notifications/Notifications";
import { setIsRoot } from "slices/authSlice";
import { useOnClickOutside } from "usehooks-ts";
// import clsx from "clsx";

export const Header = ({ sideMenu }: { sideMenu: boolean }) => {
    const dispatch = useDispatch();
    const [isHeaderOnTop, setIsHeaderOnTop] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const menuRef = useRef<HTMLDivElement>(null);

    const isFavoritePage = useMemo(() => {
        return location.pathname.includes("/favorite");
    }, [location]);

    const selector = useSelector((state: IReducer) => state);

    const { data: user } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());
    const { mutateAsync: logout } = useMutation(() => Api.Auth.Logout());

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // const notificationsCount = useSelector((state: IReducer) => state.header.notificationsCount);

    const toggleMenu = () => {
        setIsMenuOpen((isMenuOpen) => !isMenuOpen);
    };

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    };

    useOnClickOutside(menuRef, handleCloseMenu);

    const onLogout = async () => {
        await logout();
        setIsMenuOpen(false);

        removeCookie("USER_SESSION", { path: "/", domain: window.location.hostname });
        dispatch(setIsRoot(false));

        localStorage.removeItem("companyId");
        document.location.reload();
    };

    const handleProfileClick = () => {
        handleCloseMenu();
        navigate("/personal");
    };

    const handleRatingClick = () => {
        handleCloseMenu();
        navigate("/rating");
    };

    return (
        <div
            className={`relative flex items-center sm:pl-4 sm:pr-6.5 ${
                location.pathname.indexOf("admin") === -1 ? "sm:max-w-[1216px] 2xl:max-w-[1506px]" : ""
            } w-full mx-auto z-[${isHeaderOnTop ? "101" : "100"}]`}
        >
            {/*<div className="hidden sm:block">*/}
            {/*    <Notifications />*/}
            {/*</div>*/}
            {sideMenu && <div className="min-w-65" />}
            {!selector.header.isHidden && (
                <div className="block sm:hidden ml-3.5" id="headerBack">
                    {selector.header.isBack && (
                        <div
                            onClick={() => {
                                if (selector.header.backUrl) {
                                    navigate(selector.header.backUrl, { replace: true });
                                    dispatch(setBackUrl(""));
                                } else {
                                    navigate(-1);
                                }
                            }}
                        >
                            <Icon icon={Icons.ChevronLeft} width={18} height={18} color="fill-primary" />
                        </div>
                    )}
                    {!selector.header.isBack && <div className="w-4.5 h-4.5" />}
                </div>
            )}
            {!selector.header.isHidden && (
                <h4 id="headerTitle" className="block sm:hidden py-3 w-full text-center">
                    {selector.header.title}
                </h4>
            )}
            <div className="hidden sm:flex w-full">
                <Search
                    onInputFocusHandler={(isFocused) => {
                        setIsHeaderOnTop(isFocused);
                    }}
                />
            </div>
            {!isFavoritePage && (
                <div
                    className="hidden sm:block ml-7 2xl:ml-[35px] mr-5 2xl:mr-6.25 cursor-pointer"
                    id="headerFavorite"
                    onClick={() => navigate("/favorite")}
                >
                    <Icon
                        icon={Icons.Save}
                        width={20}
                        height={20}
                        color="fill-blue-drk"
                        className="2xl:!w-6.25 2xl:!h-6.25"
                    />
                </div>
            )}
            {isFavoritePage && (
                <div className="hidden sm:block ml-7 2xl:ml-[35px] mr-5 2xl:mr-6.25" id="headerFavorite">
                    <Icon
                        icon={Icons.SaveFilled}
                        width={20}
                        height={20}
                        color="fill-blue"
                        className="2xl:!w-6.25 2xl:!h-6.25"
                    />
                </div>
            )}
            {/*<div className="hidden sm:block ml-1 mr-5" id="headerPersonalMessageTyping">*/}
            {/*    <Icon icon={Icons.MessageTyping} width={20} height={20} />*/}
            {/*</div>*/}
            {!selector.header.isHidden && selector.header.isFavorite && (
                <div
                    className="block sm:hidden absolute top-3.5 right-10 sm:right-12"
                    onClick={() => dispatch(setIsFavoriteSelected(!selector.header.isFavoriteSelected))}
                >
                    {selector.header.isFavoriteSelected && (
                        <Icon icon={Icons.SaveFilled} width={18} height={18} color="fill-blue-drk" />
                    )}
                    {!selector.header.isFavoriteSelected && (
                        <Icon icon={Icons.Save} width={18} height={18} color="fill-blue-drk" />
                    )}
                </div>
            )}
            {/*<div*/}
            {/*    id="headerPersonalNotification"*/}
            {/*    className={clsx("relative mr-3 sm:mr-4 2xl:mr-5 cursor-pointer", {*/}
            {/*        "hidden sm:block": selector.header.isHidden,*/}
            {/*    })}*/}
            {/*    onClick={(e) => {*/}
            {/*        e.preventDefault();*/}
            {/*        e.stopPropagation();*/}

            {/*        if (window.screen.width <= 568) {*/}
            {/*            navigate("/notifications");*/}
            {/*        } else {*/}
            {/*            dispatch(setIsNotifications(true));*/}
            {/*        }*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {!!notificationsCount && (*/}
            {/*        <span className="absolute flex-center -top-1 2xl:-top-1.5 -right-1 2xl:-right-1.5 px-1 h-3.5 2xl:h-4.5 2xl:min-w-4.5 bg-danger rounded-[100px] p4 text-white">*/}
            {/*            {notificationsCount}*/}
            {/*        </span>*/}
            {/*    )}*/}
            {/*    <Icon*/}
            {/*        icon={Icons.Notification}*/}
            {/*        width={20}*/}
            {/*        height={20}*/}
            {/*        color="fill-blue-drk"*/}
            {/*        className="2xl:!w-6.25 2xl:!h-6.25"*/}
            {/*    />*/}
            {/*</div>*/}
            <div
                className="hidden sm:flex items-center flex-shrink-0 cursor-pointer"
                onClick={toggleMenu}
                id="headerPersonalAccount"
                ref={menuRef}
            >
                <UserAvatar
                    size={window.screen.width >= 1600 ? 35 : 28}
                    avatarId={user?.avatarId}
                    color={user?.defaultAvatarColor ?? undefined}
                    userInitials={user ? `${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}` : undefined}
                />
                {isMenuOpen && (
                    <div
                        className="absolute top-full right-5 w-64 bg-white shadow rounded-xl p-1.5"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="rounded-lg bg-background-light mb-2.5">
                            <div
                                className="px-3.5 pt-4 rounded-lg hover:bg-gray-blue cursor-pointer"
                                onClick={handleProfileClick}
                            >
                                <div className="flex items-center pb-3 qwe">
                                    <UserAvatar
                                        size={36}
                                        avatarId={user?.avatarId}
                                        color={user?.defaultAvatarColor ?? undefined}
                                        className="mr-2"
                                        userInitials={
                                            user
                                                ? `${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}`
                                                : undefined
                                        }
                                    />
                                    <div>
                                        <h4>{`${user?.firstName} ${user?.lastName}`}</h4>
                                        <div className="p3 text-blue-drk">{`@${user?.login}`}</div>
                                    </div>
                                    <div className="ml-auto">
                                        <Icon
                                            icon={Icons.ChevronRight}
                                            width="14px"
                                            height="14px"
                                            color="fill-blue-drk"
                                        />
                                    </div>
                                </div>
                                <div className="flex space-x-0.5">
                                    <AchievementIcons limit={8} achievements={user?.achievements ?? []} />
                                </div>
                                <div className="mt-4 w-full h-0.25 border-b border-gray-blue"></div>
                            </div>
                            <div
                                onClick={handleRatingClick}
                                className="px-3.5 py-4 rounded-lg hover:bg-gray-blue cursor-pointer"
                            >
                                <div className="flex items-center pb-1">
                                    <div className="pr-2">
                                        <Icon icon={Icons.Trophy} width="16px" height="16px" color="fill-blue-drk" />
                                    </div>
                                    <div className="pr-4 text-blue-drk">Баллы</div>
                                    <div className="p1-accent">{user?.rating}</div>
                                </div>
                                <div className="flex items-center">
                                    <div className="pr-2">
                                        <Icon icon={Icons.Bar} width="16px" height="16px" color="fill-blue-drk" />
                                    </div>
                                    <div className="pr-4 text-blue-drk">В общем рейтинге</div>
                                    <div className="p1-accent">{user?.totalRank}</div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="flex items-center px-3.5 py-2.5 cursor-pointer">
                            <div className="pr-2">
                                <Icon icon={Icons.EducationBadge} width="16px" height="16px" />
                            </div>
                            <span className="p1">Сертификаты</span>
                        </div>
                        <div className="flex items-center px-3.5 py-2.5 cursor-pointer">
                            <div className="pr-2">
                                <Icon icon={Icons.ChatSupport} width="16px" height="16px" />
                            </div>
                            <span className="p1">Обратная связь</span>
                        </div> */}
                        <div
                            className="flex items-center px-3.5 py-2.5 rounded-lg cursor-pointer hover:bg-background"
                            onClick={onLogout}
                        >
                            <div className="pr-2">
                                <Icon icon={Icons.AdminMenuIconLogout} width="16px" height="16px" color="fill-black" />
                            </div>
                            <span className="p1">Выход</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
